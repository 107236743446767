import React from 'react'
import create from 'zustand'
export interface SnackbarMessage {
  message: string
  key: number
  type?: 'success' | 'error' | 'info' | 'warning'
}

type SnackbarState = {
  open: boolean
  snackPack: SnackbarMessage[]
  messageInfo?: SnackbarMessage
  autoHideDuration: number | null
}

const DEFAULT_AUTO_HIDE_DURATION = 6000

export const useSnackbarStore = create<SnackbarState>((set, get) => ({
  open: false,
  snackPack: [],
  messageInfo: undefined,
  autoHideDuration: DEFAULT_AUTO_HIDE_DURATION
}))

export const addSnackbar = (
  message: string,
  type?: 'success' | 'error' | 'info' | 'warning',
  autoHideDuration: number = DEFAULT_AUTO_HIDE_DURATION
) => {
  useSnackbarStore.setState((state) => ({
    ...state,
    snackPack: [
      ...state.snackPack,
      { message, key: new Date().getTime(), type }
    ],
    autoHideDuration: autoHideDuration ?? state.autoHideDuration
  }))
}

export const closeSnackbar = () => {
  useSnackbarStore.setState({ open: false, messageInfo: undefined })
}
