import { IReceiptItem } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import {DetectedField, DoubleField, StringField} from '../DetectedField'
import IItemObject from '../IItemObject'
import ModelObject from '../ModelObject'

export type ReceiptItemColumn =
  FieldAndColumnName.ReceiptItemColumn_Name
  | FieldAndColumnName.ReceiptItemColumn_Price
  | FieldAndColumnName.ReceiptItemColumn_Quantity
  | FieldAndColumnName.ReceiptItemColumn_TotalPrice

export class ReceiptItem extends ModelObject implements IItemObject {
  constructor(
    public readonly name?: StringField,
    public readonly quantity?: DoubleField,
    public readonly price?: DoubleField,
    public readonly totalPrice?: DoubleField
  ) {
    super()
  }

  copy({
    name = this.name,
    quantity = this.quantity,
    price = this.price,
    totalPrice = this.totalPrice
  }): ReceiptItem {
    return new ReceiptItem(name, quantity, price, totalPrice)
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }

  addColumn(
    gridColumnName: FieldAndColumnName,
    newValue: FormGridRowValue,
    modifiedBy: string
  ): ReceiptItem {
    switch(gridColumnName) {
      case FieldAndColumnName.ReceiptItemColumn_Name:
        return this.copy({ name: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.ReceiptItemColumn_Price:
        return this.copy({ price: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.ReceiptItemColumn_Quantity:
        return this.copy({ quantity: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.ReceiptItemColumn_TotalPrice:
        return this.copy({ totalPrice: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      default:
        return this
    }
  }

  deleteColumn(gridColumn: FieldAndColumnName): ReceiptItem {
    return this.copy({ [gridColumn]: null })
  }

  fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
    switch (column) {
      case FieldAndColumnName.ReceiptItemColumn_Name:
        return this.name
      case FieldAndColumnName.ReceiptItemColumn_Quantity:
        return this.quantity
      case FieldAndColumnName.ReceiptItemColumn_Price:
        return this.price
      case FieldAndColumnName.ReceiptItemColumn_TotalPrice:
        return this.totalPrice
      default:
        return undefined
    }
  }

  // toGridRow(id: number) {
  //   return {
  //     id,
  //     [FieldAndColumnName.ReceiptItemColumn_Name]: this.name?.parsedValue,
  //     [FieldAndColumnName.ReceiptItemColumn_Quantity]: this.quantity?.parsedValue,
  //     [FieldAndColumnName.ReceiptItemColumn_Price]: this.price?.parsedValue,
  //     [FieldAndColumnName.ReceiptItemColumn_TotalPrice]: this.totalPrice?.parsedValue
  //   }
  // }

  toJson(): IReceiptItem {
    return {
      Name: this.name?.toModelKeyValue(),
      Quantity: this.quantity?.toModelKeyValue(),
      Price: this.price?.toModelKeyValue(),
      TotalPrice: this.totalPrice?.toModelKeyValue()
    } as IReceiptItem
  }
}


