import {
  ChartOfAccounts360,
  ChartOfAccounts360PageTable,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import type { GridCellParams, GridColDef } from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React from 'react'

import { ChartOfAccounts360PageToTableData } from './ChartOfAccounts360PageToTableData'

interface IChartOfAccounts360EditorProps {
  chartOfAccounts360: ChartOfAccounts360
}

export const ChartOfAccounts360Editor = (
  props: IChartOfAccounts360EditorProps
) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function renderLongTextCell(params: GridCellParams) {
    return (
      <span
        title={params.value as string}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {params.value}
      </span>
    )
  }

  const toColumns = (
    renderLongTextCell: (params: GridCellParams) => JSX.Element
  ): GridColDef[] => {
    return [
      {
        field: 'id',
        type: 'string',
        headerName: 'Page',
        width: 80,
        editable: false
      },
      {
        field: FieldAndColumnName.ChartOfAccounts360PagesEditor_Code,
        type: 'string',
        headerName: 'Code',
        width: 80,
        editable: true
      },
      {
        field: FieldAndColumnName.ChartOfAccounts360PagesEditor_Name,
        type: 'string',
        headerName: 'Name',
        width: 200,
        editable: true,
        renderCell: (params: GridCellParams) => renderLongTextCell(params)
      },
      {
        field: FieldAndColumnName.ChartOfAccounts360PagesEditor_Probability,
        type: 'number',
        headerName: 'Probability',
        width: 100,
        editable: true,
        headerAlign: 'left',
        align: 'left'
      }
    ]
  }

  const columns: GridColDef[] = toColumns(renderLongTextCell)
  const rows = ChartOfAccounts360PageToTableData.toRows(
    props.chartOfAccounts360.chartOfAccountsPages
      .chartOfAccounts360PageTableRows
  )

  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={'Code'}
            defaultValue={props.chartOfAccounts360.code}
            fullWidth
            multiline
            placeholder="N/A"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={'Name'}
            defaultValue={props.chartOfAccounts360.name}
            fullWidth
            multiline
            placeholder="N/A"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={'Probability'}
            defaultValue={props.chartOfAccounts360.probability}
            fullWidth
            multiline
            placeholder="N/A"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            disableColumnMenu
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar
            }}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default ChartOfAccounts360Editor
