import {List, Map} from 'immutable'
import {Line} from '../ocr/Line'
import {DetectedField} from './DetectedField'

export interface LinesUnderField {
  field: DetectedField,
  // merged line from different pages (lines can't merge cross page as not make sense for geometry)
  mergedLineFromPages: List<Line>
}


export class ModelSpecificLineItems {
  private readonly fieldIdMap: Map<string, LinesUnderField>
  constructor(
    readonly mergedLines: List<LinesUnderField>
  ) {
    this.fieldIdMap = Map(mergedLines.map(fieldAndLines => [fieldAndLines.field.id, fieldAndLines]))
  }

  find(fieldId: string): LinesUnderField | undefined {
    return this.fieldIdMap.get(fieldId)
  }

  linesIdByFieldIds(fieldIds: string[]): string[] {
    return fieldIds.flatMap(fieldId => {
      const f = this.find(fieldId)
      if (f) {
        return f.mergedLineFromPages.map(line => line.id).toArray()
      } else {
        return []
      }
    })
  }

  static empty(): ModelSpecificLineItems {
    return new ModelSpecificLineItems(List())
  }

  static fromFields(fields: List<DetectedField>): ModelSpecificLineItems {
    const lines = fields.map(field => {
      return {
        field,
        mergedLineFromPages: field.toMergedLines()
      }
    })
    return new ModelSpecificLineItems(lines)
  }

  pageIndexes(): number[] {
    return this.mergedLines.flatMap(line =>
      line.mergedLineFromPages.map(l => l.page)
    ).toSet().toArray()
  }

}
