import { BaseShape } from './Shape'
import { CreateType, DrawStyle, Point} from './Types'

export class Rect extends BaseShape {

	readonly ctrlPointSize = 6
	constructor(
		public shapeId: string,
		public shapeIndex: number,
		public coordinates: Point[],
		public drawStyle: DrawStyle,
		public tagName: string,
		public tagId: string,
		public shapeType: CreateType = CreateType.Rect
	) {
		super(shapeId, shapeIndex, coordinates, drawStyle, tagName, tagId, shapeType)
	}

	get ctrlsData(): Point[] {
		const [[x0, y0], [x1, y1]] = this.coordinates
		return [
			[x0, y0],
			[x0 + (x1 - x0) / 2, y0],
			[x1, y0],
			[x1, y0 + (y1 - y0) / 2],
			[x1, y1],
			[x0 + (x1 - x0) / 2, y1],
			[x0, y1],
			[x0, y0 + (y1 - y0) / 2]
		]
	}

	getAnnotationCoor(){
		return {
			xmin: this.coordinates[0][0],
			ymin: this.coordinates[0][1],
			xmax: this.coordinates[1][0],
			ymax: this.coordinates[1][1]
		}
	}

	getActiveCtrlPointIndex(percentageMousePoint: Point, coordinateConverter: (percentageMousePoint: Point) => Point){
		const ctrlIndex = this.ctrlsData.findIndex(ctrlPoint => {
			const [x, y] = coordinateConverter(percentageMousePoint)
			const [cx, cy] = coordinateConverter(ctrlPoint)
			const xmin = cx - this.ctrlPointSize
			const xmax = cx + this.ctrlPointSize
			const ymin = cy - this.ctrlPointSize
			const ymax = cy + this.ctrlPointSize
			return x > xmin && x < xmax && y > ymin && y < ymax
		})

		this.activeCtrlPointIndex = ctrlIndex
		return ctrlIndex
	}

	create(percentageMousePoint: Point){
		if(this.isActive){
			const [x, y] = percentageMousePoint
			this.coordinates.splice(1,1, [x, y])
		}
	}

	resize(percentageMousePoint: Point){
		if(this.activeCtrlPointIndex > -1){
			const [[x0, y0], [x1, y1]] = this.coordinates
			const [x, y] = percentageMousePoint
			let coordinates: Point[] = []
			switch(this.activeCtrlPointIndex){
				case 0:
					coordinates = [[x, y], [x1, y1]]
					break
				case 1:
					coordinates = [[x0, y], [x1, y1]]
					break
				case 2:
					coordinates = [[x0, y], [x, y1]]
					break
				case 3:
					coordinates = [[x0, y0], [x, y1]]
					break
				case 4:
					coordinates = [[x0, y0], [x, y]]
					break
				case 5:
					coordinates = [[x0, y0], [x1, y]]
					break
				case 6:
					coordinates = [[x, y0], [x1, y]]
					break
				case 7:
					coordinates = [[x, y0], [x1, y1]]
					break
				default:
					break
			}
			if(this.isCoordinateValid(coordinates)){
				this.coordinates = coordinates
			}
		}
	}

	isValidShape(){
		return this.isCoordinateValid(this.coordinates)
	}

	isCoordinateValid(coordinates: Point[]){
		const [[x0, y0], [x1, y1]] = coordinates
		return Math.abs(x0 - x1) * 100 > 1 && Math.abs(y0 - y1) * 100 > 1
	}

	isMouseOver(percentageMousePoint: Point){
		const [x, y] = percentageMousePoint
		const [[x0, y0], [x1, y1]] = this.coordinates
		return x > x0 && x < x1 && y > y0 && y < y1
	}

	sortCoordinates(){
		const [[x0, y0], [x1, y1]] = this.coordinates
		this.coordinates = [[Math.min(x0, x1), Math.min(y0, y1)], [Math.max(x0, x1), Math.max(y0, y1)]]
	}

	drawShape(ctx: CanvasRenderingContext2D, coordinateConverter: (percentageMousePoint: Point) => Point){
		const [[x0, y0], [x1, y1]] = this.coordinates.map((point) => coordinateConverter(point))
		ctx.save()
		ctx.fillStyle = this.drawStyle.fillStyle
		ctx.lineWidth = this.drawStyle.lineWidth
		ctx.strokeStyle = this.drawStyle.strokeStyle
		const w = x1 - x0
		const h = y1 - y0
		ctx.strokeRect(x0, y0, w, h)
		if (!this.isCreating) ctx.fillRect(x0, y0, w, h)
		ctx.restore()
	}

	drawCtrlPoints(ctx: CanvasRenderingContext2D, coordinateConverter: (percentageMousePoint: Point) => Point){
		this.ctrlsData.forEach(point => {
			this.drawCtrlPoint(coordinateConverter(point), ctx)
		})
	}

	private drawCtrlPoint(point: Point, ctx: CanvasRenderingContext2D){
		const [x, y] =  point
		const size = this.ctrlPointSize
		ctx.save()
		ctx.shadowColor ='black'
		ctx.shadowBlur = 2
		ctx.fillStyle = '#fff'
		ctx.fillRect(x - size / 2, y - size / 2, size, size)
		ctx.restore()
	}
}

