import { OcrDocument } from '../../OcrDocument'
import { IAtoQuarterlyPaygInstalmentNoticeModel } from '../../types/ISmartDocsResult'
import { BusinessModelMapper } from '../BusinessModelMapper'
import { AtoQuarterlyPaygInstalmentNotice } from './AtoQuarterlyPaygInstalmentNotice'

export class AtoQuarterlyPaygInstalmentNoticeMapper extends BusinessModelMapper {
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IAtoQuarterlyPaygInstalmentNoticeModel
  ) {
    super(document)
  }

  fromJson(): AtoQuarterlyPaygInstalmentNotice {
    const atoQuarterlyPaygInstalmentNoticeModel = this.jsonModel
    return new AtoQuarterlyPaygInstalmentNotice(
      atoQuarterlyPaygInstalmentNoticeModel.Name,
      atoQuarterlyPaygInstalmentNoticeModel.Version,
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.RecipientAndAddress.Recipient),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.RecipientAndAddress.Address),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.DocumentId),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.Abn),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.Quarter),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.Year),
      this.mapDateBlock(atoQuarterlyPaygInstalmentNoticeModel.FormDueOn),
      this.mapDateBlock(atoQuarterlyPaygInstalmentNoticeModel.PaymentDueOn),
      this.mapDollarBlock(atoQuarterlyPaygInstalmentNoticeModel.InstalmentAmount),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.AtoCode),
      this.mapTextBlock(atoQuarterlyPaygInstalmentNoticeModel.EftCode)
    )
  }
}
