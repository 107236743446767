import ModelObject from 'domain/businessModel/ModelObject'
import { FormGridRowId } from 'utils/DataTypeMapper'
import { FieldAndColumnName, FormFieldValidationResult } from './FieldValidatorDef'
import { FormFieldValue, IFieldValidator } from './IFieldValidator'
import { IValidatorFactory } from './IValidatorFactory'

export class FormFieldValidator implements IFieldValidator {
    static emptyValidatorArray = []
    static emptyGridCellInitialRows = []
    static fieldName = FieldAndColumnName.INVALID_FIELD_NAME

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return FormFieldValidator.fieldName
    }

    static generateErrorKey(fieldName: string, fieldId: string | number | null = null) {
        return fieldId !== null ? `${fieldName}${fieldId}` : fieldName
    }

    static generateValidators(validatorFactory: IValidatorFactory<FormFieldValidator>, fieldName: FieldAndColumnName[]) {
        return fieldName.map(name => validatorFactory(name))
    }
}
