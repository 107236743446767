import {
  DateField,
  FieldAndColumnName,
  FormControlContext,
  FormFieldValidator,
  FormGridRowValue
} from '@bgl/textract-business-model-editor'
import { ClickAwayListener, TextField } from '@material-ui/core'
import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import MomentAdapter from '@material-ui/pickers/adapter/moment'
import { debounce, has } from 'lodash'
import moment from 'moment'
import React, { FunctionComponent, useContext, useMemo, useState } from 'react'

import { EditorFieldEvents } from './EditorFieldEvents'

export const FormDateField: FunctionComponent<{
  id: FieldAndColumnName
  label: string
  events: EditorFieldEvents
  field?: DateField
  className?: string
  readonlyMode?: boolean
  allowBeyondToday?: boolean
}> = ({
  id,
  label,
  events,
  field,
  className,
  readonlyMode,
  allowBeyondToday
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const {
    // handleInputValue,
    errors
    // record
  } = useContext(FormControlContext)
  const errorKey = useMemo(
    () => FormFieldValidator.generateErrorKey(id, field?.id || ''),
    [id, field]
  )
  const debouncedOnChange = debounce(
    (value: FormGridRowValue) => events.onValueChanged?.(id, value, field),
    500
  )
  const defaultValue =
    field?.parsedValue !== undefined ? field.parsedValue.toDate() : null
  const handleClickAway = () => {
    if (isOpen) {
      setOpen(false)
    }
  }

  return !readonlyMode ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <LocalizationProvider
          dateAdapter={MomentAdapter}
          dateLibInstance={moment}
          locale={'en-au'}
        >
          <DatePicker
            inputFormat="DD/MM/YYYY"
            label={label}
            maxDate={!allowBeyondToday ? moment() : undefined}
            value={defaultValue}
            open={isOpen}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onChange={(date: moment.Moment | null) => {
              // NOTE: Do not call handleInputValue() to immediately validate new value as onValueChanged() trigger
              // a new instance of model been injected to model editor, and useFormControl() validates all fields.
              // I intend to leave handleInputValue() for later use, redux-form might be used if performance is concern.
              // handleInputValue(id, date, field?.id || "", record)
              debouncedOnChange(date)
            }}
            renderInput={(params) => (
              <TextField
                className={className}
                fullWidth
                id={id}
                onFocus={() => events.onFieldFocus?.(id, field)}
                error={has(errors, errorKey)}
                helperText={errors[errorKey]}
                defaultValue={'N/A'}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  ) : (
    <TextField
      className={className}
      fullWidth
      id={id}
      label={label}
      onFocus={() => events.onFieldFocus?.(id, field)}
      error={has(errors, errorKey)}
      helperText={errors[errorKey]}
      value={moment(defaultValue).format('DD/MM/YYYY') || 'N/A'}
      disabled={readonlyMode}
    />
  )
}
