import { List } from 'immutable'
import _ from 'lodash'
import { OcrDocument } from '../../OcrDocument'
import {
  IBusinessModelTypeModel,
  IBusinessModelTypePage,
  ISplitDocRange,
  ISplitPageDetection
} from '../../types/ISmartDocsResult'
import { BusinessModelMapper } from '../BusinessModelMapper'
import {BusinessModelType, SplitDocRange, SplitPageDetection} from './BusinessModelType'
import { BusinessModelTypePageTable, BusinessModelTypePageTableRow } from './BusinessModelTypePageTable'

export class BusinessModelTypeMapper extends BusinessModelMapper {
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IBusinessModelTypeModel
  ) {
    super(document)
  }

  fromJson(): BusinessModelType {
    const businessModelTypeModel = this.jsonModel
    const businessModelTypePageTable = this.mapTable(businessModelTypeModel.Pages)
    const splitPageDetections = this.mapSplitPageDetections(businessModelTypeModel.SplitPageDetections)
    const splitRanges:List<SplitDocRange> =this.mapSplitDoc(businessModelTypeModel.SplitRanges)
    return new BusinessModelType(
      businessModelTypeModel.Version,
      businessModelTypeModel.ModelName,
      businessModelTypeModel.Probability,
      businessModelTypePageTable,
      splitPageDetections,
      splitRanges
    )
  }

  mapPageRow(page: IBusinessModelTypePage): BusinessModelTypePageTableRow | undefined {
    return new BusinessModelTypePageTableRow(
      page.Id,
      page.ModelName,
      page.Probability
    )
  }

  mapTable(pages: IBusinessModelTypePage[]): BusinessModelTypePageTable {
    const rows = _.compact((pages || []).map(this.mapPageRow))
    return new BusinessModelTypePageTable(
      List(rows)
    )
  }

  mapSplitPageDetections(splitPageDetections: ISplitPageDetection[]): List<SplitPageDetection> {
    const rows = _.compact((splitPageDetections || []).map(row => {return new SplitPageDetection(row.PageNumber, row.DocumentSplitType, row.Probability)} ))
    return List(rows)
  }

  mapSplitDoc(splitDoc: ISplitDocRange[]): List<SplitDocRange> {
    const rows = _.compact((splitDoc || []).map(row => {return new SplitDocRange(row.Start, row.End)} ))
    return List(rows)
  }
}
