import {List} from "immutable"
import {
  IDocument
} from "./types/ISmartDocsResult"
import {PageVisitor} from "./DocumentVisitor"
import {JsonToOcrDocumentConverter} from "./JsonToOcrDocumentConverter"
import {ElementLocator} from "./locator/ElementLocator"
import {Page} from "./ocr/Page"

export class OcrDocument {
  public readonly elementLocator: ElementLocator
  constructor(
    public readonly pages: List<Page>
  ) {
    this.elementLocator = new ElementLocator(pages)
  }

  accept<R>(visitor: PageVisitor<R>): List<R> {
    return this.pages.map(page => visitor.visitPage(page))
  }

  static fromJson(document: IDocument): OcrDocument {
    return new JsonToOcrDocumentConverter(document).convert()
  }
}


