import * as _ from 'lodash'
import * as React from 'react'
import { ReactNode } from 'react'

export const emailRegex = (email: string): boolean => {
  // eslint-disable-next-line max-len
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}

export const urlRegex = (url: string): boolean => {
  return /^(?:http|https):\/\/[\w.-]+(?:\.[\w-]+)+[\w\-.,@?^=%&:;/~\\+#]+$/.test(
    url
  )
}

export const timeRegex = (time: string): boolean => {
  return /([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(time)
}

export const isEmailValid = (
  username: string | undefined,
  isFailed: boolean = false
): boolean => {
  return !isFailed && (username === undefined || emailRegex(username))
}

export const emailHelpText = (
  username: string | undefined,
  isFailed: boolean = false
): ReactNode => {
  if (username === undefined || isEmailValid(username, isFailed)) return null
  return username === '' ? 'Email is Required' : 'Invalid Email Address'
}

export const usernameSignUpHelpText = (
  username: string | undefined,
  isUsernameExist1: boolean,
  isUsernameExist2: boolean,
  isUsernameInvalid: boolean
): ReactNode => {
  if (isUsernameExist1) return 'Email is registered, please sign in'
  if (isUsernameExist2) return 'Email is registered'
  if (isUsernameInvalid) return 'Invalid Email Address'
  return emailHelpText(username, isUsernameExist1 || isUsernameExist2)
}

export const usernameSignInHelpText = (
  username: string | undefined,
  isNotRegistered: boolean
): ReactNode => {
  if (isNotRegistered) return 'Email is not registered, please sign up'
  return emailHelpText(username, isNotRegistered)
}

export const usernameForgotHelpText = (
  username: string | undefined,
  isNotRegistered: boolean,
  isLimitExceeded: boolean,
  isNotAuthorized: boolean
): ReactNode => {
  if (isNotRegistered) return 'Email is not registered, please sign up'
  if (isLimitExceeded)
    return 'Attempt limit exceeded, please try again after 2 minutes.'
  if (isNotAuthorized)
    return 'Password cannot be reset, please sign in with the initial password sent to your mobile or email.'
  return emailHelpText(username, isNotRegistered || isLimitExceeded)
}

export const usernameConfirmHelpText = (
  isNotVerified: boolean,
  isConfirmed: boolean
): ReactNode => {
  if (isConfirmed) return 'User is already confirmed, please sign in.'
  if (isNotVerified) return 'Email is not confirmed'
  return null
}

export const isMobileValid = (
  mobile: string | undefined,
  isLimitExceeded: boolean = false
): boolean => {
  return (
    !isLimitExceeded && (!mobile || mobile === '04' || /^04\d{8}$/.test(mobile))
  )
}

export const mobileHelpText = (
  mobile: string | undefined,
  isLimitExceeded: boolean = false
): ReactNode => {
  if (isLimitExceeded)
    return 'Attempt limit exceeded, please try again after 2 minutes.'
  return (
    !isMobileValid(mobile, isLimitExceeded) &&
    'Invalid Mobile Number. (eg. 0412345678)'
  )
}

export const isSignUpPasswordValid = (
  password: string | undefined
): boolean => {
  return (
    password === undefined ||
    (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]).{10,32}$/.test(
      password
    ) &&
      !/(\s)/.test(password))
  )
}

export const signUpPasswordHelpText = (
  password: string | undefined
): ReactNode => {
  if (password === undefined || isSignUpPasswordValid(password)) return null
  return password ? (
    <span style={{ display: 'block' }}>
      <span style={{ display: /\d/.test(password) ? 'none' : 'block' }}>
        At least 1 Number
      </span>
      <span style={{ display: /[a-z]/.test(password) ? 'none' : 'block' }}>
        At least 1 Lowercase
      </span>
      <span style={{ display: /[A-Z]/.test(password) ? 'none' : 'block' }}>
        At least 1 Uppercase
      </span>
      <span
        style={{
          display: /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/.test(password)
            ? 'none'
            : 'block'
        }}
      >
        At least 1 Special Character
        {` ^$*.[]{}()?"!@#%&/\\,><':;|_~\`=+-`}{' '}
      </span>
      <span style={{ display: /(\s)/.test(password) ? 'block' : 'none' }}>
        No white space
      </span>
      <span style={{ display: /^.{10,32}$/.test(password) ? 'none' : 'block' }}>
        At least 10 characters, but no more than 32
      </span>
    </span>
  ) : (
    'Password is Required'
  )
}

export const isSignInPasswordValid = (
  password: string | undefined,
  passwordMismatch: boolean,
  passwordExpired: boolean,
  isLimitExceeded: boolean
): boolean => {
  return (
    !passwordMismatch &&
    !passwordExpired &&
    !isLimitExceeded &&
    (password === undefined || password !== '')
  )
}

export const signInPasswordHelpText = (
  password: string | undefined,
  passwordMismatch: boolean,
  passwordExpired: boolean,
  isLimitExceeded: boolean
): ReactNode => {
  if (passwordMismatch) return 'Invalid password provided'
  if (passwordExpired) return 'Temporary password has expired'
  if (isLimitExceeded)
    return 'Attempt limit exceeded, please try again after 2 minutes'
  if (password === '') return 'Password is Required'
  return null
}

export const isConfirmPasswordValid = (
  newPassword: string | undefined,
  confirmPassword: string | undefined
): ReactNode => {
  return newPassword
    ? confirmPassword === undefined || confirmPassword === newPassword
    : true
}

export const confirmPasswordHelpText = (
  newPassword: string | undefined,
  confirmPassword: string | undefined,
  isLimitExceeded: boolean
): ReactNode => {
  if (isLimitExceeded)
    return 'Attempt limit exceeded, please try again after 2 minutes.'
  return (
    !isConfirmPasswordValid(newPassword, confirmPassword) &&
    'Password does not match'
  )
}

export const isNameValid = (name: string | undefined): boolean => {
  return name === undefined || _.trim(name) !== ''
}

export const nameHelpText = (name: string | undefined): ReactNode => {
  return name === undefined || isNameValid(name) ? null : 'Name is Required'
}

export const isCodeValid = (
  code: string | undefined,
  isFailed: boolean
): boolean => {
  return !isFailed && (code === undefined || _.trim(code) !== '')
}

export const codeHelpText = (
  code: string | undefined,
  codeMismatch: boolean,
  codeExpired: boolean
): ReactNode => {
  if (codeMismatch) return 'Invalid confirmation code provided'
  if (codeExpired) return 'Expired confirmation code provided'
  return code === ''
    ? 'Confirmation Code is Required'
    : 'Please check email for Confirmation Code'
}

export const authenticationCodeHelpText = (
  code: string | undefined,
  codeMismatch: boolean,
  codeExpired: boolean,
  isSMS: boolean
): ReactNode => {
  if (codeMismatch) return 'Invalid authentication code provided'
  if (codeExpired) return 'Expired authentication code provided'
  return code === ''
    ? 'Authentication Code is Required'
    : isSMS
    ? 'Please check SMS for Authentication Code.'
    : 'Please check authenticator app for Authentication Code.'
}
