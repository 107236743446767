import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { trackPage } from '../../services/GoogleAnalytics'

const PublicRouter = () => {
  const location = useLocation()

  React.useEffect(() => {
    trackPage(location.pathname)
  }, [location])

  return <Outlet />
}

export default PublicRouter
