import TrainingReport, {
  DetailObjectDetectionMetricsType,
  Evaluation,
  Metrics,
  OverallObjectDetectionMetricsType,
  TrainReportDTOObjectDetection
} from './TrainingReport'

export default class ObjectDetectionReport extends TrainingReport {
  constructor(
    public id: string,
    public datetime: string,
    public projectId: string,
    public epoch: number,
    public details: DetailObjectDetectionMetricsType[],
    public overallMetrics: OverallObjectDetectionMetricsType
  ) {
    super(id, datetime, projectId, epoch)
  }

  static fromJson(
    trainingReportDTO: TrainReportDTOObjectDetection
  ): ObjectDetectionReport {
    const { id, datetime, projectId, epoch, details, ...overallMetrics } =
      trainingReportDTO
    return new ObjectDetectionReport(
      id,
      datetime,
      projectId,
      epoch,
      details,
      overallMetrics
    )
  }

  getMainMetrics() {
    const metrics = this.overallMetrics.mAPpt5
    return {
      name: Metrics.MAPpt5,
      value: metrics,
      score: (metrics * 100).toFixed(1),
      evaluation: this.getEvaluation(metrics)
    }
  }

  generateOverallTableColumns = () => {
    const ObjectDetectionOverallTableHeader = [
      'mAPpt5',
      'precision',
      'recall',
      'mAPpt5pt95',
      'boxLoss',
      'clsLoss',
      'objLoss'
    ]
    return this.generateColumn(ObjectDetectionOverallTableHeader)
  }

  generateOverallTableRow = () => {
    const { mAPpt5, precision, recall, mAPpt5pt95, clsLoss, boxLoss, objLoss } =
      this.overallMetrics
    return [
      {
        id: this.epoch.toString(),
        mAPpt5: mAPpt5.toFixed(1),
        precision: precision.toFixed(1),
        recall: recall.toFixed(1),
        mAPpt5pt95: mAPpt5pt95.toFixed(1),
        boxLoss: boxLoss.toFixed(3),
        clsLoss: clsLoss.toFixed(3),
        objLoss: objLoss.toFixed(3)
      }
    ]
  }

  generateDetailTableColumns = () => {
    const ObjectDetectionDetailTableHeader = [
      'mAPpt5',
      'precision',
      'recall',
      'mAPpt5pt95',
      'f1'
    ]
    return this.generateColumn(ObjectDetectionDetailTableHeader)
  }

  generateDetailTableRows = (tagIdToName: (tagId: string) => string) => {
    return this.details.map((detail) => {
      const { mAPpt5, mAPpt5pt95, recall, precision, f1, labelId } = detail
      return {
        id: labelId,
        tagName: tagIdToName(labelId),
        mAPpt5: mAPpt5.toFixed(3),
        precision: precision.toFixed(3),
        recall: recall.toFixed(3),
        mAPpt5pt95: mAPpt5pt95.toFixed(3),
        f1: f1.toFixed(3)
      }
    })
  }

  private getEvaluation(metrics: number) {
    //TODO: Evaluation algorithm need to be discussed
    if (metrics > 0.9) return Evaluation.Good
    if (metrics <= 0.9 && metrics > 0.6) return Evaluation.Fair
    if (metrics <= 0.6) return Evaluation.Poor
    return Evaluation.NotAvailable
  }
}
