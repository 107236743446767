import {Geometry} from "./OcrElement"
import {List} from "immutable"
import {WordVisitor} from "../DocumentVisitor"
import {ILineBlock, IRelationship, IWordBlock} from "../types/ISmartDocsResult"
import {Word} from "./Word"
import {TextElement} from "./TextElement"

export class Line extends TextElement {
  constructor(
    id: string,
    page: number,
    confidence: number,
    geometry: Geometry,
    text: string,
    words: List<Word>,
  ) {
    super(id, page, confidence, geometry, text, words)
  }

  accept<R>(visitor: WordVisitor<R>): List<R> {
    return this.words.map(w => visitor.visitWord(w))
  }

  static fromJson(line: ILineBlock, childWordsFinder: (relationships: IRelationship[]) => List<IWordBlock>): Line {
    const words = childWordsFinder(line.Relationships).map(word => Word.fromJson(word))
    const text = words.reduce((aText, word) => aText + ' ' + word.text, '')
    return new Line(
      line.Id,
      line.Page,
      line.Confidence,
      Geometry.fromJson(line.Geometry),
      text,
      words,
    )
  }

}
