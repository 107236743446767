/* eslint-env browser */

import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons'
import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Fade, Paper, Popper, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { PrimaryLink } from '../../../assets/StyledLink'

const useStyles = makeStyles((theme) => ({
  copyIcon: {
    cursor: 'pointer',
    color: '#c64f9d',
    '&:hover': {
      color: 'rgba(198,79,157,0.85)'
    }
  }
}))

const CircleWithStepNumber = (step) => (
  <div
    style={{
      width: '70px',
      height: '70px',
      borderRadius: '50%',
      backgroundColor: '#761bcb',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontSize: '35px',
      fontWeight: '600',
      paddingTop: '5px',
      marginBottom: '2.5rem'
    }}
  >
    {step}
  </div>
)

function TryItYourself() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  const list = [
    'Person Name',
    'Organisation Name',
    'Street Address',
    'Email Address',
    'Phone Number',
    'Date',
    'Bank Account Number',
    'Reference Number',
    'Financial Amounts',
    'URL',
    'Australian Company Number (ACN)',
    'Australian Business Number (ABN)',
    'Australian Tax File Number (TFN)'
  ]

  const emailSubject = 'SmartDocs Redaction Trial'

  const emailBody =
    /* eslint-disable-next-line max-len */
    "Hi%20Smart%20Docs%20Team,%0A%0APlease%20redact%20the%20following%20from%20my%20files%20(***Action%20required***:%20remove%20the%20data%20types%20for%20which%20no%20redaction%20is%20needed):%0A%0A-%20Person%20Name%0A%0A-%20Organisation%20Name%0A%0A-%20Street%20Address%0A%0A-%20Email%20Address%0A%0A-%20Phone%20Number%0A%0A-%20Date%0A%0A-%20Bank%20Account%20Number%0A%0A-%20Reference%20Number%0A%0A-%20Financial%20Amounts%0A%0A-%20URL%0A%0A-%20Australian%20Company%20Number%20(ACN)%0A%0A-%20Australian%20Business%20Number%20(ABN)%0A%0A-%20Australian%20Tax%20File%20Number%20(TFN)%0A%0APlease%20redact%20the%20above%20data%20using%20the%20following%20method%20(***Action%20required***:%20keep%20only%20the%20redaction%20method%20which%20you'd%20like%20to%20apply%20and%20remove%20the%20other%20two):%0A%0A-%20Black%20out%0A%0A-%20Mask%20with%20X%0A%0A-%20Pseudonymisation%0A%0AThanks."

  const emailLink =
    /* eslint-disable-next-line max-len */
    'mailto:trial@redact.doc.com.ai?subject=' +
    emailSubject +
    '&body=' +
    emailBody

  const lines = decodeURIComponent(emailBody).split('\n')

  const handleCopyToClipboard = () => {
    const body = decodeURIComponent(emailBody)
    navigator.clipboard.writeText(body).then(() => {
      setOpen(false)
      setAnchorEl(null)
    })
  }

  const renderConfigList = () => {
    return list.map((item, index) => {
      return (
        <li key={index}>
          <FontAwesomeIcon
            icon={faShieldCheck}
            style={{ marginRight: '10px' }}
            color={'#c64f9d'}
          />
          <span style={{ fontSize: '14px', lineHeight: '26px' }}>{item}</span>
        </li>
      )
    })
  }

  const renderEmailLink = () => {
    return (
      <a href={emailLink} style={{ color: 'blue' }}>
        trial@redact.doc.com.ai
      </a>
    )
  }

  return (
    <section className="try-it-yourself">
      <div className="layout">
        <div>
          <h1>Try It Yourself!</h1>
          <p className="tryItSubtitle">
            Give it a go by emailing your own files to {renderEmailLink()} and
            see how SmartDocs Redaction can assist you.
          </p>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              window.location.href = emailLink
            }}
          >
            Email Us
          </Button>
        </div>
        <div className="steps">
          <div className="step">
            {CircleWithStepNumber('1')}
            <h3>Attach Files</h3>
            <p>
              We support a maximum 50 pages for each attachment. Please try
              splitting files if they exceed the limit.
            </p>
            <br />
            <p>
              For each email account, we allow up to 5 files for free trail.
              Please{' '}
              <a href="/#/contact-us" target="_blank" rel="noreferrer">
                Contact Us
              </a>{' '}
              if you have more files to try.
            </p>
            <br />
            <p
              style={{
                color: '#414042',
                fontSize: '14px'
              }}
            >
              * SmartDocs AI Studio will not retain your files uploaded here.
              Please refer to our{' '}
              <PrimaryLink
                target="_blank"
                href="https://www.bglcorp.com/cloud-subscription-agreement/"
                style={{ color: 'blue', fontSize: '14px' }}
              >
                Terms and Conditions{' '}
              </PrimaryLink>
              for more details.
            </p>
          </div>

          <div className="step">
            {CircleWithStepNumber('2')}
            <h3>Configure What to Redact</h3>
            <p>
              List the data types you wish to redact and the redaction method
              you wish to apply in the email. Below are all data types we
              support at the current stage:
            </p>
            <ul>{renderConfigList()}</ul>
          </div>

          <div className="step">
            {CircleWithStepNumber('3')}
            <h3>Send Email</h3>
            <p>
              Send your files to {renderEmailLink()} for redaction. Once the
              files finish processing, we will send the redacted copy by reply
              email.
            </p>
            <Button
              variant={'contained'}
              color={'secondary'}
              onClick={handleClick}
            >
              Show Email Template
            </Button>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              transition
              className={classes.popper}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper
                    variant={'elevation'}
                    elevation={2}
                    style={{
                      padding: '1rem',
                      maxWidth: '400px',
                      maxHeight: '600px',
                      overflow: 'auto'
                    }}
                  >
                    <Tooltip title="Copy to Clipboard">
                      <span>
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={handleCopyToClipboard}
                          className={classes.copyIcon}
                        />
                      </span>
                    </Tooltip>
                    <p>
                      {lines.map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== lines.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TryItYourself
