import { createStyles, makeStyles } from '@material-ui/core/styles'

const drawerWidth = 80
const menuItemWidth = 55
const menuItemHeight = 55
const borderRadius = 55

export const useDashboardStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.light,
      height: '4rem',
      justifyContent: 'space-around'
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: `${drawerWidth} !important`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    drawerButton: {
      position: 'absolute',
      left: theme.spacing(0.5),
      '&:hover': {
        color: theme.palette.grey['500']
      }
    },
    projectTypeChip: {
      marginLeft: '0.5rem',
      fontSize: '0.9rem'
    },
    projectTypeTooltip: {
      marginLeft: '0.2rem',
      fontSize: '0.9rem'
    },
    verticalDivider: {
      height: '60%',
      margin: theme.spacing(0, 1, 0, 1)
    },
    menuList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    menuItem: {
      // width: menuItemWidth,
      // height: menuItemWidth,
      // borderRadius: menuItemWidth,
      // margin: `${theme.spacing(1.5, 0, 2.75)} !important`,
      // padding: `${theme.spacing(1, 2)} !important`,
      // color: 'white !important',
      // display: 'flex !important',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // position: 'relative',
      // '&$selected': {
      //   backgroundColor: theme.palette.primary['50'],
      //   '& .MuiListItemIcon-root': {
      //     color: theme.palette.primary.main
      //   }
      // },
      // '&$selected:hover': {
      //   backgroundColor: theme.palette.common.white
      // },
      // '&:hover': {
      //   backgroundColor: theme.palette.primary['200'],
      //   '& .MuiListItemIcon-root': {
      //     color: theme.palette.common.white
      //   }
      // }
    },
    navLinkContainer: {
      padding: 0
    },
    navLink: {
      width: menuItemWidth,
      height: menuItemHeight,
      borderRadius: borderRadius,
      margin: `${theme.spacing(1.5, 0, 2.75)} !important`,
      padding: `${theme.spacing(1, 2)} !important`,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary['200']
      }
    },
    activeNavLink: {
      backgroundColor: theme.palette.primary['50'],
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary['50']
      }
    },
    navLinkDisabled: {
      opacity: 0.5,
      '&:hover': {
        cursor: 'not-allowed'
      }
    },
    ripple: {},
    selected: {},
    menuItemIcon: {
      color: theme.palette.common.white,
      justifyContent: 'center'
    },
    menuItemTextContainer: {
      margin: 0,
      position: 'absolute',
      top: 58,
      color: 'white',
      fontSize: 10,
      fontWeight: 400,
      textTransform: 'uppercase'
    },
    menuItemText: {},
    hide: {
      display: 'none !important'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      alignItems: 'center',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      scrollbarWidth: 'none'
    },
    drawerHeader: {
      left: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      position: 'relative',
      '& a': {
        '&:hover': {
          scale: 1.2
        }
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth,
      backgroundColor: theme.palette.primary['50'],
      minHeight: '100vh'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    activeMenuViewer: {
      height: 'calc(100% - 64px - 35px)'
    },
    zendeskChatBadge: {
      '& .MuiBadge-badge': {
        top: '12px',
        right: '12px'
      }
    }
  })
)
