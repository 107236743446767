import {
  IPageTransform,
  OcrDocument,
  Page,
  PageTransformCalculator,
  PageVisitor
} from '@bgl/textract-business-model-editor'

class PageToTransform implements PageVisitor<IPageTransform> {
  visitPage(page: Page): IPageTransform {
    return PageTransformCalculator.fromPage(page).toTransform()
  }
}

export function adapterFromDocument(document: OcrDocument): IPageTransform[] {
  return document.accept(new PageToTransform()).toArray()
}
