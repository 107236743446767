import autobind from 'autobind-decorator'
import { List } from 'immutable'
import _ from 'lodash'
import { OcrDocument } from '../../OcrDocument'
import { IAtoPaygInstalmentReportModel, IPayGInstalmentYearlyTable, IPaygInstalmentYearlyTableDataRow } from '../../types/ISmartDocsResult'
import { BusinessModelMapper } from '../BusinessModelMapper'
import { AtoPaygInstalmentReport } from './AtoPaygInstalmentReport'
import { PayGInstalmentYearlyTable } from './PayGInstalmentYearlyTable'
import { PayGInstalmentYearlyTableRow } from './PayGInstalmentYearlyTableRow'

@autobind
export class AtoPaygInstalmentReportMapper extends BusinessModelMapper {
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IAtoPaygInstalmentReportModel
  ) {
    super(document)
  }

  fromJson(): AtoPaygInstalmentReport {
    const atoPaygInstalmentReportModel = this.jsonModel
    const paygInstalmentYearlyTable = this.mapReportTable(atoPaygInstalmentReportModel.Table)
    return new AtoPaygInstalmentReport(
      atoPaygInstalmentReportModel.Name,
      atoPaygInstalmentReportModel.Version,
      paygInstalmentYearlyTable,
      this.mapTextBlock(atoPaygInstalmentReportModel.FinancialYear),
      this.mapTextBlock(atoPaygInstalmentReportModel.TaxAgent),
      this.mapDateBlock(atoPaygInstalmentReportModel.LastUpdatedDate)
    )
  }

  mapReportTableRow(row: IPaygInstalmentYearlyTableDataRow): PayGInstalmentYearlyTableRow | undefined {
    const tfnNumber = this.mapTextBlock(row.TFN)
    const clientName = this.mapTextBlock(row.ClientName)
    const quarter1 = this.mapDollarBlock(row.Quarter1)
    const quarter2 = this.mapDollarBlock(row.Quarter2)
    const quarter3 = this.mapDollarBlock(row.Quarter3)
    const quarter4 = this.mapDollarBlock(row.Quarter4)
    const totalInstalment = this.mapDollarBlock(row.TotalInstalment)

    return new PayGInstalmentYearlyTableRow(
      tfnNumber,
      clientName,
      quarter1,
      quarter2,
      quarter3,
      quarter4,
      totalInstalment
      )
  }

  mapReportTable(table: IPayGInstalmentYearlyTable): PayGInstalmentYearlyTable {
    const rows = _.compact((table.Table.DataRows || []).map(this.mapReportTableRow))
    return new PayGInstalmentYearlyTable(
        List(rows)
      )
  }
}



