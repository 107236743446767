import React from 'react'

function UseCase() {
  return (
    <section className="use-case">
      <div className="container container-wrapper">
        <div className="use-case-layout">
          <div className="left">
            <h3>Use Case</h3>
            <p>
              Simply upload a document and watch as <b>SmartDocs powerful AI</b>{' '}
              transform your unstructured data into <b>smart digital data!</b>
            </p>
          </div>
          <div className="right">
            <iframe
              loading="lazy"
              title="SmartDocs AI Studio | The future of data intelligence | BGL Corporate Solutions"
              src="https://www.youtube.com/embed/TvitYIsqR60?feature=oembed"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              name="fitvid1"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default UseCase
