import React from 'react'

import { ssoURL } from '../../ApiPath'
import facebookIcon from '../../assets/images/facebook.png'
import footerAwards from '../../assets/images/Image_BGLAWARDS_0623_BGL.png'
import instagramIcon from '../../assets/images/instagram.jpeg'
import linkedinIcon from '../../assets/images/linkedin.png'
import twitterIcon from '../../assets/images/twitter.png'
import youtubeIcon from '../../assets/images/youtube.png'

function Footer() {
  return (
    <section className="footer">
      <div className="container container-wrapper">
        <div className="layout">
          <div className="left">
            <p className="footerSubHeader">Company</p>
            <p className="separator">&nbsp;</p>
            {/* <p>
              <span
                onClick={() =>
                  document.getElementById('pretrainedmodels')?.scrollIntoView()
                }
                onKeyDown={() =>
                  document.getElementById('pretrainedmodels')?.scrollIntoView()
                }
                role="button"
                tabIndex={0}
              >
                AI Models
              </span>
            </p>
            <p>
              <span
                onClick={() =>
                  document.getElementById('customform')?.scrollIntoView()
                }
                onKeyDown={() =>
                  document.getElementById('customform')?.scrollIntoView()
                }
                role="button"
                tabIndex={0}
              >
                Custom Form
              </span>
            </p>
            <p>
              <span
                onClick={() =>
                  document.getElementById('media')?.scrollIntoView()
                }
                onKeyDown={() =>
                  document.getElementById('media')?.scrollIntoView()
                }
                role="button"
                tabIndex={0}
              >
                Media
              </span>
            </p>
            <p>
              <span
                onClick={() =>
                  document.getElementById('about')?.scrollIntoView()
                }
                onKeyDown={() =>
                  document.getElementById('about')?.scrollIntoView()
                }
                role="button"
                tabIndex={0}
              >
                About
              </span>
            </p>
            <p>
              <a href="/#/contact-us" target="_blank" rel="noreferrer">
                Contact Us
              </a>
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p> */}
            <p>
              <a
                href="https://www.bglcorp.com/about/"
                target="_blank"
                rel="noreferrer"
              >
                About BGL
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/careers/"
                target="_blank"
                rel="noreferrer"
              >
                Work with us
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/blog/"
                target="_blank"
                rel="noreferrer"
              >
                BGL blog
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/contact/"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/hub/support/"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p className="separator">&nbsp;</p>
            <p>Suite 2, 606-608 Hawthorn Road</p>
            <p>(PO Box 8063) Brighton East</p>
            <p>Victoria, 3187 Australia</p>
            <p>ABN: 60 606 354 079</p>
            <p className="separator">&nbsp;</p>
            <p>Phone: 1300 654 401</p>
            <p>Email: info@bglcorp.com.au</p>
          </div>
          <div className="middle">
            <p className="footerSubHeader">Login</p>
            <p className="separator">&nbsp;</p>
            <p>
              <a
                href={`${ssoURL}/login?app=sdd360`}
                target="_blank"
                rel="noreferrer"
              >
                SmartDocs AI Studio
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://sso.bgl360.com.au/login?app=mybgl"
                target="_blank"
                rel="noreferrer"
              >
                MyBGL
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://status.bgl360.com.au/"
                target="_blank"
                rel="noreferrer"
              >
                BGL Services Status
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p className="separator">&nbsp;</p>
            <p>
              <a
                href="https://www.bglcorp.com/cloud-subscription-agreement/"
                target="_blank"
                rel="noreferrer"
              >
                Cloud Subscription Agreement
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/support-service-protocol/"
                target="_blank"
                rel="noreferrer"
              >
                Support Service Protocol
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
            <p>
              <a
                href="https://www.bglcorp.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              {/*<OpenInNewIcon fontSize="small" className="icon" />*/}
            </p>
          </div>
          <div className="right">
            <div className="img">
              <img src={footerAwards} alt="footer-awards" />
            </div>
          </div>
        </div>
        <div className="bottom">
          <p style={{ flexBasis: '95%' }}>
            Copyright © 2023 BGL Corporate Solutions, Inc. All rights reserved.
          </p>
          <a
            href="https://www.facebook.com/BGLCorp/"
            title="Follow on Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebookIcon} alt="facebook-icon" />
          </a>
          <a
            href="https://twitter.com/BGLdot"
            title="Follow on Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitterIcon} alt="twitter-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/bgl-corporate-solutions"
            title="Follow on LinkedIn"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedinIcon} alt="linkedin-icon" />
          </a>
          <a
            href="https://www.youtube.com/user/BGLCORPSOL"
            title="Follow on Youtube"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtubeIcon} alt="youtube-icon" />
          </a>
          <a
            href="https://www.instagram.com/bgldot/"
            title="Follow on Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagramIcon} alt="instagram-icon" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Footer
