import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import _ from 'lodash'
import React, { ChangeEvent, useEffect, useState } from 'react'

import { FlexColPaper } from '../assets/StyledContainer'
import TaggingApi from '../services/TaggingApi'
import CallToActionWrapper from './callToAction/CallToActionWrapper'
import GooglePlaces from './others/GooglePlaces'

export interface onBoardingRequestData {
  companyName: string
  abn: string | undefined
  phoneNumber: string
  billingAddress: string
  billingPostcode: string
  billingCountry: string
  mailingAddress: string
  mailingPostcode: string
  mailingCountry: string
  email: string
  firstName: string
  lastName: string
  contactNumber: string
}

const useStyles = makeStyles({
  formSubtitle: {
    fontWeight: 600
  },
  signUpFormActionWrapper: {
    marginTop: '1rem'
  },
  checkboxLabel: {
    fontSize: '0.8rem'
  }
})

const taggingApi = TaggingApi.getInstance()

interface ErrorData {
  [key: string]: string | undefined
}

function SignUpForm() {
  const classes = useStyles()

  const [formData, setFormData] = useState<onBoardingRequestData>({
    companyName: '',
    abn: undefined,
    phoneNumber: '',
    billingAddress: '',
    billingPostcode: '',
    billingCountry: '',
    mailingAddress: '',
    mailingPostcode: '',
    mailingCountry: '',
    email: '',
    firstName: '',
    lastName: '',
    contactNumber: ''
  })

  const [countries, setCountries] = useState<{ name: string; code: string }[]>(
    []
  )
  const [errors, setErrors] = useState<ErrorData>({})
  const [requestPending, setRequestPending] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [useBillingForMailing, setUseBillingForMailing] = useState(false)

  useEffect(() => {
    import('../services/data/countries').then((_countries) => {
      setCountries(_countries.default ?? [])
    })
  }, [])

  useEffect(() => {
    // sync mailing address with billing address
    if (useBillingForMailing) {
      setFormData((prevData) => ({
        ...prevData,
        mailingAddress: prevData.billingAddress,
        mailingPostcode: prevData.billingPostcode,
        mailingCountry: prevData.billingCountry
      }))
    }
  }, [
    useBillingForMailing,
    formData.billingAddress,
    formData.billingPostcode,
    formData.billingCountry
  ])

  const validateField = (name: string, value: string): string | undefined => {
    switch (name) {
      case 'companyName':
        if (!value || value.length > 256) return 'Invalid company name'
        break
      case 'abn':
        if (value && !/^[0-9]/.test(value)) return 'Invalid ABN'
        break
      case 'phoneNumber':
        if (!value || !/^[0-9]/.test(value)) return 'Invalid phone number'
        break
      case 'billingAddress':
        if (!value || value.length > 1024) return 'Invalid billing address'
        break
      case 'billingPostcode':
        if (!value || value.length > 256) return 'Invalid postcode'
        break
      case 'billingCountry':
        if (!value || value.length > 256) return 'Invalid country'
        break
      case 'mailingAddress':
        if (!value || value.length > 1024) return 'Invalid mailing address'
        break
      case 'mailingPostcode':
        if (!value || value.length > 256) return 'Invalid postcode'
        break
      case 'mailingCountry':
        if (!value || value.length > 256) return 'Invalid country'
        break
      case 'email': {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        if (!value || !emailPattern.test(value)) return 'Invalid email'
        break
      }
      case 'firstName':
        if (!value || value.length > 256) return 'Invalid first name'
        break
      case 'lastName':
        if (!value || value.length > 256) return 'Invalid last name'
        break
      case 'contactNumber':
        if (!value || !/^[0-9]/.test(value) || value.length > 256)
          return 'Invalid contact number'
        break
      default:
        return
    }
  }

  const updateError = (name: string, value: string) => {
    const error = validateField(name, value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
    updateError(name, value)
  }

  const handleMailingAddressCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setUseBillingForMailing(e.target.checked)
    if (!e.target.checked) {
      setFormData((prevData) => ({
        ...prevData,
        mailingAddress: '',
        mailingPostcode: '',
        mailingCountry: ''
      }))
      updateError('mailingAddress', '')
      updateError('mailingPostcode', '')
      updateError('mailingCountry', '')
    }
  }

  const handleSubmit = async () => {
    const newErrors: ErrorData = {}
    for (const [name, value] of Object.entries(formData)) {
      const error = validateField(name, value)
      if (error) newErrors[name] = error
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return // Prevent submission
    }

    setRequestPending(true)
    try {
      await taggingApi.onBoarding(formData)
      setIsSubmitted(true)
    } catch (e) {
      setOpenSnackbar(true)
    } finally {
      setRequestPending(false)
    }
  }

  const handleUpdateBillingAddressWithGoogleApi = (
    address: string,
    postcode: string,
    country: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      billingAddress: address,
      billingPostcode: postcode,
      billingCountry: country
    }))
    updateError('billingAddress', address)
    updateError('billingPostcode', postcode)
    updateError('billingCountry', country)
  }

  const handleUpdateMailingAddressWithGoogleApi = (
    address: string,
    postcode: string,
    country: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      mailingAddress: address,
      mailingPostcode: postcode,
      mailingCountry: country
    }))
    updateError('mailingAddress', address)
    updateError('mailingPostcode', postcode)
    updateError('mailingCountry', country)
  }

  if (isSubmitted) {
    return (
      <FlexColPaper elevation={0}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid container item xs={12} justifyContent={'center'}>
            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#4caf50" />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color={'primary'}
              gutterBottom
              align={'center'}
            >
              Thank you for your interest in BGL SmartDocs AI Studio!
            </Typography>
            <Typography variant="subtitle1" gutterBottom align={'center'}>
              Your sign up request has been submitted successfully! <br />
              We will get back to you shortly through email. <br />
            </Typography>
          </Grid>
        </Grid>
        <Button variant={'contained'} color={'primary'} href={'/'}>
          Back to Home
        </Button>
      </FlexColPaper>
    )
  }

  return (
    <CallToActionWrapper
      childComponent={
        <FlexColPaper elevation={0}>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Grid container item xs={12} alignItems={'flex-start'}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.formSubtitle}
            >
              General Detail:
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                error={!!errors.companyName}
                helperText={errors.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                fullWidth
                size={'small'}
                label="ABN"
                name="abn"
                value={formData.abn}
                error={!!errors.abn}
                helperText={errors.abn}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            style={{ width: '100%', margin: '2rem 0 1rem 0' }}
          />
          <Grid container item xs={12} alignItems={'flex-start'}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.formSubtitle}
            >
              Billing Address:
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GooglePlaces
                handleUpdatePlaceWithGoogleApi={
                  handleUpdateBillingAddressWithGoogleApi
                }
                error={!!errors.billingAddress}
                helperText={errors.billingAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="Postcode"
                name="billingPostcode"
                value={formData.billingPostcode}
                error={!!errors.billingPostcode}
                helperText={errors.billingPostcode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={formData.billingCountry ?? null}
                fullWidth
                autoComplete
                size={'small'}
                options={_.map(countries, 'name')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country or Region"
                    variant="outlined"
                    className="textFiled"
                    fullWidth
                    required
                    name={'billingCountry'}
                    error={!!errors.billingCountry}
                    helperText={errors.billingCountry}
                  />
                )}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: string | null
                ) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    billingCountry: value ?? ''
                  }))
                  updateError('billingCountry', value ?? '')
                }}
              />
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            style={{ width: '100%', margin: '2rem 0 1rem 0' }}
          />
          <Grid
            container
            item
            xs={12}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography
              variant="h6"
              gutterBottom
              className={classes.formSubtitle}
            >
              Mailing Address:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useBillingForMailing}
                  size={'small'}
                  onChange={(e) => handleMailingAddressCheckbox(e)}
                  color="primary"
                />
              }
              label="Same to Billing Address"
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          {!useBillingForMailing && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GooglePlaces
                  handleUpdatePlaceWithGoogleApi={
                    handleUpdateMailingAddressWithGoogleApi
                  }
                  error={!!errors.mailingAddress}
                  helperText={errors.mailingAddress}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="textFiled"
                  variant="outlined"
                  required
                  fullWidth
                  size={'small'}
                  label="Postcode"
                  name="mailingPostcode"
                  value={formData.mailingPostcode}
                  error={!!errors.mailingPostcode}
                  helperText={errors.mailingPostcode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={formData.mailingCountry ?? null}
                  fullWidth
                  autoComplete
                  size={'small'}
                  options={_.map(countries, 'name')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country or Region"
                      variant="outlined"
                      className="textFiled"
                      fullWidth
                      required
                      name={'mailingCountry'}
                      error={!!errors.mailingCountry}
                      helperText={errors.mailingCountry}
                    />
                  )}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: string | null
                  ) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      mailingCountry: value ?? ''
                    }))
                    updateError('mailingCountry', value ?? '')
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Divider
            variant="middle"
            style={{ width: '100%', margin: '2rem 0 1rem 0' }}
          />
          <Grid container item xs={12} alignItems={'flex-start'}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.formSubtitle}
            >
              Account Owner Contact Detail:
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="First Name"
                name="firstName"
                value={formData.firstName}
                error={!!errors.firstName}
                helperText={errors.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                error={!!errors.lastName}
                helperText={errors.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                error={!!errors.contactNumber}
                helperText={errors.contactNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="textFiled"
                variant="outlined"
                required
                fullWidth
                size={'small'}
                label="Email"
                name="email"
                value={formData.email}
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={'flex-end'}
            className={classes.signUpFormActionWrapper}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={requestPending}
            >
              Submit
            </Button>
          </Grid>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity={'error'}>
              Sign up failed: Please try again later or contact support.
            </Alert>
          </Snackbar>
        </FlexColPaper>
      }
    />
  )
}

export default SignUpForm
