/* eslint-env browser */
import { ImgComparisonSlider } from '@img-comparison-slider/react'
import { useMediaQuery } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import maskWithXImg from '../../../assets/images/redaction/MaskingX.png'
import originalImg from '../../../assets/images/redaction/Sample - Original.png'
import blackoutImg from '../../../assets/images/redaction/SampleBlackout.png'
import pseudoImg from '../../../assets/images/redaction/SamplePseudo.png'

function DataSanitisation() {
  const [activeSection, setActiveSection] = useState('Blackout')
  const [sliderValue, setSliderValue] = useState(10)
  const sectionRef = useRef(null) // reference to the section

  const reducedImage = useMediaQuery('(max-width: 500px)')

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect()
        const viewportHeight = window.innerHeight

        const startThreshold = 0.6 * rect.height
        const endThreshold = rect.height

        if (
          rect.top <= viewportHeight - startThreshold &&
          rect.top > viewportHeight - endThreshold
        ) {
          const positionRatio =
            (viewportHeight - rect.top - startThreshold) /
            (endThreshold - startThreshold)

          setSliderValue(positionRatio * 80 + 10)
        } else if (rect.top <= viewportHeight - endThreshold) {
          setSliderValue(90)
        } else if (rect.top > viewportHeight - startThreshold) {
          setSliderValue(10)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const sections = [
    {
      title: 'Blackout',
      description:
        'Redacted items are removed and covered by a black box on selecting Blackout option. This option applies to both text and images.',
      image: blackoutImg
    },
    {
      title: 'Pseudonymisation',
      description:
        'Redacted items will be replaced by artificial identifiers. The pseudonyms keeps the ' +
        'data structure and the data type intact for your future use in analytics or testing.',
      image: pseudoImg
    },
    {
      title: 'Masking with " X "',
      description:
        'Each character of redacted items will be replaced with an "X".',
      image: maskWithXImg
    }
  ]

  const activeImage = sections.find(
    (section) => section.title === activeSection
  ).image

  return (
    <section className="data-sanitisation" ref={sectionRef}>
      <div className="layout container">
        <div className="left-panel">
          <h2>Our Data Sanitisation Techniques</h2>
          {sections.map((section, index) => (
            <div key={index}>
              <button onClick={() => setActiveSection(section.title)}>
                <h3
                  className={`${
                    activeSection !== section.title ? 'light' : ''
                  }`}
                >
                  {section.title}
                </h3>
              </button>
              {activeSection === section.title && <p>{section.description}</p>}
            </div>
          ))}
        </div>
        <div className="right-panel">
          <ImgComparisonSlider
            class="slider-with-animated-handle rendered"
            value={sliderValue}
            tabindex="0"
          >
            <div slot="first" className="after">
              <img
                src={activeImage}
                height={reducedImage ? 600 : 1000}
                alt={'redacted'}
              />
              <span>After</span>
            </div>
            <div slot="second" className="before">
              <img
                src={originalImg}
                height={reducedImage ? 600 : 1000}
                alt={'original'}
              />
              <span>Before</span>
            </div>
            <svg
              slot="handle"
              className="custom-animated-handle"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              viewBox="-8 -3 16 6"
            >
              <path
                stroke="#fff"
                d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2"
                strokeWidth="1"
                fill="#761bcb"
                vectorEffect="non-scaling-stroke"
              ></path>
            </svg>
          </ImgComparisonSlider>
        </div>
      </div>
    </section>
  )
}

export default DataSanitisation
