import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useCallToActionStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: '#eceffa',
      minHeight: window.innerHeight + 'px'
    },
    topSection: {
      width: '100%',
      height: window.innerHeight * 0.1 + 'px'
    },
    contentLeft: {
      minHeight: window.innerHeight * 0.7 + 'px',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up('md')]: {
        borderRadius: '20px 0 0 20px'
      }
    },
    contentRight: {
      minHeight: window.innerHeight * 0.7 + 'px',
      height: '680px',
      backgroundColor: theme.palette.common.white,
      overflowY: 'auto',
      [theme.breakpoints.up('md')]: {
        borderRadius: '0 20px 20px 0'
      },
      '& img': {
        display: 'block',
        margin: '50px auto 30px',
        height: 50
      },
      '& div[data-test]': {
        display: 'flex',
        justifyContent: 'center',
        '& span': {
          '&>div': {
            display: 'none'
          },
          '&>button': {
            backgroundColor: '#FFFFFF',
            border: '1px solid rgb(209,213,219)',
            borderRadius: '.5rem',
            boxSizing: 'border-box',
            color: ' #111827',
            fontSize: '.875rem',
            fontWeight: '500',
            lineHeight: '1rem',
            padding: '.75rem 1rem',
            textAlign: 'center',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            cursor: 'pointer',
            userSelect: 'none',
            '&:hover': {
              backgroundColor: 'rgb(249,250,251)'
            }
          }
        }
      }
    }
  })
)
