import {
  BusinessModelType,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import { Grid, TextField, Typography } from '@material-ui/core'
import type {
  GridCellParams,
  GridColDef,
  GridRowsProp
} from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React from 'react'

import {
  BusinessModelTypeToTableData,
  SplitPageDetectionToTableData
} from './BusinessModelTypeToTableData'

interface IBusinessModelTypeEditorProps {
  businessModelType: BusinessModelType
}

export const BusinessModelTypeEditor = (
  props: IBusinessModelTypeEditorProps
) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function renderLongTextCell(params: GridCellParams) {
    return (
      <span
        title={params.value as string}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {params.value}
      </span>
    )
  }

  const toColumns = (
    renderLongTextCell: (params: GridCellParams) => JSX.Element
  ): GridColDef[] => {
    return [
      {
        field: 'id',
        type: 'string',
        headerName: 'Page',
        width: 80,
        editable: false
      },
      {
        field: FieldAndColumnName.BusinessModelTypePagesEditor_ModelName,
        type: 'string',
        headerName: 'Model Name',
        width: 200,
        editable: true,
        renderCell: (params: GridCellParams) => renderLongTextCell(params)
      },
      {
        field: FieldAndColumnName.BusinessModelTypePagesEditor_Probability,
        type: 'number',
        headerName: 'Probability',
        width: 100,
        editable: true,
        headerAlign: 'left',
        align: 'left'
      }
    ]
  }

  const toSplitColumns = (
    renderLongTextCell: (params: GridCellParams) => JSX.Element
  ): GridColDef[] => {
    return [
      {
        field: 'id',
        type: 'string',
        headerName: 'Page',
        width: 80,
        editable: false
      },
      {
        field: 'documentSplitType',
        type: 'string',
        headerName: 'Document Split Type',
        width: 200,
        editable: true,
        renderCell: (params: GridCellParams) => renderLongTextCell(params)
      },
      {
        field: 'probability',
        type: 'number',
        headerName: 'Probability',
        width: 100,
        editable: true,
        headerAlign: 'left',
        align: 'left'
      }
    ]
  }

  const columns: GridColDef[] = toColumns(renderLongTextCell)

  const rows: GridRowsProp = BusinessModelTypeToTableData.toRows(
    props.businessModelType.businessModelTypePages
      .businessModelTypePageTableRows
  )

  const splitRows: GridRowsProp = SplitPageDetectionToTableData.toRows(
    props.businessModelType.splitPageDetections
  )

  const splitColumns: GridColDef[] = toSplitColumns(renderLongTextCell)

  const isMoreThanOnePage = props.businessModelType.splitPageDetections.size > 1

  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={'Model Name'}
            defaultValue={props.businessModelType.modelName}
            fullWidth
            multiline
            placeholder="N/A"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={'Probability'}
            defaultValue={props.businessModelType.probability}
            fullWidth
            multiline
            placeholder="N/A"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            disableColumnMenu
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar
            }}
          />
        </Grid>
        {isMoreThanOnePage && (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: '16px',
                  borderBottom: '2px solid #5F5CD7',
                  display: 'inline-block',
                  padding: '0 8px'
                }}
              >
                Split Page Detection
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                autoHeight
                disableColumnMenu
                rows={splitRows}
                columns={splitColumns}
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  )
}
