// eslint-disable-next-line import/named
import { Color, createTheme, responsiveFontSizes } from '@material-ui/core'

type ColorPartial = Partial<Color>

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteColor extends ColorPartial {}
}

export const Purple = '#605CD8'
export const Pink = '#D53D9C'
export const Grey = '#838383'
export const Success = '#5CB85C'
export const Warning = '#F0AD4E'
export const Error = '#D9534F'

export const TableBoxShadow =
  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 2px 1px -1px rgb(0 0 0 / 14%), 0px 1px 1px -1px rgb(0 0 0 / 12%)'

export const TaggingTheme = createTheme({
  palette: {
    primary: {
      main: '#5F5CD7', //the main purple
      light: '#C9CAF1', //top bar background
      dark: '#4643c9', //main hover background color
      50: '#ECEFFA', //page & project selector field background
      100: '#D8D9F5', //logout button background
      200: '#8786E0' //menu item hover highlight color
    },
    secondary: {
      main: '#D53D9C', //the main pink
      light: '#FCEBF5' //tags page tab background
    },
    success: {
      main: '#03B21B' //green button
    },
    warning: {
      main: Warning,
      light: '#F0874E'
    },
    error: {
      main: '#E22828', //main red color
      light: '#F6CCC6' //background red
    },
    info: {
      main: '#5BC0DE'
    },
    grey: {
      100: '#f8f8f8', //Material Table Pagination background
      200: '#F0F0F0', //table body divider
      300: '#DEDEDE', //grey button
      400: '#D9D9D9', //table header divider
      500: '#9D9D9D', //title text
      700: '#58595C' //copyright text
    }
  },
  typography: {
    h2: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: 3
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '34px'
    },
    body1: {
      color: '#212121' //body text color
    }
  }
})

export default responsiveFontSizes(TaggingTheme)
