import React, { createContext, useCallback, useState } from 'react'

import ConfirmationDialog, {
  IConfirmationDialog
} from '../../components/others/ConfirmationDialog'

type OpenDialogParamsType = Omit<IConfirmationDialog, 'dialogOpen'>

interface IConfirmationDialogContextProps {
  children: React.ReactNode
}

type ConfirmationDialogContextType = {
  openConfirmDialog: (params: OpenDialogParamsType) => void
}

const defaultConfirmationDialogContext: ConfirmationDialogContextType = {
  openConfirmDialog: () => {}
}

const defaultOpenDialogParams: OpenDialogParamsType = {
  title: '',
  content: <></>,
  action: () => Promise.resolve(),
  actionName: '',
  handleCancel: () => {}
}

export const ConfirmationDialogContext =
  createContext<ConfirmationDialogContextType>(defaultConfirmationDialogContext)

export const ConfirmationDialogProvider = ({
  children
}: IConfirmationDialogContextProps) => {
  const [openDialogParams, setOpenDialogParams] =
    useState<OpenDialogParamsType>(defaultOpenDialogParams)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const openDialog = useCallback((params: OpenDialogParamsType) => {
    const { handleCancel, ...other } = params
    setOpenDialogParams({
      ...other,
      handleCancel: () => {
        handleCancel()
        setDialogOpen(false)
      }
    })
    setDialogOpen(true)
  }, [])

  return (
    <ConfirmationDialogContext.Provider
      value={{
        openConfirmDialog: openDialog
      }}
    >
      {children}
      <ConfirmationDialog {...openDialogParams} dialogOpen={dialogOpen} />
    </ConfirmationDialogContext.Provider>
  )
}
