import {List} from 'immutable'
import {IBusinessModel, IModelType} from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import {ASICFeeMapper} from './ASICFeeMapper'
import {FieldAndColumnName} from "../../validator/FieldValidatorDef";
import {FormGridRowValue} from "../../../utils/DataTypeMapper";
import {uuid} from "../../../utils/utils";
import {TransactionDetails} from "./TransactionDetails";

export class ASICFee extends BusinessModel{
    constructor(
        modelType: IModelType,
        version: string,
        readonly ASICFeeIssueDate?:DateField,
        readonly ASICFeeCompanyName?:StringField,
        readonly ASICFeeACN?:StringField,
        readonly ASICFeeAccountNumber?:StringField,
        readonly ASICFeeCurrentDueAmount?:DoubleField,
        readonly ASICFeeCurrentDueDate?: DateField,
        readonly ASICFeeOverdueAmount?:DoubleField,
        readonly ASICFeeTotalDue?:DoubleField,
        readonly companyStatementCompanyName?: StringField,
        readonly companyStatementACN?: StringField,
        readonly companyStatementIssueDate?: DateField,
        readonly companyRegisteredAddress?: StringField,
        readonly companyOfficeholder?: List<StringField>,
        readonly companyOfficeholderDOB?: List<DateField>,
        readonly companyMember?: List<StringField>,
        readonly transactionDetailsForThisPeriod?: List<TransactionDetails>,
        readonly outstandingTransactions?: List<TransactionDetails>
    ) {
        super(IModelType.ASICFee, version)
    }

    protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
        switch (id) {
            case FieldAndColumnName.ASICFeeEditor_ASICFeeCompanyName:
                return new StringField(uuid(), value as string, [], List(), modifiedBy)

            case FieldAndColumnName.ASICFeeEditor_ASICFeeIssueDate:
            case FieldAndColumnName.ASICFeeEditor_ASICFeeCurrentDueDate:
                return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)

            case FieldAndColumnName.ASICFeeEditor_ASICFeeTotalDue:
                return new DoubleField(uuid(), value as number, [], List(), modifiedBy)
            default:
                return undefined
        }
    }

    copy({
        modelType = this.modelType,
        version = this.version,
        ASICFeeIssueDate = this.ASICFeeIssueDate,
        ASICFeeCompanyName = this.ASICFeeCompanyName,
        ASICFeeACN = this.ASICFeeACN,
        ASICFeeAccountNumber = this.ASICFeeAccountNumber,
        ASICFeeCurrentDueAmount = this.ASICFeeCurrentDueAmount,
        ASICFeeCurrentDueDate = this.ASICFeeCurrentDueDate,
        ASICFeeOverdueAmount = this.ASICFeeOverdueAmount,
        ASICFeeTotalDue = this.ASICFeeTotalDue,
        companyStatementCompanyName = this.companyStatementCompanyName,
        companyStatementACN = this.companyStatementACN,
        companyStatementIssueDate = this.companyStatementIssueDate,
        companyRegisteredAddress = this.companyRegisteredAddress,
        companyOfficeholder = this.companyOfficeholder,
        companyOfficeholderDOB = this.companyOfficeholderDOB,
        companyMember = this.companyMember,
        transactionDetailsForThisPeriod = this.transactionDetailsForThisPeriod,
        outstandingTransactions = this.outstandingTransactions
         }):ASICFee{
    return new ASICFee(
        modelType,
        version,
        ASICFeeIssueDate,
        ASICFeeCompanyName,
        ASICFeeACN,
        ASICFeeAccountNumber,
        ASICFeeCurrentDueAmount,
        ASICFeeCurrentDueDate,
        ASICFeeOverdueAmount,
        ASICFeeTotalDue,
        companyStatementCompanyName,
        companyStatementACN,
        companyStatementIssueDate,
        companyRegisteredAddress,
        companyOfficeholder,
        companyOfficeholderDOB,
        companyMember,
        transactionDetailsForThisPeriod,
        outstandingTransactions
        )
    }

    toJson(): IBusinessModel {
        return ASICFeeMapper.toJson(this)
    }
}