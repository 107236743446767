import create from 'zustand'

export interface IPaymentStates {
  paymentDialogOpen: boolean
  openPaymentDialog: () => void
  closePaymentDialog: () => void
  paymentSuccess: boolean
  setPaymentSuccess: (success: boolean) => void
}

export const usePaymentStore = create<IPaymentStates>((set) => ({
  paymentDialogOpen: false,
  openPaymentDialog: () => set({ paymentDialogOpen: true }),
  closePaymentDialog: () => set({ paymentDialogOpen: false }),
  paymentSuccess: false,
  setPaymentSuccess: (success: boolean) => set({ paymentSuccess: success })
}))
