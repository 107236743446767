import {
  FieldAndColumnName,
  StringField
} from '@bgl/textract-business-model-editor'
import { List } from 'immutable'
import React, { FunctionComponent } from 'react'

import { EditorFieldEvents } from './EditorFieldEvents'
import { FormStringField } from './FormStringField'

export const FormStringFieldArray: FunctionComponent<{
  id: FieldAndColumnName
  label: string
  fields: List<StringField>
  events: EditorFieldEvents
  className?: string
}> = ({ id, label, fields, events, className }) => {
  if (fields && fields.size !== 0) {
    return (
      <>
        {fields.map((field, index) => (
          <FormStringField
            key={index}
            id={id}
            label={label}
            events={events}
            field={field}
            className={className}
          />
        ))}
      </>
    )
  }
  return (
    <FormStringField
      id={id}
      label={label}
      events={events}
      field={undefined}
      className={className}
    />
  )
}
