import clsx from 'clsx'
import _ from 'lodash'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { IOverlay } from '../overlay/IOverlay'
import { IOverlayPageTransform } from '../overlay/IOverlayPageTransform'
import SelectableOverlay from '../overlay/SelectableOverlay'
import { onPassword } from '../utils/utils'
import {options} from "./PDFViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IPDFViewerMobileProps {
  pdf: Blob
  fileName: string
  overlays?: IOverlay[]
  forceUpdateCounter: number
  pageTransforms?: IOverlayPageTransform[]
  onDocumentClose?: () => void
  onDownloadFile: () => void
  handleDocumentLoadSuccess: (pdf: pdfjs.PDFDocumentProxy) => void
  bottomBarElem?: JSX.Element | JSX.Element[]
  viewMode?: 'wordTagging' | 'pageTagging'
  scrollIntoView?: (id?: string) => void
  scale?: number
  onPasswordCancel?: () => void
}

export default function PDFViewerMobile(props: IPDFViewerMobileProps) {
  const [numPages, setNumPages] = useState(0)
  const [viewMode, setViewMode] = useState(
    props.viewMode ? props.viewMode : 'wordTagging'
  )
  const [pinchingScale, setPinchingScale] = useState(1)
  const [internalScale, setInternalScale ] = useState(1)

  const scaleToFitPage = (pdf: pdfjs.PDFDocumentProxy) => {
    const containerWidth = document.getElementById('PDFContainer')?.clientWidth || window.innerWidth
    pdf.getPage(1).then(page => page.getViewport({ scale: 1 }).width).then(width => {
      const displayWidth = containerWidth - 48 // 48 is the padding
      const initialScale = displayWidth / width
      setInternalScale(initialScale)
    })
  }

  const findTransformByPage = (
    pageNum: number
  ): IOverlayPageTransform | undefined => {
    return _.head(
      props.pageTransforms?.filter((transform) => transform.page === pageNum)
    )
  }

  const rotatePage = (index: number) => {
    const pageNum = index + 1
    const pageElem = document.querySelector(
      "[data-page-number='" + pageNum + "']"
    )
    const canvasElem = (
      [].slice.call(
        pageElem?.getElementsByTagName('canvas')
      ) as HTMLCanvasElement[]
    )[0]
    const transformByPage = findTransformByPage(pageNum)
    if (transformByPage && canvasElem) {
      canvasElem.style.transform = `rotate(${transformByPage.angleDegree}deg) scale(${transformByPage.scale})`
    }
  }

  const scaleFromPage = (page: number) => {
    const pageTransform = props.pageTransforms?.find((p) => p.page === page)
    if (pageTransform) {
      return pageTransform.scale
    }
    return 1
  }

  function onDocumentLoadSuccess(pdf: pdfjs.PDFDocumentProxy) {
    props.handleDocumentLoadSuccess(pdf)
    setNumPages(pdf.numPages)
    scaleToFitPage(pdf)
  }

  function handleOnPassword(callback: (password: string) => void, reason: number) {
    onPassword(callback, reason, props.onPasswordCancel)
  }

  const renderPDFOverlaysByPage = (pageNumber: number) => {
    if (!props.overlays || props.overlays.length === 0) return
    const overlaysOfPage: IOverlay[] = props.overlays.filter(
      (overlay) => overlay.page === pageNumber
    )
    return overlaysOfPage.map((overlay) => {
      return (
        <SelectableOverlay
          key={overlay.id}
          overlay={overlay}
          isNonSelectable={true}
        />
      )
    })
  }

  return (
    <div
      id="PDFContainer"
      className="textractDocViewer textractDocViewerMobile"
    >
      <div id="viewerContainer" tabIndex={0}>
        <div key="viewer" id="viewer" className="pdfViewer mobile">
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={8}
            wheel={{
              wheelDisabled: true
            }}
            zoomAnimation={{
              disabled: true
            }}
            onZoomStop={ref => {
              setPinchingScale(ref.state.scale)
            }}
            panning={{
              disabled: pinchingScale === 1
            }}
          >
            <TransformComponent >
          <Document
            file={props.pdf}
            loading={<div>Loading...</div>}
            onLoadSuccess={onDocumentLoadSuccess}
            onPassword={(callback: (password: string) => void, reason: number) => handleOnPassword(callback, reason)}
            options={options}
            className={clsx(viewMode === 'pageTagging' ? 'spread' : '')}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`pageWrapper_${index + 1}`} className="pageWrapper">
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={scaleFromPage(index + 1) * internalScale}
                  onRenderSuccess={() => rotatePage(index)}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  loading={<div>Loading...</div>}
                >
                  <div key={`bglLayer_${index + 1}`} className="bglLayer">
                    {renderPDFOverlaysByPage(index + 1)}
                  </div>
                </Page>
              </div>
            ))}
          </Document>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>
    </div>
  )
}
