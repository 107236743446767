import { FieldAndColumnName } from '@bgl/textract-business-model-editor'
// eslint-disable-next-line max-len
import { AtoActivityStatementTransaction } from '@bgl/textract-business-model-editor/dist/domain/businessModel/atoActivityStatement/AtoActivityStatementTransaction'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class AtoTransactionTableRow {
  static toRows(
    transactions: List<AtoActivityStatementTransaction>
  ): GridRowsProp {
    return transactions
      .map((transaction, index) =>
        AtoTransactionTableRow.toGridRow(transaction, index)
      )
      .toArray()
  }

  static toGridRow = (
    transaction: AtoActivityStatementTransaction,
    id: number
  ) => {
    return {
      id,
      [FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate]:
        transaction.processedDate?.parsedValue?.toDate(),
      [FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate]:
        transaction.effectiveDate?.parsedValue?.toDate(),
      [FieldAndColumnName.AtoActivityStatementTransactionTable_Description]:
        transaction.description?.parsedValue,
      [FieldAndColumnName.AtoActivityStatementTransactionTable_Debit]:
        transaction._debit()?.parsedValue,
      [FieldAndColumnName.AtoActivityStatementTransactionTable_Credit]:
        transaction._credit()?.parsedValue,
      [FieldAndColumnName.AtoActivityStatementTransactionTable_Balance]:
        transaction.balance?.parsedValue
    }
  }
}
