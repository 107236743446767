import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useDashboardProfileStyle = makeStyles((theme) =>
  createStyles({
    btnLabelContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: 10
    },
    btnBusinessName: {
      fontSize: '0.75rem'
    },
    accountSummary: {
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.primary['50'],
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.primary['50']
      }
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      marginBottom: 10
    },
    menuItemText: {
      fontSize: '0.875rem'
    },
    userEmail: {
      fontSize: '0.75rem',
      color: 'grey'
    },
    menuPaper: {
      minWidth: 200,
      '& .MuiList-root': {
        padding: theme.spacing(0, 0, 0, 0)
      }
    }
  })
)
