import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@material-ui/core'
import * as React from 'react'

export const Documentation = () => {
  return (
    <Tooltip title={'Documentation'}>
      <IconButton
        onClick={() =>
          window.open('https://smartdocs-ai-studio.readme.io/docs', '_blank')
        }
      >
        <FontAwesomeIcon icon={faCircleQuestion} />
      </IconButton>
    </Tooltip>
  )
}
