import { Button } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PageError } from './Layout'
export const useErrorPageStye = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageContainer: {
      width: 300,
      height: 320,
      backgroundImage: 'url(/static/images/smartie.png)',
      backgroundPosition: '-600px -200px'
    }
  })
)

function ErrorPage(props: { message: PageError }) {
  const classes = useErrorPageStye()
  const navigate = useNavigate()
  return (
    <div className={classes.container}>
      <h1>Oops!</h1>
      <div className={classes.imageContainer}></div>
      <h3>{props.message}</h3>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate('/', { replace: true })
        }}
      >
        Go to home page
      </Button>
    </div>
  )
}

export default ErrorPage
