import React from 'react'

import bglReleaseAIInSF360 from '../../assets/images/AUSFINTECH.png'
import mediaAWS from '../../assets/images/most_innof.png'
import mediaMachineLearning from '../../assets/images/xmost_innovative_company-0104-1.png.pagespeed.ic.JnIyPDbpmj.png'
import mediaMostInnovative from '../../assets/images/xmost_innovpany-01-1024x538-1.png.pagespeed.ic.BwHO0PUJpD.png'
function Media() {
  return (
    <section className="media" id="media">
      <div className="container container-wrapper">
        <h3>Media</h3>
        <div className="media-layout">
          <div className="media-detail">
            <div className="img">
              <img src={mediaMachineLearning} alt="media-maching-learning" />
            </div>
            <div className="detail-wrap">
              <h5>Machine learning will bolster compliance – SMS Magazine</h5>
              <p>
                Artificial intelligence (AI) and machine learning provide the
                possibility of enabling SMSF software and systems ...
              </p>
              <div className="media-read-more">
                <a
                  href="https://smsmagazine.com.au/news/2017/08/02/machine-learning-will-bolster-compliance/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="media-detail">
            <div className="img">
              <img src={mediaMostInnovative} alt="media-most-innovative" />
            </div>
            <div className="detail-wrap">
              <h5>
                BGL Corporate Solutions ranked #5 most innovative technology
                company
              </h5>
              {/* eslint-disable-next-line */}
              <p className="most-innovative">
                BGL Corporate Solutions, developer of Australia’s leading SMSF
                administration and ASIC corporate compliance software solutions
                ...
              </p>
              <div className="media-read-more">
                <a
                  href="https://www.bglcorp.com/2019/08/12/bgl-ranked-5-most-innovative-technology-company/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="media-detail">
            <div className="img">
              <img src={mediaAWS} alt="media-aws" />
            </div>
            <div className="detail-wrap">
              <h5>BGL Case Study by Amazon Web Services (AWS)</h5>
              <p>
                With the machine-learning component in artificial intelligence
                (AI), these assistants have huge stores of data ...
              </p>
              <div className="media-read-more">
                {/* eslint-disable max-len */}
                <a
                  href="https://aws.amazon.com/solutions/case-studies/?customer-references-cards.sort-by=item.additionalFields.sortDate&customer-references-cards.sort-order=desc&awsf.content-type=*all&awsf.customer-references-location=*all&awsf.customer-references-segment=*all&awsf.customer-references-industry=*all&awsf.customer-references-use-case=*all&awsf.customer-references-tech-category=*all&awsf.customer-references-product=*all"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="media-detail">
            <div className="img">
              <img src={bglReleaseAIInSF360} alt="media-first-ai" />
            </div>
            <div className="detail-wrap">
              <h5>
                BGL releases the first SMSF Artificial Intelligence in Simple
                Fund 360
              </h5>
              {/* eslint-disable-next-line */}
              <p className="first-ai">
                BGL Corporate Solutions, Australia’s leading supplier of SMSF
                administration and ASIC corporate compliance solutions, is ...
              </p>
              <div className="media-read-more">
                <a
                  href="https://australianfintech.com.au/bgl-releases-first-smsf-artificial-intelligence-simple-fund-360/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Media
