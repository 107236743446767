import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuIcon from '@material-ui/icons/Menu'
import { useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ssoURL } from '../../ApiPath'
import bglIcon from '../../assets/images/BGL_logo.png'

export function Header() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const handleLogin = () => {
    window.location.replace(ssoURL + '/?app=sdd360&appFilter=sdd360&')
  }

  const navItems = [
    {
      label: 'Pre-Trained AI Models',
      link: '/'
    },
    {
      label: 'Custom Training',
      link: '/'
    },
    {
      label: 'Privacy Redaction',
      link: '/#/privacy-redaction'
    },
    {
      label: 'Documents',
      link: 'https://smartdocs-ai-studio.readme.io/docs'
    },
    {
      label: 'Pricing',
      link: '/#/pricing'
    },
    {
      label: 'Contact Us',
      link: 'https://www.bglcorp.com/smartdocs-ai-studio/#contact'
    }
  ]

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        {navItems.map((item) => (
          <ListItem
            button
            key={item.label}
            onClick={(e) => {
              e.stopPropagation()
              handleDrawerToggle()
              window.location.href = item.link ?? ''
            }}
          >
            <a href={item.link}>{item.label}</a>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button>
          <Button
            variant="contained"
            color="default"
            fullWidth
            onClick={() => {
              window.location.href = '/#/sign-up'
            }}
          >
            Sign Up
          </Button>
        </ListItem>
        <ListItem button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleLogin}
          >
            Login
          </Button>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <header className="header">
      <div className="container container-wrapper header-layout">
        <div className="icon">
          <a href="/">
            {/* <DeveloperIcon width={218} height={45} /> */}
            <img src={bglIcon} alt="bgl" />
          </a>
        </div>
        <div className="small-icon">
          <a href="/">
            {/* <DeveloperSmallIcon width={35} height={35} /> */}
            <img src={bglIcon} alt="bgl" />
          </a>
        </div>
        <div className="menus">
          <div className="dropdown">
            {/* eslint-disable-next-line */}
            <a className="hoverable">
              Features{' '}
              <FontAwesomeIcon
                icon={faChevronDown}
                className="chevronDownIcon"
              />
            </a>
            <div className="dropdown-menu">
              <div className="divider" />
              <div className="menu-layout">
                <div>
                  <span
                    onClick={() => navigate('/')}
                    onKeyDown={() => navigate('/')}
                    role="button"
                    tabIndex={0}
                  >
                    Approach 1: Pre-trained AI Models
                  </span>
                </div>
                <div>
                  <span
                    onClick={() => navigate('/')}
                    onKeyDown={() => navigate('/')}
                    role="button"
                    tabIndex={0}
                  >
                    Approach 2: Custom Training
                  </span>
                </div>
                <div>
                  <a href="/#/privacy-redaction" style={{ color: '#00000099' }}>
                    Privacy Redaction
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            href="https://smartdocs-ai-studio.readme.io/docs"
            target="_blank"
            rel="noreferrer"
          >
            Documents
          </a>
          <a href="/#/pricing">Pricing</a>
          <a href="https://www.bglcorp.com/smartdocs-ai-studio/#contact">
            Contact Us
          </a>
          <div className="buttons">
            <Button
              variant={'contained'}
              color={'default'}
              onClick={() => {
                window.location.href = '/#/sign-up'
              }}
            >
              Sign Up
            </Button>
            <Button
              variant={'contained'}
              color={'secondary'}
              onClick={handleLogin}
            >
              Login
            </Button>
          </div>
        </div>
        <IconButton
          edge="end"
          onClick={handleDrawerToggle}
          className="drawer-button"
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Box component="nav">
        <Drawer
          container={document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          anchor={'right'}
          className="home-drawer"
        >
          {drawer}
        </Drawer>
      </Box>
    </header>
  )
}

export default Header
