import { FieldAndColumnName } from '@bgl/textract-business-model-editor'
import { BankAuthorityAccount } from '@bgl/textract-business-model-editor/dist/domain/businessModel/bankAuthority/BankAuthorityAccount'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class BankAuthTableData {
  static toRows(accounts: List<BankAuthorityAccount>): GridRowsProp {
    return accounts
      .map((account, index) => BankAuthTableData.toGridRow(account, index))
      .toArray()
  }

  static toGridRow = (account: BankAuthorityAccount, id: number) => {
    return {
      id,
      [FieldAndColumnName.BankAuthorityAccounts_AccountName]:
        account.accountName?.parsedValue,
      [FieldAndColumnName.BankAuthorityAccounts_AccountNumber]:
        account.accountNumber?.parsedValue,
      [FieldAndColumnName.BankAuthorityAccounts_Bsb]: account.bsb?.parsedValue
    }
  }
}
