import _ from 'lodash'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Document, Page, pdfjs } from 'react-pdf'
import { IOverlay } from '../overlay/IOverlay'
import { IOverlayPageTransform } from '../overlay/IOverlayPageTransform'
import SelectableOverlay from '../overlay/SelectableOverlay'
import { onPassword } from '../utils/utils'
import {options} from "./PDFViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IPDFViewerSinglePageProps {
  pdf: Blob
  onRef: any
  fileName: string
  overlays?: IOverlay[]
  forceUpdateCounter: number
  pageTransforms?: IOverlayPageTransform[]
  onDocumentClose?: () => void
  onDownloadFile: () => void
  handleDocumentLoadSuccess: (pdf: pdfjs.PDFDocumentProxy) => void
  bottomBarElem?: JSX.Element | JSX.Element[]
  goToPage?: (pageNumber: number) => void
  scale?: number
  onPasswordCancel?: () => void
}

export default function PDFViewerSinglePage(props: IPDFViewerSinglePageProps) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    setPageNumber(1)
    setNumPages(0)
  }, [props.pdf])


  useImperativeHandle(props.onRef, () => {
   return {
      setPageNumber: handleSetPageNumber,
      prevPage: previousPage,
      nextPage: nextPage,
      }
  })

  function handleSetPageNumber(pageNum: number) {
    if (pageNum > numPages || pageNum < 0 || pageNum === pageNumber ) return
    setPageNumber(pageNum)
  }

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setPageNumber(1)
    setNumPages(pdf.numPages);
    return props.handleDocumentLoadSuccess(pdf)
  }

  function handleOnPassword(callback: (password: string) => void, reason: number) {
    onPassword(callback, reason, props.onPasswordCancel)
  }
  const findTransformByPage = (
    pageNum: number
  ): IOverlayPageTransform | undefined => {
    return _.head(
      props.pageTransforms?.filter((transform) => transform.page === pageNum)
    )
  }

  const rotatePage = (pageNum: number) => {
    const pageElem = document.querySelector(
      "[data-page-number='" + pageNum + "']"
    )
    const canvasElem = (
      [].slice.call(
        pageElem?.getElementsByTagName('canvas')
      ) as HTMLCanvasElement[]
    )[0]
    const transformByPage = findTransformByPage(pageNum)
    if (transformByPage && canvasElem) {
      canvasElem.style.transform = `rotate(${transformByPage.angleDegree}deg) scale(${transformByPage.scale})`
    }
  }

  const scaleFromPage = (page: number) => {
    const pageTransform = props.pageTransforms?.find((p) => p.page === page)
    if (pageTransform) {
      return pageTransform.scale
    }
    return 1
  }

  const renderPDFOverlaysByPage = (pageNumber: number) => {
    if (!props.overlays || props.overlays.length === 0) return
    const overlaysOfPage: IOverlay[] = props.overlays.filter(
      (overlay) => overlay.page === pageNumber
    )
    return overlaysOfPage.map((overlay) => {
      return (
        <SelectableOverlay
          key={overlay.id}
          overlay={overlay}
          isNonSelectable={true}
        />
      )
    })
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage(): number {
    if (pageNumber <= 1) return pageNumber
    changePage(-1)
    return pageNumber
  }

  function nextPage(): number {
    if (pageNumber >= numPages) return pageNumber
    changePage(1)
    return pageNumber
  }


  return (
    <div
      id="PDFContainer"
      className="textractDocViewer textractDocViewerMobile"
    >
      <div id="viewerContainer" tabIndex={0}>
        <div key="viewer" id="viewer" className="pdfViewer mobile">
          <Document
            file={props.pdf}
            loading={<div>Loading...</div>}
            onLoadSuccess={onDocumentLoadSuccess}
            onPassword={(callback: (password: string) => void, reason: number) => handleOnPassword(callback, reason)}
            options={options}
          >
            <div className="pageWrapper">
              <ErrorBoundary
                FallbackComponent={() => <div>Something went wrong</div>}
              >
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={props.scale ? scaleFromPage(pageNumber) * props.scale : 1}
                onRenderSuccess={() => rotatePage(pageNumber)}
              >
                <div style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  textAlign: 'center',
                  fontSize: '12px',
                  color: 'rgba(0,0,0,0.68)',
                }}>
                  <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                  </p>
                </div>
              </Page>
              </ErrorBoundary>
              <div className="bglLayer">
                {renderPDFOverlaysByPage(pageNumber)}
              </div>

            </div>
          </Document>
        </div>
      </div>
    </div>
  )
}
