import { FieldAndColumnName } from '@bgl/textract-business-model-editor'
import { SplitPageDetection } from '@bgl/textract-business-model-editor/dist/domain/businessModel/businessModelType/BusinessModelType'
import { BusinessModelTypePageTableRow } from '@bgl/textract-business-model-editor/dist/domain/businessModel/businessModelType/BusinessModelTypePageTable'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class BusinessModelTypeToTableData {
  static toRows = (
    models: List<BusinessModelTypePageTableRow>
  ): GridRowsProp => {
    return models
      .map((model, index) =>
        BusinessModelTypeToTableData.toGridRow(model, index + 1)
      )
      .toArray()
  }

  static toGridRow = (model: BusinessModelTypePageTableRow, id: number) => {
    return {
      id,
      [FieldAndColumnName.BusinessModelTypePagesEditor_ModelName]:
        model.modelName,
      [FieldAndColumnName.BusinessModelTypePagesEditor_Probability]:
        model.probability
    }
  }
}

export class SplitPageDetectionToTableData {
  static toRows = (pageDetections: List<SplitPageDetection>): GridRowsProp => {
    return pageDetections
      .map((pageDetection, index) =>
        SplitPageDetectionToTableData.toGridRow(pageDetection, index + 1)
      )
      .toArray()
  }

  static toGridRow = (model: SplitPageDetection, id: number) => {
    return {
      id,
      documentSplitType: model.documentSplitType,
      probability: model.probability
    }
  }
}
