import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import React from 'react'

import {
  closeSnackbar,
  useSnackbarStore
} from '../services/stores/SnackBarStore'

export default function ConsecutiveSnackbars() {
  const { open, snackPack, messageInfo, autoHideDuration } = useSnackbarStore()

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when don't have an active one
      useSnackbarStore.setState({
        messageInfo: { ...snackPack[0] },
        snackPack: snackPack.slice(1),
        open: true
      })
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      closeSnackbar()
    }
  }, [snackPack, messageInfo, open])

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    closeSnackbar()
  }

  return (
    <div>
      <Snackbar
        key={messageInfo?.key}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={messageInfo?.type || 'info'}>
          {messageInfo?.message}
        </Alert>
      </Snackbar>
    </div>
  )
}
