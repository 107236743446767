import {List} from 'immutable'
import {flatten} from '../../utils/utils'
import {CellVisitor, LineVisitor, PageVisitor, TableVisitor, WordVisitor} from '../DocumentVisitor'
import {Cell} from '../ocr/Cell'
import {Line} from '../ocr/Line'
import {Page} from '../ocr/Page'
import {Table} from '../ocr/Table'
import {Word} from '../ocr/Word'

export class WordsVisitor implements PageVisitor<List<Word>>,
  TableVisitor<List<Word>>,
  CellVisitor<List<Word>>,
  LineVisitor<List<Word>>,
  WordVisitor<Word> {
  visitWord(word: Word): Word {
    return word
  }

  visitLine(line: Line): List<Word> {
    return line.accept(this)
  }

  visitCell(cell: Cell): List<Word> {
    return cell.accept(this)
  }

  visitTable(table: Table): List<Word> {
    return flatten(table.accept(this))
  }

  visitPage(page: Page): List<Word> {
    const allWords = flatten(page.acceptLineVisitor(this))
      // as table and lines shares same words, we needs only words
      // .concat(
      //   flatten(page.acceptTableVisitor(this))
      // )

    return allWords
  }
}
