export enum CreateType {
  Rect = 'Rect',
  Dot = 'Dot'
}
export type Point = [number, number]

export interface DrawStyle {
  strokeStyle: string
  fillStyle: string
  lineWidth: number
}

export interface Label {
  font: string
  height: string
  textMaxLen: number
  fillStyle: string
}

export interface Ctrl {
  strokeStyle: string
  fillStyle: string
  r: number
}

export type ZoomType = 'in' | 'out'