import type { IDocument } from '@bgl/textract-business-model-editor'
import { ISmartDocsResult } from '@bgl/textract-business-model-editor'
import autobind from 'autobind-decorator'
import * as _ from 'lodash'
import moment from 'moment'

export enum WorkflowStatus {
  INITED = 'INITED',
  QR_SCANNED_OR_LINK_CLICKED = 'QR_SCANNED_OR_LINK_CLICKED',
  FILE_RECEIVED = 'FILE_RECEIVED',
  FILE_UPLOADED = 'FILE_UPLOADED',
  OCR_SCHEDULED = 'OCR_SCHEDULED',
  PREDICTION_SCHEDULED = 'PREDICTION_SCHEDULED',
  RESULT_READY = 'RESULT_READY',
  EXPIRED_OR_NOT_FOUND = 'EXPIRED_OR_NOT_FOUND',
  EXCEPTION_OCCURRED = 'EXCEPTION_OCCURRED',
  FILE_DELETED = 'FILE_DELETED'
}

export const ProgressingStatus = [
  WorkflowStatus.FILE_RECEIVED,
  WorkflowStatus.FILE_UPLOADED,
  WorkflowStatus.OCR_SCHEDULED,
  WorkflowStatus.PREDICTION_SCHEDULED
]

export const ProcessedStatus = [
  WorkflowStatus.RESULT_READY,
  WorkflowStatus.EXPIRED_OR_NOT_FOUND,
  WorkflowStatus.EXCEPTION_OCCURRED
]

export interface FileImageUrls {
  urls: string[]
}
export interface highlightDTO {
  content?: string[]
  'file.name'?: string[]
}

export interface TFileDTO {
  id: string
  filename: string
  workflowKey: string
  size: number
  pages?: number
  workflowStatus?: WorkflowStatus
  lastUpdatedTime?: string
  isRead?: boolean
  labelIds?: string[]
  businessModelSize?: number
  wordAnnotationSize?: number
  pageAnnotationSize?: number
  objectAnnotationSize?: number
  analyticalGroup1?: string
  analyticalGroup2?: string
  highlight?: highlightDTO
  uploadUser?: string
  emailFileKey?: string
  exceptionMessage?: string
  fileImageUrls?: FileImageUrls
}

@autobind
export class TFile {
  constructor(
    public id: string,
    public filename: string,
    public workflowKey: string,
    public size: number,
    public uploadedAt: moment.Moment,
    public updatedAt: moment.Moment | undefined = undefined,
    public uploadUser: string | undefined = undefined,
    public isActive: boolean = false,
    public pages: number | undefined = undefined,
    public workflowStatus: WorkflowStatus | undefined = undefined,
    public isRead: boolean | undefined = undefined,
    public labelIds: string[] | undefined = undefined,
    public businessModelSize: number | undefined = undefined,
    public wordAnnotationSize: number | undefined = undefined,
    public pageAnnotationSize: number | undefined = undefined,
    public objectAnnotationSize: number | undefined = undefined,
    public analyticalGroup1: string | undefined = undefined,
    public analyticalGroup2: string | undefined = undefined,
    public highlight: highlightDTO | undefined = undefined,
    public emailFileKey: string | undefined = undefined,
    public exceptionMessage: string | undefined = undefined,
    public fileBlob: Blob | null = null,
    public fileTextract: IDocument | undefined = undefined,
    public fileResult: ISmartDocsResult | null = null,
    public fileImageUrls: FileImageUrls | null = null
  ) {}

  static fromJson(tFileDTO: TFileDTO): TFile {
    const {
      id,
      filename,
      workflowKey,
      size,
      pages,
      workflowStatus,
      isRead,
      labelIds,
      businessModelSize,
      wordAnnotationSize,
      pageAnnotationSize,
      objectAnnotationSize,
      analyticalGroup1,
      analyticalGroup2,
      highlight,
      uploadUser,
      emailFileKey,
      exceptionMessage
    } = tFileDTO
    const uploadAt = moment(id.split('_')[0])
    const updatedAt = tFileDTO.lastUpdatedTime
      ? moment(tFileDTO.lastUpdatedTime)
      : undefined
    return new TFile(
      id,
      filename,
      workflowKey,
      size,
      uploadAt,
      updatedAt,
      uploadUser,
      false,
      pages,
      workflowStatus,
      isRead,
      labelIds,
      businessModelSize,
      wordAnnotationSize,
      pageAnnotationSize,
      objectAnnotationSize,
      analyticalGroup1,
      analyticalGroup2,
      highlight,
      emailFileKey,
      exceptionMessage
    )
  }

  copy({
    isActive = this.isActive,
    pages = this.pages,
    workflowStatus = this.workflowStatus,
    isRead = this.isRead,
    labelIds = this.labelIds,
    fileBlob = this.fileBlob,
    fileTextract = this.fileTextract,
    fileResult = this.fileResult,
    filename = this.filename,
    fileImageUrls = this.fileImageUrls
  }: Partial<TFile>): TFile {
    return new TFile(
      this.id,
      filename,
      this.workflowKey,
      this.size,
      this.uploadedAt,
      this.updatedAt,
      this.uploadUser,
      isActive,
      pages,
      workflowStatus,
      isRead,
      labelIds,
      this.businessModelSize,
      this.wordAnnotationSize,
      this.pageAnnotationSize,
      this.objectAnnotationSize,
      this.analyticalGroup1,
      this.analyticalGroup2,
      this.highlight,
      this.emailFileKey,
      this.exceptionMessage,
      fileBlob,
      fileTextract,
      fileResult,
      fileImageUrls
    )
  }

  mergeFile({ pages, workflowStatus }: TFile): TFile {
    return this.copy({ pages, workflowStatus })
  }

  isOpened(): boolean {
    return !!this.fileImageUrls || !!this.fileBlob
  }

  setIsActive(isActive: boolean): TFile {
    return this.copy({ isActive })
  }

  setStatus(workflowStatus: string): TFile {
    return this.copy({ workflowStatus: workflowStatus as WorkflowStatus })
  }

  setResult(fileResult: ISmartDocsResult | null): TFile {
    return this.copy({ fileResult })
  }

  setFileName(filename: string): TFile {
    return this.copy({ filename })
  }

  setIsRead(): TFile {
    return this.copy({ isRead: true })
  }

  setLabelIds(labelIds: string[]): TFile {
    return this.copy({ labelIds })
  }

  setImageUrls(fileImageUrls: FileImageUrls): TFile {
    return this.copy({ fileImageUrls })
  }

  get annotationSize(): number {
    return (
      (this.wordAnnotationSize ?? 0) +
      (this.pageAnnotationSize ?? 0) +
      (this.objectAnnotationSize ?? 0)
    )
  }

  isError(): boolean {
    return (
      this.workflowStatus === WorkflowStatus.EXCEPTION_OCCURRED ||
      this.workflowStatus === WorkflowStatus.EXPIRED_OR_NOT_FOUND
    )
  }
}

export class TFiles {
  constructor(public files: TFile[] = []) {}

  static fromJson(tFileDTOS: TFileDTO[]): TFiles {
    const tFiles = tFileDTOS.map(TFile.fromJson)
    return new TFiles(tFiles)
  }

  public get allFiles(): TFile[] {
    return this.files
  }

  getIndex(file: TFile): number {
    return this.files.indexOf(file)
  }

  copy({ files = this.files }: Partial<TFiles>): TFiles {
    return new TFiles(files)
  }

  isEmpty(): boolean {
    return this.files.length === 0
  }

  size(): number {
    return this.files.length
  }

  fileIds(): string[] {
    return this.files.map((file) => file.id)
  }

  filesInMap(): { [id: string]: TFile } {
    return _.fromPairs(this.files.map((file) => [file.id, file]))
  }

  openedFiles(): TFiles {
    return this.copy({ files: this.files.filter((file) => file.isOpened()) })
  }

  unionFile(file: TFile): TFiles {
    return this.copy({
      files: _.unionBy(this.files, [file], 'id')
    })
  }

  updateFile(fileId: string, fn: (value: TFile) => TFile): TFiles {
    return this.copy({
      files: this.files.map((f) => (f.id === fileId ? fn(f) : f))
    })
  }

  deleteFile(fileId: string): TFiles {
    return this.copy({
      files: this.files.filter(({ id }) => id !== fileId)
    })
  }

  activeFile(id: string): TFiles {
    return this.copy({
      files: this.files.map((f) => f.setIsActive(f.id === id))
    })
  }

  mergeFiles(files: TFiles): TFiles {
    const fileInMap = this.filesInMap()
    return files.allFiles.reduce((files: TFiles, file: TFile) => {
      const merged = fileInMap[file.id]?.mergeFile(file) ?? file
      return files.unionFile(merged)
    }, this)
  }

  getActiveFile(): TFile | undefined {
    return (
      this.openedFiles()
        .filter(({ isActive }) => isActive)
        .head() ?? this.openedFiles().head()
    )
  }

  head(): TFile | undefined {
    return this.files[0]
  }

  filter(
    fn: (value: TFile, index?: number, array?: TFile[]) => boolean
  ): TFiles {
    return this.copy({ files: this.files.filter(fn) })
  }

  mapValues<T>(fn: (value: TFile, index?: number) => T): T[] {
    return this.files.map(fn)
  }

  hasNoCreditsException(): boolean {
    return this.files.some(
      (file) => file.exceptionMessage === 'No credits left.'
    )
  }
}
