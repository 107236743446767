import React from "react";
import {BoundingBox, IOverlay} from "./IOverlay";
import {createSelectable, TSelectableItemProps} from "../selectable"
import {mathRound} from "../utils/utils"

export interface SelectableOverlayProps {
  overlay: IOverlay
  isNonSelectable?: boolean
}

const SelectableOverlay = createSelectable<SelectableOverlayProps>((props: SelectableOverlayProps & TSelectableItemProps) => {
  const {selectableRef, isSelected, isSelecting, overlay} = props

  //'not-selectable' is the one you don't allow selection
  const classNames = [
    'wordBlock',
    'overlay',
    props.isNonSelectable && 'not-selectable',
    isSelecting && 'selecting',
    isSelected && 'selected'
  ]
    .filter(Boolean)
    .join(' ')

  function toStyle(boundingBox: BoundingBox) {
    return {
      left: mathRound(boundingBox.left * 100) + '%',
      top: mathRound(boundingBox.top * 100) + '%',
      width: mathRound(boundingBox.width * 100) + '%',
      height: mathRound(boundingBox.height * 100) + '%'
    }
  }

  return (
    <div
      ref={selectableRef}
      className={classNames}
      key={overlay.id}
      id={overlay.id}
      style={toStyle(overlay.boundingBox)}
      title={overlay.hint}
      // onClick={(event) => onClick(event, block)}
    />
  )
})

export default SelectableOverlay
