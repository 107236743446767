import autobind from 'autobind-decorator'
import * as _ from 'lodash'

import { PlaceDetails, PlaceDetailsAddressTypes } from '../services/GoogleApi'
import { postcodeValidator } from '../utils/validator'

export interface AddressDTO {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  postcode: string
  country: string
  placeId?: string
}

@autobind
export default class Address {
  constructor(
    public addressLine1?: string,
    public addressLine2?: string,
    public city?: string,
    public state?: string,
    public postcode?: string,
    public country?: string,
    public placeId?: string
  ) {}

  static fromJson(addressDTO: AddressDTO): Address {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      postcode,
      country,
      placeId
    } = addressDTO
    return new Address(
      addressLine1,
      addressLine2,
      city,
      state,
      postcode,
      country,
      placeId
    )
  }

  copy({
    addressLine1 = this.addressLine1,
    addressLine2 = this.addressLine2,
    city = this.city,
    state = this.state,
    postcode = this.postcode,
    country = this.country,
    placeId = this.placeId
  }): Address {
    return new Address(
      addressLine1,
      addressLine2,
      city,
      state,
      postcode,
      country,
      placeId
    )
  }

  setAddressLine1(addressLine1?: string): Address {
    return this.copy({ addressLine1 })
  }

  setAddressLine2(addressLine2?: string): Address {
    return this.copy({ addressLine2 })
  }

  setCity(city?: string): Address {
    return this.copy({ city })
  }

  setState(state?: string): Address {
    return this.copy({ state })
  }

  setPostcode(postcode?: string): Address {
    return this.copy({ postcode })
  }

  setCountry(country?: string): Address {
    return this.copy({ country })
  }

  setPlaceId(placeId?: string): Address {
    return this.copy({ placeId })
  }

  withPlaceDetails(placeDetails: PlaceDetails): Address {
    const {
      subpremise,
      street_number,
      route,
      locality,
      administrative_area_level_1,
      postal_code,
      country,
      place_id
    } = addressComponentsFromPlaceDetails(placeDetails)
    const street = street_number ? `${street_number} ${route}` : route
    return this.copy({
      addressLine1: subpremise ?? street,
      addressLine2: subpremise ? street : undefined,
      city: locality,
      state: administrative_area_level_1,
      postcode: postal_code,
      country: country,
      placeId: place_id
    })
  }

  canSetup(): boolean {
    return (
      this.addressLine1 !== '' &&
      this.city !== '' &&
      this.state !== '' &&
      this.postcode !== '' &&
      this.country !== ''
    )
  }

  isValid(): boolean {
    return (
      Boolean(this.addressLine1) &&
      Boolean(this.city) &&
      Boolean(this.state) &&
      Boolean(this.postcode) &&
      Boolean(this.country)
    )
  }

  setInvalid(): Address {
    return this.copy({
      addressLine1: this.addressLine1 || '',
      city: this.city || '',
      state: this.state || '',
      postcode: this.postcode || '',
      country: this.country || ''
    })
  }

  isWarning(): boolean {
    return !(
      Boolean(this.addressLine1) &&
      Boolean(this.city) &&
      Boolean(this.state) &&
      Boolean(this.postcode) &&
      Boolean(this.country) &&
      postcodeValidator(this.postcode ?? '')
    )
  }

  formattedSuburb(): string {
    return `${this.city ?? ''} ${this.state ?? ''} ${
      this.postcode ?? ''
    }`.trim()
  }

  formattedAddress(): string {
    return _.compact([
      this.addressLine1 || null,
      this.addressLine2 || null,
      this.formattedSuburb() || null,
      this.country
    ]).join(', ')
  }

  toAddressDTO(): AddressDTO {
    return {
      addressLine1: this.addressLine1 ?? '',
      addressLine2: this.addressLine2,
      city: this.city ?? '',
      state: this.state ?? '',
      postcode: this.postcode ?? '',
      country: this.country ?? '',
      placeId: this.placeId
    }
  }
}

export const addressComponentsFromPlaceDetails = (
  placeDetails: PlaceDetails
) => {
  const getType = (type: PlaceDetailsAddressTypes) =>
    _.find(placeDetails.address_components, ({ types }) =>
      _.includes(types, type)
    )?.long_name
  return {
    subpremise: getType(PlaceDetailsAddressTypes.subpremise),
    street_number: getType(PlaceDetailsAddressTypes.street_number),
    route: getType(PlaceDetailsAddressTypes.route),
    locality: getType(PlaceDetailsAddressTypes.locality),
    administrative_area_level_1: getType(
      PlaceDetailsAddressTypes.administrative_area_level_1
    ),
    postal_code: getType(PlaceDetailsAddressTypes.postal_code),
    country: getType(PlaceDetailsAddressTypes.country),
    place_id: placeDetails.place_id
  }
}
