import {
  FieldAndColumnName,
  PayGInstalmentYearlyTable
} from '@bgl/textract-business-model-editor'
import type { GridColDef } from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React from 'react'

import { PayGInstalmentTableData } from './PayGInstalTableData'

interface IPaygInstalmentYearlyTableEditorProps {
  payGInstalmentYearlyTable: PayGInstalmentYearlyTable
}

export const PayGInstalmentYearlyTableEditor = (
  props: IPaygInstalmentYearlyTableEditorProps
) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  const columns: GridColDef[] = [
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber,
      type: 'string',
      headerName: 'TFN',
      description: 'Tfn Number',
      width: 100,
      editable: true
    },
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName,
      type: 'string',
      headerName: 'CLIENT NAME',
      description: 'Client Name',
      width: 200,
      editable: true
    },
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1,
      type: 'number',
      headerName: 'Q1',
      width: 80,
      editable: true
    },
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2,
      type: 'number',
      headerName: 'Q2',
      width: 80,
      editable: true
    },
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3,
      type: 'number',
      headerName: 'Q3',
      width: 80,
      editable: true
    },
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4,
      type: 'number',
      headerName: 'Q4',
      width: 80,
      editable: true
    },
    {
      field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment,
      type: 'number',
      headerName: 'TOTAL INSTALMENT',
      width: 100,
      editable: true
    }
  ]
  const rows = PayGInstalmentTableData.toRows(props.payGInstalmentYearlyTable)
  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
    />
  )
}

export default PayGInstalmentYearlyTableEditor
