import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useLoadingStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: 40
  }
}))

const Loading = ({ paddingTop = true }: { paddingTop?: boolean }) => {
  const classes = useLoadingStyles()
  return (
    <div
      className={classes.wrapper}
      style={{ paddingTop: paddingTop ? 200 : 0 }}
    >
      <FontAwesomeIcon icon={faSpinner} pulse />
    </div>
  )
}

export default Loading
