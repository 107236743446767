import React from 'react'

import PretrainedAIModels from './PretrainedAIModels'

function PretrainedAIModelsTop() {
  return (
    <section className="pretrained-ai-models" id="pretrainedmodels">
      <div className="top">
        <div className="container container-wrapper">
          <h3 className="title">Approach 1:</h3>
          <h3 className="sub-title">Pre-trained AI Models</h3>
          <p>
            SmartDocs AI Studio has a wide range of pre-trained business models
            ranging from Bank Statements, Utility Bills to Invoices.
          </p>
          <p>
            Simply select the desired pre-trained projects and start extracting
            (predicate) information from new files – no tagging and training
            required!
          </p>
          <PretrainedAIModels />
        </div>
      </div>
    </section>
  )
}

export default PretrainedAIModelsTop
