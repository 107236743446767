import { createStyles, makeStyles } from '@material-ui/core/styles'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React from 'react'

import Business from '../../models/Business'
import { useInitStore } from '../../services/stores/InitStore'
import { usePaymentStore } from '../../services/stores/PaymentStore'

const checkoutStyles = makeStyles((theme) =>
  createStyles({
    payNowButton: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginTop: theme.spacing(2),
      borderRadius: '0.5rem',
      padding: theme.spacing(1, 2),
      fontSize: '1rem',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.grey[700],
        cursor: 'not-allowed'
      }
    },
    paymentMessage: {
      color: theme.palette.error.main,
      fontSize: '0.8rem',
      marginTop: theme.spacing(1)
    }
  })
)
const CheckoutForm = () => {
  const classes = checkoutStyles()
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = React.useState<string | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { setPaymentSuccess } = usePaymentStore((state) => ({
    setPaymentSuccess: state.setPaymentSuccess
  }))
  const currentBusiness = useInitStore((state) => state.currentBusiness)
  const setCurrentBusiness = useInitStore((state) => state.setCurrentBusiness)

  const calcBusinessCreditsAfterPayment = (amount: number) => {
    //   After received payment succeeds from Stripe, update the business credits left in frontend assuming the payment is successful
    const amountInDollars = amount / 100
    const newCreditsLeft = currentBusiness.creditsLeft + amountInDollars
    const newBusiness: Business = currentBusiness.setCreditsLeft(newCreditsLeft)
    setCurrentBusiness(newBusiness)
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    })

    if (result.error) {
      if (result.error.message) {
        setMessage(result.error.message)
      } else {
        setMessage('An unexpected error occurred.')
      }
    }

    if (result.paymentIntent?.status === 'succeeded') {
      setPaymentSuccess(true)
      calcBusinessCreditsAfterPayment(result.paymentIntent.amount)
    }
    setIsLoading(false)
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <PaymentElement id="payment-element" />
      <button
        className={classes.payNowButton}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">{isLoading ? 'Submitting' : 'Pay now'}</span>
      </button>
      {message && (
        <div className={classes.paymentMessage} id="payment-message">
          {message}
        </div>
      )}
    </form>
  )
}

export default CheckoutForm
