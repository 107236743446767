import autobind from 'autobind-decorator'
import * as _ from 'lodash'

@autobind
export default class GoogleApi {
  private static instance: GoogleApi
  apiKey: string
  apiPath: string = 'https://maps.googleapis.com/maps/api'
  autocompleteService: any
  placeService: any
  sessionToken: any
  location: any
  predictPlace: _.DebouncedFunc<
    (
      input: string,
      types: string[],
      countryCode: string,
      currentLot: GeolocationPosition | null,
      callback: (results?: PlacePrediction[]) => void
    ) => void
  >

  private constructor(apiKey: string) {
    this.apiKey = apiKey
    this.loadGoogleScript()
    this.predictPlace = _.debounce(this._predictPlace, 1000)
  }

  static getInstance(): GoogleApi {
    if (!GoogleApi.instance) {
      GoogleApi.instance = new GoogleApi(
        'AIzaSyDUggK7wEALAUmhjHxvjlAGpS1rQH34xhc'
      )
    }
    return GoogleApi.instance
  }

  loadGoogleScript() {
    const position = document.querySelector('head')
    const googleMaps = document.getElementById('google-maps')
    if (position && !googleMaps) {
      const script = document.createElement('script')
      script.setAttribute('async', '')
      script.setAttribute('id', 'google-maps')
      script.src = `${this.apiPath}/js?key=${this.apiKey}&libraries=places`
      position.appendChild(script)
    }
  }

  _predictPlace(
    input: string,
    types: string[],
    countryCode: string,
    currentLot: GeolocationPosition | null,
    callback: (results?: PlacePrediction[]) => void
  ): void {
    if ((window as any).google) {
      if (!this.autocompleteService) {
        this.autocompleteService = new (
          window as any
        ).google.maps.places.AutocompleteService()
      }
      if (!this.location && currentLot) {
        this.location = new (window as any).google.maps.LatLng({
          lat: currentLot.coords.latitude,
          lng: currentLot.coords.longitude
        })
      }
      if (this.autocompleteService && input) {
        this.autocompleteService.getPlacePredictions(
          {
            input,
            componentRestrictions: { country: countryCode },
            types,
            location: this.location,
            radius: this.location ? 500 : undefined
          },
          callback
        )
      } else {
        callback([])
      }
    }
  }

  getPlaceDetails(
    placeId: string,
    callback: (placeDetails?: PlaceDetails) => void
  ): void {
    if ((window as any).google) {
      if (!this.placeService) {
        this.placeService = new (
          window as any
        ).google.maps.places.PlacesService(
          document.getElementById('google-map')
        )
      }
      if (this.placeService) {
        const fields = [
          'name',
          'formatted_address',
          'formatted_phone_number',
          'website',
          'geometry',
          'address_components'
        ]
        this.placeService.getDetails({ fields, placeId }, callback)
      }
    }
  }
}

export enum PlaceTypes {
  establishment = 'establishment',
  address = 'address',
  cities = '(cities)'
}

export interface PlacePrediction {
  description: string
  id: string
  place_id: string
  reference: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
  types: PlaceTypes[]
}

export enum PlaceDetailsAddressTypes {
  subpremise = 'subpremise',
  street_number = 'street_number',
  route = 'route',
  locality = 'locality',
  administrative_area_level_2 = 'administrative_area_level_2',
  administrative_area_level_1 = 'administrative_area_level_1',
  country = 'country',
  postal_code = 'postal_code'
}

export interface PlaceDetails {
  place_id: string
  name: string
  formatted_address: string
  formatted_phone_number: string
  international_phone_number?: string
  website: string
  address_components: {
    long_name: string
    types: PlaceDetailsAddressTypes[]
  }[]
}
