import {
  BankAuthority,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import React from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateFieldArray } from '../FormDateFieldArray'
import { FormStringField } from '../FormStringField'
import { FormStringFieldArray } from '../FormStringFieldArray'
import { BankAuthorityAccountsTableEditor } from './BankAuthorityTableEditor'

interface IBankAuthorityEditorProps extends EditorFieldEvents {
  bankAuthority: BankAuthority
  className?: string
}

export const BankAuthorityEditor = (props: IBankAuthorityEditorProps) => {
  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.BankAuthorityEditor_PracticeName}
            label={'Practice Name'}
            events={props}
            field={props.bankAuthority.practiceName}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.BankAuthorityEditor_AcnAbn}
            label={'Acn ABN'}
            events={props}
            field={props.bankAuthority.acnAbn}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.BankAuthorityEditor_SmsfName}
            label={'Smsf Name'}
            events={props}
            field={props.bankAuthority.smsfName}
            className={props.className}
          />
        </Grid>
        {props.bankAuthority.signatoriesName && (
          <Grid item xs={6}>
            <FormStringFieldArray
              id={FieldAndColumnName.BankAuthorityEditor_SignatoriesName}
              label={'Signatories Name'}
              events={props}
              fields={props.bankAuthority.signatoriesName}
              className={props.className}
            />
          </Grid>
        )}
        {props.bankAuthority.signature && (
          <Grid item xs={6}>
            <FormStringFieldArray
              id={FieldAndColumnName.BankAuthorityEditor_Signature}
              label={'Signature'}
              events={props}
              fields={props.bankAuthority.signature}
              className={props.className}
            />
          </Grid>
        )}
        {props.bankAuthority?.signatureDate && (
          <Grid item xs={6}>
            <FormDateFieldArray
              id={FieldAndColumnName.BankAuthorityEditor_SignatureDate}
              label={'Signature Date'}
              events={props}
              fields={props.bankAuthority.signatureDate}
              className={props.className}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <BankAuthorityAccountsTableEditor
            accounts={props.bankAuthority?.accounts}
          />
        </Grid>
      </Grid>
    </form>
  )
}
