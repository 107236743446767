import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { faGrid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Tooltip
} from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'

import { useDashboardStyles } from '../assets/styles/DashboardStyles'
import PopoverMessenger from '../components/contactUs/PopoverMessenger'
import DashboardUserProfile from '../components/toolBar/DashboardUserProfile'
import ProjectSelector from '../components/toolBar/ProjectSelector'
import useZendeskStore from '../services/stores/ZenDeskStore'
import AppSwitcher from '../utils/AppSwitcher'
import { Documentation } from '../utils/Documentation'
import SignOut from '../utils/SignOut'
import useZenDeskWidget from '../utils/useZenDeskWidget'

interface IHeaderProps {
  openMenu: boolean
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({ openMenu, setOpenMenu }: IHeaderProps) => {
  const classes = useDashboardStyles()
  const [appSwitchAnchorEl, setAppSwitchAnchorEl] =
    React.useState<HTMLButtonElement | null>(null)
  const unreadCount = useZendeskStore((state) => state.unreadCount)
  const { showChatWidget } = useZenDeskWidget()

  function handleOpenSwitcher(event: React.MouseEvent<HTMLButtonElement>) {
    setAppSwitchAnchorEl(event.currentTarget)
  }

  const open = Boolean(appSwitchAnchorEl)
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, { [classes.appBarShift]: openMenu })}
    >
      <Toolbar>
        <Box>
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => setOpenMenu(true)}
            className={clsx(classes.menuButton, openMenu && classes.hide)}
          >
            <Menu />
          </IconButton>
        </Box>
        <ProjectSelector />
        <PopoverMessenger />
        <Tooltip title={'Live Chat'}>
          <Badge
            badgeContent={unreadCount}
            color="secondary"
            className={classes.zendeskChatBadge}
            onClick={showChatWidget}
          >
            <IconButton>
              <FontAwesomeIcon icon={faComment} />
            </IconButton>
          </Badge>
        </Tooltip>
        <Documentation />
        <SignOut />
        <Tooltip title={'App switcher'}>
          <IconButton onClick={handleOpenSwitcher}>
            <FontAwesomeIcon icon={faGrid} />
          </IconButton>
        </Tooltip>
        <Divider orientation={'vertical'} className={classes.verticalDivider} />
        <DashboardUserProfile />
      </Toolbar>
      <AppSwitcher
        open={open}
        anchorEl={appSwitchAnchorEl}
        setAppSwitchAnchorEl={setAppSwitchAnchorEl}
      />
    </AppBar>
  )
}

export default Header
