import {
  IPageAnnotations,
  IWordAnnotation
} from '@bgl/textract-business-model-editor'

import { Tags } from '../models/Tag'

const createStyleElement = (fileId: string): HTMLStyleElement => {
  const styleElement = document.createElement('style')
  styleElement.setAttribute('type', 'text/css')
  styleElement.setAttribute('id', `DocViewerCss_${fileId}`)
  document.querySelector('head')?.append(styleElement)
  return styleElement
}

export const InsertAnnotationCss = (
  fileId: string,
  annotations: IWordAnnotation[] = [],
  tags: Tags | null
) => {
  const styleElement =
    document.getElementById(`DocViewerCss_${fileId}`) ??
    createStyleElement(fileId)
  styleElement.textContent = annotations
    .map(({ WordId, Annotation }) => {
      const tag = tags?.allTags.find((tag) => tag.id === Annotation)
      const rgb = tag ? tag.color : ''
      return /^\d+$/.test(WordId[0])
        ? `#\\3${WordId[0]} ${WordId.slice(
            1
          )} { background: ${rgb}; opacity: 0.5; }`
        : `#${WordId} { background: ${rgb}; opacity: 0.5; }`
    })
    .join('\n')
}

export const InsertAnnotationCssForMultiClassPages = (
  fileId: string,
  annotations: IPageAnnotations[] = [],
  tags: Tags | null
) => {
  const styleElement =
    document.getElementById(`DocViewerCss_${fileId}`) ??
    createStyleElement(fileId)
  styleElement.textContent = annotations
    .map(({ Page, Annotations }) => {
      const tag = tags?.allTags.find(
        (tag) => tag.id === Annotations[0].Annotation
      )
      const rgb = tag ? tag.color : ''
      return /^\d+$/.test(Page[0])
        ? `#\\3${Page[0]} ${Page.slice(
            1
          )} { background: ${rgb}; opacity: 0.5; }`
        : `#${Page} { background: ${rgb}; opacity: 0.5; }`
    })
    .join('\n')
}
