import type { TransactionColumn } from '@bgl/textract-business-model-editor'
import {
  DetectedField,
  FieldAndColumnName,
  Transaction,
  Transactions
} from '@bgl/textract-business-model-editor'
import type {
  GridCellParams,
  GridColDef,
  GridEditCellValueParams,
  GridValueGetterParams
} from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import moment from 'moment'
import React, { FunctionComponent } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { BankStatementTransactionToTableData } from './BankStatementTransactionToTableData'

interface ITransactionTableEditorProp extends EditorFieldEvents {
  transactions: Transactions
  onCellClicked?: (
    transactions: Transactions,
    transaction: Transaction,
    field: DetectedField
  ) => void
  tableReadOnly?: boolean
}

export const TransactionTableEditor: FunctionComponent<
  ITransactionTableEditorProp
> = (tableProps: ITransactionTableEditorProp) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function onCellClick(params: GridCellParams) {
    if (tableProps.onCellClicked && tableProps.transactions) {
      const columnName = params.field
      const rowId = +params.id
      const transactions = tableProps.transactions
      const transaction = transactions.transactionByIndex(rowId)
      const field = transactions.fieldByColRow(
        columnName as TransactionColumn,
        rowId
      )
      if (transaction && field) {
        tableProps.onCellClicked(transactions, transaction, field)
      } else {
        console.error(
          `not able to field cell based on column ${columnName} and rowId ${rowId}`
        )
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: FieldAndColumnName.TransactionTable_Date,
      type: 'string',
      headerName: 'DATE',
      description: 'date',
      width: 100,
      editable: !tableProps.tableReadOnly
    },
    {
      field: FieldAndColumnName.TransactionTable_TotalDebit,
      type: 'number',
      headerName: 'DEBIT',
      width: 120,
      // resizable: true,
      editable: !tableProps.tableReadOnly
    },
    {
      field: FieldAndColumnName.TransactionTable_TotalCredit,
      type: 'number',
      headerName: 'CREDIT',
      width: 120,
      // resizable: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.value) {
          return Math.abs(params.value as number)
        } else {
          return undefined
        }
      },
      editable: !tableProps.tableReadOnly
    },
    {
      field: FieldAndColumnName.TransactionTable_Description,
      headerName: 'DESCRIPTION',
      width: 600,
      // resizable: true,
      editable: !tableProps.tableReadOnly
    },
    {
      field: FieldAndColumnName.TransactionTable_OpeningBalance,
      type: 'number',
      headerName: 'BALANCE',
      width: 90,
      // resizable: true,
      hide: false,
      editable: !tableProps.tableReadOnly
    }
  ]

  const rows = BankStatementTransactionToTableData.toRows(
    tableProps.transactions.transactions
  )

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
      onCellClick={onCellClick}
      onCellEditCommit={(params: GridEditCellValueParams) => {
        console.log('onCellValueChange', params)
        let value = params.value
        if (params.field === FieldAndColumnName.TransactionTable_Date) {
          value = moment(params.value as Date)
        }
        tableProps.onGridValueChanged?.(
          params.field as FieldAndColumnName,
          params.id,
          value
        )
      }}
      // onCellValueChange={
      //   (params: GridEditCellValueParams) => {
      //     // NOTE: Do not call handleGridInputValue() to immediately validate new value as onValueChanged() trigger
      //     // a new instance of model been injected to model editor, and useFormControl() validates all fields.
      //     // I intend to leave handleGridInputValue() for later use, redux-form might be used if performance is concern.
      //     // handleGridInputValue(params.field, params.id, params.value, record)
      //     console.log(`onCellValueChange: ${params.field} ${params.id} ${params.value}`)
      //     let value = params.value
      //     if (params.field === FieldAndColumnName.TransactionTable_Date) {
      //       value = moment(params.value as Date)
      //     }
      //     tableProps.onGridValueChanged?.(params.field as FieldAndColumnName, params.id, value)
      //   }
      // }
    />
  )
}
