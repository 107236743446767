import autobind from 'autobind-decorator'
import {List} from 'immutable'
import * as _ from 'lodash'
import { uuid } from 'utils/utils'
import {OcrDocument} from '../../OcrDocument'
import {
  IBankStatementModel,
  IBusinessModel,
  IDataType,
  IModelKeyValue,
  IModelType,
  ITransactionTable,
  ITransactionTableDataRow
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {BankStatement, RecipientAndAddress, TransactionTable} from './BankStatement'
import {Transaction} from './Transaction'
import {Transactions} from './Transactions'

@autobind
export class BankStatementMapper extends BusinessModelMapper{
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IBankStatementModel
  ) {
    super(document)
  }

  fromJson(): BankStatement {
    const jsonModel = this.jsonModel
    const FinancialInstitution = this.mapTextBlock(this.jsonModel.FinancialInstitution)
    const TransactionTables = this.jsonModel.TransactionTables
    const transactionTables = TransactionTables.map((table: ITransactionTable) => {
      return this.mapTransactionTable(table)
    })
    const recipientAndAddress = new RecipientAndAddress(
        this.mapTextBlock(jsonModel.RecipientAndAddress.Recipient),
        this.mapTextBlock(jsonModel.RecipientAndAddress.Address)  
      )


    const model = new BankStatement(      
      jsonModel.Version,
      jsonModel.BankName,
      List(transactionTables),
      recipientAndAddress,
      FinancialInstitution,
    )
    return model
  }

  mapTransaction(row: ITransactionTableDataRow): Transaction | undefined {
    const transactionDate = this.mapDateBlock(row.TransactionDate)
    const debit = this.mapDollarBlock(row.Debit)
    const credit = this.mapDollarBlock(row.Credit)
    if (transactionDate && (debit || credit)) {
      return new Transaction(
        transactionDate,
        this.mapTextBlock(row.Description),
        debit,
        credit,
        this.mapDollarBlock(row.Balance)
      )
    } else {
      return undefined
    }
  }

  mapTransactionTable(table: ITransactionTable): TransactionTable {
    const transactions = _.compact((table.Table.DataRows || []).map(this.mapTransaction))
    return new TransactionTable(
      new Transactions(List(transactions)),
      this.mapTextBlock(table.AccountName),
      this.mapTextBlock(table.ABN),
      this.mapTextBlock(table.BsbAccountNumber?.Bsb),
      this.mapTextBlock(table.BsbAccountNumber?.AccountNumber),
      this.mapDateBlock(table.StatementPeriod?.Start),
      this.mapDateBlock(table.StatementPeriod?.End),
      this.mapDollarBlock(table.AccountBalances?.OpeningBalance),
      this.mapDollarBlock(table.AccountBalances?.ClosingBalance),
      this.mapDollarBlock(table.AccountBalances?.TotalCredits),
      this.mapDollarBlock(table.AccountBalances?.TotalDebits)
    )
  }

  static toJson(model: BankStatement): IBusinessModel {
    return {
      ModelType: IModelType.BankStatement,
      Version: model.version,
      BankName: model.bankName,
      FinancialInstitution: model.financialInstitution?.toModelKeyValue(),
      RecipientAndAddress: {
        Recipient: model.recipientAndAddress.recipient?.toModelKeyValue(),
        Address: model.recipientAndAddress.address?.toModelKeyValue()
      },
      TransactionTables: model.transactionTables.toArray().map(table => table.toJson()),
      Currency: 'AUD'
    } as IBankStatementModel
  }

}
