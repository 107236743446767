import {
  FieldAndColumnName,
  Transaction
} from '@bgl/textract-business-model-editor'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class BankStatementTransactionToTableData {
  static toRows(transactions: List<Transaction>): GridRowsProp {
    return transactions
      .map((transaction, index) =>
        BankStatementTransactionToTableData.toGridRow(transaction, index)
      )
      .toArray()
  }

  static toGridRow = (transaction: Transaction, id: number) => {
    return {
      id,
      [FieldAndColumnName.TransactionTable_Date]:
        transaction.date?.parsedValue?.format('DD/MM/YYYY'),
      [FieldAndColumnName.TransactionTable_Description]:
        transaction.description?.parsedValue,
      [FieldAndColumnName.TransactionTable_TotalDebit]:
        transaction._debit()?.parsedValue,
      [FieldAndColumnName.TransactionTable_TotalCredit]:
        transaction._credit()?.parsedValue,
      [FieldAndColumnName.TransactionTable_OpeningBalance]:
        transaction.balance?.parsedValue
    }
  }
}
