import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {IBusinessModel, IModelType, IPropertySettlementStatement} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {PropertySettlementStatement} from './PropertySettlementStatement'

@autoBind
export class PropertySettlementStatementMapper extends BusinessModelMapper {
    constructor(
        document: OcrDocument,
        private readonly jsonModel: IPropertySettlementStatement
    ) {
        super(document)
    }

    fromJson(): PropertySettlementStatement {
        const PSSJson = this.jsonModel

        return new PropertySettlementStatement(
            IModelType.PropertySettlementStatement,
            PSSJson.Version,
            this.mapTextBlock(PSSJson.PurchaserName),
            this.mapTextBlock(PSSJson.VendorName),
            this.mapDateBlock(PSSJson.SettlementDate),
            this.mapTextBlock(PSSJson.PropertyAddress),
            this.mapPSSBlocks(PSSJson.SaleOrPurchasePrice),
            this.mapPSSBlocks(PSSJson.ExpenseCouncilRates),
            this.mapPSSBlocks(PSSJson.ExpenseWaterRates),
            this.mapPSSBlocks(PSSJson.ExpenseElectricityAndGas),
            this.mapPSSBlocks(PSSJson.RentalIncome),
            this.mapPSSBlocks(PSSJson.ExpenseAgentsCommissions),
            this.mapPSSBlocks(PSSJson.ExpenseSolicitorFees),
            this.mapPSSBlocks(PSSJson.ExpenseStationeryPhoneAndPostage),
            this.mapPSSBlocks(PSSJson.ExpenseTitleFees),
            this.mapPSSBlocks(PSSJson.SaleRelatedAgentFee),
            this.mapPSSBlocks(PSSJson.DepositPaid),
            this.mapPSSBlocks(PSSJson.ExpenseLandTax),
            this.mapPSSBlocks(PSSJson.SearchFees),
            this.mapPSSBlocks(PSSJson.SettlementFees),
            this.mapPSSBlocks(PSSJson.GSTExpense),
            this.mapPSSBlocks(PSSJson.TransferDuty),
            this.mapPSSBlocks(PSSJson.DeductibleExpense),
            this.mapPSSBlocks(PSSJson.RegistrationFees),
            this.mapPSSBlocks(PSSJson.FundsByLoan),
            this.mapPSSBlocks(PSSJson.FundsByPerson),
            this.mapPSSBlocks(PSSJson.CertificateFees),
            this.mapPSSBlocks(PSSJson.ExpenseStrataLevyFees),
            this.mapPSSBlocks(PSSJson.PenaltyInterest),
            this.mapPSSBlocks(PSSJson.MortgageReleaseFees),
            this.mapPSSBlocks(PSSJson.StampDuty),
            this.mapPSSBlocks(PSSJson.ExpensePestControl),
            this.mapPSSBlocks(PSSJson.VendorFees),
            this.mapPSSBlocks(PSSJson.ResidualMoney)
        )
    }

    static toJson(model: PropertySettlementStatement): IBusinessModel {
        return {
            ModelType: IModelType.PropertySettlementStatement,
            Version: model.version
        } as IPropertySettlementStatement
    }
}
