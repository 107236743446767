import {IBusinessCardModel, IContactNames, IModelType} from "../../types/ISmartDocsResult"
import {BusinessModelMapper} from "../BusinessModelMapper"
import {BusinessCard, ContactName} from "./BusinessCard"
import {OcrDocument} from "../../OcrDocument"
import autobind from "autobind-decorator"
import {List} from "immutable"

@autobind
export class BusinessCardMapper extends BusinessModelMapper{
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IBusinessCardModel,
  ) {
    super(document)
  }

  fromJson(): BusinessCard {
    const businessCardModel = this.jsonModel
    const contactNames = businessCardModel.ContactNames?.map((contactName: IContactNames) => {
      return new ContactName(
        this.mapTextBlock(contactName.FirstName),
        this.mapTextBlock(contactName.LastName),
      )
    })
    const model = new BusinessCard(
      IModelType.BusinessCard,
      businessCardModel.Version,
      contactNames ? List(contactNames) : List(),
      this.mapTextBlocks(businessCardModel.JobTitles),
      this.mapTextBlocks(businessCardModel.Departments),
      this.mapTextBlocks(businessCardModel.Emails),
      this.mapTextBlocks(businessCardModel.Websites),
      this.mapTextBlocks(businessCardModel.MobilePhones),
      this.mapTextBlocks(businessCardModel.OtherPhones),
      this.mapTextBlocks(businessCardModel.WorkPhones),
      this.mapTextBlocks(businessCardModel.Faxes),
      this.mapTextBlocks(businessCardModel.Address),
      this.mapTextBlocks(businessCardModel.CompanyNames)
    )
    return model
  }

  static toJson(model: BusinessCard): IBusinessCardModel {
    return {
      ModelType: IModelType.BusinessCard,
      Version: model.version,
      ContactNames: model.contactNames.toArray().map(item => item.toJson()),
      JobTitles: model.jobTitles.toArray().map(item => item.toModelKeyValue()),
      Departments: model.departments.toArray().map(item => item.toModelKeyValue()),
      Emails: model.emails.toArray().map(item => item.toModelKeyValue()),
      Websites: model.websites.toArray().map(item => item.toModelKeyValue()),
      MobilePhones: model.mobilePhones.toArray().map(item => item.toModelKeyValue()),
      OtherPhones: model.otherPhones.toArray().map(item => item.toModelKeyValue()),
      WorkPhones: model.workPhones.toArray().map(item => item.toModelKeyValue()),
      Faxes: model.faxes.toArray().map(item => item.toModelKeyValue()),
      Address: model.addresses.toArray().map(item => item.toModelKeyValue()),
      CompanyNames: model.companyNames.toArray().map(item => item.toModelKeyValue()),
    } as IBusinessCardModel
  }

}
