import React, { Component, ComponentType } from 'react'

import { getBoundsForNode, TComputedBounds, TGetBoundsForNodeArgs } from './utils'
import {TSelectableItemState, TSelectableItemProps, TSelectableGroupContext} from './Selectable.types'
import { SelectableGroupContext } from './SelectableGroup.context'

type TAddedProps = Partial<Pick<TSelectableItemProps, 'isSelected'>>

export const createSelectable = <T extends any>(
  WrappedComponent: ComponentType<TSelectableItemProps & T>
): ComponentType<T & TAddedProps> =>
  class SelectableItem extends Component<T & TAddedProps, TSelectableItemState> {
    static contextType = SelectableGroupContext

    state = {
      isSelected: this.props.isSelected ?? false,
      isSelecting: false,
    }

    node: HTMLElement | null = null

    bounds: TComputedBounds[] | null = null

    private getContext(): TSelectableGroupContext {
      if (! this.context.selectable.isDefaultImplementation()) {
        return this.context
      } else if ((window as any).selectableGroup) {
        return (window as any).selectableGroup.contextValue
      } else {
        return this.context
      }
    }

    componentDidMount() {
      this.updateBounds()
      // @ts-ignore
      this.getContext().selectable.register(this)
    }

    componentWillUnmount() {
      // @ts-ignore
      this.getContext().selectable.unregister(this)
    }

    updateBounds = (containerScroll?: TGetBoundsForNodeArgs) => {
      this.bounds = getBoundsForNode(this.node!, containerScroll)
    }

    getSelectableRef = (ref: HTMLElement | null) => {
      this.node = ref
    }

    render() {
      return (
        <WrappedComponent {...this.props} {...this.state} selectableRef={this.getSelectableRef} />
      )
    }
  }
