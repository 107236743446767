import autobind from 'autobind-decorator'
import { List } from 'immutable'
import * as _ from 'lodash'
import { OcrDocument } from '../../OcrDocument'
import { IAtoActivityStatementModel, IAtoActivityStatementTransactionTableDataRow, IAtoTransactionTable } from '../../types/ISmartDocsResult'
import { BusinessModelMapper } from '../BusinessModelMapper'
import { AtoActivityStatement, AtoTransactionTable } from './AtoActivityStatement'
import { AtoActivityStatementTransaction } from './AtoActivityStatementTransaction'
import { AtoActivityStatementTransactions } from './AtoActivityStatementTransactions'

@autobind
export class AtoActivityStatementMapper extends BusinessModelMapper {
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IAtoActivityStatementModel
  ) {
    super(document)
  }

  fromJson(): AtoActivityStatement {
    const model = this.jsonModel
    const transactionTables = this.jsonModel.TransactionTables.map((table: IAtoTransactionTable) => {
      return this.mapAtoTransactionTable(table)
    })
    return new AtoActivityStatement(
      model.Name,
      model.Version,
      List(transactionTables)
    )
  }

  mapTransaction(row: IAtoActivityStatementTransactionTableDataRow): AtoActivityStatementTransaction | undefined {
    const processedDate = this.mapDateBlock(row.ProcessedDate)
    const effectiveDate = this.mapDateBlock(row.EffectiveDate)
    const debit = this.mapDollarBlock(row.Debit)
    const credit = this.mapDollarBlock(row.Credit)
    if (processedDate && effectiveDate && (debit || credit)) {
      return new AtoActivityStatementTransaction(
        processedDate,
        effectiveDate,
        this.mapTextBlock(row.Description),
        debit,
        credit,
        this.mapDollarBlock(row.Balance)
      )
    } else {
      return undefined
    }
  }

  mapAtoTransactionTable(table: IAtoTransactionTable): AtoTransactionTable {
    const transactions = _.compact((table.Table.DataRows || []).map(this.mapTransaction))
    return new AtoTransactionTable(
      new AtoActivityStatementTransactions(List(transactions)),
      this.mapTextBlock(table.AbnNumber),
      this.mapTextBlock(table.TfnNumber),
      this.mapDateBlock(table.StatementPeriod?.Start),
      this.mapDateBlock(table.StatementPeriod?.End)
    )
  }
}
