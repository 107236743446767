import {List, Set, OrderedMap} from "immutable"
import {
  BlockRelationshipType,
  BlockType,
  IBlock,
  ICellBlock,
  IDocument,
  ILineBlock,
  IPageBlock, IRelationship,
  ITableBlock,
  IWordBlock
} from "./types/ISmartDocsResult"
import {OcrDocument} from "./OcrDocument"
import autobind from "autobind-decorator"
import {Page} from "./ocr/Page"
import {Table} from "./ocr/Table"
import {Cell} from "./ocr/Cell"
import {Line} from "./ocr/Line"

@autobind
export class JsonToOcrDocumentConverter {
  private readonly pageMap: OrderedMap<string, IPageBlock>
  private readonly tableMap: OrderedMap<string, ITableBlock>
  private readonly lineMap: OrderedMap<string, ILineBlock>
  private readonly cellMap: OrderedMap<string, ICellBlock>
  private readonly wordMap: OrderedMap<string, IWordBlock>
  constructor(
    private readonly doc: IDocument
  ) {
    this.pageMap = JsonToOcrDocumentConverter.filterBlocks(doc, BlockType.PAGE, block => block as IPageBlock)
    this.tableMap = JsonToOcrDocumentConverter.filterBlocks(doc, BlockType.TABLE, block => block as ITableBlock)
    this.lineMap = JsonToOcrDocumentConverter.filterBlocks(doc, BlockType.LINE, block => block as ILineBlock)
    this.cellMap = JsonToOcrDocumentConverter.filterBlocks(doc, BlockType.CELL, block => block as ICellBlock)
    this.wordMap = JsonToOcrDocumentConverter.filterBlocks(doc, BlockType.WORD, block => block as IWordBlock)
  }

  convert(): OcrDocument {
    const pages = this.pageMap.map(page => {
      const {width, height} = this.pageDimension(page.Page)
      return Page.fromJson(width, height, page, this.lineBuilder, this.tableBuilder)
    }).toList()
    return new OcrDocument(pages)
  }

  private pageDimension(page: number): {width: number, height: number} {
    const dim = this.doc.DocumentMetadata.PageDimensions.find(dimension => dimension.Page === page)
    if (dim) {
      return {width: dim.Width, height: dim.Height}
    } else {
      return {width: 1, height: 1}
    }
  }

  tableBuilder(relationships: IRelationship[]): List<Table> {
    const tables = this.tableChildren(relationships)
    return tables.map(table => {
      return Table.fromJson(table, this.cellBuilder)
    })
  }

  cellBuilder(relationships: IRelationship[]): List<Cell> {
    const cells = this.cellChildren(relationships)
    return cells.map(cell => {
      return Cell.fromJson(cell, this.wordsChildren)
    })
  }

  lineBuilder(relationships: IRelationship[]): List<Line> {
    const lines = this.lineChildren(relationships)
    return lines.map(line => {
      return Line.fromJson(line, this.wordsChildren)
    })
  }

  tableChildren(relationships: IRelationship[]): List<ITableBlock> {
    const childIdSet = this.childIdsFromRelationship(relationships)
    return this.tableMap.filter(a => childIdSet.contains(a.Id)).toList()
  }

  cellChildren(relationships: IRelationship[]): List<ICellBlock> {
    const childIdSet = this.childIdsFromRelationship(relationships)
    return this.cellMap.filter(a => childIdSet.contains(a.Id)).toList()
  }

  lineChildren(relationships: IRelationship[]): List<ILineBlock> {
    const childIdSet = this.childIdsFromRelationship(relationships)
    return this.lineMap.filter(a => childIdSet.contains(a.Id)).toList()
  }

  wordsChildren(relationships: IRelationship[]): List<IWordBlock> {
    const childIdSet = this.childIdsFromRelationship(relationships)
    return this.wordMap.filter(word => childIdSet.contains(word.Id)).toList()
  }

  private childIdsFromRelationship(relationships: IRelationship[]): Set<string> {
    if (relationships === undefined || relationships === null) {
      return Set()
    } else {
      const childIds = relationships.filter(relationship =>
        relationship.Type === BlockRelationshipType.CHILD
      ).flatMap(relationship =>
        relationship.Ids
      )
      return Set(childIds)
    }
  }

  private static filterBlocks<T>(doc: IDocument, blockType: BlockType, convert: (block: IBlock) => T): OrderedMap<string, T> {
    const blocks = doc.Blocks.filter(block =>
      block.BlockType === blockType
    ).map(block => {
      return [block.Id, convert(block)] as [string, T]
    })
    return OrderedMap<string, T>(blocks)
  }

}
