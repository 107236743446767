import {
  AtoActivityStatement,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import React from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormStringField } from '../FormStringField'
import AtoTransactionTableEditor from './AtoTransactionTableEditor'

interface IAtoActivityStatementEditorProps extends EditorFieldEvents {
  atoActivityStatement: AtoActivityStatement
  className?: string
}

export const AtoActivityStatementEditor = (
  props: IAtoActivityStatementEditorProps
) => {
  const atoActivityStatementTable = props.atoActivityStatement.firstTable()

  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoActivityStatementEditor_AbnNumber}
            label={'ABN Number'}
            events={props}
            field={atoActivityStatementTable?.abnNumber}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoActivityStatementEditor_TfnNumber}
            label={'TFN Number'}
            events={props}
            field={atoActivityStatementTable?.tfnNumber}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.AtoActivityStatementEditor_StartDate}
            label={'Start Date'}
            events={props}
            field={atoActivityStatementTable?.startDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.AtoActivityStatementEditor_EndDate}
            label={'End Date'}
            events={props}
            field={atoActivityStatementTable?.endDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={12}>
          <AtoTransactionTableEditor
            transactions={atoActivityStatementTable?.transactions}
          />
        </Grid>
      </Grid>
    </form>
  )
}
