import { apiPath } from '../ApiPath'
import { AuthResponseDTO } from '../models/Authorization'
import BaseApi from './BaseApi'
import { trackEvent } from './GoogleAnalytics'

export default class AuthorizationApi extends BaseApi {
  private static instance: AuthorizationApi
  authApiPath: string

  private constructor(apiPath: string) {
    super()
    this.authApiPath = `${apiPath}/ssoauth`
  }

  static getInstance(): AuthorizationApi {
    if (!AuthorizationApi.instance) {
      AuthorizationApi.instance = new AuthorizationApi(apiPath)
    }
    return AuthorizationApi.instance
  }

  getAuthorizations = async (): Promise<AuthResponseDTO | undefined> => {
    try {
      return this.loadByGet(`${this.authApiPath}`, undefined, undefined, true)
    } catch (e) {
      trackEvent({
        category: 'ApiError',
        action: 'getAuthorizations',
        label: `${this.mapError(e)}`
      })
      throw new Error('Authorization failed')
    }
  }
}
