import React from 'react'

import { ReactComponent as BuninessCaseIcon } from '../../assets/images/streamline-icon-content-pencil-write@64x64.svg'
import { ReactComponent as CustomerBusinessModelIcon } from '../../assets/images/streamline-icon-target-center@64x64.svg'
import { ReactComponent as EndToEndSolutionSupportIcon } from '../../assets/images/streamline-icon-trip-dgestination@64x64.svg'

function ConsultingServices() {
  return (
    <section className="consulting-services" id="consultingservices">
      <div className="container container-wrapper">
        <h3>Consulting Services</h3>
        <p>Dedicated services from the SmartDocs AI Studio team</p>
        <div className="services">
          <div className="service">
            <BuninessCaseIcon className="icon" />
            <h4>Buniness Case</h4>
            <p>
              Technical expertise to identify the use cases and integrate AI
              with existing business flow
            </p>
          </div>

          <div className="service middle-service">
            <EndToEndSolutionSupportIcon className="icon" />
            <h4>End to End Solution support</h4>
            <p>
              From model fine-tuning to API integration and production
              deployment, support your E2E Machine Learning solution
            </p>
          </div>

          <div className="service">
            <CustomerBusinessModelIcon className="icon" />
            <h4>Customer Business Model</h4>
            <p>Customised solution to meet your business needs</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConsultingServices
