import { Popover } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useBusinessStore } from '../services/stores/BusinessStore'

interface IAppSwitcherProps {
  open: boolean
  anchorEl: HTMLElement | null
  setAppSwitchAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >
}

type styleProps = {
  appsCount: number
}

const useStyles = makeStyles<Theme, styleProps>({
  appGrid: {
    display: 'grid',
    gridTemplateColumns: (props) =>
      `repeat(${Math.min(props.appsCount, 3)}, minmax(28px, 1fr))`,
    padding: '10px',
    gap: '10px'
  },
  appWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& img': {
      width: '28px',
      height: '28px'
    },
    '& span': {
      maxWidth: '50px',
      fontSize: '10px',
      marginTop: '5px',
      textAlign: 'center'
    },
    '&:hover': {
      cursor: 'pointer',
      '& img': {
        filter: 'brightness(1.3)'
      }
    }
  }
})

const AppSwitcher = ({
  open,
  anchorEl,
  setAppSwitchAnchorEl
}: IAppSwitcherProps) => {
  const supportedApplications = useBusinessStore(
    (state) => state.supportedApplications
  )
  const classes = useStyles({ appsCount: supportedApplications?.length || 3 })

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAppSwitchAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <div className={classes.appGrid}>
        {supportedApplications &&
          supportedApplications.map((app) => (
            <a
              key={app.name}
              href={app.url}
              target="_blank"
              rel="noreferrer"
              className={classes.appWrapper}
            >
              <img src={app.logoUrl} alt={app.desc} />
              <span>{app.name}</span>
            </a>
          ))}
      </div>
    </Popover>
  )
}

export default AppSwitcher
