import { Link } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'

export const PrimaryLink = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'none'
    }
  })
)(Link)

export const GrayLink = withStyles(() =>
  createStyles({
    root: {
      color: 'rgba(0, 0, 0, 0.54) !important',
      cursor: 'pointer',
      textDecoration: 'none'
    }
  })
)(Link)
