import { BaseShape } from '../models/Shape'
import { DrawStyle, Point } from '../models/Types'
import {CreateType} from './Types'

export class Dot extends BaseShape {

  private readonly extraSelectionRadius = 3
  readonly dotRadius = 2.5
  readonly ctrlPointSize = 12

  constructor(
    public shapeId: string,
    public shapeIndex: number,
    public coordinates: Point[],
    public drawStyle: DrawStyle,
    public tagName: string,
    public tagId: string,
    public shapeType: CreateType = CreateType.Dot
  ) {
    super(shapeId, shapeIndex, coordinates, drawStyle, tagName, tagId, shapeType)
  }

  getAnnotationCoor(){
    const x = this.coordinates[0][0]
    const y = this.coordinates[0][1]
    return {
      xmin: x,
      xmax: x,
      ymin: y,
      ymax: y
    }
  }

  isMouseOver(percentageMousePoint: Point, coordinateConverter: (percentageMousePoint: Point) => Point){
    const [x, y] = coordinateConverter(percentageMousePoint)
    const [cx, cy] = coordinateConverter(this.coordinates[0])
    return Math.sqrt(Math.pow(x - cx, 2) + Math.pow(y - cy, 2)) < this.dotRadius + this.extraSelectionRadius
  }


  drawShape(ctx: CanvasRenderingContext2D, coordinateConverter: (percentageMousePoint: Point) => Point){
    const [x, y] = coordinateConverter(this.coordinates[0])
    ctx.save()
    ctx.fillStyle = this.drawStyle.strokeStyle
    ctx.strokeStyle = this.drawStyle.strokeStyle
    ctx.lineWidth = this.drawStyle.lineWidth
    ctx.beginPath()
    ctx.arc(x, y, this.dotRadius, 0, 2 * Math.PI)
    ctx.fill()
    ctx.stroke()
    ctx.restore()
  }

  drawCtrlPoints(ctx: CanvasRenderingContext2D, coordinateConverter: (percentageMousePoint: Point) => Point) {
    const point = coordinateConverter(this.coordinates[0])
    this.drawCircleCtrl(point, ctx)
  }

  private drawCircleCtrl(point: Point, ctx: CanvasRenderingContext2D){
    const [x, y] = point
    const size = this.ctrlPointSize
    ctx.save()
    ctx.shadowColor ='#fff'
    ctx.shadowBlur = 2
    ctx.strokeStyle = '#fff'
    ctx.lineWidth = 2
    ctx.beginPath()
    ctx.arc(x, y, size / 2, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.restore()
  }
}