import create from 'zustand'

import Business, { BusinessUsage } from '../../models/Business'
import { SsoClientApi, SupportedApp } from '../SsoClientApi'
import TaggingApi from '../TaggingApi'
import { useInitStore } from './InitStore'
import { setProjects } from './ProjectStore'

const taggingApi = TaggingApi.getInstance()
const ssoClientApi = SsoClientApi.getInstance()

interface IBusinessState {
  currentBusinessUsage: BusinessUsage[] | []
  supportedApplications: SupportedApp[] | undefined
  fetchingSupportedApplications: boolean
  setCurrentBusinessUsage: (usage: BusinessUsage[]) => void
  getBusiness: (businessId: string) => Promise<void>
}

const setCurrentBusiness = useInitStore.getState().setCurrentBusiness

export const useBusinessStore = create<IBusinessState>((set) => ({
  currentBusinessUsage: [],
  supportedApplications: undefined,
  fetchingSupportedApplications: false,
  setCurrentBusinessUsage: (usage) => set({ currentBusinessUsage: usage }),
  getBusiness: (businessId: string) => {
    return taggingApi.getBusiness(businessId).then(({ business, projects }) => {
      setCurrentBusiness(business ?? new Business())
      setProjects(projects ?? [])
    })
  }
}))

export const getSupportedApplications = async () => {
  useBusinessStore.setState({ fetchingSupportedApplications: true })
  const supportedApplications = await ssoClientApi.getSupportedApplications()
  const filteredSupportedApplications = supportedApplications?.filter(
    (app) => app.desc !== 'SDD360'
  )
  useBusinessStore.setState({
    supportedApplications: filteredSupportedApplications
  })
  useBusinessStore.setState({ fetchingSupportedApplications: false })
}
