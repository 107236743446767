import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { List } from 'immutable'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import { WaterBillMapper } from './WaterBillMapper'

export class WaterBill extends BusinessModel {
  constructor(
      modelType: IModelType,
      version: string,
      readonly propertyAddress?: StringField,
      readonly issueDate?: DateField,
      readonly dueDate?: DateField,
      readonly totalAmount?: DoubleField,
      readonly clientAddress?: StringField
  ){
    super(modelType, version)
  }

  copy({
    modelType = this.modelType,
    version = this.version,
    propertyAddress = this.propertyAddress,
    issueDate = this.issueDate,
    dueDate = this.dueDate,
    totalAmount = this.totalAmount,
    clientAddress = this.clientAddress
  }): WaterBill {
    return new WaterBill(modelType, version, propertyAddress, issueDate, dueDate, totalAmount, clientAddress)
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.WaterBillEditor_PropertyAddress:
      case FieldAndColumnName.WaterBillEditor_ClientAddress:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      case FieldAndColumnName.WaterBillEditor_DueDate:
      case FieldAndColumnName.WaterBillEditor_IssueDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)

      case FieldAndColumnName.WaterBillEditor_TotalAmount:
        return new DoubleField(uuid(), value as number, [], List(), modifiedBy)

      default:
        return undefined
    }
  }

  toJson(): IBusinessModel {
    return WaterBillMapper.toJson(this)
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return this.issueDate?.parsedValue
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return this.dueDate?.parsedValue
  }

  getTotalAmount(): number {
    return this.totalAmount?.parsedValue || 0
  }
}