import React from 'react'

import aboutBGL from '../../assets/images/BGL_ProductTeam.png'

function AboutBGL() {
  return (
    <section className="about-bgl">
      <div className="container container-wrapper" id="about">
        <div className="layout">
          <div className="left">
            <h3>About BGL</h3>
            {/* eslint-disable-next-line */}
            <p>
              BGL delivers SMSF administration and corporate compliance
              management software solutions to over 8,000 businesses in 15
              countries – all through its user-friendly and highly intelligent
              web applications, combining amazing UX with powerful technology.
            </p>
            {/* eslint-disable-next-line */}
            <p>
              Founded in 1983 with only 2 employees, BGL has grown to be the #1
              developer of compliance software solutions in the world –
              employing an amazing team of 150+ people across the globe!
            </p>
          </div>
          <div className="right">
            <div className="img">
              <img src={aboutBGL} alt="about-bgl" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutBGL
