import {Geometry, OcrElement} from "./OcrElement"
import {List} from "immutable"
import {Word} from "./Word"
import {Line} from "./Line"

export abstract class TextElement extends OcrElement {
  protected constructor(
    id: string,
    page: number,
    confidence: number,
    geometry: Geometry,
    public readonly text: string,
    public readonly words: List<Word>,
  ) {
    super(id, page, confidence, geometry)
  }

  static mergeToLineBasedOnPage(elements: List<TextElement>): List<Line> {
    const words = elements.flatMap(e => e.words)
    return Word.mergeToLineBasedOnPage(words)
  }
}
