import {Dot} from '../models/Dot'
import {Rect} from '../models/Rect'
import {BaseShape} from '../models/Shape'
import {CreateType, DrawStyle, Point} from '../models/Types'


export const DEFAULT_TAG_NAME: string = 'Untagged'
export const DEFAULT_TAG_ID: string = 'Unknown'

export const shapeFactory = (
  shapeType: CreateType,
  shapeId: string,
  shapeIndex: number,
  coordinates: Point[],
  drawStyle: DrawStyle,
  tagName: string = DEFAULT_TAG_NAME,
  tagId: string = DEFAULT_TAG_ID
): BaseShape => {
    switch (shapeType) {
      case CreateType.Rect:
        return new Rect(shapeId, shapeIndex, coordinates, drawStyle, tagName, tagId)
      case CreateType.Dot:
        return new Dot(shapeId, shapeIndex, coordinates, drawStyle, tagName, tagId)
      default:
        return new Rect(shapeId, shapeIndex, coordinates, drawStyle, tagName, tagId)
    }
  }

