import {List} from "immutable"
import {IWordText} from "./types/ISmartDocsResult"

export class ModifiedWordText {
  constructor(
    public readonly wordId: string,
    public readonly text: string,
    public readonly source: string
  ) {
  }
}

export class ModifiedWordTexts {
  constructor(
    private readonly words: List<ModifiedWordText>
  ) {
  }
  find(wordId: string): ModifiedWordText | undefined {
    return this.words.find(text => text.wordId === wordId)
  }

  has(wordId: string): boolean {
    return this.find(wordId) !== undefined
  }

  addOrUpdateText(wordId: string, newText: string, newSource: string): ModifiedWordTexts {
    if (this.has(wordId)) {
      return this.updateExistsText(wordId, newText, newSource)
    } else {
      return new ModifiedWordTexts(this.words.push(
        new ModifiedWordText(wordId, newText, newSource)
      ))
    }
  }

  private updateExistsText(wordId: string, newText: string, newSource: string): ModifiedWordTexts {
    const words = this.words.map(word => {
      if (word.wordId == wordId) {
        return new ModifiedWordText(wordId, newText, newSource)
      } else {
        return word
      }
    })
    return new ModifiedWordTexts(words)
  }

  toJson(): IWordText[] {
    return this.words.map(word => {
      return {
        WordId: word.wordId,
        Text: word.text,
        Source: word.source
      }
    }).toArray()
  }

  static fromJson(wordText?: IWordText[]): ModifiedWordTexts {
    if (wordText) {
      const words = wordText.map(word => {
        return new ModifiedWordText(word.WordId, word.Text, word.Source)
      })
      return new ModifiedWordTexts(List(words))
    } else {
      return new ModifiedWordTexts(List())
    }
  }
}
