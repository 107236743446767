import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useCopyrightStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '1rem',
      color: theme.palette.grey['700'],
      fontSize: 13,
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        display: 'block'
      }
    }
  })
)
