import React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'

const StyledPreviewPanel = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.51);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	overflow-x: hidden;
	overflow-y: scroll;
`

const StyledImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	margin: 20px 0;
	padding: 20px;
	&:hover {
		background: rgba(255, 255, 255, 0.35);
		cursor: pointer;
	}
	&.active {
		background-color: rgba(255, 255, 255, 0.35);
	}
`
export interface IAnnotatorPageImage {
	pageId: string,
	imageSrc: string
}

interface IPreviewPanel {
	pageImages: IAnnotatorPageImage[]
	imageSrc: string
	setImageSrc: (imageSrc: string) => void
	setPageId: (pageId: string) => void
}

const PreviewPanel = (props: IPreviewPanel) => {

	useEffect(() => {
		if(props.pageImages.length > 0) {
			props.setImageSrc(props.pageImages[0].imageSrc)
			props.setPageId(props.pageImages[0].pageId)
		}
	}, [props.pageImages])

	return (
		<StyledPreviewPanel>
			{props.pageImages.map((pageImage, index) => {
				return (
					<StyledImageContainer className={props.imageSrc === pageImage.imageSrc ? 'active' : ''}
						key={index}
						onClick={() => {
							props.setImageSrc(pageImage.imageSrc)
							props.setPageId(pageImage.pageId)
						}}
					>
						<img src={pageImage.imageSrc} style={{ width: 150 }} alt="preview" />
					</StyledImageContainer>
				)
			})}
		</StyledPreviewPanel>
	)
}

export default PreviewPanel