import {
  BankStatement,
  DetectedField,
  FieldAndColumnName,
  Transaction,
  Transactions
} from '@bgl/textract-business-model-editor'
import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { FunctionComponent } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDollarField } from '../FormDollarField'
import { FormStringField } from '../FormStringField'
import { TransactionTableEditor } from './TransactionTableEditor'

interface BankStatementProps extends EditorFieldEvents {
  bankStatement: BankStatement
  className?: string
  readonlyMode?: boolean
  tableReadOnly?: boolean
}

export const BankStatementEditor: FunctionComponent<BankStatementProps> = (
  bsProps: BankStatementProps
) => {
  const transactionTable = bsProps.bankStatement.firstTable()

  function onCellClicked(
    transactions: Transactions,
    transaction: Transaction,
    field: DetectedField
  ) {
    if (bsProps.onFieldFocus) {
      bsProps.onFieldFocus(undefined, field)
    }
  }

  return (
    <form noValidate autoComplete="on">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Bank Name"
            defaultValue={bsProps.bankStatement.bankName}
            placeholder="N/A"
            fullWidth
            className={bsProps.className}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.BankStatementEditor_FinancialInstitution}
            label={'Financial Institution'}
            events={bsProps}
            field={bsProps.bankStatement.financialInstitution}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.TransactionTable_AccountName}
            label={'Account Name'}
            events={bsProps}
            field={transactionTable?.accountName}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.TransactionTable_ABN}
            label={'ABN'}
            events={bsProps}
            field={transactionTable?.abn}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.BankStatementEditor_BSB}
            label={'BSB'}
            events={bsProps}
            field={transactionTable?.bsb}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.BankStatementEditor_AccountNumber}
            label={'Account Number'}
            events={bsProps}
            field={transactionTable?.accountNumber}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.BankStatementEditor_StartDate}
            label={'Start Date'}
            events={bsProps}
            field={transactionTable?.startDate}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.BankStatementEditor_EndDate}
            label={'End Date'}
            events={bsProps}
            field={transactionTable?.endDate}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.TransactionTable_OpeningBalance}
            label={'Opening Balance'}
            events={bsProps}
            field={transactionTable?.openingBalance}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.TransactionTable_ClosingBalance}
            label={'Closing Balance'}
            events={bsProps}
            field={transactionTable?.closingBalance}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.TransactionTable_TotalCredit}
            label={'Total Credit'}
            events={bsProps}
            field={transactionTable?.totalCredits}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.TransactionTable_TotalDebit}
            label={'Total Debit'}
            events={bsProps}
            field={transactionTable?.totalDebits}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={12}>
          <TransactionTableEditor
            transactions={transactionTable?.transactions}
            onCellClicked={onCellClicked}
            tableReadOnly={bsProps.tableReadOnly}
            onGridValueChanged={(gridColumnName, rowIndex, value) => {
              bsProps.onGridValueChanged?.(gridColumnName, rowIndex, value)
            }}
          ></TransactionTableEditor>
        </Grid>
      </Grid>
    </form>
  )
}
