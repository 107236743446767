import '../../assets/css/style.css'

import React from 'react'

import Footer from './Footer'
import Header from './Header'

function SharedLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="home-container">
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default SharedLayout
