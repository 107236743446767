import { TagDataType, Tags } from '../../models/Tag'

export const DemoWaterBillTags: Tags = Tags.fromJson([
  {
    id: 'PropertyAddress-6XbQB5UG',
    name: 'PropertyAddress',
    order: 1,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'IssueDate-yUw51B',
    name: 'IssueDate',
    order: 2,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DueDate-uO7OXOs8',
    name: 'DueDate',
    order: 3,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalAmount-xj2FsvCo',
    name: 'TotalAmount',
    order: 4,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'AccountNumber-Kab1WSMH',
    name: 'AccountNumber',
    order: 5,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'InvoiceNumber-8YimDDrb',
    name: 'InvoiceNumber',
    order: 6,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'CustomerName-TatUHQ2h',
    name: 'CustomerName',
    order: 7,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'OverdueAmount-DMltU37C',
    name: 'OverdueAmount',
    order: 8,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'CustomerABN-4vurt9EI',
    name: 'CustomerABN',
    order: 9,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'GSTIncluded-lw17NBrt',
    name: 'GSTIncluded',
    order: 10,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'CustomerAddress-4R50JFWT',
    name: 'CustomerAddress',
    order: 11,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'SupplierName-rpenmLj5',
    name: 'SupplierName',
    order: 12,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'SupplierABN-SeiJQ9E',
    name: 'SupplierABN',
    order: 13,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'BillingStartDate-3j5J8kIB',
    name: 'BillingStartDate',
    order: 14,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'BillingEndDate-wkNPKjb',
    name: 'BillingEndDate',
    order: 15,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ServicePhoneNumber-b7fclq8Z',
    name: 'ServicePhoneNumber',
    order: 16,
    projectId: 'YihpYEHXmsVeRe',
    dataType: TagDataType.String,
    isHidden: false
  }
])

export const DemoBankStatementTags: Tags = Tags.fromJson([
  {
    id: 'StartPeriod',
    projectId: 'mvtrql6OxKaGTO',
    name: 'StartPeriod',
    dataType: TagDataType.String,
    order: 1,
    isHidden: false
  },
  {
    id: 'EndPeriod',
    projectId: 'mvtrql6OxKaGTO',
    name: 'EndPeriod',
    dataType: TagDataType.String,
    order: 2,
    isHidden: false
  },
  {
    id: 'BSB',
    projectId: 'mvtrql6OxKaGTO',
    name: 'BSB',
    dataType: TagDataType.String,
    order: 3,
    isHidden: false
  },
  {
    id: 'AccountNumber',
    projectId: 'mvtrql6OxKaGTO',
    name: 'AccountNumber',
    dataType: TagDataType.String,
    order: 4,
    isHidden: false
  },
  {
    id: 'TableHeadDate',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableHeadDate',
    dataType: TagDataType.String,
    order: 5,
    isHidden: false
  },
  {
    id: 'TableHeadDesc',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableHeadDesc',
    dataType: TagDataType.String,
    order: 6,
    isHidden: false
  },
  {
    id: 'TableHeadDebit',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableHeadDebit',
    dataType: TagDataType.String,
    order: 7,
    isHidden: false
  },
  {
    id: 'TableHeadCredit',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableHeadCredit',
    dataType: TagDataType.String,
    order: 8,
    isHidden: false
  },
  {
    id: 'TableHeadDebitCredit',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableHeadDebitCredit',
    dataType: TagDataType.String,
    order: 9,
    isHidden: false
  },
  {
    id: 'TableHeadBalance',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableHeadBalance',
    dataType: TagDataType.String,
    order: 10,
    isHidden: false
  },
  {
    id: 'TableDate',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableDate',
    dataType: TagDataType.String,
    order: 11,
    isHidden: false
  },
  {
    id: 'TableDesc',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableDesc',
    dataType: TagDataType.String,
    order: 12,
    isHidden: false
  },
  {
    id: 'TableDebit',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableDebit',
    dataType: TagDataType.String,
    order: 13,
    isHidden: false
  },
  {
    id: 'TableCredit',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableCredit',
    dataType: TagDataType.String,
    order: 14,
    isHidden: false
  },
  {
    id: 'TableDebitCredit',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableDebitCredit',
    dataType: TagDataType.String,
    order: 15,
    isHidden: false
  },
  {
    id: 'TableBalance',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TableBalance',
    dataType: TagDataType.String,
    order: 16,
    isHidden: false
  },
  {
    id: 'TotalIn-ElWiPZvo',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TotalIn',
    dataType: TagDataType.String,
    order: 17,
    isHidden: false
  },
  {
    id: 'TotalOut-SuQ2NKQH',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TotalOut',
    dataType: TagDataType.String,
    order: 18,
    isHidden: false
  },
  {
    id: 'OpeningBalance-buei9kes',
    projectId: 'mvtrql6OxKaGTO',
    name: 'OpeningBalance',
    dataType: TagDataType.String,
    order: 19,
    isHidden: false
  },
  {
    id: 'ClosingBalance-jEPPZl5F',
    projectId: 'mvtrql6OxKaGTO',
    name: 'ClosingBalance',
    dataType: TagDataType.String,
    order: 20,
    isHidden: false
  },
  {
    id: 'FinancialInstitution-557eXxjT',
    projectId: 'mvtrql6OxKaGTO',
    name: 'FinancialInstitution',
    dataType: TagDataType.String,
    order: 21,
    isHidden: false
  },
  {
    id: 'fin-xvfrNTq',
    projectId: 'mvtrql6OxKaGTO',
    name: 'fin',
    dataType: TagDataType.String,
    order: 22,
    isHidden: true
  },
  {
    id: 'BSB-KJqxt60R',
    projectId: 'mvtrql6OxKaGTO',
    name: 'BSB Wrong',
    dataType: TagDataType.String,
    order: 23,
    isHidden: true
  },
  {
    id: 'AccountName-qtMS3dvV',
    projectId: 'mvtrql6OxKaGTO',
    name: 'AccountName',
    dataType: TagDataType.String,
    order: 24,
    isHidden: false
  },
  {
    id: 'ABN-vr1sxw3E',
    projectId: 'mvtrql6OxKaGTO',
    name: 'ABN',
    dataType: TagDataType.String,
    order: 25,
    isHidden: false
  },
  {
    id: 'BankProductType-mq6lpkgU',
    projectId: 'mvtrql6OxKaGTO',
    name: 'BankProductType',
    dataType: TagDataType.String,
    order: 26,
    isHidden: false
  },
  {
    id: 'LoanInterest-T5hWEqGW',
    projectId: 'mvtrql6OxKaGTO',
    name: 'LoanInterest',
    dataType: TagDataType.String,
    order: 27,
    isHidden: false
  },
  {
    id: 'TotalLoanInterest-40MzZVV3',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TotalInterestCharged',
    dataType: TagDataType.String,
    order: 28,
    isHidden: false
  },
  {
    id: 'TotalLoanRedraw-ot6OstbI',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TotalLoanRedraw',
    dataType: TagDataType.String,
    order: 29,
    isHidden: false
  },
  {
    id: 'TotalLoanRepayment-84IjBj7c',
    projectId: 'mvtrql6OxKaGTO',
    name: 'TotalLoanRepayment',
    dataType: TagDataType.String,
    order: 30,
    isHidden: false
  },
  {
    id: 'fin-HTkyGL4X',
    projectId: 'mvtrql6OxKaGTO',
    name: 'fin',
    dataType: TagDataType.String,
    order: 31,
    isHidden: false
  }
])

export const DemoContractNoteTags = Tags.fromJson([
  {
    id: 'Other',
    name: 'Other',
    order: 0,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'BrokerName',
    name: 'BrokerName',
    order: 1,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Trade',
    name: 'Trade',
    order: 2,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalAmount',
    name: 'TotalAmount',
    order: 3,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TransactionDate',
    name: 'TransactionDate',
    order: 4,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'SettlementDate',
    name: 'SettlementDate',
    order: 5,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Unit',
    name: 'Unit',
    order: 6,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'UnitPrice',
    name: 'UnitPrice',
    order: 7,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Consideration',
    name: 'Consideration',
    order: 8,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Brokerage',
    name: 'Brokerage',
    order: 9,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'GST',
    name: 'GST',
    order: 10,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ReferenceNumber',
    name: 'ReferenceNumber',
    order: 11,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'AsxCode',
    name: 'AsxCode',
    order: 12,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'OtherFee',
    name: 'OtherFee',
    order: 13,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'CompanyName-z9TcHjXR',
    name: 'CompanyName',
    order: 14,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'SecurityType-a4y84qiD',
    name: 'SecurityType',
    order: 15,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Market-6HLxixm',
    name: 'Market',
    order: 16,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'AccruedInterest-U1EbBHM',
    name: 'AccruedInterest',
    order: 17,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'MaturityDate-37fsMsQ9',
    name: 'MaturityDate',
    order: 18,
    projectId: 'y2mvchBZV8Ymfq',
    dataType: TagDataType.String,
    isHidden: false
  }
])

export const DemoAnnualTaxStatementTags = Tags.fromJson([
  {
    id: 'FrankedDistribution-2fo8CePg',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'FrankedDistribution',
    dataType: TagDataType.String,
    order: 1,
    isHidden: false
  },
  {
    id: 'FrankingCredit-uPEJXpKC',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'FrankingCredit',
    dataType: TagDataType.String,
    order: 2,
    isHidden: false
  },
  {
    id: 'TFNAmountsWithheld-hX8FaXlZ',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'TFNAmountsWithheld',
    dataType: TagDataType.String,
    order: 3,
    isHidden: false
  },
  {
    id: 'AssessableForeignSourceIncome-vkqUqUZU',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'AssessableForeignSourceIncome',
    dataType: TagDataType.String,
    order: 4,
    isHidden: false
  },
  {
    id: 'OtherNetForeignSourceIncome-VtNwhMXe',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'OtherNetForeignSourceIncome',
    dataType: TagDataType.String,
    order: 5,
    isHidden: false
  },
  {
    id: 'AusFrankingCreditFromNZCompany-hRlX2fKH',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'AusFrankingCreditFromNZCompany',
    dataType: TagDataType.String,
    order: 6,
    isHidden: false
  },
  {
    id: 'ForeignIncomeTaxOffsets-IWFoNx0',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'ForeignIncomeTaxOffsets',
    dataType: TagDataType.String,
    order: 7,
    isHidden: false
  },
  {
    id: 'NonPrimaryProductionIncome-U0nygSOR',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'NonPrimaryProductionIncome',
    dataType: TagDataType.String,
    order: 8,
    isHidden: false
  },
  {
    id: 'TotalCapitalGains-pspV0cM',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'TotalCapitalGains',
    dataType: TagDataType.String,
    order: 9,
    isHidden: false
  },
  {
    id: 'NonPrimaryDeductions-Sn8BnIeG',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'NonPrimaryDeductions',
    dataType: TagDataType.String,
    order: 10,
    isHidden: false
  },
  {
    id: 'UnfrankedDistributions-6eqmtD',
    projectId: 'zUIYHQVTCkXWYs',
    name: 'UnfrankedDistributions',
    dataType: TagDataType.String,
    order: 11,
    isHidden: false
  }
])

export const DemoDividendStatementTags = Tags.fromJson([
  {
    id: 'ShareCode-siHwJSB8',
    name: 'ShareCode',
    order: 1,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DividendFranked-Xy8asOZL',
    name: 'DividendFranked',
    order: 2,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DividendUnfranked-eRST1cd',
    name: 'DividendUnfranked',
    order: 3,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'FrankingCredits-52to9oyQ',
    name: 'FrankingCredits',
    order: 4,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'AssessableForeignIncome-GZj2hSXh',
    name: 'AssessableForeignIncome',
    order: 5,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'ForeignIncomeTaxOffset-rObuEtfY',
    name: 'ForeignIncomeTaxOffset',
    order: 6,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'FrankingCreditsFromNZ-7kck5M2F',
    name: 'FrankingCreditsFromNZ',
    order: 7,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'TFNAmountsWithheld-HwuplMR6',
    name: 'TFNAmountsWithheld',
    order: 8,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'ForeignResidentWithholding-hpc4yfE3',
    name: 'ForeignResidentWithholding',
    order: 9,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'PaymentDate-nM33RiW',
    name: 'PaymentDate',
    order: 10,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'RecordDate-y5dJq27r',
    name: 'RecordDate',
    order: 11,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'NumberOfShares-Ftqm91zq',
    name: 'NumberOfShares',
    order: 12,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DividendPerShare-rvGTlsHn',
    name: 'DividendPerShare',
    order: 13,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'TotalDividend-KpDpdmwd',
    name: 'TotalDividend',
    order: 14,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'NetDividend-tyeqMfM5',
    name: 'NetDividend',
    order: 15,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DRPNumberOfShares-9Wvfnb3Q',
    name: 'DRPNumberOfShares',
    order: 16,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DRPLastBalanceBringForward-UIFdvH0p',
    name: 'DRPLastBalanceBringForward',
    order: 17,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DRPTotalBalance-sMZxas80',
    name: 'DRPTotalBalance',
    order: 18,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DRPSharePrice-ogKjkHOk',
    name: 'DRPSharePrice',
    order: 19,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'PostDRPShareBalance-ejipiibB',
    name: 'PostDRPShareBalance',
    order: 20,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'PostDRPCreditBalance-l6dM4bTW',
    name: 'PostDRPCreditBalance',
    order: 21,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'FrankingRate-Sh4yRb9',
    name: 'FrankingRate',
    order: 22,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'FrankingPercentage-KTx4Fmc4',
    name: 'FrankingPercentage',
    order: 23,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'DRPShareCost-rHiHDh6o',
    name: 'DRPShareCost',
    order: 24,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'ExchangeRate-cK0LQifi',
    name: 'ExchangeRate',
    order: 25,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'Currency-sYQ7rWCR',
    name: 'Currency',
    order: 26,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'SupplementaryDividendNZ-c6966Ztn',
    name: 'SupplementaryDividendNZ',
    order: 27,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'FeeDeduction-RHDej5th',
    name: 'FeeDeduction',
    order: 28,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'CompanyName-brJoG3td',
    name: 'CompanyName',
    order: 29,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'CapitalReturn-QXInEp5Z',
    name: 'CapitalReturn',
    order: 30,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'CapitalComponentPerShare-rSraqIv7',
    name: 'CapitalComponentPerShare',
    order: 31,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'SMSFLIC-6SgyG3OZ',
    name: 'SMSFLIC',
    order: 32,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'IndividualTrustLIC-nFb5mFx8',
    name: 'IndividualTrustLIC',
    order: 33,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'InterestIncome-zLKwiiJR',
    name: 'InterestIncome',
    order: 34,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  },
  {
    id: 'TotalNetAmount-QJ7hDe7L',
    name: 'TotalNetAmount',
    order: 35,
    projectId: 'CXVrcRgSHseUNs',
    isHidden: false,
    dataType: TagDataType.String
  }
])

export const DemoRentalSummaryTags = Tags.fromJson([
  {
    id: 'StartDate-FV78YXP1',
    name: 'StartDate',
    order: 1,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'EndDate-jURUqnYq',
    name: 'EndDate',
    order: 2,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Address-63OTH48u',
    name: 'Address',
    order: 3,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Credit-KrCv1GJz',
    name: 'Credit',
    order: 4,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Debit-WgI8EoOY',
    name: 'Debit',
    order: 5,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'CreditAmount-e7JYj',
    name: 'CreditAmount',
    order: 6,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DebitAmount-HOWhuTv',
    name: 'DebitAmount',
    order: 7,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'GST-CQVn97CE',
    name: 'GST',
    order: 8,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'GSTAmount-Tk4ND55q',
    name: 'GSTAmount',
    order: 9,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseAgentsManagementFees-Bt5cRNOk',
    name: 'ExpenseAgentsManagementFees',
    order: 10,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseAgentsCommissions-wawbdUig',
    name: 'ExpenseAgentsCommissions',
    order: 11,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseElectricityandGas-nOKLqaNc',
    name: 'ExpenseElectricityandGas',
    order: 12,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseWaterRates-LHzBoxvz',
    name: 'ExpenseWaterRates',
    order: 13,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseInsurancePremium-JbbMiLu',
    name: 'ExpenseInsurancePremium',
    order: 14,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseGardenandLawn-t554VcrN',
    name: 'ExpenseGardenandLawn',
    order: 15,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseStrataLevyFees-fZiRCr1o',
    name: 'ExpenseStrataLevyFees',
    order: 16,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseAdvertising-PcKtnDuM',
    name: 'ExpenseAdvertising',
    order: 17,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseCleaning-3TGKMkBt',
    name: 'ExpenseCleaning',
    order: 18,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseCouncilRate-TqjsdNe8',
    name: 'ExpenseCouncilRate',
    order: 19,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseLandTax-FMwhxxg2',
    name: 'ExpenseLandTax',
    order: 20,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseSundry-csXyJTCo',
    name: 'ExpenseSundry',
    order: 21,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseNonSpecified-SO3NTnZU',
    name: 'ExpenseNonSpecified',
    order: 22,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'PaymenttoOwner-334dejd5',
    name: 'PaymenttoOwner',
    order: 23,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'AmounttoOwner-Ci84cFzg',
    name: 'AmounttoOwner',
    order: 24,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'IncomeAccount-IIhjWw6',
    name: 'IncomeAccount',
    order: 25,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalDebit-dVqgSdNe',
    name: 'TotalDebit',
    order: 26,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalCredit-BwPI2fq',
    name: 'TotalCredit',
    order: 27,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseRepairsMaintenance-FJ9YKwNx',
    name: 'ExpenseRepairsMaintenance',
    order: 28,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Details-2wnYNbVg',
    name: 'Details',
    order: 29,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'Balance-NXyudnlk',
    name: 'Balance',
    order: 30,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'IncomeAccountHeader-3tyrMluV',
    name: 'IncomeAccountHeader',
    order: 31,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseAccountHeader-0e8oghd7',
    name: 'ExpenseAccountHeader',
    order: 32,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'BalanceAmount-GVhpHTuw',
    name: 'BalanceAmount',
    order: 33,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalGSTAmount-JGRQ5NOJ',
    name: 'TotalGSTAmount',
    order: 34,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DescTotalGSTofIncome-VFdU9er5',
    name: 'DescTotalGSTofIncome',
    order: 35,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DescTotalGSTofExpenses-trTvvUT9',
    name: 'DescTotalGSTofExpenses',
    order: 36,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DescTotalDebitCredit-QHWPxbZ0',
    name: 'DescTotalDebitCredit',
    order: 37,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TableHeaderGST-VdGqJT2P',
    name: 'TableHeaderGST',
    order: 38,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DescBalance-I4IudYu',
    name: 'DescBalance',
    order: 39,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'DescTotalGST-Z6DD42cb',
    name: 'DescTotalGST',
    order: 40,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'RemainingBalance-c1DMQBJM',
    name: 'RemainingBalance',
    order: 41,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'OwnerContributions-dCNDS7ut',
    name: 'OwnerContributions',
    order: 42,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'OwnerContributionsAmount-njiw1UFO',
    name: 'OwnerContributionsAmount',
    order: 43,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'StatementStartDate-VhUbijo5',
    name: 'StatementStartDate',
    order: 44,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'StatementEndDate-LKRp5ROU',
    name: 'StatementEndDate',
    order: 45,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalIncomeGSTAmount-cFHs3WOy',
    name: 'TotalIncomeGSTAmount',
    order: 46,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'TotalExpenseGSTAmount-avvoIkEW',
    name: 'TotalExpenseGSTAmount',
    order: 47,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'OpeningBalanceAmount-GNlW3oLz',
    name: 'OpeningBalanceAmount',
    order: 48,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'WithheldFund-L7QQuZw',
    name: 'WithheldFund',
    order: 49,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'WithheldAmount-J7M68uTK',
    name: 'WithheldAmount',
    order: 50,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpensePestControl-LIQfYvc',
    name: 'ExpensePestControl',
    order: 51,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpenseLegalFees-hJdyMSH3',
    name: 'ExpenseLegalFees',
    order: 52,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  },
  {
    id: 'ExpensePostage-rGDEKOrq',
    name: 'ExpensePostage',
    order: 53,
    projectId: 'Ni4ROLd5WUQyS5',
    dataType: TagDataType.String,
    isHidden: false
  }
])

export const EmptyTags = Tags.fromJson([])
