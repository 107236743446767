import {OcrElement, Geometry} from "./OcrElement"
import {List, Range} from "immutable"
import {CellVisitor} from "../DocumentVisitor"
import {IRelationship, ITableBlock} from "../types/ISmartDocsResult"
import {Cell} from "./Cell"

export class Table extends OcrElement {
  public readonly rows: List<Row>

  constructor(
    id: string,
    page: number,
    confidence: number,
    geometry: Geometry,
    public readonly cells: List<Cell>,
  ) {
    super(id, page, confidence, geometry)
    this.rows = Table.cellsToRows(cells)
  }

  accept<R>(visitor: CellVisitor<R>): List<R> {
    return this.cells.map(c => visitor.visitCell(c))
  }

  cellByColRow(columnIndex: number, rowIndex: number): Cell | undefined {
    if (rowIndex >= 0 && rowIndex < this.rows.size && columnIndex >= 0 && columnIndex < this.columnSize()) {
      const row = this.rows.get(rowIndex)
      return row?.cells.find(cell => cell.columnIndex === columnIndex)
    } else {
      return undefined
    }
  }

  columns(): List<Column> {
    return Range(0, this.columnSize()).map(columnIndex => {
      const maxWidth = this.cells.filter(cell => cell.columnIndex == columnIndex).map(cell => cell.width()).max()
      const maxColumnTextLength = this.cells.filter(cell => cell.columnIndex == columnIndex).map(cell => cell.text.length).max()
      return new Column(columnIndex, maxWidth ?? 0, maxColumnTextLength ?? 0)
    }).toList()
  }

  columnSize(): number {
    const size = this.cells.map(cell => cell.columnIndex).max()
    if (size) {
      return size + 1
    } else {
      return 0
    }
  }

  static fromJson(table: ITableBlock, cellBuilder: (relationships: IRelationship[]) => List<Cell>): Table {
    return new Table(
      table.Id,
      table.Page,
      table.Confidence,
      Geometry.fromJson(table.Geometry),
      cellBuilder(table.Relationships),
    )
  }

  private static cellsToRows(cells: List<Cell>): List<Row> {
    return Range(0, this.rowsCount(cells)).map((rowIndex) => {
      const cellsInRow = cells.filter(cell => cell.rowIndex === rowIndex)
      return new Row(rowIndex, cellsInRow)
    }).toList()
  }

  private static rowsCount(cells: List<Cell>): number {
    return cells.map(cell => cell.rowIndex).toSet().size
  }

}

export class Column {
  constructor(
    public readonly columnIndex: number,
    public readonly maxWidth: number,
    public readonly maxColumnTextLength: number
  ) {
  }
}

export class Row {
  constructor(
    public readonly rowIndex: number,
    public readonly cells: List<Cell>
  ) {
  }
}
