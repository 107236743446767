import { createStyles, makeStyles } from '@material-ui/core/styles'

import { TaggingTheme } from '../Theme'

export const useLoginSliderStyles = makeStyles((theme) =>
  createStyles({
    slider: {
      width: '100%',
      height: '100%',
      '& .slick-dots': {
        bottom: '10px',
        '& li': {
          width: '20px',
          height: '6px',
          borderRadius: '6px',
          background: '#b3d8fe',
          transition: 'all 0.3s ease-in-out 0s',
          '& button': {
            display: 'none'
          }
        },
        '& li .slick-active': {
          width: '33px',
          background: '#007cfb'
        }
      },
      '& .slick-arrow': {
        opacity: '0.3',
        cursor: 'pointer',
        transition: 'all .2s ease-in',
        zIndex: '100',
        '&:hover': {
          opacity: 0.8
        }
      },
      '& .slick-prev': {
        left: '5px'
      },
      '& .slick-next': {
        right: '5px'
      }
    },
    sliderGrid: {
      padding: '30px',
      marginTop: '0px'
    },
    sliderH3: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      marginBottom: 10
    },
    sliderH6: {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    sliderBody1: {
      color: '#F3F3F3'
    },
    sliderBody2: {
      color: TaggingTheme.palette.grey['700']
    },
    sliderPage1Paper: {
      height: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#F9FAF8',
      '@media(min-width: 600px)': {
        height: '180px',
        padding: '15px 20px'
      }
    },
    stepDesktopLayout: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'start'
    },
    stepMobileLayout: {
      display: 'flex',
      flexDirection: 'column'
    },
    step2DesktopLayout: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'end'
    },
    step2MobileLayout: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    sliderPage2Paper: {
      height: '160px',
      minWidth: '240px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      overflow: 'hidden'
    },
    sliderPage2H6: {
      color: '#FF83B2',
      fontSize: 18,
      fontWeight: 'bold'
    },
    sliderIcon: {
      color: theme.palette.primary.main,
      marginBottom: '5px'
    },
    sliderVideo: {
      objectFit: 'contain',
      height: '160px'
    },
    sliderDot: {
      color: theme.palette.secondary.main
    }
  })
)
