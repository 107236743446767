import autobind from 'autobind-decorator'
import { List } from 'immutable'

import Address, { AddressDTO } from './Address'
import Project, { ProjectDTO } from './Project'

export interface BusinessDTO {
  id?: string
  name: string
  owner?: string
  address: string
  website?: string
  phone?: string
  addressComponents: AddressDTO
  projects?: ProjectDTO[]
  creditsLeft?: number
}

export interface BusinessUsage {
  createdTime: string
  creditsLeft: number
}

export interface BusinessUsageDTO {
  usages: BusinessUsage[]
}

@autobind
export default class Business {
  constructor(
    public id: string = '',
    public name: string | null = null,
    public address: Address = new Address(),
    public website: string | undefined = undefined,
    public phone: string | undefined = undefined,
    public projects: Project[] = [],
    public owner: string | undefined = undefined,
    public agreeTC: boolean | null = null,
    public showAgreeTCError: boolean = false,
    public creditsLeft: number = 0
  ) {}

  static fromJson(businessDTO: BusinessDTO): Business {
    const {
      id,
      name,
      owner,
      website,
      phone,
      addressComponents,
      projects,
      creditsLeft
    } = businessDTO
    const address = addressComponents
      ? Address.fromJson(addressComponents)
      : undefined
    const projectsArr = projects
      ? projects.map((projectDTO) => Project.fromJson(projectDTO))
      : undefined
    return new Business(
      id,
      name,
      address,
      website,
      phone,
      projectsArr,
      owner,
      null,
      false,
      creditsLeft
    )
  }

  copy({
    id = this.id,
    name = this.name,
    owner = this.owner,
    address = this.address,
    website = this.website,
    phone = this.phone,
    projects = this.projects,
    agreeTC = this.agreeTC,
    showAgreeTCError = this.showAgreeTCError,
    creditsLeft
  }: Partial<Business>): Business {
    return new Business(
      id,
      name,
      address,
      website,
      phone,
      projects,
      owner,
      agreeTC,
      showAgreeTCError,
      creditsLeft
    )
  }

  setId(id: string): Business {
    return this.copy({ id })
  }

  setName(name: string | null): Business {
    return this.copy({ name })
  }

  setAddress(address: Address): Business {
    return this.copy({ address })
  }

  setWebsite(website: string | undefined): Business {
    return this.copy({ website })
  }

  setPhone(phone: string | undefined): Business {
    return this.copy({ phone })
  }

  setAgreeTC(agreeTC: boolean): Business {
    return this.copy({ agreeTC })
  }

  setShowAgreeTCError(showAgreeTCError: boolean): Business {
    return this.copy({ showAgreeTCError })
  }

  setCreditsLeft(creditsLeft: number): Business {
    return this.copy({ creditsLeft })
  }

  canSetup(): boolean {
    return (
      this.name !== '' &&
      this.address.canSetup() &&
      (this.agreeTC || this.agreeTC === null)
    )
  }

  isValid(): boolean {
    return Boolean(this.name) && this.address.isValid() && Boolean(this.agreeTC)
  }

  setInvalid(): Business {
    return this.copy({
      name: this.name || '',
      address: this.address.setInvalid(),
      showAgreeTCError: !this.agreeTC
    })
  }

  isWarning(): boolean {
    return this.address.isWarning() || !this.name
  }

  toBusinessDTO(): BusinessDTO {
    return {
      id: this.id || undefined,
      name: this.name ?? '',
      address: this.address.formattedAddress(),
      website: this.website || undefined,
      phone: this.phone || undefined,
      addressComponents: this.address.toAddressDTO()
    }
  }
}

export class Businesses {
  constructor(public readonly businesses: Business[]) {}

  static fromJson(businessesDTO: BusinessDTO[]): Businesses {
    const businesses = businessesDTO.map((businessDTO) =>
      Business.fromJson(businessDTO)
    )
    return new Businesses(businesses)
  }

  first(): Business | undefined {
    return this.businesses[0]
  }
}
