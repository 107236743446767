import ModelObject from 'domain/businessModel/ModelObject'
import moment from 'moment'
import { createContext } from 'react'
import { FormGridRowId, FormGridRowValue } from 'utils/DataTypeMapper'

export type FormControlContextType = {
    handleInputValue: (fieldName: string, value: string | moment.Moment | null, fieldId: FormGridRowId, record: ModelObject | undefined) => void,
    handleGridInputValue: (fieldName: string, fieldId: FormGridRowId, value: FormGridRowValue, record: ModelObject | undefined) => void,
    errors: { [key: string]: string },
    gridErrors: { [key: string]: string },
    record: ModelObject | undefined
}

const defaultRecord = new ModelObject()

export const defaultFormControl: FormControlContextType = {
  errors: {},
  gridErrors: {},
  handleInputValue: () => {
    // do nothing
  },
  handleGridInputValue: () => {
    // do nothing
  },
  record: defaultRecord
}

export const FormControlContext =
  createContext<FormControlContextType>(defaultFormControl)
