import {BusinessModelMapper} from "../BusinessModelMapper";
import {OcrDocument} from "../../OcrDocument";
import {IBusinessModel, ICouncilRateModel, IModelType} from "../../types/ISmartDocsResult";
import {CouncilRate} from "./CouncilRate";

export class CouncilRateMapper extends BusinessModelMapper{
    constructor(
        document: OcrDocument,
        private readonly jsonModel: ICouncilRateModel
    ) {
        super(document);
    }

    fromJson(): CouncilRate {
        const CouncilRateModel = this.jsonModel
        const model = new CouncilRate(
            IModelType.CouncilRate,
            CouncilRateModel.Version,
            this.mapTextBlock(CouncilRateModel.PropertyAddress),
            this.mapDateBlock(CouncilRateModel.IssueDate),
            this.mapDateBlock(CouncilRateModel.CurrentAmountDueDate),
            this.mapDoubleBlock(CouncilRateModel.CurrentAmountDue),
            this.mapDateBlock(CouncilRateModel.FirstInstalmentDueDate),
            this.mapDoubleBlock(CouncilRateModel.FirstInstalmentAmount),
            this.mapDateBlock(CouncilRateModel.SecondInstalmentDueDate),
            this.mapDoubleBlock(CouncilRateModel.SecondInstalmentAmount),
            this.mapDateBlock(CouncilRateModel.ThirdInstalmentDueDate),
            this.mapDoubleBlock(CouncilRateModel.ThirdInstalmentAmount),
            this.mapDateBlock(CouncilRateModel.FourthInstalmentDueDate),
            this.mapDoubleBlock(CouncilRateModel.FourthInstalmentAmount),
            this.mapDoubleBlock(CouncilRateModel.DiscountTotalAmountDue),
            this.mapDoubleBlock(CouncilRateModel.TotalAmount),
        )
        return model;
    }

    static toJson(model: CouncilRate): IBusinessModel{
        return {
            ModelType: IModelType.CouncilRate,
            Version: model.version,
            PropertyAddress:model.propertyAddress?.toModelKeyValue(),
            IssueDate: model.issueDate?.toModelKeyValue(),
            CurrentAmountDueDate: model.currentAmountDueDate?.toModelKeyValue(),
            CurrentAmountDue: model.currentAmountDue?.toModelKeyValue(),
            FirstInstalmentDueDate: model.firstInstalmentDueDate?.toModelKeyValue(),
            FirstInstalmentAmount: model.firstInstalmentAmount?.toModelKeyValue(),
            SecondInstalmentDueDate: model.secondInstalmentDueDate?.toModelKeyValue(),
            SecondInstalmentAmount: model.secondInstalmentAmount?.toModelKeyValue(),
            ThirdInstalmentDueDate: model.thirdInstalmentDueDate?.toModelKeyValue(),
            ThirdInstalmentAmount: model.thirdInstalmentAmount?.toModelKeyValue(),
            FourthInstalmentDueDate: model.fourthInstalmentDueDate?.toModelKeyValue(),
            FourthInstalmentAmount: model.fourthInstalmentAmount?.toModelKeyValue(),
            DiscountTotalAmountDue: model.discountTotalAmountDue?.toModelKeyValue(),
            TotalAmount: model.totalAmount?.toModelKeyValue(),
        } as ICouncilRateModel
    }
}