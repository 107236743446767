import create from 'zustand'

export interface iZendeskStoreState {
  unreadCount: number
}

const useZendeskStore = create<iZendeskStoreState>(() => ({
  unreadCount: 0
}))

export const setUnreadCount = (unreadCount: number) => {
  useZendeskStore.setState({ unreadCount })
}

export default useZendeskStore
