import {List} from 'immutable'
import {OcrDocument} from '../../OcrDocument'
import {
  IBusinessModel,
  IModelType,
  IReceiptItem, IReceiptModel
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {Receipt} from './Receipt'
import {ReceiptItem} from './ReceiptItem'

export class ReceiptMapper extends BusinessModelMapper{
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IReceiptModel
  ) {
    super(document)
  }

  fromJson(): Receipt {
    const receiptModel = this.jsonModel
    const items = receiptModel.Items?.map((item: IReceiptItem) => {
      return new ReceiptItem(
        this.mapTextBlock(item.Name),
        this.mapDoubleBlock(item.Quantity),
        this.mapDoubleBlock(item.Price),
        this.mapDoubleBlock(item.TotalPrice)
      )
    })
    const model = new Receipt(
      receiptModel.Version,
      items ? List(items) : List(),
      this.mapTextBlock(receiptModel.ReceiptType),
      this.mapTextBlock(receiptModel.MerchantName),
      this.mapTextBlock(receiptModel.MerchantPhoneNumber),
      this.mapTextBlock(receiptModel.MerchantAddress),
      this.mapDateBlock(receiptModel.TransactionDate),
      this.mapTextBlock(receiptModel.TransactionTime),
      this.mapDoubleBlock(receiptModel.Total),
      this.mapDoubleBlock(receiptModel.Subtotal),
      this.mapDoubleBlock(receiptModel.Tax),
      this.mapDoubleBlock(receiptModel.Tip)
    )
    return model
  }

  static toJson(model: Receipt): IBusinessModel {
    return {
      ModelType: IModelType.Receipt,
      Version: model.version,
      Items: model.items?.toArray().map(i => i.toJson()),
      ReceiptType: model.receiptType?.toModelKeyValue(),
      MerchantName: model.merchantName?.toModelKeyValue(),
      MerchantPhoneNumber: model.merchantPhoneNumber?.toModelKeyValue(),
      MerchantAddress: model.merchantAddress?.toModelKeyValue(),
      TransactionDate: model.transactionDate?.toModelKeyValue(),
      TransactionTime: model.transactionTime?.toModelKeyValue(),
      Total: model.total?.toModelKeyValue(),
      Subtotal: model.subtotal?.toModelKeyValue(),
      Tax: model.tax?.toModelKeyValue(),
      Tip: model.tip?.toModelKeyValue()
    } as IReceiptModel
  }

}
