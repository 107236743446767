import {
  DetectedField,
  FieldAndColumnName,
  Invoice,
  InvoiceItem,
  InvoiceItemColumn
} from '@bgl/textract-business-model-editor'
import type {
  GridCellParams,
  GridColDef,
  GridEditCellValueParams,
  GridRowData,
  GridRowsProp
} from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import { List } from 'immutable'
import React, { useMemo } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { InvoiceItemToTableData } from './InvoiceItemToTableData'

interface IInvoiceItemsEditorProp extends EditorFieldEvents {
  invoice: Invoice
  onCellClick?: (
    invoice: Invoice,
    item: InvoiceItem,
    field: DetectedField
  ) => void
}

export const InvoiceItemsEditor = (itemsProp: IInvoiceItemsEditorProp) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function onCellClick(params: GridCellParams) {
    if (itemsProp.onCellClick && itemsProp.invoice) {
      const columnName = params.field
      const rowId = +params.id
      const invoice = itemsProp.invoice
      const item = invoice.byIndex(rowId)
      const field = item?.fieldByColumn(columnName as InvoiceItemColumn)
      if (item && field) {
        itemsProp.onCellClick(invoice, item, field)
      } else {
        console.error(
          `not able to field cell based on column ${columnName} and rowId ${rowId}`
        )
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: FieldAndColumnName.InvoiceItemColumn_Description,
      headerName: 'DESCRIPTION',
      description: 'Description',
      width: 200,
      editable: true
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_Amount,
      headerName: 'AMOUNT',
      description: 'Amount',
      width: 130,
      editable: true
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_Quantity,
      headerName: 'QUANTITY',
      description: 'Quantity',
      width: 130,
      editable: true,
      type: 'number'
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_UnitPrice,
      headerName: 'UNITPRICE',
      description: 'Unit Price',
      width: 130,
      editable: true
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_ProductCode,
      headerName: 'PRODUCTCODE',
      description: 'Production',
      width: 140
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_Unit,
      headerName: 'UNIT',
      description: 'Unit',
      width: 120,
      editable: true
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_Date,
      headerName: 'DATE',
      description: 'Date',
      width: 150,
      editable: true
    },
    {
      field: FieldAndColumnName.InvoiceItemColumn_Tax,
      headerName: 'TAX',
      description: 'Tax',
      width: 120,
      editable: true
    }
  ]

  const rows: GridRowsProp = InvoiceItemToTableData.toRows(
    itemsProp.invoice.items
  )

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
      onCellClick={onCellClick}
      onCellValueChange={(params: GridEditCellValueParams) => {
        // handleGridInputValue(params.field, params.id, params.value, record)
        itemsProp.onGridValueChanged?.(
          params.field as FieldAndColumnName,
          params.id,
          params.value
        )
      }}
    />
  )
}
