import { ThemeProvider } from '@material-ui/styles'
import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'

import { isProd } from './ApiPath'
import AppRoutes from './AppRoutes'
import TaggingTheme from './assets/Theme'
import ConsecutiveSnackbars from './utils/ConsecutiveSnackBars'

ReactGA.initialize([
  {
    trackingId: 'G-28RRF43D1K',
    gaOptions: {
      testMode: false,
      userId:
        localStorage.getItem(
          'CognitoIdentityServiceProvider.7sbj0js065bpqdnrvlcp2dmo0e.LastAuthUser'
        ) ||
        localStorage.getItem(
          'CognitoIdentityServiceProvider.9h0qc3pj4r756kj658utjd54p.LastAuthUser'
        )
    }
  }
])

Amplify.configure({
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools: 'enable',
  aws_user_pools_id: isProd
    ? 'ap-southeast-2_gdH7jHbx3'
    : 'ap-southeast-2_lwEVIAmOn',
  aws_user_pools_web_client_id: isProd
    ? '7sbj0js065bpqdnrvlcp2dmo0e'
    : '9h0qc3pj4r756kj658utjd54p',
  authenticationFlowType: 'USER_SRP_AUTH'
})

ReactDOM.render(
  <ThemeProvider theme={TaggingTheme}>
    <ConsecutiveSnackbars />
    <AppRoutes />
  </ThemeProvider>,
  document.getElementById('smartdocs-tagging-app')
)
