export enum Metrics {
  F1Score = 'F1-Score',
  Precision = 'Precision',
  Recall = 'Recall',
  Support = 'Support',
  Accuracy = 'Accuracy',
  MAPpt5 = 'mAP@50',
  MAPpt5pt95 = 'mAP@[.5,.95]',
  Mae = 'mae',
  MaeTrain = 'Train-mae',
  Rmse = 'rmse',
  RmseTrain = 'Train-rmse',
  BoxLoss = 'Box Loss',
  ClsLoss = 'CLS Loss',
  ObjLoss = 'Object Loss',
  F1 = 'f1'
}

export const metricsNameMap: { [key: string]: Metrics } = {
  f1Score: Metrics.F1Score,
  precision: Metrics.Precision,
  recall: Metrics.Recall,
  support: Metrics.Support,
  accuracy: Metrics.Accuracy,
  mAPpt5: Metrics.MAPpt5,
  mAPpt5pt95: Metrics.MAPpt5pt95,
  mae: Metrics.Mae,
  maeTrain: Metrics.MaeTrain,
  rmse: Metrics.Rmse,
  rmseTrain: Metrics.RmseTrain,
  boxLoss: Metrics.BoxLoss,
  clsLoss: Metrics.ClsLoss,
  objLoss: Metrics.ObjLoss,
  f1: Metrics.F1
}

type MainMetricsType = {
  name: Metrics
  value: number
  score: string
  evaluation: Evaluation
}

type TableColumnType = {
  field: string
  headerName: Metrics
}

export enum Evaluation {
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor',
  NotAvailable = 'N/A'
}

export type DetailNLPMetricsType = {
  labelId: string
  f1Score: number
  precision: number
  recall: number
  support: number
}

export type DetailObjectDetectionMetricsType = {
  labelId: string
  f1: number
  mAPpt5: number
  mAPpt5pt95: number
  precision: number
  recall: number
}

export type DetailObjectCountMetricsType = {
  labelId: string
  mae: number
  maeTrain: number
  rmse: number
  rmseTrain: number
}

export type DetailMetrics =
  | DetailNLPMetricsType[]
  | DetailObjectDetectionMetricsType[]
  | DetailObjectCountMetricsType[]

export type OverallNLPMetricsType = Omit<DetailNLPMetricsType, 'labelId'> & {
  accuracy: number
}

export type OverallObjectDetectionMetricsType = Omit<
  DetailObjectDetectionMetricsType,
  'labelId' | 'f1'
> & {
  percentage: number
  boxLoss: number
  clsLoss: number
  objLoss: number
}

export type OverallObjectCountMetricsType = Omit<
  DetailObjectCountMetricsType,
  'labelId' | 'maeTrain' | 'rmseTrain'
> & {
  percentage: number
}

export type OverallMetrics =
  | OverallNLPMetricsType
  | OverallObjectDetectionMetricsType
  | OverallObjectCountMetricsType

export type ConfusionMatrix = number[][]

export type BaseTrainReportDTO<T, M> = {
  id: string
  datetime: string
  projectId: string
  epoch: number
  details: T[]
  confusionMatrix?: ConfusionMatrix
} & M

export type TrainReportDTONLP = BaseTrainReportDTO<
  DetailNLPMetricsType,
  OverallNLPMetricsType
>

export type TrainReportDTOObjectDetection = BaseTrainReportDTO<
  DetailObjectDetectionMetricsType,
  OverallObjectDetectionMetricsType
>

export type TrainReportDTOObjectCount = BaseTrainReportDTO<
  DetailObjectCountMetricsType,
  OverallObjectCountMetricsType
>

export type TrainReportsDTO =
  | TrainReportDTONLP[]
  | TrainReportDTOObjectDetection[]
  | TrainReportDTOObjectCount[]

type OverallTableRowNLP = {
  id: string
  f1Score: string
  accuracy: string
  precision: string
  recall: string
  support: string
}

type OverallTableRowObjectDetection = {
  id: string
  mAPpt5: string
  precision: string
  recall: string
  mAPpt5pt95: string
  boxLoss: string
  clsLoss: string
  objLoss: string
}

type OverallTableRowObjectCount = {
  id: string
  mae: string
  rmse: string
}

type OverallTableRow =
  | OverallTableRowNLP
  | OverallTableRowObjectDetection
  | OverallTableRowObjectCount

type DetailTableRowNLP = Omit<OverallTableRowNLP, 'accuracy'> & {
  tagName: string
}

type DetailTableRowObjectDetection = Omit<
  OverallTableRowObjectDetection,
  'boxLoss' | 'clsLoss' | 'objLoss'
> & { tagName: string; f1: string }

type DetailTableRowObjectCount = OverallTableRowObjectCount & {
  tagName: string
  maeTrain: string
  rmseTrain: string
}

type DetailTableRow =
  | DetailTableRowNLP
  | DetailTableRowObjectDetection
  | DetailTableRowObjectCount

export default abstract class TrainingReport {
  protected constructor(
    public id: string,
    public datetime: string,
    public projectId: string,
    public epoch: number
  ) {}

  public abstract details: DetailMetrics
  public abstract overallMetrics: OverallMetrics
  public abstract generateOverallTableColumns(): TableColumnType[]
  public abstract generateOverallTableRow(): OverallTableRow[]
  public abstract generateDetailTableColumns(): TableColumnType[]
  public abstract generateDetailTableRows(
    tagIdToName: (tagId: string) => string
  ): DetailTableRow[]
  public abstract getMainMetrics(): MainMetricsType

  generateColumn = (keys: string[]) => {
    return keys.map((key) => ({
      field: key as string,
      headerName: metricsNameMap[key as string]
    }))
  }
}
