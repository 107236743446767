import autobind from 'autobind-decorator'
import type { AxiosError, Method, ResponseType } from 'axios'
import axios from 'axios'

import { ssoURL } from '../ApiPath'
import { WorkflowStatus } from '../models/TFile'
import { IWorkflowStatusDTO } from '../utils/types'
import { addSnackbar } from './stores/SnackBarStore'

interface ErrorResponseData {
  error?: Object[]
}

export type RejectedFileNameList = {
  filesNotSupported: string[]
  filesOverMaxSize: string[]
  filesLoadTest: string[]
}

export interface IFileUploadResponse {
  workflows: IWorkflowStatusDTO[]
  rejectedFileList: RejectedFileNameList
}

@autobind
export default class BaseApi {
  constructor() {
    this.initPermissionErrorInterceptor()
  }

  private initPermissionErrorInterceptor() {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.type &&
            error.response.data.type === 'USER_AUTH_ERROR'
          ) {
            this.handleSessionError()
          } else if (
            error.response.status === 403 &&
            error.response.data.error &&
            error.response.data.error.startsWith(
              'User does not have all of the required permissions'
            )
          ) {
            this.handlePermissionError(error.response.data.error)
          }
        }
        return Promise.reject(error)
      }
    )
  }

  loadByGet(
    url: string,
    auth?: { apiKey?: string },
    responseType?: ResponseType,
    withCredentials?: boolean,
    signal?: AbortSignal
  ) {
    return this.loadBy(
      'GET',
      url,
      undefined,
      auth,
      responseType,
      withCredentials,
      signal
    )
  }

  loadByPost(
    url: string,
    data?: Object,
    auth?: { apiKey?: string },
    withCredentials?: boolean
  ) {
    return this.loadBy('POST', url, data, auth, undefined, withCredentials)
  }

  loadByPatch(
    url: string,
    data?: object,
    auth?: { apiKey?: string },
    withCredentials?: boolean
  ) {
    return this.loadBy('PATCH', url, data, auth, undefined, withCredentials)
  }

  loadByDelete(
    url: string,
    auth?: { apiKey?: string },
    withCredentials?: boolean
  ) {
    return this.loadBy(
      'DELETE',
      url,
      undefined,
      auth,
      undefined,
      withCredentials
    )
  }

  loadByBlob(url: string, signal: AbortSignal) {
    return axios({
      url,
      method: 'GET',
      responseType: 'blob',
      signal: signal
    })
      .then((response) => new Blob([response.data]))
      .catch((error) => {
        this.handleGetFileBlobError(this.mapError(error))
        throw error
      })
  }

  uploadFileFormData(
    url: string,
    body: FormData,
    onProgress: (percentComplete: number) => void
  ): Promise<IFileUploadResponse> {
    const request = new XMLHttpRequest()
    return new Promise((resolve, reject) => {
      request.upload.addEventListener(
        'progress',
        (event: any) => {
          if (event.lengthComputable) {
            onProgress(event.loaded / event.total)
          }
        },
        false
      )
      request.onloadend = () => {
        if (request.status >= 200 && request.status < 400) {
          const response = JSON.parse(request.response)
          resolve(response)
        } else {
          console.log(request.responseText)
          reject()
          this.handleFileUploadError(
            `Error ${request.status} while uploading file: ${request.responseText}`
          )
        }
      }
      request.open('post', url, true)
      request.withCredentials = true
      request.send(body)
    })
  }

  mapError(error: string | AxiosError): string {
    if (error) {
      if (typeof error === 'string') {
        return error
      } else {
        if (error.response) {
          let serverMessage = ''
          if (
            typeof error.response.data === 'object' &&
            error.response.data &&
            'error' in error.response.data
          ) {
            serverMessage = JSON.stringify(
              (error.response.data as ErrorResponseData).error
            )
            // If need to output the error message, modify the following line
            console.error(serverMessage)
          }
          if (
            error.response.status === 401 ||
            error.response.statusText === '401'
          ) {
            return `${
              error.response.status
            }_${new Date().getTime()}_${serverMessage} `
          }
          return `${error.response.status}_${error.response.statusText}_${serverMessage}`
        }
        return error.message
      }
    }
    return 'UnknownError'
  }

  private loadBy(
    method: Method,
    url: string,
    data?: Object,
    auth?: { apiKey?: string },
    responseType?: ResponseType,
    withCredentials: boolean = true,
    signal?: AbortSignal
  ) {
    const { apiKey } = auth ?? {}
    const headers = apiKey ? { 'api-key': apiKey } : {}

    return axios({
      url,
      headers,
      data,
      method,
      responseType,
      withCredentials,
      signal
    })
      .then((response) => response.data)
      .catch((error) => {
        this.handleBaseApiError(this.mapError(error))
        throw error
      })
  }

  private handleSessionError() {
    window.location.href = `${ssoURL}/login?app=sdd360&appFilter=sdd360`
  }
  private handlePermissionError(errorMessage: string) {
    addSnackbar(errorMessage, 'error')
  }

  private handleFileUploadError(errorMessage: string) {
    addSnackbar(errorMessage, 'error')
  }

  private handleGetFileBlobError(errorMessage: string) {
    addSnackbar(errorMessage, 'error')
  }

  private handleBaseApiError(errorMessage: string) {
    addSnackbar(errorMessage, 'error', 10000)
  }
}
