import { faShieldAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import redactImg from '../../../assets/images/redaction/redactSamplePdf.png'

function DataProtection() {
  const autoItems = [
    'Person Name',
    'Organisation Name',
    'Street Address',
    'Email Address',
    'Phone Number',
    'Date',
    'Bank Account Number',
    'Reference Number',
    'Financial Amounts',
    'URL',
    'Australian Company Number (ACN)',
    'Australian Business Number (ABN)',
    'Australian Tax File Number (TFN)'
  ]

  const renderAutoItems = () => {
    return autoItems.map((item, index) => {
      return (
        <li key={index}>
          <FontAwesomeIcon
            icon={faShieldAlt}
            style={{ marginRight: '10px' }}
            size={'lg'}
            color={'#5f5cd7'}
          />
          <span className="dataProtectionItemText">{item}</span>
        </li>
      )
    })
  }

  return (
    <section className="data-protection">
      <div className="container">
        <div>
          <h1 className="dataProtectionHeader">
            SmartDocs AI Studio Makes Data Protection Seamlessly
          </h1>
          <p>
            SmartDocs Redaction delivers a fully automated and highly accurate
            anonymisation of a range of sensitive data, including but not
            limited to Personally Identifiable Information (PII).
          </p>
        </div>
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-4 left-panel">
            <h3>Auto Items</h3>
            <ul>{renderAutoItems()}</ul>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 right-panel">
            <img src={redactImg} alt="sample" width={600} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DataProtection
