import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

export const trackPage = (pathname: string | undefined): void => {
  if (pathname) {
    const paths = pathname.split('/')
    const firstPath = paths.find((value) => value.length > 0) ?? ''
    const lastPath = paths.pop() ?? ''
    ReactGA.send({ hitType: 'pageview', page: `${firstPath}/${lastPath}` })
  } else {
    ReactGA.send({ hitType: 'pageview' })
  }
}

export const trackEvent = (args: UaEventOptions): void => {
  ReactGA.event({ ...args, label: args.label?.replace('@', '###') })
}
