import moment from 'moment'

export enum TrainingJobStatus {
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  Stopping = 'Stopping',
  Stopped = 'Stopped'
}

export enum TrainingModelSize {
  Lightning = 'Lightning',
  Normal = 'Normal',
  Large = 'Large',
  XLarge = 'XLarge',
  XXLarge = 'XXLarge',
  XXXLarge = 'XXXLarge',
  XXXXLarge = 'XXXXLarge'
}

export interface TJobDTO {
  id: string
  projectId: string
  status: TrainingJobStatus
  startedTime: string
  modelS3Key?: string
  percentage?: number
  message?: string
  name?: string
  trainingStartedTime?: string
  trainingEndedTime?: string
  instanceType?: string
  modelSize?: TrainingModelSize
}

export class TJob {
  constructor(
    public id: string,
    public status: TrainingJobStatus,
    public startedAt: moment.Moment,
    public modelS3Key: string | null = null,
    public percentage: number | null = null,
    public message: string | null = null,
    public name?: string,
    public trainingStartedTime?: moment.Moment,
    public trainingEndedTime?: moment.Moment,
    public instanceType?: string,
    public modelSize?: TrainingModelSize
  ) {}

  static fromJson(tJobDTO: TJobDTO): TJob {
    const {
      id,
      status,
      startedTime,
      modelS3Key,
      percentage,
      message,
      name,
      trainingStartedTime,
      trainingEndedTime,
      instanceType,
      modelSize
    } = tJobDTO
    return new TJob(
      id,
      status,
      moment(startedTime),
      modelS3Key,
      percentage,
      message,
      name,
      trainingStartedTime ? moment(trainingStartedTime) : undefined,
      trainingEndedTime ? moment(trainingEndedTime) : undefined,
      instanceType,
      modelSize
    )
  }
}

export class TJobs {
  static freeJobsLimit = 3

  static fromJson(tJobsDTOs: TJobDTO[]): TJobs {
    const jobs = tJobsDTOs.map(TJob.fromJson)
    return new TJobs(jobs)
  }

  constructor(public jobs: TJob[] = []) {}

  size(): number {
    return this.jobs.length
  }

  reachedLimit(): boolean {
    return this.size() >= TJobs.freeJobsLimit
  }

  mapValues<T>(fn: (value: TJob, index?: number) => T): T[] {
    return this.jobs.map(fn)
  }
}
