import {IBusinessModel, IIdCardModel, IModelType} from "../../types/ISmartDocsResult"
import {BusinessModelMapper} from "../BusinessModelMapper"
import {IdCard} from "./IdCard"
import {OcrDocument} from "../../OcrDocument"

export class IdCardMapper extends BusinessModelMapper{
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IIdCardModel
  ) {
    super(document)
  }

  fromJson(): IdCard {
    const idCardModel = this.jsonModel
    const model = new IdCard(
      idCardModel.Version,
      this.mapTextBlock(idCardModel.DocumentType),
      this.mapTextBlock(idCardModel.MachineReadableZone),
      this.mapTextBlock(idCardModel.Sex),
      this.mapTextBlock(idCardModel.Nationality),
      this.mapTextBlock(idCardModel.Address),
      this.mapTextBlock(idCardModel.CountryRegion),
      this.mapDateBlock(idCardModel.DateOfBirth),
      this.mapDateBlock(idCardModel.DateOfExpiration),
      this.mapTextBlock(idCardModel.DocumentNumber),
      this.mapTextBlock(idCardModel.FirstName),
      this.mapTextBlock(idCardModel.LastName),
      this.mapTextBlock(idCardModel.Region)
    )
    return model
  }

  static toJson(model: IdCard): IBusinessModel {
    return {
      ModelType: IModelType.IdCard,
      Version: model.version,
      DocumentType: model.documentType?.toModelKeyValue(),
      MachineReadableZone: model.machineReadableZone?.toModelKeyValue(),
      Sex: model.sex?.toModelKeyValue(),
      Nationality: model.nationality?.toModelKeyValue(),
      Address: model.address?.toModelKeyValue(),
      CountryRegion: model.countryRegion?.toModelKeyValue(),
      DateOfBirth: model.dateOfBirth?.toModelKeyValue(),
      DateOfExpiration: model.dateOfExpiration?.toModelKeyValue(),
      DocumentNumber: model.documentNumber?.toModelKeyValue(),
      FirstName: model.firstName?.toModelKeyValue(),
      LastName: model.lastName?.toModelKeyValue(),
      Region: model.region?.toModelKeyValue()
    } as IIdCardModel
  }
}
