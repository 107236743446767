import { DetectedField } from "domain/businessModel/DetectedField"
import { FormFieldValidationResult } from "domain/validator/FieldValidatorDef"
import { FormFieldValidator } from "domain/validator/FormFieldValidator"
import { List } from "immutable"
import { has, isEmpty } from "lodash"
import moment from 'moment'
import { useCallback, useEffect, useState } from "react"
import ModelObject from "domain/businessModel/ModelObject"
import {FormGridRowId, FormGridRowValue} from "../DataTypeMapper";

/**
 * The return object of useFormControl().
 * @typedef {Object} IUseFormReturn
 * @property {Function} handleInputValue - validate user input for individual and array field.
 * @property {Function} handleGridInputValue - validate user input for grid.
 * @property {Object.<string, string>} errors - name error mapping object for all invalid individual and array field.
 * @property {Object.<string, string>} gridErrors - name error mapping object for all invalid cell of grid.
 */
interface IUseFormReturn {
    handleInputValue: (fieldName: string, value: string | moment.Moment | null, fieldId: string | number, record: ModelObject | undefined) => void,
    handleGridInputValue: (fieldName: string, fieldId: string | number, value: FormGridRowValue, record: ModelObject | undefined) => void,
    errors: { [key: string]: string },
    gridErrors: { [key: string]: string }
}

/**
 * A hook to help on form fields validation and error text returns. Individual field, array field and grid can be validated using this hook
 *
 * This hook takes four parameters and return object containing handleInputValue, handleGridInputValue, errors and gridErrors.
 *
 * @param {FormFieldValidator[]} fieldValidators Validators for Individual field or Array fields to help determin if user input is valid.
 * @param {ModelObject | undefined} fieldInitialObject Object contains initial values for Individual or Array fields which used to
 * generate errors for editor component's first render .
 * @param {FormFieldValidator[]} gridValidators Validators for grid columns to help determin if user input is valid.
 * @param {GridRowData[]} gridInitialRows Initial value for grid, used to generate gridErrors for editor component's first render.
 *
 * @returns {IUseFormReturn} the return object.
 *
 * @example
 *
 * import { useFormControl } from '<path_to_useFormControl>/useFormControl'
 * import { useFormFieldValidator } from '<path_to_useFormFieldValidator>/useFormFieldValidator'
 * 
 * const fieldNames = [
 *   FieldAndColumnName.BankStatementEditor_AccountNumber,
 *   FieldAndColumnName.BankStatementEditor_BSB,
 *   FieldAndColumnName.BankStatementEditor_StartDate,
 *   FieldAndColumnName.BankStatementEditor_EndDate
 * ]
 * 
 *  const columnNames = [
 *    FieldAndColumnName.TransactionTable_Date,
 *    FieldAndColumnName.TransactionTable_Debit,
 *    FieldAndColumnName.TransactionTable_Credit
 *  ]
 *
 * export const MyComponent = () => {
 *   const fieldValidators = useMemo(
 *     () => FormFieldValidator.generateValidators(BankStatementValidatorFactory, fieldNames)
 *     , []
 *   )
 * 
 *   const gridValidators = useMemo(
 *     () => FormFieldValidator.generateValidators(TransactionTableCellValidatorFactory, columnNames)
 *     , []
 *   )
 * 
 *   const initialFieldValueObject = <instance of ModelObject>
 *   const initialGridValueRows = <instance of GridRowsProp>
 * 
 *   const { handleInputValue, handleGridInputValue, errors, gridErrors } = useFormControl(
 *     fieldValidators,
 *     initialFieldObject,
 *     gridValidators,
 *     initialGridValueRows
 *   )
 *  
 *   return (
 *     <FormControlContext.Provider value={{ handleInputValue, handleGridInputValue, errors, gridErrors }}>
 *       <YOUR-FORM>
 *     </FormControlContext.Provider>
 *   );
 * }
 */

export default function useFormControl(
    fieldValidators: readonly FormFieldValidator[],
    fieldInitialObject: ModelObject | undefined,
    gridValidators: readonly FormFieldValidator[] = FormFieldValidator.emptyValidatorArray,
    gridInitialRows: readonly any[] = FormFieldValidator.emptyGridCellInitialRows,
    notifyError?: (error: boolean) => void
): IUseFormReturn {
    const [errors, updateErrors] = useState<{ [key: string]: string }>({})
    const [gridErrors, updateGridErrors] = useState<{ [key: string]: string }>({})

    const validateUserInput = 
        (validators: readonly FormFieldValidator[], updateErrorResult: Function,  errorResult: { [key: string]: string }) => 
        (fieldName: string, value: FormGridRowValue, fieldId: FormGridRowId, record: ModelObject | undefined) => 
    {
        const validator = validators.find(v => v.getName() === fieldName)

        if (!validator) {
            return
        }

        const result = validator.validate(value, record, fieldId)
        const errorKey = FormFieldValidator.generateErrorKey(fieldName, fieldId)
        if (result !== FormFieldValidationResult.VALIDATION_SUCCESS) {
            updateErrorResult({
                ...errorResult,
                [errorKey]: result
            })
        } else {
            if (has(errorResult, errorKey)) {
                const updatedErrors = { ...errorResult }
                delete updatedErrors[errorKey]
                updateErrorResult(updatedErrors)
            }
        }
    }

    const handleInputValue = useCallback((fieldName: string, value: FormGridRowValue, fieldId: FormGridRowId, record: ModelObject | undefined): void => {
        validateUserInput(fieldValidators, updateErrors, errors)(fieldName, value, fieldId, record)
    }, [fieldValidators, errors])

    const handleGridInputValue = useCallback((fieldName: string, fieldId: FormGridRowId, value: FormGridRowValue, record: ModelObject | undefined) => {
        validateUserInput(gridValidators, updateGridErrors, gridErrors)(fieldName, value, fieldId, record)
    }, [gridValidators, gridErrors])

    // validate initial value of individual feilds
    useEffect(() => {
        const initErrors: { [key: string]: string } = {}
        const validate = (value: DetectedField, validator: FormFieldValidator) => {
            const result = validator.validate(value?.parsedValue, fieldInitialObject, value?.id)
            if (result !== FormFieldValidationResult.VALIDATION_SUCCESS) {
                initErrors[FormFieldValidator.generateErrorKey(validator.getName(), value?.id)] = result
            }
        }

        for (const validator of fieldValidators) {
            const initialValue = fieldInitialObject?.get(validator.getName())
            if (List.isList(initialValue)) {
                initialValue.forEach(v => validate(v as DetectedField, validator))
            } else {
                validate(initialValue as DetectedField, validator)
            }
        }
        updateErrors(initErrors)
    }, [fieldValidators, fieldInitialObject])

    // validate initial value of grid
    useEffect(() => {
        const initGridErrors: { [key: string]: string } = {};
        for (const row of gridInitialRows) {
            for (const validator of gridValidators) {
                if (has(row, validator.getName())) {
                    const result = validator.validate(row[validator.getName()], fieldInitialObject, row.id)
                    if (result !== FormFieldValidationResult.VALIDATION_SUCCESS) {
                        initGridErrors[FormFieldValidator.generateErrorKey(validator.getName(), row.id)] = result
                    }
                }
            }
        }
        updateGridErrors(initGridErrors)
    }, [gridValidators, gridInitialRows, fieldInitialObject])

    useEffect(() => {
        if (notifyError) {
            notifyError(!isEmpty(gridErrors) || !isEmpty(errors))
        }
    }, [notifyError, errors, gridErrors])

    return { handleInputValue, handleGridInputValue, errors, gridErrors }
}