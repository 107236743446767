import { List } from 'immutable'

export interface ListAssistantsResponseDTO {
  object: string
  data?: AssistantResponseDTO[]
  first_id?: string
  last_id?: string
  has_more: boolean
}

export interface AssistantResponseDTO {
  id: string
  object: string
  created_at: number
  name: string
  description: string | undefined
  model: string
  instructions: string
  tools: ToolDTO[]
  tool_resources: Record<string, any>
  metadata: Record<string, string>
}

export interface ToolDTO {
  type: string
  function?: AssistantFunctionDTO
}

export interface AssistantFunctionDTO {
  description?: string
  name: string
  parameters?: Record<string, any>
}

export class Assistants {
  constructor(
    readonly assistants: List<Assistant>,
    readonly loadingAssistant: boolean
  ) {}

  static empty(): Assistants {
    return new Assistants(List(), false)
  }

  static fromDTO(dto: ListAssistantsResponseDTO): Assistants | undefined {
    const data = dto.data
    if (!data) {
      return undefined
    }
    const orderedData = data.sort((a, b) => a.created_at - b.created_at)
    return new Assistants(List(orderedData.map(Assistant.fromDTO)), false)
  }

  firstAssistant(): Assistant | null {
    return this.assistants.first() || null
  }

  updateAssistant(assistant: Assistant): Assistants {
    return new Assistants(
      this.assistants.map((a) => (a.id === assistant.id ? assistant : a)),
      this.loadingAssistant
    )
  }

  setLoadingAssistant(loadingAssistant: boolean): Assistants {
    return new Assistants(this.assistants, loadingAssistant)
  }
}

export class Assistant {
  id: string
  object: string
  createdAt: number
  name: string
  description: string | undefined

  constructor(
    id: string,
    object: string,
    createdAt: number,
    name: string,
    description: string | undefined
  ) {
    this.id = id
    this.object = object
    this.createdAt = createdAt
    this.name = name
    this.description = description
  }

  static fromDTO(dto: AssistantResponseDTO): Assistant {
    return new Assistant(
      dto.id,
      dto.object,
      dto.created_at,
      dto.name,
      dto.description
    )
  }
}
