import { Button } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'

export const PrimaryButton = withStyles((theme) =>
  createStyles({
    root: {
      '&:not([disabled])': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark
        }
      }
    },
    label: {
      textTransform: 'none'
    }
  })
)(Button)

export const GreenButton = withStyles((theme) =>
  createStyles({
    root: {
      '&:not([disabled])': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark
        }
      }
    },
    label: {
      textTransform: 'none'
    }
  })
)(Button)

export const RedButton = withStyles((theme) =>
  createStyles({
    root: {
      '&:not([disabled])': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.dark
        }
      }
    },
    label: {
      textTransform: 'none'
    }
  })
)(Button)

export const GrayButton = withStyles((theme) =>
  createStyles({
    root: {
      '&:not([disabled])': {
        color: theme.palette.common.white,
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.64)'
        }
      }
    },
    label: {
      textTransform: 'none'
    }
  })
)(Button)

export const OutlinedButton = withStyles((theme) =>
  createStyles({
    root: {
      '&:not([disabled])': {
        color: theme.palette.common.black,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }
    },
    label: {
      textTransform: 'none'
    }
  })
)(Button)

export const NoHoverBackgroundButton = withStyles(() =>
  createStyles({
    root: {
      '& MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  })
)(Button)
