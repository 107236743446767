import {
  FieldAndColumnName,
  InvoiceItem
} from '@bgl/textract-business-model-editor'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class InvoiceItemToTableData {
  static toRows(items: List<InvoiceItem>): GridRowsProp {
    return items
      .map((item, index) => InvoiceItemToTableData.toGridRow(item, index))
      .toArray()
  }

  static toGridRow = (item: InvoiceItem, id: number) => {
    return {
      id,
      [FieldAndColumnName.InvoiceItemColumn_Amount]: item.amount?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_Description]:
        item.description?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_Quantity]:
        item.quantity?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_UnitPrice]:
        item.unitPrice?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_ProductCode]:
        item.productCode?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_Unit]: item.unit?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_Date]: item.date?.parsedValue,
      [FieldAndColumnName.InvoiceItemColumn_Tax]: item.tax?.parsedValue
    }
  }
}
