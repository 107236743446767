import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { List } from 'immutable'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import { UtilityBillMapper } from './UtilityBillMapper'

export class UtilityBill extends BusinessModel {
  constructor(
      modelType: IModelType,
      version: string,
      readonly propertyAddress?: StringField,
      readonly issueDate?: DateField,
      readonly dueDate?: DateField,
      readonly totalAmount?: DoubleField,
      readonly accountNumber?: StringField,
      readonly invoiceNumber?: StringField,
      readonly customerName?: StringField,
      readonly overDueAmount?: DoubleField,
      readonly customerABN?: StringField,
      readonly GSTAmount?:DoubleField,
      readonly customerAddress?:StringField,
      readonly supplierName?: StringField,
      readonly supplierABN?: StringField,
      readonly billingStartDate?: DateField,
      readonly billingEndDate?: DateField,
      readonly servicePhoneNumber?: List<StringField>
  ){
    super(modelType, version)
  }

  copy({
    version = this.version,
    propertyAddress = this.propertyAddress,
    issueDate = this.issueDate,
    dueDate = this.dueDate,
    totalAmount = this.totalAmount,
    accountNumber = this.accountNumber,
    invoiceNumber = this.invoiceNumber,
    customerName = this.customerName,
    overDueAmount = this.overDueAmount,
    customerABN = this.customerABN,
    GSTAmount = this.GSTAmount,
    customerAddress = this.customerAddress,
    supplierName = this.supplierName,
    supplierABN = this.supplierABN,
    billingStartDate = this.billingStartDate,
    billingEndDate = this.billingEndDate,
    servicePhoneNumber = this.servicePhoneNumber
  }): UtilityBill {
    return new UtilityBill(
      this.modelType,
      version,
      propertyAddress,
      issueDate,
      dueDate,
      totalAmount,
      accountNumber,
      invoiceNumber,
      customerName,
      overDueAmount,
      customerABN,
      GSTAmount,
      customerAddress,
      supplierName,
      supplierABN,
      billingStartDate,
      billingEndDate,
      servicePhoneNumber
    )
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.UtilityBillEditor_PropertyAddress:
      case FieldAndColumnName.UtilityBillEditor_AccountNumber:
      case FieldAndColumnName.UtilityBillEditor_InvoiceNumber:
      case FieldAndColumnName.UtilityBillEditor_CustomerName:
      case FieldAndColumnName.UtilityBillEditor_CustomerABN:
      case FieldAndColumnName.UtilityBillEditor_CustomerAddress:
      case FieldAndColumnName.UtilityBillEditor_SupplierABN:
      case FieldAndColumnName.UtilityBillEditor_SupplierName:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      case FieldAndColumnName.UtilityBillEditor_DueDate:
      case FieldAndColumnName.UtilityBillEditor_IssueDate:
      case FieldAndColumnName.UtilityBillEditor_BillingStartDate:
      case FieldAndColumnName.UtilityBillEditor_BillingEndDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)

      case FieldAndColumnName.UtilityBillEditor_TotalAmount:
      case FieldAndColumnName.UtilityBillEditor_OverDueAmount:
      case FieldAndColumnName.UtilityBillEditor_GSTAmount:
        return new DoubleField(uuid(), value as number, [], List(), modifiedBy)

      default:
        return undefined
    }
  }

  protected handleAdd(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue, modifiedBy: string): BusinessModel {
    if (id === FieldAndColumnName.UtilityBillEditor_ServicePhoneNumber) {
      const initList = this.servicePhoneNumber ?? List()
      const updatedList = initList.push(new StringField(uuid(), value as string, [], List(), modifiedBy))
      return this.copy({ [id]: updatedList }) as BusinessModel
    } else {
      return super.handleAdd(id, field, value, modifiedBy)
    }
  }

  toJson(): IBusinessModel {
    return UtilityBillMapper.toJson(this)
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return this.issueDate?.parsedValue
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return this.dueDate?.parsedValue
  }

  getTotalAmount(): number {
    return this.totalAmount?.parsedValue || 0
  }
}