import { IBusinessModel, IModelType, ITransactionTable } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { mathRound, uuid } from 'utils/utils'
import BusinessModel, { Action } from '../BusinessModel'
import {DateField, DetectedField, DollarField, StringField} from '../DetectedField'
import IItemObject from '../IItemObject'
import ModelObject from '../ModelObject'
import { BankStatementMapper } from './BankStatementMapper'
import { Transaction, TransactionColumn } from './Transaction'
import {Transactions} from './Transactions'

export class BankStatement extends BusinessModel {

  constructor(
    version: string,
    readonly bankName: string,
    readonly transactionTables: List<TransactionTable>,
    readonly recipientAndAddress: RecipientAndAddress,
    readonly financialInstitution?: StringField,
  ) {
    super(IModelType.BankStatement, version)
  }

  copy({
         version = this.version,
         bankName = this.bankName,
         transactionTables = this.transactionTables,
         recipientAndAddress = this.recipientAndAddress,
         financialInstitution = this.financialInstitution
       }): BankStatement {
    return new BankStatement(
      version,
      bankName,
      transactionTables,
      recipientAndAddress,
      financialInstitution
    )
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.BankStatementEditor_FinancialInstitution:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      default:
        return undefined
    }
  }

  firstTable(): TransactionTable | undefined {
    return this.transactionTables.first(undefined)
  }

  protected listFields(): List<DetectedField> {
    return this.transactionTables.flatMap(table => table.listFields())
  }

  // determine if the given id presenting individual field in table
  private isField(id: FieldAndColumnName): boolean {
    return Object.keys(this.firstTable()!).includes(id)
  }

  protected getAction(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue): Action {
    if (!Object.keys(this.firstTable()!).includes(id)) {
      return Action.Invalid
    }

    const found = this.firstTable()!.get(id)
    if (found && field && value) {
      return Action.Update
    } else if (!found && !field && value) {
      return Action.Add
    } else if (found && field && !value) {
      return Action.Delete
    }

    return Action.Invalid
  }

  protected getFinancialInstitutionAction(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue): Action {
    if (!Object.keys(this).includes(id)) {
      return Action.Invalid
    }

    const found = this.get(id)
    if (found && field && value) {
      return Action.Update
    } else if (!found && !field && value) {
      return Action.Add
    } else if (found && field && !value) {
      return Action.Delete
    }

    return Action.Invalid
  }

  update(id: FieldAndColumnName, field: DetectedField, value: FormGridRowValue, modifiedBy: string): BusinessModel {
    if(id === FieldAndColumnName.BankStatementEditor_FinancialInstitution){
      switch(this.getFinancialInstitutionAction(id, field,value)) {
        case Action.Update:
          return this.handleUpdate(id, field, value, modifiedBy)

        case Action.Add:
          return this.handleAdd(id, value, modifiedBy)

        case Action.Delete:
          return this.handleDelete(id)
      }
    }
    if (!this.firstTable()) {
      if (id && value && this.isField(id)) {
        const transactions = new Transactions(List())
        const transactionTable = new TransactionTable(transactions)
        const newField = transactionTable.createMember(id, value, modifiedBy)
        const updatedTable =  transactionTable.copy({ [id]: newField })
        // TODO: what's value of bank name for this case???
        return this.copy({ transactionTables: List([updatedTable]) })
      }

      console.error('Updating bank statement without transaction table!')
      return this
    }

    if (this.isField(id)) {
      switch(this.getAction(id, field,value)) {
        case Action.Update:
          return this.handleUpdate(id, field, value, modifiedBy)

        case Action.Add:
          return this.handleAdd(id, value, modifiedBy)

        case Action.Delete:
          return this.handleDelete(id)
      }
    }

    return this
  }

  protected handleUpdate(id: FieldAndColumnName, field: DetectedField, value: any, modifiedBy: string): BankStatement {
    if (id === FieldAndColumnName.BankStatementEditor_FinancialInstitution){
      if (value === (this.financialInstitution as DetectedField).parsedValue) {
        return this
      }
      const updatedFinancialInstitution = (this.financialInstitution as DetectedField).updateValue(value, modifiedBy)
      return this.copy({financialInstitution:updatedFinancialInstitution})
    }
    const transactionTable = this.firstTable()!
    const foundInTransactionTable = transactionTable.get(id)
    if (foundInTransactionTable && (foundInTransactionTable as DetectedField).sameKey(field)) {
      if (value === (foundInTransactionTable as DetectedField).parsedValue) {
        return this
      }

      const updatedField = (foundInTransactionTable as DetectedField).updateValue(value, modifiedBy)
      const updatedTransactionTable = transactionTable.copy({ [id]: updatedField })
      const updatedTransactionTables = this.transactionTables.setIn([0], updatedTransactionTable)

      return this.copy({ transactionTables: updatedTransactionTables })
    }

    return this
  }

  protected handleAdd(id: FieldAndColumnName, value: any, modifiedBy: string): BankStatement {
    if (id === FieldAndColumnName.BankStatementEditor_FinancialInstitution){
      const newFinancialInstitution = this.createMember(id, value, modifiedBy)
      return this.copy({ financialInstitution: newFinancialInstitution })
    }
    const transactionTable = this.firstTable()!

    const updatedTransactionTable = transactionTable.copy({ [id]: transactionTable.createMember(id, value, modifiedBy) })
    const updatedTransactionTables = this.transactionTables.setIn([0], updatedTransactionTable)

    return this.copy({ transactionTables: updatedTransactionTables })
  }

  protected handleDelete(id: FieldAndColumnName): BankStatement {
    if(id === FieldAndColumnName.BankStatementEditor_FinancialInstitution){
      return this.copy({ financialInstitution: undefined })
    }
    const transactionTable = this.firstTable()!

    const updatedTransactionTable = transactionTable.copy({ [id]: null })
    const updatedTransactionTables = this.transactionTables.setIn([0], updatedTransactionTable)

    // TODO: as currently we only display first table and leave other doing nothing,
    // so do not check if first table is empty one after user keeping deleting fields on it.
    // Need to implement the logic after display multiply tables implementation finished.

    return this.copy({ transactionTables: updatedTransactionTables })
  }

  updateGrid(gridColumnName: FieldAndColumnName, gridFieldId: string | number, newValue: FormGridRowValue, modifiedBy: string): BusinessModel {
    const transactionTable = this.firstTable()

    if (!transactionTable) {
      if (gridColumnName && newValue) {
        const transaction = new Transaction(new DateField(uuid(), moment(), [], List(), modifiedBy))
        const transactions = new Transactions(List([transaction.addColumn(gridColumnName, newValue, modifiedBy)]))
        const transactionTable = new TransactionTable(transactions)
        // TODO: what's value of bank name for this case???
        return new BankStatement(this.version, this.bankName, List([transactionTable]), this.recipientAndAddress, this.financialInstitution)
      }

      console.error('Updating grid for bank statement without transaction table!')
      return this
    }

    const transactions = transactionTable.transactions.transactions

    if (transactions.isEmpty()) {
      console.error('Updating bank statement with empty transactions!')
      return this
    }

    const transactionRow = transactions.get(Number(gridFieldId))
    if (transactionRow) {
      const updateBankStatement = (updatedRow: Transaction): BankStatement => {
        const updatedTransactions = new Transactions(transactions.setIn([Number(gridFieldId)], updatedRow))
        const updatedTransactionTable = transactionTable.copy({ transactions: updatedTransactions })
        const updatedTransactionTables = this.transactionTables.setIn([0], updatedTransactionTable)
        return new BankStatement(this.version, this.bankName,updatedTransactionTables, this.recipientAndAddress,this.financialInstitution)
      }

      switch(this.getGridAction(transactionRow as IItemObject, gridColumnName, newValue)) {
        case Action.Update:
          const field = transactionRow.fieldByColumn(gridColumnName as TransactionColumn)
          if (field) {
            return updateBankStatement(transactionRow.copy({ [gridColumnName]: field.updateValue(newValue, modifiedBy) }))
          }
          return this

        case Action.Add:
          return updateBankStatement(transactionRow.addColumn(gridColumnName, newValue, modifiedBy))

        case Action.Delete:
          return updateBankStatement(transactionRow.deleteColumn(gridColumnName))

        default:
          console.error('Performing unexpected action on BankStatement transactions!')

      }
    }

    console.error('Updating non-existing row in grid!')
    return this
  }

  updateTransactionList(modifiedBy: string, existingValue?: Transaction, newValue?: Transaction): BusinessModel {
    const addFirstTransaction = (): BankStatement => {
      if (newValue) {
        const transactions = new Transactions(List([newValue]))
        const transactionTable = new TransactionTable(transactions)
        // TODO: what's value of bank name for this case???
        return new BankStatement(this.version, this.bankName,List([transactionTable]), this.recipientAndAddress,this.financialInstitution)
      }

      console.error('Change made to transaction list for bank statement without transaction table!')
      return this
    }
    const transactionTable = this.firstTable()

    if (!transactionTable) {
      return addFirstTransaction()
    }

    const transactions = transactionTable.transactions.transactions

    if (transactions.isEmpty()) {
      return addFirstTransaction()
    }

    const sortAndRecalculateBalance = (trans: Transactions): Transactions => {
      const reCalcuatedArray: Transaction[] = []
      trans.transactions.sort((a, b) => {
        if (a.date.parsedValue.isBefore(b.date.parsedValue)) { return -1 }
        if (b.date.parsedValue.isBefore(a.date.parsedValue)) { return 1 }
        return 0
      }).forEach((t: Transaction, index: number) => {
        const openingBalance = this.transactionTables.first()?.openingBalance?.parsedValue
        if (index === 0) {
          if (openingBalance) {
            reCalcuatedArray.push(
              t.updateBalance(mathRound(openingBalance - t.amount.parsedValue), modifiedBy)
            )
          } else {
            // if there is no opendingBalance in the table, adding transaction without updating balance
            reCalcuatedArray.push(t)
          }
        } else {
          const lastBalance = reCalcuatedArray[index - 1]?.balance?.parsedValue
          if (lastBalance) {
            reCalcuatedArray.push(
              t.updateBalance(mathRound(lastBalance - t.amount.parsedValue), modifiedBy)
            )
          }
        }
      })
      return new Transactions(List.of(...reCalcuatedArray))
    }

    const generateBankStatement = (transactions: Transactions): BankStatement => {
      const table = transactionTable.copy({ transactions })
      const updatedTransactionTables = this.transactionTables.setIn([0], table)
      return new BankStatement(this.version, this.bankName,updatedTransactionTables, this.recipientAndAddress,this.financialInstitution)
    }

    switch(this.getTransactionListAction(existingValue as IItemObject, newValue as IItemObject)) {
      case Action.Update:
        if (existingValue && newValue) {
          const index = transactions.findIndex(value => Transaction.isEqual(value, existingValue))
          if (index !== -1) {

            const updatedTransactions = sortAndRecalculateBalance(new Transactions(transactions.setIn([index], newValue)))

            return generateBankStatement(updatedTransactions)
          }
        }
        return this

      case Action.Add:
        if (newValue) {
          const updatedTransactions = sortAndRecalculateBalance(
            new Transactions(transactions.push(newValue).sort((a, b) => a.date.parsedValue.isBefore(b.date.parsedValue) ? -1 : 1))
          )
          return generateBankStatement(updatedTransactions)
        }
        return this

      case Action.Delete:
        if (existingValue) {
          const index = transactions.findIndex(value => Transaction.isEqual(value, existingValue))
          const updatedTransactions = sortAndRecalculateBalance(new Transactions(transactions.delete(index)))
          return generateBankStatement(updatedTransactions)
        }
        return this

      default:
        console.error('Performing unexpected action on BankStatement transactions!')
    }

    console.error('Updating non-existing row in grid!')
    return this
  }

  toJson(): IBusinessModel {
    return BankStatementMapper.toJson(this)
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return this.transactionTables.first()?.startDate?.parsedValue
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return this.transactionTables.first()?.endDate?.parsedValue
  }

  getTotalAmount(): number {
    return Math.abs((this.transactionTables.first()?.closingBalance?.parsedValue || 0) - (this.transactionTables.first()?.openingBalance?.parsedValue || 0))
  }
}

export class RecipientAndAddress extends ModelObject{
  constructor(
    readonly recipient?: StringField,
    readonly address?: StringField
  ){
    super()
  }

}

export class TransactionTable extends ModelObject {
  constructor(
    readonly transactions: Transactions,
    readonly accountName?: StringField,
    readonly abn?:StringField,
    readonly bsb?: StringField,
    readonly accountNumber?: StringField,
    readonly startDate?: DateField,
    readonly endDate?: DateField,
    readonly openingBalance?: DollarField,
    readonly closingBalance?: DollarField,
    readonly totalCredits?: DollarField,
    readonly totalDebits?: DollarField

  ) {
    super()
  }

  createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.TransactionTable_AccountName:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)
      case FieldAndColumnName.TransactionTable_ABN:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)
      case FieldAndColumnName.BankStatementEditor_BSB:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)
      case FieldAndColumnName.BankStatementEditor_AccountNumber:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)
      case FieldAndColumnName.BankStatementEditor_StartDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)
      case FieldAndColumnName.BankStatementEditor_EndDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)
      case FieldAndColumnName.TransactionTable_OpeningBalance:
        return new DollarField(uuid(), value as number, [], List(), modifiedBy)
      case FieldAndColumnName.TransactionTable_ClosingBalance:
        return new DollarField(uuid(), value as number, [], List(), modifiedBy)
      case FieldAndColumnName.TransactionTable_TotalCredit:
        return new DollarField(uuid(), value as number, [], List(), modifiedBy)
      case FieldAndColumnName.TransactionTable_TotalDebit:
        return new DollarField(uuid(), value as number, [], List(), modifiedBy)
      default:
        return undefined
    }
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this).concat(this.transactions.listFields())
  }

  copy({
    transactions = this.transactions,
    accountName = this.accountName,
    abn = this.abn,
    bsb = this.bsb,
    accountNumber = this.accountNumber,
    startDate = this.startDate,
    endDate = this.endDate,
    openingBalance = this.openingBalance,
    closingBalance = this.closingBalance,
    totalCredits = this.totalCredits,
    totalDebits = this.totalDebits
  }): TransactionTable {
    return new TransactionTable(
      transactions,
      accountName,
      abn,
      bsb,
      accountNumber,
      startDate,
      endDate,
      openingBalance,
      closingBalance,
      totalCredits,
      totalDebits
    )
  }

  private generateBsbAccountNumber(): object {
    const bsbAccountNumber = {}
    if (this.bsb) {
      Object.assign(bsbAccountNumber, { Bsb: this.bsb?.toModelKeyValue() })
    }

    if (this.accountNumber) {
      Object.assign(bsbAccountNumber, { ...bsbAccountNumber, AccountNumber: this.accountNumber?.toModelKeyValue() })
    }

    return isEmpty(bsbAccountNumber) ? {} : bsbAccountNumber
  }

  private generateStatementPeriod(): object  {
    const statementPeriod = {}
    if (this.startDate) {
      Object.assign(statementPeriod, { Start: this.startDate?.toModelKeyValue() })
    }

    if (this.endDate) {
      Object.assign(statementPeriod, { ...statementPeriod, End: this.endDate?.toModelKeyValue() })
    }

    return isEmpty(statementPeriod) ? {} : statementPeriod
  }

  private generateAccountBalances(): object {
    const accountBalances = {}
    if (this.openingBalance) {
      Object.assign(accountBalances, { OpeningBalance: this.openingBalance?.toModelKeyValue() })
    }

    if (this.totalCredits) {
      Object.assign(accountBalances, { ...accountBalances, TotalCredits: this.totalCredits?.toModelKeyValue() })
    }

    if (this.totalDebits) {
      Object.assign(accountBalances, { ...accountBalances, TotalDebits: this.totalDebits?.toModelKeyValue() })
    }

    if (this.closingBalance) {
      Object.assign(accountBalances, { ...accountBalances, ClosingBalance: this.closingBalance?.toModelKeyValue() })
    }

    return isEmpty(accountBalances) ? {} : accountBalances
  }

  toJson(): ITransactionTable {
    return {
      AccountName: this.accountName?.toModelKeyValue(),
      ABN: this.abn?.toModelKeyValue(),
      BsbAccountNumber: this.generateBsbAccountNumber(),
      StatementPeriod: this.generateStatementPeriod(),
      AccountBalances: this.generateAccountBalances(),
      Table: {
        // Headers and SpecialRows are required from client side?
        // Headers?: ITransactionTableDataRow,
        // SpecialRows?: ITransactionTableSpecialRow[],
        DataRows: this.transactions.transactions.toArray().map(t => t.toJson())
      }
    }
  }
}

