export type Filters = Array<
  AggValueFilter | AggValuesFilter | AggNumRangeFilter | AggDateRangeFilter
>

export enum FilterCategories {
  ValueType = 'valueType',
  ValuesType = 'valuesType',
  DateRangeType = 'dateRangeType',
  NumRangeType = 'numRangeType'
}

export enum AggValueGeneralFilterTypes {
  Textract = 'textract',
  Business_models = 'business_models'
}

export enum AggValueAnnotationFilterTypes {
  WordAnnotations = 'word_annotations',
  PageAnnotations = 'page_annotations',
  ObjectAnnotations = 'object_annotations'
}

export enum AggValuesFilterTypes {
  FileMime = 'file.mime_type',
  FileAttributes = 'file.attributes',
  Workflow = 'workflow.status',
  LabelIds = 'label_ids'
}

export enum AggRangeFilterTypes {
  Created = 'file.created',
  Updated = 'file.updated',
  Pages = 'file.pages',
  Size = 'file.size'
}

export enum AggOtherFilterTypes {
  Content = 'content',
  FileCreatedHistogram = 'file.created.histogram',
  BusinessValuesKeywords = 'business_values.keywords',
  BusinessValuesNumbers = 'business_values.numbers',
  BusinessValuesDates = 'business_values.dates'
}

export type AggAllFilterTypes =
  | AggValueGeneralFilterTypes
  | AggValueAnnotationFilterTypes
  | AggValuesFilterTypes
  | AggRangeFilterTypes
  | AggOtherFilterTypes

export interface AggValueFilter {
  key: string
  value: string
  from?: number
  toExclusive?: number
  excluded?: boolean
}

// file.mime_type file.attributes workflow.status label_ids
export interface AggValuesFilter {
  key: string
  values: string[]
  excluded?: boolean
}

export interface AggNumRangeFilter {
  key: string
  from: number
  toExclusive?: number
}

export interface AggDateRangeFilter {
  key: string
  toExclusive: string
  from?: string
  excluded?: boolean
}

export interface AggStatusDTO {
  key: string
  doc_count: number
}

export interface AggRangeStatus extends AggStatusDTO {
  to: number
  to_as_string?: string
  from?: number
  from_as_string?: string
}

export interface AggDateStatus extends AggStatusDTO {
  to: number
  to_as_string: string
  from?: number
  from_as_string?: string
}

export interface AggNumStatus extends AggStatusDTO {
  to?: number
  from?: number
}

export interface AggCreatedHistogramDTO extends AggStatusDTO {
  key_as_string: string
}

export interface AggExtraStats {
  count: number
  min: number
  max: number
  avg: number
  sum: number
}

export interface AggFileSizeDTO extends AggStatusDTO {
  to?: number
  from?: number
}

export interface AggFileDatedDTO extends AggStatusDTO {
  to?: number
  to_as_string?: string
  from?: number
  from_as_string?: string
}

export interface AggBusinessModel extends AggStatusDTO {
  stats?: AggExtraStats
}

export interface AggDTO {
  'workflow.status'?: AggStatusDTO[]
  'file.created'?: AggFileDatedDTO[]
  'file.updated'?: AggFileDatedDTO[]
  label_ids?: AggStatusDTO[]
  'file.size'?: AggFileSizeDTO[]
  'file.pages'?: AggFileSizeDTO[]
  page_annotations?: AggStatusDTO[]
  'file.attributes'?: AggStatusDTO[]
  word_annotations?: AggStatusDTO[]
  object_annotations?: AggStatusDTO[]
  'file.mime_type'?: AggStatusDTO[]
  business_models?: AggBusinessModel[]
  textract?: AggStatusDTO[]
  'file.created.histogram'?: AggCreatedHistogramDTO[]
  content?: AggStatusDTO[]
}

export class Aggregation {
  constructor(
    public workflowStatus: AggStatusDTO[] = [],
    public fileCreated: AggFileDatedDTO[] = [],
    public fileUpdated: AggFileDatedDTO[] = [],
    public labelIds: AggStatusDTO[] = [],
    public fileSize: AggFileSizeDTO[] = [],
    public filePages: AggFileSizeDTO[] = [],
    public fileAttributes: AggStatusDTO[] = [],
    public wordAnnotations: AggStatusDTO[] = [],
    public pageAnnotations: AggStatusDTO[] = [],
    public objectAnnotations: AggStatusDTO[] = [],
    public fileMimeTypes: AggStatusDTO[] = [],
    public businessModels: AggBusinessModel[] = [],
    public textract: AggStatusDTO[] = [],
    public fileCreatedHistogram: AggCreatedHistogramDTO[] = [],
    public content: AggStatusDTO[] = []
  ) {}

  static fromJson(aggDTO: AggDTO): Aggregation {
    return new Aggregation(
      aggDTO['workflow.status'],
      aggDTO['file.created'],
      aggDTO['file.updated'],
      aggDTO['label_ids'],
      aggDTO['file.size'],
      aggDTO['file.pages'],
      aggDTO['file.attributes'],
      aggDTO['word_annotations'],
      aggDTO['page_annotations'],
      aggDTO['object_annotations'],
      aggDTO['file.mime_type'],
      aggDTO['business_models'],
      aggDTO['textract'],
      aggDTO['file.created.histogram'],
      aggDTO['content']
    )
  }

  updateAggregation(newAgg: Aggregation): Aggregation {
    return new Aggregation(
      newAgg.workflowStatus,
      newAgg.fileCreated,
      newAgg.fileUpdated,
      newAgg.labelIds,
      newAgg.fileSize,
      newAgg.filePages,
      newAgg.fileAttributes,
      newAgg.wordAnnotations,
      newAgg.pageAnnotations,
      newAgg.objectAnnotations,
      newAgg.fileMimeTypes,
      newAgg.businessModels,
      newAgg.textract,
      newAgg.fileCreatedHistogram,
      newAgg.content
    )
  }
}
