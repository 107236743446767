import { OcrElement } from 'domain/ocr/OcrElement'
import {List, OrderedMap} from 'immutable'
import {compact, flatten} from '../../utils/utils'
import {CellVisitor, LineVisitor, PageVisitor, TableVisitor, WordVisitor} from '../DocumentVisitor'
import {Cell} from '../ocr/Cell'
import {Line} from '../ocr/Line'
import {Page} from '../ocr/Page'
import {Table} from '../ocr/Table'
import {Word} from '../ocr/Word'

export class ElementLocator {
  private readonly idToElement: OrderedMap<string, OcrElement>

  constructor(
    private readonly pages: List<Page>
  ) {
    const mapper = new IdElementMapper()
    const idToElementList: List<IdElementMap> = pages.flatMap(page => mapper.visitPage(page))
    this.idToElement = OrderedMap(idToElementList)
  }

  get(id: string): OcrElement | undefined {
    return this.idToElement.get(id)
  }

  word(id: string): Word | undefined {
    const element = this.get(id)
    if (element instanceof Word) {
      return element as Word
    } else {
      return undefined
    }
  }

  wordsFromArray(ids: string[]): List<Word> {
    return this.words(List(ids))
  }

  words(ids: List<string>): List<Word> {
    return compact(ids.map(id => this.word(id)))
  }

  pageIndexToNumber(pageNumber: number | undefined): Page | undefined {
    if (pageNumber) {
      return this.pages.find(page => page.page === pageNumber)
    } else {
      return undefined
    }
  }

  page(id: string): Page | undefined {
    const element = this.get(id)
    if (element instanceof Page) {
      return element as Page
    } else {
      return undefined
    }
  }

  line(id: string): Line | undefined {
    const element = this.get(id)
    if (element instanceof Line) {
      return element as Line
    } else {
      return undefined
    }
  }
}

export type IdElementMap = [string, OcrElement]

export class IdElementMapper implements PageVisitor<List<IdElementMap>>,
  TableVisitor<List<IdElementMap>>,
  CellVisitor<List<IdElementMap>>,
  LineVisitor<List<IdElementMap>>,
  WordVisitor<IdElementMap> {
  visitWord(word: Word): IdElementMap {
    return [word.id, word]
  }

  visitLine(line: Line): List<IdElementMap> {
    const words = line.accept(this)
    return words.push([line.id, line])
  }

  visitCell(cell: Cell): List<IdElementMap> {
    return cell.accept(this).push([cell.id, cell])
  }

  visitTable(table: Table): List<IdElementMap> {
    return flatten(table.accept(this)).concat(
      [[table.id, table] as IdElementMap]
    )
  }

  visitPage(page: Page): List<IdElementMap> {
    return flatten(page.acceptLineVisitor(this))
      .concat(
        flatten(page.acceptTableVisitor(this))
      ).concat(
        [[page.id, page] as IdElementMap]
      )
  }
}
