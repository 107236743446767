import {
  Button,
  Popover,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { Briefcase } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { useProjectSelectorStyles } from '../../assets/styles/ProjectSelectorStyles'
import Project from '../../models/Project'
import { useInitStore } from '../../services/stores/InitStore'
import { useProjectStore } from '../../services/stores/ProjectStore'

const ProjectSelector = () => {
  const classes = useProjectSelectorStyles()
  const user = useInitStore((state) => state.authorizations?.username)
  const currentBusiness = useInitStore((state) => state.currentBusiness)
  const currentProject = useProjectStore((state) => state.currentProject)
  const projects = useProjectStore((state) => state.projects)

  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const options = useMemo(
    () =>
      projects.length !== 0
        ? [...projects].sort((a, b) =>
            (a.name as string) > (b.name as string) ? 1 : -1
          )
        : [],
    [projects]
  )

  if (options.length === 0) return <span>{`Hi, ${user ?? ''}`}</span>

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={<Briefcase size={20} />}
        endIcon={<KeyboardArrowDown />}
        disableRipple
        className={classes.selectBtn}
      >
        <Typography color={'primary'} noWrap>
          {currentProject?.name ?? 'Select a Project'}
        </Typography>
      </Button>
      <Popover
        transitionDuration={10}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.popoverPaper
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -3,
          horizontal: 'left'
        }}
      >
        <Autocomplete
          options={options}
          noOptionsText={'No project found'}
          getOptionLabel={(option) => option.name ?? 'Select project here'}
          groupBy={(option) => option.businessId ?? 'Unknown Businesses'}
          getOptionSelected={(option, value) => option.id === value.id}
          disablePortal
          popupIcon={null}
          closeIcon={null}
          open={true}
          classes={{
            root: classes.selector,
            paper: classes.popupMenuPaper
          }}
          onChange={(event: React.ChangeEvent<{}>, value: Project | null) => {
            if (value) {
              handleClose()
              navigate(
                `/business/${value.businessId}/${
                  value.id ? 'project/' + value.id + '/dashboard' : 'projects'
                }`,
                { replace: true }
              )
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              placeholder={'Search by Project Name'}
              fullWidth
              InputProps={{
                ...params.InputProps
              }}
            />
          )}
          renderOption={(option: Project) =>
            option.name ? (
              <>
                <Tooltip title={option.name} arrow>
                  <Typography noWrap>{option.name}</Typography>
                </Tooltip>
                <span className={classes.projectTypeChip}>
                  {option.projectType}
                </span>
              </>
            ) : (
              <span className={classes.emptyBusiness}>
                No projects. Click to redirect to this business
              </span>
            )
          }
          renderGroup={({ group, children, key }) => (
            <div key={key}>
              <div
                className={clsx({
                  [classes.groupTitle]: true,
                  [classes.homeGroupTitle]: group === currentBusiness.id
                })}
              >
                <Typography noWrap>{currentBusiness.name}</Typography>
              </div>
              {children}
            </div>
          )}
        />
      </Popover>
    </>
  )
}

export default ProjectSelector
