import { IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import { ChartOfAccounts360PageTable } from './ChartOfAccounts360PageTable'

export class ChartOfAccounts360 extends BusinessModel {
  constructor(
    version: string,
    readonly code: string,
    readonly name: string,
    readonly probability: number,
    readonly chartOfAccountsPages: ChartOfAccounts360PageTable
  ) {
    super(IModelType.ChartOfAccounts360, version)
  }
}
