import BusinessModel from '../BusinessModel';
import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import {InvoiceItem} from './InvoiceItem'
import {List} from "immutable"
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { uuid } from 'utils/utils'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { InvoiceMapper } from './InvoiceMapper'

export class Invoice extends BusinessModel {

  constructor(
    version: string,
    public readonly items: List<InvoiceItem>,
    public readonly customerName?: StringField,
    public readonly customerId?: StringField,
    public readonly purchaseOrder?: StringField,
    public readonly invoiceId?: StringField,
    public readonly invoiceDate?: DateField,
    public readonly dueDate?: DateField,
    public readonly vendorName?: StringField,
    public readonly vendorAddress?: StringField,
    public readonly vendorAddressRecipient?: StringField,
    public readonly customerAddress?: StringField,
    public readonly customerAddressRecipient?: StringField,
    public readonly billingAddress?: StringField,
    public readonly billingAddressRecipient?: StringField,
    public readonly shippingAddress?: StringField,
    public readonly shippingAddressRecipient?: StringField,
    public readonly subTotal?: DoubleField,
    public readonly totalTax?: DoubleField,
    public readonly invoiceTotal?: DoubleField,
    public readonly amountDue?: DoubleField,
    public readonly serviceAddress?: StringField,
    public readonly serviceAddressRecipient?: StringField,
    public readonly remittanceAddress?: StringField,
    public readonly remittanceAddressRecipient?: StringField,
    public readonly serviceStartDate?: DateField,
    public readonly serviceEndDate?: DateField,
    public readonly previousUnpaidBalance?: DoubleField,
  ) {
    super(IModelType.Invoice, version)
  }

  copy({
    version = this.version,
    items = this.items,
    customerName = this.customerName,
    customerId = this.customerId,
    purchaseOrder = this.purchaseOrder,
    invoiceId = this.invoiceId,
    invoiceDate = this.invoiceDate,
    dueDate = this.dueDate,
    vendorName = this.vendorName,
    vendorAddress = this.vendorAddress,
    vendorAddressRecipient = this.vendorAddressRecipient,
    customerAddress = this.customerAddress,
    customerAddressRecipient = this.customerAddressRecipient,
    billingAddress = this.billingAddress,
    billingAddressRecipient = this.billingAddressRecipient,
    shippingAddress = this.shippingAddress,
    shippingAddressRecipient = this.shippingAddressRecipient,
    subTotal = this.subTotal,
    totalTax = this.totalTax,
    invoiceTotal = this.invoiceTotal,
    amountDue = this.amountDue,
    serviceAddress = this.serviceAddress,
    serviceAddressRecipient = this.serviceAddressRecipient,
    remittanceAddress = this.remittanceAddress,
    remittanceAddressRecipient = this.remittanceAddressRecipient,
    serviceStartDate = this.serviceStartDate,
    serviceEndDate = this.serviceEndDate,
    previousUnpaidBalance = this.previousUnpaidBalance,
  }): Invoice {
    return new Invoice(
      version,
      items,
      customerName,
      customerId,
      purchaseOrder,
      invoiceId,
      invoiceDate,
      dueDate,
      vendorName,
      vendorAddress,
      vendorAddressRecipient,
      customerAddress,
      customerAddressRecipient,
      billingAddress,
      billingAddressRecipient,
      shippingAddress,
      shippingAddressRecipient,
      subTotal,
      totalTax,
      invoiceTotal,
      amountDue,
      serviceAddress,
      serviceAddressRecipient,
      remittanceAddress,
      remittanceAddressRecipient,
      serviceStartDate,
      serviceEndDate,
      previousUnpaidBalance
    )
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.InvoiceEditor_CustomerName:
      case FieldAndColumnName.InvoiceEditor_CustomerId:
      case FieldAndColumnName.InvoiceEditor_PurchaseOrder:
      case FieldAndColumnName.InvoiceEditor_InvoiceId:
      case FieldAndColumnName.InvoiceEditor_VendorName:
      case FieldAndColumnName.InvoiceEditor_VendorAddress:
      case FieldAndColumnName.InvoiceEditor_VendorAddressRecipient:
      case FieldAndColumnName.InvoiceEditor_CustomerAddress:
      case FieldAndColumnName.InvoiceEditor_CustomerAddressRecipient:
      case FieldAndColumnName.InvoiceEditor_BillingAddressRecipient:
      case FieldAndColumnName.InvoiceEditor_BillingAddress:
      case FieldAndColumnName.InvoiceEditor_ShippingAddress:
      case FieldAndColumnName.InvoiceEditor_ShippingAddressRecipient:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      case FieldAndColumnName.InvoiceEditor_InvoiceDate:
      case FieldAndColumnName.InvoiceEditor_DueDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)
      
      case FieldAndColumnName.InvoiceEditor_SubTotal:
      case FieldAndColumnName.InvoiceEditor_TotalTax:
      case FieldAndColumnName.InvoiceEditor_InvoiceTotal:
        return new DoubleField(uuid(), value as number, [], List(), modifiedBy)
      
      default:
        return undefined
    }
  }

  protected getGridMember(): List<InvoiceItem> {
    return this.items
  }

  protected listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this).concat(
      this.items.flatMap(item => item.listFields())
    )
  }

  byIndex(index: number): InvoiceItem | undefined {
    return this.items.get(index)
  }

  // static toGridColDef(): any[] {
  //   return [
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_Description,
  //       headerName: "DESCRIPTION",
  //       description: "Description",
  //       width: 200,
  //       editable: true,
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_Amount,
  //       headerName: "AMOUNT",
  //       description: "Amount",
  //       width: 130,
  //       editable: true
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_Quantity,
  //       headerName: "QUANTITY",
  //       description: "Quantity",
  //       width: 130,
  //       editable: true,
  //       type: "number"
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_UnitPrice,
  //       headerName: "UNITPRICE",
  //       description: "Unit Price",
  //       width: 130,
  //       editable: true,
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_ProductCode,
  //       headerName: "PRODUCTCODE",
  //       description: "Production",
  //       width: 140,
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_Unit,
  //       headerName: "UNIT",
  //       description: "Unit",
  //       width: 120,
  //       editable: true,
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_Date,
  //       headerName: "DATE",
  //       description: "Date",
  //       width: 150,
  //       editable: true,
  //
  //     },
  //     {
  //       field: FieldAndColumnName.InvoiceItemColumn_Tax,
  //       headerName: "TAX",
  //       description: "Tax",
  //       width: 120,
  //       editable: true,
  //
  //     },
  //   ]
  // }

  // toGridRowsProp() {
  //   return this.items.map((invoiceItem, index) => {
  //     return invoiceItem.toGridRow(index)
  //   }).toArray()
  // }

  toJson(): IBusinessModel {
    return InvoiceMapper.toJson(this)
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return this.invoiceDate?.parsedValue
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return this.dueDate?.parsedValue
  }

  getTotalAmount(): number {
    return this.invoiceTotal?.parsedValue || 0
  }

}
