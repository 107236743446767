import ModelObject from 'domain/businessModel/ModelObject'
import { FormFieldValue } from 'domain/validator/IFieldValidator'
import { FormGridRowId } from 'utils/DataTypeMapper'
import { FieldAndColumnName, FormFieldValidationResult } from './FieldValidatorDef'
import { FormFieldValidator } from './FormFieldValidator'
import { IValidatorFactory } from './IValidatorFactory'

class DateValidator extends FormFieldValidator {
    static columnName = FieldAndColumnName.TransactionTable_Date

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.TRANSACTIONTABLE_EMPTY_DATE
        // }

        // const startDate = record?.get(FieldAndColumnName.BankStatementEditor_StartDate)
        // const endDate = record?.get(FieldAndColumnName.BankStatementEditor_EndDate)
        // if (isNil(startDate) || isNil(endDate)) {
        //     return FormFieldValidationResult.TRANSACTIONTABLE_MISSING_DATE_RANGE
        // }

        // if (moment(value as Date).isSameOrAfter((startDate as DetectedField).parsedValue)
        //     && moment(value as Date).isSameOrBefore((endDate as DetectedField).parsedValue)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // return FormFieldValidationResult.TRANSACTIONTABLE_IOCORRECT_DATE
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return DateValidator.columnName
    }
}

class DebitValidator extends FormFieldValidator {
    static columnName = FieldAndColumnName.TransactionTable_TotalDebit

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // return Number(value) !== 0
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.TRANSACTIONTABLE_ZERO_DEBIT
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return DebitValidator.columnName
    }
}

class CreditValidator extends FormFieldValidator {
    static columnName = FieldAndColumnName.TransactionTable_TotalCredit

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNil(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // return Math.abs(Number(value)) >= 30000
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.TRANSACTIONTABLE_INCORRECT_CREDIT
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return CreditValidator.columnName
    }
}

class ClosingBalanceValidator extends FormFieldValidator {
    static columnName = FieldAndColumnName.TransactionTable_ClosingBalance

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // First row, skip validation
        // if (id === 0) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (record) {
        //     // First, we need get the entire grid
        //     const transactions = record.get(FieldAndColumnName.BankStatementEditor_Transactions)
        //     const rows = (transactions as Transactions).toGridRowsProp()

        //     // Next, we need balance value of transaction before current one
        //     const lastRow = rows[id as number - 1]
        //     const lastBalance = get(lastRow, FieldAndColumnName.TransactionTable_Balance, 0)

        //     // Now, current debit and credit values
        //     const currentRow = rows[id as number]
        //     const currentDebit = get(currentRow, FieldAndColumnName.TransactionTable_Debit, 0)
        //     const currentCredit = get(currentRow, FieldAndColumnName.TransactionTable_Credit, 0)

        //     // Calculate what value current balance should be
        //     const balance = lastBalance - currentDebit - currentCredit

        //     // Compare
        //     if (Number(value) === Math.round(balance * 100) / 100) {
        //         return FormFieldValidationResult.VALIDATION_SUCCESS
        //     } else {
        //         return FormFieldValidationResult.TRANSACTIONTABLE_INCORRECT_BALANCE
        //     }
        // }

        // return FormFieldValidationResult.INVALID_INPUT
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return ClosingBalanceValidator.columnName
    }
}

class OpeningBalanceValidator extends FormFieldValidator {
    static columnName = FieldAndColumnName.TransactionTable_OpeningBalance

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // First row, skip validation
        // if (id === 0) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (record) {
        //     // First, we need get the entire grid
        //     const transactions = record.get(FieldAndColumnName.BankStatementEditor_Transactions)
        //     const rows = (transactions as Transactions).toGridRowsProp()

        //     // Next, we need balance value of transaction before current one
        //     const lastRow = rows[id as number - 1]
        //     const lastBalance = get(lastRow, FieldAndColumnName.TransactionTable_Balance, 0)

        //     // Now, current debit and credit values
        //     const currentRow = rows[id as number]
        //     const currentDebit = get(currentRow, FieldAndColumnName.TransactionTable_Debit, 0)
        //     const currentCredit = get(currentRow, FieldAndColumnName.TransactionTable_Credit, 0)

        //     // Calculate what value current balance should be
        //     const balance = lastBalance - currentDebit - currentCredit

        //     // Compare
        //     if (Number(value) === Math.round(balance * 100) / 100) {
        //         return FormFieldValidationResult.VALIDATION_SUCCESS
        //     } else {
        //         return FormFieldValidationResult.TRANSACTIONTABLE_INCORRECT_BALANCE
        //     }
        // }

        // return FormFieldValidationResult.INVALID_INPUT
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return OpeningBalanceValidator.columnName
    }
}

export const TransactionTableCellValidatorFactory: IValidatorFactory<FormFieldValidator> = (columnName: string) => {
    switch (columnName) {
        case DateValidator.columnName:
            return new DateValidator()
        case DebitValidator.columnName:
            return new DebitValidator()
        case CreditValidator.columnName:
            return new CreditValidator()
        case OpeningBalanceValidator.columnName:
            return new OpeningBalanceValidator()
        case ClosingBalanceValidator.columnName:
            return new ClosingBalanceValidator()
        default:
            throw Error(`Invalid column name(${columnName}) received in TransactionTableCellValidatorFactory`)
    }
}
