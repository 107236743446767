import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {IBusinessModel, IModelType, IUtilityBillModel} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {UtilityBill} from './UtilityBill'

@autoBind
export class UtilityBillMapper extends BusinessModelMapper {
  constructor(
     document:OcrDocument,
     private readonly jsonModel: IUtilityBillModel
  ){
    super(document)
  }

  fromJson(): UtilityBill {
    const UtilityBillModel = this.jsonModel
    const model = new UtilityBill(
        IModelType.UtilityBill,
        UtilityBillModel.Version,
        this.mapTextBlock(UtilityBillModel.PropertyAddress),
        this.mapDateBlock(UtilityBillModel.IssueDate),
        this.mapDateBlock(UtilityBillModel.DueDate),
        this.mapDoubleBlock(UtilityBillModel.TotalAmount),
        this.mapTextBlock(UtilityBillModel.AccountNumber),
        this.mapTextBlock(UtilityBillModel.InvoiceNumber),
        this.mapTextBlock(UtilityBillModel.CustomerName),
        this.mapDoubleBlock(UtilityBillModel.OverDueAmount),
        this.mapTextBlock(UtilityBillModel.CustomerABN),
        this.mapDoubleBlock(UtilityBillModel.GSTAmount),
        this.mapTextBlock(UtilityBillModel.CustomerAddress),
        this.mapTextBlock(UtilityBillModel.SupplierName),
        this.mapTextBlock(UtilityBillModel.SupplierABN),
        this.mapDateBlock(UtilityBillModel.BillingStartDate),
        this.mapDateBlock(UtilityBillModel.BillingEndDate),
        this.mapTextBlocks(UtilityBillModel.ServicePhoneNumber),
    )
    return model
  }

  static toJson(model: UtilityBill): IBusinessModel {
    return {
      ModelType: IModelType.UtilityBill,
      Version: model.version,
      PropertyAddress: model.propertyAddress?.toModelKeyValue(),
      IssueDate: model.issueDate?.toModelKeyValue(),
      DueDate: model.dueDate?.toModelKeyValue(),
      TotalAmount: model.totalAmount?.toModelKeyValue(),
      AccountNumber: model.accountNumber?.toModelKeyValue(),
      InvoiceNumber: model.invoiceNumber?.toModelKeyValue(),
      CustomerName: model.customerName?.toModelKeyValue(),
      OverDueAmount: model.overDueAmount?.toModelKeyValue(),
      CustomerABN: model.customerABN?.toModelKeyValue(),
      GSTAmount: model.GSTAmount?.toModelKeyValue(),
      CustomerAddress: model.customerAddress?.toModelKeyValue(),
      SupplierName: model.supplierName?.toModelKeyValue(),
      SupplierABN: model.supplierABN?.toModelKeyValue(),
      BillingStartDate: model.billingStartDate?.toModelKeyValue(),
      BillingEndDate: model.billingEndDate?.toModelKeyValue(),
      ServicePhoneNumber: model.servicePhoneNumber?.toArray().map(item => item.toModelKeyValue()),
    } as IUtilityBillModel
  }
}
