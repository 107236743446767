import Typography from '@material-ui/core/Typography'
import React from 'react'

import { GrayLink, PrimaryLink } from '../../assets/StyledLink'
import { useCopyrightStyles } from '../../assets/styles/CopyrightStyles'

const Copyright = () => {
  const classes = useCopyrightStyles()

  return (
    <Typography className={classes.root}>
      <span>
        {`Copyright © ${new Date().getFullYear()} `}
        <GrayLink href="https://www.bglcorp.com/" target="_blank">
          BGL Corporate Solutions
        </GrayLink>
      </span>
      <span>
        <PrimaryLink
          href="https://www.bglcorp.com/cloud-subscription-agreement/"
          target="_blank"
        >
          Terms and Conditions
        </PrimaryLink>
        {` | `}
        <PrimaryLink
          href="https://www.bglcorp.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </PrimaryLink>
      </span>
    </Typography>
  )
}

export default Copyright
