import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import {List} from "immutable"
import ModelObject from '../ModelObject'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import moment from 'moment'
import IItemObject from '../IItemObject'
import { IInvoiceItem } from 'domain/types/ISmartDocsResult'

export type InvoiceItemColumn =
  FieldAndColumnName.InvoiceItemColumn_Amount
  | FieldAndColumnName.InvoiceItemColumn_Date
  | FieldAndColumnName.InvoiceItemColumn_Description
  | FieldAndColumnName.InvoiceItemColumn_ProductCode
  | FieldAndColumnName.InvoiceItemColumn_Quantity
  | FieldAndColumnName.InvoiceItemColumn_Tax
  | FieldAndColumnName.InvoiceItemColumn_Unit
  | FieldAndColumnName.InvoiceItemColumn_UnitPrice

export class InvoiceItem extends ModelObject implements IItemObject {
  constructor(
    public readonly amount?: DoubleField,
    public readonly description?: StringField,
    public readonly quantity?: DoubleField,
    public readonly unitPrice?: DoubleField,
    public readonly productCode?: StringField,
    public readonly unit?: StringField,
    public readonly date?: DateField,
    public readonly tax?: DoubleField
  ) {
    super()
  }

  copy({
    amount = this.amount,
    description = this.description,
    quantity = this.quantity,
    unitPrice = this.unitPrice,
    productCode = this.productCode,
    unit = this.unit,
    date = this.date,
    tax = this.tax
  }): InvoiceItem {
    return new InvoiceItem(
      amount,
      description,
      quantity,
      unitPrice,
      productCode,
      unit,
      date,
      tax
    )
  }

  addColumn(
    gridColumnName: FieldAndColumnName,
    newValue: FormGridRowValue,
    modifiedBy: string
  ): InvoiceItem {
    switch(gridColumnName) {
      case FieldAndColumnName.InvoiceItemColumn_Amount:
        return this.copy({ amount: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_Date:
        return this.copy({ date: new DateField(uuid(), newValue as moment.Moment, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_Description:
        return this.copy({ description: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_ProductCode:
        return this.copy({ productCode: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_Quantity:
        return this.copy({ quantity: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_Tax:
        return this.copy({ tax: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_Unit:
        return this.copy({ unit: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.InvoiceItemColumn_UnitPrice:
        return this.copy({ unitPrice: new DoubleField(uuid(), newValue as number, [], List(), modifiedBy)})

      default:
        return this
    }
  }

  deleteColumn(gridColumn: FieldAndColumnName): InvoiceItem {
    return this.copy({ [gridColumn]: null })
  }

  fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
    switch (column) {
      case FieldAndColumnName.InvoiceItemColumn_Amount:
        return this.amount
      case FieldAndColumnName.InvoiceItemColumn_Date:
        return this.date
      case FieldAndColumnName.InvoiceItemColumn_Description:
        return this.description
      case FieldAndColumnName.InvoiceItemColumn_ProductCode:
        return this.productCode
      case FieldAndColumnName.InvoiceItemColumn_Quantity:
        return this.quantity
      case FieldAndColumnName.InvoiceItemColumn_Tax:
        return this.tax
      case FieldAndColumnName.InvoiceItemColumn_Unit:
        return this.unit
      case FieldAndColumnName.InvoiceItemColumn_UnitPrice:
        return this.unitPrice
      default:
        return undefined
    }
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }

  // toGridRow(id: number) {
  //   return {
  //     id,
  //     [FieldAndColumnName.InvoiceItemColumn_Amount]: this.amount?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_Description]: this.description?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_Quantity]: this.quantity?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_UnitPrice]: this.unitPrice?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_ProductCode]: this.productCode?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_Unit]: this.unit?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_Date]: this.date?.parsedValue,
  //     [FieldAndColumnName.InvoiceItemColumn_Tax]: this.tax?.parsedValue
  //   }
  // }

  toJson(): IInvoiceItem {
    return {
      Amount: this.amount?.toModelKeyValue(),
      Description: this.description?.toModelKeyValue(),
      Quantity: this.quantity?.toModelKeyValue(),
      UnitPrice: this.unitPrice?.toModelKeyValue(),
      ProductCode: this.productCode?.toModelKeyValue(),
      Unit: this.unit?.toModelKeyValue(),
      Date: this.date?.toModelKeyValue(),
      Tax: this.tax?.toModelKeyValue()
    } as IInvoiceItem
  }
}
