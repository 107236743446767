import { List } from "immutable"
import { Line } from "./Line"
import _ from "lodash"
import { applyAngleToPoint, minMaxDifference } from "../../utils/utils"
import { Page } from "./Page"

export interface IPageTransform {
  page: number;
  width: number;
  height: number;
  angleDegree: number;
  angledWidth: number;
  angledHeight: number;
  scale: number;
}


export class PageTransformCalculator {
  constructor(
    private readonly page: number,
    private readonly lineBlocks: List<Line>,
    private readonly pageWidth: number,
    private readonly pageHeight: number
  ) {
  }

  static fromPage(page: Page): PageTransformCalculator {
    return new PageTransformCalculator(page.page, page.lines, page.pageWidth, page.pageHeight)
  }

  toTransform(): IPageTransform {
    const degree = this.pageAngleDegree()
    const [angledWidth, angledHeight, scale] = this.calcScale(degree)
    return {
      page: this.page,
      width: this.pageWidth,
      height: this.pageHeight,
      angleDegree: degree,
      angledWidth: angledWidth,
      angledHeight: angledHeight,
      scale: scale
    }
  }

  private pageAngleDegree(): number {
    if (this.lineBlocks.size > 5) {
      const longestLines = this.lineBlocks.sortBy(line => -line.width()).take(10)
      const angles = longestLines.map(line => line.geometry.angleDegree(this.pageWidth, this.pageHeight))
      return _.mean(angles.toArray())
    } else {
      return 0
    }
  }

  private calcScale(angleDegree: number): [number, number, number] {
    const angledCorners = [
      applyAngleToPoint({x: 0, y: 0}, angleDegree, this.pageWidth, this.pageHeight),
      applyAngleToPoint({x: 1, y: 0}, angleDegree, this.pageWidth, this.pageHeight),
      applyAngleToPoint({x: 0, y: 1}, angleDegree, this.pageWidth, this.pageHeight),
      applyAngleToPoint({x: 1, y: 1}, angleDegree, this.pageWidth, this.pageHeight)]
    const angledWidth = minMaxDifference(angledCorners.map(corner => corner.x))
    const angledHeight = minMaxDifference(angledCorners.map(corner => corner.y))
    const scale = Math.min(1 / angledWidth, 1 / angledHeight)
    return [angledWidth, angledHeight, scale]
  }
}
