import {
  CouncilRate,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { FC } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDollarField } from '../FormDollarField'
import { FormStringField } from '../FormStringField'

interface ICouncilRateProps extends EditorFieldEvents {
  councilRate: CouncilRate
  className?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    totalAmount: {
      '& label': {
        fontWeight: 600,
        color: 'black',
        fontSize: '1.2rem'
      }
    }
  })
)

export const CouncilRateEditor: FC<ICouncilRateProps> = (
  props: ICouncilRateProps
) => {
  const styles = useStyles()
  const councilRate = props.councilRate
  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormStringField
            id={FieldAndColumnName.CouncilRate_PropertyAddress}
            label={'Property Address'}
            events={props}
            field={councilRate.propertyAddress}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.CouncilRate_FirstInstalmentDueDate}
            label={'First Instalment Due Date'}
            events={props}
            field={councilRate.firstInstalmentDueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.CouncilRate_FirstInstalmentAmount}
            label={'First Instalment Amount'}
            events={props}
            field={councilRate.firstInstalmentAmount}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.CouncilRate_SecondInstalmentDueDate}
            label={'Second Instalment Due Date'}
            events={props}
            field={councilRate.secondInstalmentDueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.CouncilRate_SecondInstalmentAmount}
            label={'Second Instalment Amount'}
            events={props}
            field={councilRate.secondInstalmentAmount}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.CouncilRate_ThirdInstalmentDueDate}
            label={'Third Instalment Due Date'}
            events={props}
            field={councilRate.thirdInstalmentDueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.CouncilRate_ThirdInstalmentAmount}
            label={'Third Instalment Amount'}
            events={props}
            field={councilRate.thirdInstalmentAmount}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.CouncilRate_FourthInstalmentDueDate}
            label={'Fourth Instalment Due Date'}
            events={props}
            field={councilRate.thirdInstalmentDueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.CouncilRate_FourthInstalmentAmount}
            label={'Fourth Instalment Amount'}
            events={props}
            field={councilRate.fourthInstalmentAmount}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.CouncilRate_CurrentAmountDueDate}
            label={'Current Amount Due Date'}
            events={props}
            field={councilRate.currentAmountDueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.CouncilRate_CurrentAmountDue}
            label={'Current Amount Due'}
            events={props}
            field={councilRate.currentAmountDue}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.CouncilRate_IssueDate}
            label={'Issue Date'}
            events={props}
            field={councilRate.issueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.CouncilRate_TotalAmount}
            label={'Total Amount'}
            events={props}
            field={councilRate.totalAmount}
            className={clsx([styles.totalAmount, props.className])}
          />
        </Grid>
      </Grid>
    </form>
  )
}
