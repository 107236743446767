import {OcrElement, Geometry} from "./OcrElement"
import {List} from "immutable"
import {WordVisitor} from "../DocumentVisitor"
import {ICellBlock, IRelationship, IWordBlock} from "../types/ISmartDocsResult"
import {Word} from "./Word"
import {TextElement} from "./TextElement"

export class Cell extends TextElement {
  constructor(
    id: string,
    page: number,
    confidence: number,
    geometry: Geometry,
    words: List<Word>,
    public readonly rowIndex: number,  //start from ZERO, where json from 1
    public readonly columnIndex: number,//start from ZERO, where json from 1
    public readonly rowSpan: number,
    public readonly columnSpan: number,
    text: string,
  ) {
    super(id, page, confidence, geometry, text, words)
  }

  accept<R>(visitor: WordVisitor<R>): List<R> {
    return this.words.map(w => visitor.visitWord(w))
  }

  static fromJson(cell: ICellBlock, childWordsFinder: (relationships: IRelationship[]) => List<IWordBlock>): Cell {
    const words = childWordsFinder(cell.Relationships ?? []).map(word => Word.fromJson(word))
    const text = words.reduce((aText, word) => aText + ' ' + word.text, '')
    return new Cell(
      cell.Id, cell.Page, cell.Confidence, Geometry.fromJson(cell.Geometry),
      words,
      cell.RowIndex - 1,
      cell.ColumnIndex - 1,
      cell.RowSpan,
      cell.ColumnSpan,
      text
    )
  }

}
