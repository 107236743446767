import _ from 'lodash'
import { IPageAnnotation, IPageAnnotations } from "./types/ISmartDocsResult"
import {Set as ImmutableSet, Map as ImmutableMap, List} from "immutable"
import {Page} from "./ocr/Page"
import { PageAnnotation } from "./PageAnnotation"
import { Annotation, Annotations } from "./Annotation"

export class TaggingPage {
  constructor(
    public readonly pageId: string,
    public readonly annotations: PageAnnotation[]
  ) {
  }

  copy({
    pageId = this.pageId,
    annotations = this.annotations
  }): TaggingPage {
    return new TaggingPage(pageId, annotations)
  }

  static fromJson(pageId: string, annotations: IPageAnnotations): TaggingPage {
    const foundTags = annotations.Annotations.map(annotation => {
      return new PageAnnotation(pageId, annotation.Annotation, annotation.Probability, annotation.Source, false)
    })
    return new TaggingPage(pageId, _.compact(foundTags))
  }

  hasTaggedWith(tag: string): boolean {
    return this.annotations.find(annotation => annotation.tagId === tag) !== undefined
  }

  hasTaggedWithList(tagIds: Set<string>): boolean {
    return this.annotations.find(annotation => tagIds.has(annotation.tagId)) !== undefined
  }

  addAnnotation(annotation: PageAnnotation): TaggingPage {
    const newAnnotations = this.annotations.concat([annotation])
    return this.copy({annotations: newAnnotations})
  }

  removeAllAnnotations(): TaggingPage {
    return this.copy({annotations: []})
  }

  removeAnnotation(annotation: PageAnnotation): TaggingPage {
    const newAnnotations = this.annotations.filter(existsAnnotation => existsAnnotation !== annotation)
    return this.copy({annotations: newAnnotations})
  }

  removeAnnotationIfHave(tag: string): TaggingPage {
    const annotationWithTag = this.annotationByTag(tag)
    if (annotationWithTag) {
      return this.removeAnnotation(annotationWithTag)
    } else {
      return this
    }
  }

  annotationByTag(tag: string): PageAnnotation | undefined {
    return this.annotations.find(annotation => annotation.tagId === tag)
  }

  hasAnnotationUpdated(): boolean {
    return this.annotations.find(annotation => annotation.tagUpdated) !== undefined
  }

  hasAnnotation(): boolean {
    return this.annotations.length > 0
  }
}


export class TaggingPages extends Annotations{
  private readonly pageMap: ImmutableMap<string, TaggingPage>

  constructor(
    public readonly pages: ImmutableSet<TaggingPage>,
  ) {
    super()
    this.pageMap = ImmutableMap(pages.map(page => [page.pageId, page]))
  }

  copy({
         pages = this.pages,
       }): TaggingPages {
    return new TaggingPages(pages)
  }

  static fromJson(rawPages: List<Page>, pageAnnotations?: IPageAnnotations[]): TaggingPages {
    const annotations = pageAnnotations ? pageAnnotations : []
    const pages = rawPages.map(page => {
      const pageAnnotation = annotations.find(pageAnnotation => pageAnnotation.Page === page.id)
      if (pageAnnotation) {
        return TaggingPage.fromJson(page.id, pageAnnotation)
      } else {
        return new TaggingPage(page.id, [])
      }
    })
    return new TaggingPages(pages.toSet())
  }

  toJson(): IPageAnnotations[] {
    const pagesHasAnnotation = this.pages.filter(page => page.hasAnnotation())
    return pagesHasAnnotation.map(page => {
      const annotations = page.annotations.map(annotation => {
        return {
          Annotation: annotation.tagId,
          Probability: 1.0,
          Source: annotation.source
        } as IPageAnnotation
      })
      return {
        Page: page.pageId,
        Annotations: annotations
      } as IPageAnnotations
    }).toArray()
  }

  page(pageId: string): TaggingPage | undefined {
    return this.pageMap.get(pageId)
  }

  pagesByTagIds(tagIds: Set<string>): TaggingPage[] {
    return this.pages.filter(page => {
      return page.hasTaggedWithList(tagIds)
    }).toArray()
  }

  private addTagToPage(page: TaggingPage, tag: string, userEmail: string): TaggingPage {
    const exist = page.hasTaggedWith(tag)
    if (exist) {
      return page
    } else {
      const newAnnotation = new PageAnnotation(page.pageId, tag, 1.0, userEmail, true)
      const newPage = page.addAnnotation(newAnnotation)
      return newPage
    }
  }

  addTag(pageIds: Set<string>, tagId: string, userEmail: string): TaggingPages {
    const pagesWithNewTag = this.pages.map(page => {
      if (pageIds.has(page.pageId)) {
        return this.addTagToPage(page, tagId, userEmail)
      } else {
        return page
      }
    })
    return this.copy({
      pages: pagesWithNewTag
    })
  }

  setTags(pageIds: Set<string>, tagIds: Set<string>, userEmail: string): TaggingPages {
    const pagesWithNewTag = this.pages.map(page => {
      if (pageIds.has(page.pageId)) {
        return page.copy({annotations: PageAnnotation.createByTagIds(page.pageId, tagIds, userEmail)})
      } else {
        return page
      }
    })
    return this.copy({
      pages: pagesWithNewTag
    })
  }

  removeTags(pageId: string): TaggingPages {
    const pagesWithNewTag = this.pages.map(page => {
      if (page.pageId === pageId) {
        return page.removeAllAnnotations()
      } else {
        return page
      }
    })
    return this.copy({
      pages: pagesWithNewTag
    })
  }

  removeTag(pageIds: Set<string>, tagId: string): TaggingPages {
    const pagesWithTagRemoved = this.pages.map(page => {
      if (pageIds.has(page.pageId)) {
        return page.removeAnnotationIfHave(tagId)
      } else {
        return page
      }
    })
    return this.copy({
      pages: pagesWithTagRemoved
    })
  }

  ifPagesUpdated(): boolean {
    return this.pages.find(page => page.hasAnnotationUpdated()) != undefined
  }

  annotationsForViewer(): List<Annotation> {
    return this.pages.flatMap(page => List(page.annotations)).toList()
  }

  nonEmpty(): boolean {
    return this.pages.size > 0
  }


}

