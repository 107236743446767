import React from 'react'

function RedactionUseCase() {
  return (
    <section className="redactionUseCase">
      <div className="redactionUseCaseLayout">
        <div className="redactionUseCaseLayoutHeader">
          <h1>Use Case</h1>
          <p>
            Learn how SmartDocs Redaction can be applied within your industry.
          </p>
        </div>
        <div className="redactionUseCasesWrapper">
          <div className="case">
            <h3>Banking & Finance</h3>
            <p>
              Secure Personally Identifiable Information (PII) and financial
              information such as bank account number, credit card details and
              TFN.
            </p>
          </div>

          <div className="case">
            <h3>Legal</h3>
            <p>
              Conceal confidential or privileged information from legal
              documents before disclosure or publication.
            </p>
          </div>

          <div className="case">
            <h3>Healthcare</h3>
            <p>
              Mask Personal Health Information (PHI) to ensure the full
              compliance with privacy laws (e.g. HIPAA).
            </p>
          </div>

          <div className="case">
            <h3>Logistics</h3>
            <p>
              Remove sensitive data from shipping notices and invoices to
              mitigate the risk in record-keeping.
            </p>
          </div>

          <div className="case">
            <h3>Retail & E-Commerce</h3>
            <p>
              Prevent data breach of PII to avoid the damage of brand
              reputation.
            </p>
          </div>

          <div className="case">
            <h3>Software Testing</h3>
            <p>
              Make data applicable while secure for internal testing and
              training use.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RedactionUseCase
