import { Container, Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Greetings } from 'aws-amplify-react'
import React from 'react'

import { useCallToActionStyles } from '../../assets/styles/CallToActionStyles'
import LoginSlider from '../others/LoginSlider'

interface ICallToActionWrapperProps {
  childComponent: JSX.Element | JSX.Element[]
}

const CallToActionWrapper = (props: ICallToActionWrapperProps) => {
  const classes = useCallToActionStyles()
  const theme = useTheme()
  const directionToggle = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="lg" className={classes.topSection}>
        <div />
      </Container>
      <Container disableGutters maxWidth="lg">
        <Grid container direction={directionToggle ? 'row' : 'column-reverse'}>
          <Grid container item md={6} sm={12} className={classes.contentLeft}>
            <LoginSlider />
          </Grid>
          <Grid item md={6} sm={12} className={classes.contentRight}>
            <a href="/">
              <img
                src="/static/images/Logo_SDAIStudio.svg"
                alt="SDAIStudio Logo"
              />
            </a>
            {props.childComponent}
            <Greetings />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default CallToActionWrapper
