import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  faBullseyeArrow,
  faChartNetwork,
  faLightbulbOn,
  faStopwatch
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Paper, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import Slider from 'react-slick'

import { useLoginSliderStyles } from '../../assets/styles/LoginSliderStyles'

const LoginSlider = (): JSX.Element => {
  const classes = useLoginSliderStyles()
  const theme = useTheme()
  const enableAutoPlay = useMediaQuery(theme.breakpoints.up('lg'))
  const toggleFlexDirection = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Slider
      className={classes.slider}
      dots
      arrows
      infinite
      autoplay
      autoplaySpeed={20000}
    >
      <div>
        <Grid
          container
          className={classes.sliderGrid}
          spacing={4}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <Typography variant={'h3'} className={classes.sliderH3}>
              Why SmartDocs
            </Typography>
            <Typography
              variant={'body1'}
              style={{ marginTop: '20px', textAlign: 'center' }}
              className={classes.sliderBody1}
            >
              We utilise advanced AI technology to help you extract data from
              documents.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.sliderPage1Paper} elevation={10}>
              <FontAwesomeIcon
                icon={faBullseyeArrow}
                size="2x"
                className={classes.sliderIcon}
              />
              <Typography variant={'h6'} className={classes.sliderH6}>
                ACCURATE
              </Typography>
              <Typography variant={'body2'} className={classes.sliderBody2}>
                {' '}
                Minimal data is required to achieve exceptional accurate
                training results
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.sliderPage1Paper} elevation={10}>
              <FontAwesomeIcon
                icon={faStopwatch}
                size="2x"
                className={classes.sliderIcon}
              />
              <Typography variant={'h6'} className={classes.sliderH6}>
                INSTANT
              </Typography>
              <Typography variant={'body2'} className={classes.sliderBody2}>
                {' '}
                Data extraction in seconds to deliver real time user experience
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.sliderPage1Paper} elevation={10}>
              <FontAwesomeIcon
                icon={faLightbulbOn}
                size="2x"
                className={classes.sliderIcon}
              />
              <Typography variant={'h6'} className={classes.sliderH6}>
                SMART
              </Typography>
              <Typography variant={'body2'} className={classes.sliderBody2}>
                {' '}
                Built on ML, the intelligent robots keep learning to become more
                accurate
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.sliderPage1Paper} elevation={10}>
              <FontAwesomeIcon
                icon={faChartNetwork}
                size="2x"
                className={classes.sliderIcon}
              />
              <Typography variant={'h6'} className={classes.sliderH6}>
                ADAPTIVE
              </Typography>
              <Typography variant={'body2'} className={classes.sliderBody2}>
                {' '}
                Made simple to use, able to adapt wide range of use case
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          className={classes.sliderGrid}
          spacing={2}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <Typography variant={'h3'} className={classes.sliderH3}>
              Model Training
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            className={
              toggleFlexDirection
                ? classes.stepDesktopLayout
                : classes.stepMobileLayout
            }
          >
            <Grid item sm={12} md={6}>
              <Typography variant={'h6'} className={classes.sliderPage2H6}>
                Step 1 - Tag
              </Typography>
              <Typography variant={'body1'} className={classes.sliderBody1}>
                Upload your document and start tagging relevant information.{' '}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Paper className={classes.sliderPage2Paper}>
                <video
                  className={classes.sliderVideo}
                  autoPlay={enableAutoPlay}
                  loop
                  muted
                  controls
                >
                  <source src="/static/videos/step1.mp4" type="video/mp4" />
                </video>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            className={clsx(
              toggleFlexDirection
                ? classes.step2DesktopLayout
                : classes.step2MobileLayout
            )}
          >
            <Grid item sm={12} md={6}>
              <Paper className={classes.sliderPage2Paper}>
                <video
                  className={classes.sliderVideo}
                  autoPlay={enableAutoPlay}
                  loop
                  muted
                  controls
                >
                  <source src="/static/videos/step2.mp4" type="video/mp4" />
                </video>
              </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant={'h6'} className={classes.sliderPage2H6}>
                Step 2 - Train
              </Typography>
              <Typography variant={'body1'} className={classes.sliderBody1}>
                Train your model with one click of a button. Result analytics
                are available to visualise performance.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            className={clsx(
              toggleFlexDirection
                ? classes.stepDesktopLayout
                : classes.stepMobileLayout
            )}
          >
            <Grid item sm={12} md={6}>
              <Typography variant={'h6'} className={classes.sliderPage2H6}>
                Step 3 - Predict & Use
              </Typography>
              <Typography variant={'body1'} className={classes.sliderBody1}>
                Your own model is now able to auto label and extract information
                from new documents.
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Paper className={classes.sliderPage2Paper}>
                <video
                  className={classes.sliderVideo}
                  autoPlay={enableAutoPlay}
                  loop
                  muted
                  controls
                >
                  <source src="/static/videos/step3.mp4" type="video/mp4" />
                </video>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Slider>
  )
}

export default LoginSlider
