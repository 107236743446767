import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {IBusinessModel, IModelType, IWaterBillModel} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {WaterBill} from './WaterBill'

@autoBind
export class WaterBillMapper extends BusinessModelMapper {
  constructor(
     document:OcrDocument,
     private readonly jsonModel: IWaterBillModel
  ){
    super(document)
  }

  fromJson(): WaterBill {
    const waterBillModel = this.jsonModel
    const model = new WaterBill(
        IModelType.WaterBill,
        waterBillModel.Version,
        this.mapTextBlock(waterBillModel.PropertyAddress),
        this.mapDateBlock(waterBillModel.IssueDate),
        this.mapDateBlock(waterBillModel.DueDate),
        this.mapDoubleBlock(waterBillModel.TotalAmount),
        this.mapTextBlock(waterBillModel.ClientAddress)
    )
    return model
  }

  static toJson(model: WaterBill): IBusinessModel {
    return {
      ModelType: IModelType.WaterBill,
      Version: model.version,
      PropertyAddress: model.propertyAddress?.toModelKeyValue(),
      IssueDate: model.issueDate?.toModelKeyValue(),
      DueDate: model.dueDate?.toModelKeyValue(),
      TotalAmount: model.totalAmount?.toModelKeyValue(),
      ClientAddress: model.clientAddress?.toModelKeyValue()
    } as IWaterBillModel
  }
}
