import { List, OrderedMap } from 'immutable'

export class AnnotationId {
  constructor(
    readonly pageId: string,
    readonly id: string
  ) {}
}

export abstract class Annotation {
  constructor(
    readonly id: AnnotationId,
    readonly tagId: string,
    readonly confidence: number,
    readonly source: string
  ) {}

  abstract viewText(
    idToPageNumber: (id: AnnotationId) => number | undefined
  ): string
}

export abstract class Annotations {
  abstract annotationsForViewer(): List<Annotation>

  groupByTagId(): OrderedMap<string, List<Annotation>> {
    return this.annotationsForViewer().groupBy(annotation => annotation.tagId).map(value => value.toList()).toMap()
  }

  groupByAnnotationId(findAnnotationNameById: (annotationId: string) => string): OrderedMap<string, List<string>> {
    return this.annotationsForViewer()
      .map(annotation => ({id: annotation.id.id, name: findAnnotationNameById(annotation.tagId)}))
      .groupBy(annotation => annotation.id)
      .map(value => value.map(v => v.name).toList())
      .toMap()
  }
}
