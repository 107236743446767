import { useEffect } from 'react'

import { SsoClientApi } from '../services/SsoClientApi'
import { useInitStore } from '../services/stores/InitStore'
import { setUnreadCount } from '../services/stores/ZenDeskStore'

declare global {
  interface Window {
    zE: (command: string, ...args: any[]) => void
  }
}

const zendeskApi = SsoClientApi.getInstance()

const useZenDeskWidget = () => {
  const user = useInitStore((state) => state.authorizations?.userFullName)
  const email = useInitStore((state) => state.authorizations?.username)
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'ze-snippet'
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=84e9218b-5057-42d3-8df2-f84e5928420d'
    script.async = true
    document.body.appendChild(script)

    script.onload = async () => {
      const [memberShipData, chatToken] = await Promise.all([
        zendeskApi.getZendeskMembership(),
        zendeskApi.getChatToken()
      ])
      configureChatWidget(user ?? '', email || '', chatToken ?? '')
      window.zE('webWidget', 'hide')
    }
  }, [])

  const configureChatWidget = (
    userName: string,
    userEmail: string,
    chatToken: string
  ) => {
    if (window.zE) {
      window.zE('webWidget', 'identify', {
        tags: ['sdd360'],
        name: userName,
        email: userEmail,
        chat_token: chatToken
      })
      //  hide the widget when first loaded
      window.zE('webWidget', 'hide')
      // hide the widget when close button is clicked
      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide')
      })
      window.zE('webWidget:on', 'chat:end', () => {
        window.zE('webWidget', 'clear')
      })
      //   handle unread messages, need to test
      window.zE(
        'webWidget:on',
        'chat:unreadMessages',
        (unreadCount: number) => {
          if (unreadCount > 0) {
            setUnreadCount(unreadCount)
          }
        }
      )
    }
  }

  const showChatWidget = () => {
    if (window.zE) {
      window.zE('webWidget', 'show')
      window.zE('webWidget', 'open')
    }
  }

  return { showChatWidget }
}

export default useZenDeskWidget
