import { apiPath } from '../ApiPath'
import BaseApi from './BaseApi'
import { trackEvent } from './GoogleAnalytics'

class PaymentApi extends BaseApi {
  private static instance: PaymentApi
  apiPath: string

  private constructor(apiPath: string) {
    super()
    this.apiPath = apiPath
  }

  static getInstance(): PaymentApi {
    if (!PaymentApi.instance) {
      PaymentApi.instance = new PaymentApi(apiPath)
    }
    return PaymentApi.instance
  }

  async createPaymentIntent(
    businessId: string,
    credits: number
  ): Promise<string | undefined> {
    try {
      const response = await this.loadByPost(
        `${this.apiPath}/businesses/${businessId}/credit-payment-intent`,
        { credits }
      )
      return response?.clientSecret
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'CreatePaymentIntent',
        label: `${businessId}_${this.mapError(error)}`
      })
      return undefined
    }
  }
}

export default PaymentApi
