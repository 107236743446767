/* eslint-env browser */

import React from 'react'

function ReadyForExperience() {
  return (
    <section className="ready-for-experience">
      <div className="layout">
        <div className="left-panel">
          <h1>Ready for a hassle-free redaction experience?</h1>
          <h3>
            <a href="/#/contact-us" target="_blank" rel="noreferrer">
              Contact Us
            </a>{' '}
            today!
          </h3>
        </div>
        {/*<div className="right-panel"></div>*/}
      </div>
    </section>
  )
}

export default ReadyForExperience
