import autobind from 'autobind-decorator'
import { saveAs } from 'file-saver'
import React from 'react'
import './assets/style.sass'
import { pdfjs } from 'react-pdf'
import PDFViewerMobile from './components/PDFViewerMobile'
import PDFViewerSinglePage from './components/PDFViewerSinglePage'
import { IOverlay } from './overlay/IOverlay'
import { IOverlayPageTransform } from './overlay/IOverlayPageTransform'
import { PdfMode } from './utils/utils'

interface SmartDocPDFViewerMobileProps {
  pdfBlob: Blob,
  fileName: string,
  overlays?: IOverlay[],
  pageTransforms?: IOverlayPageTransform[],
  onDocumentReady?: (pdf: pdfjs.PDFDocumentProxy) => void
  onSelecting?: (selectingItems: IOverlay[]) => void
  onSelectionFinish?: (selectedItems: IOverlay[]) => void
  viewMode?: 'wordTagging' | 'pageTagging'
  navToPage?: number
  scale?: number
  pdfMode?: PdfMode.MobileScrollMode | PdfMode.MobileSinglePageMode
  onPasswordCancel?: () => void
}

interface SmartDocPDFViewerMobileStates {
  forceOverlayUpdateCounter: number
  bottomBarElem?: JSX.Element | JSX.Element[]
  lastPdfBlob?: Blob
  documentOnLoaded?: boolean
}

// Can't use FunctionComponent as we expose methods
@autobind
class SmartDocPDFViewerMobile extends React.Component<SmartDocPDFViewerMobileProps, SmartDocPDFViewerMobileStates> {
  singlePageRef: React.RefObject<any>
  constructor(props: SmartDocPDFViewerMobileProps) {
    super(props)
    this.singlePageRef = React.createRef()
    this.state = {
      forceOverlayUpdateCounter: 0,
      lastPdfBlob: undefined,
      documentOnLoaded: false
    }

  }
  handleDocumentOnLoaded(pdf: pdfjs.PDFDocumentProxy) {
    this.setState({documentOnLoaded: true})
    if (this.props.onDocumentReady) return this.props.onDocumentReady(pdf)
  }

  onDownloadFile() {
    if (this.props.fileName) {
      // check if fileName end with .pdf, if not add .pdf, otherwise return the fileName
      const fileName = this.props.fileName.endsWith('.pdf') ? this.props.fileName : `${this.props.fileName}.pdf`
      saveAs(this.props.pdfBlob, fileName)
    } else {
      saveAs(this.props.pdfBlob, 'document.pdf')
    }
  }
  componentDidMount() {
    // this.reloadPdfIfChanged()
  }

  componentWillUnmount() {
    this.resetOverlayOnLoadState()
  }

  resetOverlayOnLoadState() {
    if (this.state.lastPdfBlob !== this.props.pdfBlob) {
      this.setState({
        documentOnLoaded: false
      })
    }
  }
  shouldComponentUpdate(nextProps: Readonly<SmartDocPDFViewerMobileProps>, nextState: Readonly<SmartDocPDFViewerMobileStates>, nextContext: any): boolean {
    return (this.props.pdfBlob !== nextProps.pdfBlob) ||
      (this.props.overlays !== nextProps.overlays) ||
      (this.state.forceOverlayUpdateCounter !== nextState.forceOverlayUpdateCounter) ||
      (this.state.documentOnLoaded !== nextState.documentOnLoaded) || (this.props.scale !== nextProps.scale)
  }

  scrollIntoView(overlayId?: string) {
    if (overlayId) {
      document.getElementById(overlayId)?.scrollIntoView({block: 'center', behavior: 'smooth'})
    }
  }

  goToPage(page: number) {
    if (this.singlePageRef.current) {
      this.singlePageRef.current.setPageNumber(page)
    }
  }

  prevPage(): number | undefined {
    if (this.singlePageRef.current) {
     return this.singlePageRef.current.prevPage()
    }
  }

  nextPage(): number | undefined {
    if (this.singlePageRef.current) {
      return this.singlePageRef.current.nextPage()
    }
  }

  render() {
    switch (this.props.pdfMode) {
      case PdfMode.MobileScrollMode:
        return (
          <PDFViewerMobile
            key="PDFViewerContainer"
            pdf={this.props.pdfBlob}
            fileName={this.props.fileName}
            forceUpdateCounter={this.state.forceOverlayUpdateCounter}
            overlays={this.props.overlays}
            pageTransforms={this.props.pageTransforms}
            onDownloadFile={this.onDownloadFile}
            handleDocumentLoadSuccess={this.handleDocumentOnLoaded}
            bottomBarElem={<></>}
            viewMode={this.props.viewMode}
            scale={this.props.scale}
            scrollIntoView={this.scrollIntoView}
            onPasswordCancel={this.props.onPasswordCancel}
          />
        )

      case PdfMode.MobileSinglePageMode:
        return (
          <PDFViewerSinglePage
            key="PDFViewerContainer"
            onRef={this.singlePageRef}
            pdf={this.props.pdfBlob}
            fileName={this.props.fileName}
            forceUpdateCounter={this.state.forceOverlayUpdateCounter}
            overlays={this.props.overlays}
            pageTransforms={this.props.pageTransforms}
            onDownloadFile={this.onDownloadFile}
            handleDocumentLoadSuccess={this.handleDocumentOnLoaded}
            bottomBarElem={<></>}
            goToPage={this.goToPage}
            scale={this.props.scale}
            onPasswordCancel={this.props.onPasswordCancel}
          />)

      default:
        return (
          <PDFViewerSinglePage
            key="PDFViewerContainer"
            onRef={this.singlePageRef}
            pdf={this.props.pdfBlob}
            fileName={this.props.fileName}
            forceUpdateCounter={this.state.forceOverlayUpdateCounter}
            overlays={this.props.overlays}
            pageTransforms={this.props.pageTransforms}
            onDownloadFile={this.onDownloadFile}
            handleDocumentLoadSuccess={this.handleDocumentOnLoaded}
            bottomBarElem={<></>}
            goToPage={this.goToPage}
            scale={this.props.scale}
            onPasswordCancel={this.props.onPasswordCancel}
          />)
    }
  }
}

export default SmartDocPDFViewerMobile

