import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useProjectSelectorStyles = makeStyles((theme) =>
  createStyles({
    groupTitle: {
      position: 'sticky',
      top: 0,
      display: 'flex',
      height: 30,
      alignItems: 'center',
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.grey['400'],
      backgroundColor: theme.palette.grey['200'],
      '& .MuiTypography-root': {
        paddingLeft: 10,
        fontWeight: 'bold',
        fontSize: 13,
        lineHeight: '13px',
        color: theme.palette.grey['500']
      }
    },
    homeGroupTitle: {
      '& .MuiTypography-root': {
        color: theme.palette.common.black
      }
    },
    selector: {
      display: 'flex',
      padding: '0 5px',
      width: 350,
      height: 42,
      fontSize: '0.875rem',
      alignItems: 'center',
      transition: 'none',
      color: theme.palette.primary.main,
      '& .MuiInputBase-root': {
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.palette.grey['400'],
        paddingLeft: 5,
        height: 30,
        fontSize: 14
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none !important'
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none !important'
      }
    },
    selectorFrontIcon: {
      margin: theme.spacing(0, 1, 0, 2)
    },
    selectBtn: {
      minWidth: 350,
      maxWidth: 400,
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      borderRadius: 20,
      backgroundColor: theme.palette.primary['50'],
      textTransform: 'none',
      paddingLeft: 30,
      '&:hover': {
        backgroundColor: theme.palette.primary['50']
      },
      '& .MuiButton-endIcon': {
        marginLeft: 'auto'
      }
    },
    popoverPaper: {
      width: 350,
      overflow: 'unset',
      borderRadius: '4px 4px 0 0'
    },
    popupMenuPaper: {
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey['400'],
      width: 350,
      margin: 0,
      borderRadius: '0 0 4px 4px',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '& ul': {
        paddingTop: 0
      },
      '& li': {
        minWidth: 40,
        height: 40,
        paddingLeft: 15,
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey['400'],
        '& .MuiTypography-root': {
          fontSize: 14
        }
      }
    },
    projectTypeChip: {
      height: 15,
      marginLeft: 10,
      borderRadius: 10,
      backgroundColor: theme.palette.grey['400'],
      fontSize: 10,
      padding: '2px 10px 0px',
      fontWeight: 700,
      lineHeight: '10px'
    },
    emptyBusiness: {
      fontSize: 13
    }
  })
)
