import {IBusinessModel, IModelType} from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DollarField, DoubleField, StringField} from '../DetectedField'
import {CouncilRateMapper} from "./CouncilRateMapper";
import {FormGridRowValue} from "../../../utils/DataTypeMapper";
import {FieldAndColumnName} from "../../validator/FieldValidatorDef";
import {List} from "immutable";
import {uuid} from "../../../utils/utils";

export class CouncilRate extends BusinessModel{
    constructor(
        modelType: IModelType,
        version: string,
        public readonly propertyAddress?: StringField,
        public readonly issueDate?: DateField,
        public readonly currentAmountDueDate?: DateField,
        public readonly currentAmountDue?:DoubleField,
        public readonly firstInstalmentDueDate?: DateField,
        public readonly firstInstalmentAmount?: DoubleField,
        public readonly secondInstalmentDueDate?: DateField,
        public readonly secondInstalmentAmount?: DoubleField,
        public readonly thirdInstalmentDueDate?: DateField,
        public readonly thirdInstalmentAmount?: DoubleField,
        public readonly fourthInstalmentDueDate?: DateField,
        public readonly fourthInstalmentAmount?: DoubleField,
        public readonly discountTotalAmountDue?: DoubleField,
        public readonly totalAmount?: DoubleField
    ) {
        super(modelType, version)
    }

    copy({
        modelType = this.modelType,
        version = this.version,
        propertyAddress = this.propertyAddress,
        issueDate = this.issueDate,
        currentAmountDueDate = this.currentAmountDueDate,
        currentAmountDue = this.currentAmountDue,
        firstInstalmentDueDate = this.firstInstalmentDueDate,
        firstInstalmentAmount = this.firstInstalmentAmount,
        secondInstalmentDueDate = this.secondInstalmentDueDate,
        secondInstalmentAmount = this.secondInstalmentAmount,
        thirdInstalmentDueDate = this.thirdInstalmentDueDate,
        thirdInstalmentAmount = this.thirdInstalmentAmount,
        fourthInstalmentDueDate = this.fourthInstalmentDueDate,
        fourthInstalmentAmount = this.fourthInstalmentAmount,
        discountTotalAmountDue = this.discountTotalAmountDue,
        totalAmount = this.totalAmount
         }): CouncilRate{
    return new CouncilRate(
        modelType,
        version,
        propertyAddress,
        issueDate,
        currentAmountDueDate,
        currentAmountDue,
        firstInstalmentDueDate,
        firstInstalmentAmount,
        secondInstalmentDueDate,
        secondInstalmentAmount,
        thirdInstalmentDueDate,
        thirdInstalmentAmount,
        fourthInstalmentDueDate,
        fourthInstalmentAmount,
        discountTotalAmountDue,
        totalAmount
    )
}

protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
        switch (id) {
            case FieldAndColumnName.CouncilRate_PropertyAddress:
                return new StringField(uuid(), value as string, [], List(), modifiedBy)

            case FieldAndColumnName.CouncilRate_IssueDate:
            case FieldAndColumnName.CouncilRate_CurrentAmountDueDate:
            case FieldAndColumnName.CouncilRate_FirstInstalmentDueDate:
            case FieldAndColumnName.CouncilRate_SecondInstalmentDueDate:
            case FieldAndColumnName.CouncilRate_ThirdInstalmentDueDate:
            case FieldAndColumnName.CouncilRate_FourthInstalmentDueDate:
                return new DateField(uuid(), value as  moment.Moment, [], List(), modifiedBy)

            case FieldAndColumnName.CouncilRate_TotalAmount:
            case FieldAndColumnName.CouncilRate_CurrentAmountDue:
            case FieldAndColumnName.CouncilRate_FirstInstalmentAmount:
            case FieldAndColumnName.CouncilRate_SecondInstalmentAmount:
            case FieldAndColumnName.CouncilRate_ThirdInstalmentAmount:
            case FieldAndColumnName.CouncilRate_FourthInstalmentAmount:
                return new DollarField(uuid(), value as number, [], List(), modifiedBy)

            default:
                return undefined
        }
}

toJson(): IBusinessModel {
    return CouncilRateMapper.toJson(this)
}
}