import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import {List} from 'immutable'
import BusinessModel from '../BusinessModel'
import {DateField, StringField} from '../DetectedField'
import {BankAuthorityAccounts} from './BankAuthorityAccounts'
import { BankAuthorityMapper } from './BankAuthorityMapper'

export class BankAuthority extends BusinessModel {
    constructor(
        modelType: IModelType,
        version: string,
        public readonly practiceName?: StringField,
        public readonly acnAbn?: StringField,
        public readonly accounts?: BankAuthorityAccounts,
        public readonly signatoriesName?: List<StringField>,
        public readonly signature?: List<StringField>,
        public readonly signatureDate?: List<DateField>,
        public readonly smsfName?: StringField
    ) {
        super(modelType, version)
    }

    copy({
         modelType = this.modelType,
         version = this.version,
         practiceName = this.practiceName,
         acnAbn = this.acnAbn,
         accounts = this.accounts,
         signatoriesName = this.signatoriesName,
         signature = this.signature,
         signatureDate = this.signatureDate,
         smsfName = this.smsfName
         }): BankAuthority {
        return new BankAuthority(modelType, version, practiceName, acnAbn, accounts, signatoriesName, signature, signatureDate, smsfName)
    }

    toJson(): IBusinessModel {
        return BankAuthorityMapper.toJson(this)
    }
}

