import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: '#810ED3',
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow)

const StyledDividerUp = withStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#5d5d5d',
      height: 3,
      margin: '10px auto',
      width: 240
    }
  })
)(Divider)

const StyledDividerDown = withStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#5d5d5d',
      height: 3,
      margin: '10px auto',
      width: 190
    }
  })
)(Divider)

const StyledDivider = () => {
  return (
    <div style={{ margin: '40px auto' }}>
      <StyledDividerUp />
      <StyledDividerDown />
    </div>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 400
  }
})

function createPricingData(
  name: string,
  proPricing: string,
  enterprisePricing: string
) {
  return { name, proPricing, enterprisePricing }
}

function createFeatureData(
  name: string,
  proFeature: string | boolean,
  enterpriseFeature: string | boolean
) {
  return { name, proFeature, enterpriseFeature }
}

function generateFeatureCell(feature: string | boolean) {
  if (typeof feature === 'boolean') {
    if (feature) {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ color: 'green', fontSize: '18px' }}
        />
      )
    } else {
      return (
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ color: 'red', fontSize: '18px' }}
        />
      )
    }
  }
  return feature
}

const pricingBaseRows = [
  createPricingData('Pricing Base', '$1 / credit', 'Bulk discount available'),
  createPricingData(
    'Subscription Price',
    '10 credits/month',
    '10 credits/month'
  )
]

const pricingFreeTrialRows = [
  createPricingData('Free Credits', '2 credits/firm', '2 credits/firm'),
  createPricingData(
    'Free Training',
    '3 trainings/project',
    '3 trainings/project'
  )
]

const pricingUseInferenceRows = [
  createPricingData('Custom File', '0.03 credits/page', '0.03 credits/page'),
  createPricingData(
    'Custom File (with Line Item Extracted)',
    '0.05 credits/page',
    '0.05 credits/page'
  ),
  createPricingData('Bank Statement', '0.08 credits/page', '0.08 credits/page'),
  createPricingData(
    'Invoice / Receipt',
    '0.04 credits/page',
    '0.04 credits/page'
  ),
  createPricingData('Rental Summary', '0.08 credits/page', '0.08 credits/page'),
  createPricingData('Contract Notes', '0.04 credits/page', '0.04 credits/page'),
  createPricingData(
    'Dividend Statement',
    '0.04 credits/page',
    '0.04 credits/page'
  ),
  createPricingData(
    'Property Settlement Statement',
    '0.08 credits/page',
    '0.08 credits/page'
  ),
  createPricingData(
    'Annual Tax Statement',
    '0.04 credits/page',
    '0.04 credits/page'
  ),
  createPricingData('Utility Bill', '0.04 credits/page', '0.04 credits/page'),
  createPricingData(
    'Model Training',
    '10 credits/training',
    '10 credits/training'
  )
]

const featurePlatformAccessRows = [
  createFeatureData('Users', 'Unlimited', 'Unlimited'),
  createFeatureData('Projects', 'Unlimited', 'Unlimited'),
  createFeatureData('Role Based Access', true, true),
  createFeatureData('MFA', true, true)
]

const featureAnnotationRows = [
  createFeatureData('Data Type Supported', 'PDF/JPEG', 'PDF/JPEG'),
  createFeatureData('Annotation & Labelling', true, true),
  createFeatureData('AI-Assisted Annotation', true, true),
  // createFeatureData('Human-in-Loop Reviewing', true, true),
  createFeatureData('Result Export into CSV', true, true)
]

const featureDataManagementRows = [
  // createFeatureData('Cloud Storage', 'Unlimited', 'Unlimited'),
  createFeatureData('Filter Aggregation', true, true),
  createFeatureData('Embeddings Projector', true, true),
  createFeatureData('Similarity Search', true, true),
  createFeatureData('Custom File Expiration', true, true)
]

const featureModelTrainingRows = [
  createFeatureData('One-click Model Training', true, true),
  createFeatureData('Model Metrics', true, true)
]

const featureDeploymentRows = [
  createFeatureData('Self-hosted Deployment', false, true)
]

const featureIntegrationRows = [
  createFeatureData('API Access', true, true),
  createFeatureData('Webhook', true, true),
  createFeatureData('Email', true, true)
]

const featureSupportRows = [
  createFeatureData('Dedicated Email / Phone Support', true, true),
  createFeatureData('Priority Customer Support', false, true),
  createFeatureData('Help Articles & Knowledge Centre', true, true)
]

function PricingTab() {
  const classes = useStyles()

  return (
    <section className="pricing-section s-padding" id={'pricing'}>
      <div className="container">
        <div className="s-title-wrap">
          <h2 className="s-title">Our Plan</h2>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tab pricing-tab-s1">
              <div className="tab-contents">
                <div className="row justify-content-lg-center">
                  <div className="col-lg-4 col-md-6 priceItemWrapper">
                    <div className="pricing-t-s2">
                      <div className="pt-header">
                        <div className="price">
                          <h3>$1 / Credit</h3>
                        </div>
                        <div className="pt-ribbon">Pro</div>
                      </div>
                      <div className="pt-body">
                        <p className="pt-descriptText">
                          Ideal for individuals and growing teams. Scale your
                          machine learning projects with SmartDocs AI
                          Studio.&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <ul className="pt-features">
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">Free trial available</span>
                          </li>
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">
                              Pay As You Go - No commitments
                            </span>
                          </li>
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">
                              Unlimited users & projects
                            </span>
                          </li>
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">
                              Dedicated customer support
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="pt-footer">
                        <button
                          className="contactButton"
                          onClick={() => (location.href = '/#/contact-us')}
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 priceItemWrapper">
                    <div className="pricing-t-s2">
                      <div className="pt-header">
                        <div className="price">
                          <h3>Custom</h3>
                        </div>
                        <div className="pt-ribbon">Enterprise</div>
                      </div>
                      <div className="pt-body">
                        <p className="pt-descriptText">
                          Ideal for large organisations with custom
                          requirements. Get in touch with us to tailor a plan
                          that best fits your specific needs.
                        </p>
                        <ul className="pt-features">
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">Everything in Pro Plan</span>
                          </li>
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">Bulk credit discounts</span>
                          </li>
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">
                              Self-hosted deployment option
                            </span>
                          </li>
                          <li>
                            <span>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                className="icon"
                              />
                            </span>
                            <span className="text">Priority support</span>
                          </li>
                        </ul>
                      </div>
                      <div className="pt-footer">
                        <button
                          className="contactButton"
                          onClick={() => (location.href = '/#/contact-us')}
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StyledDivider />
        <div className="s-title-wrap">
          <h2 className="s-title">Pricing</h2>
          <p>
            Simple and transparent credit-based pricing. You only pay what you
            use. No hidden cost.
          </p>
        </div>

        <div className="pricingTableContainer">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="pricing table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Pricing</StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingBaseRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.proPricing}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.enterprisePricing}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Free Trial</StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingFreeTrialRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.proPricing}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.enterprisePricing}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Use / Inference
                  </StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingUseInferenceRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.proPricing}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.enterprisePricing}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledDivider />
          <div className="s-title-wrap">
            <h2 className="s-title">Compare features</h2>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="feature table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Platform & Access
                  </StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featurePlatformAccessRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Annotation</StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureAnnotationRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Data Management
                  </StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureDataManagementRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Model Training
                  </StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureModelTrainingRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Deployment</StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureDeploymentRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Integration</StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureIntegrationRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Support</StyledTableCell>
                  <StyledTableCell align="center">Pro</StyledTableCell>
                  <StyledTableCell align="center">Enterprise</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureSupportRows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.proFeature)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {generateFeatureCell(row.enterpriseFeature)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </section>
  )
}

export default PricingTab
