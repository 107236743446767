import {List} from "immutable"
import _ from "lodash"
import {IBoundingBox, IGeometry, IPolygon} from "../types/ISmartDocsResult"
import { applyAngleToPoint, getLineLength, getMidPoint } from "../../utils/utils"
import { IPageTransform } from "./PageTransform"

export abstract class OcrElement {
  protected constructor(
    public readonly id: string,
    public readonly page: number,
    public readonly confidence: number,
    public readonly geometry: Geometry,
  ) {
  }

  width(): number {
    return this.geometry.boundingBox.width
  }

  static averageConfidence(elements: List<OcrElement>): number {
    if (elements.isEmpty()) {
      return 0
    } else {
      const sum = _.sum(elements.map(e => e.confidence).toArray())
      return sum / elements.size
    }
  }

  static mergeGeometries(geos: List<Geometry>): Geometry | undefined {
    const first = geos.get(0)
    if (first) {
      return geos.reduce((geo, newGeo) => geo.mergeWith(newGeo), first)
    } else {
      return undefined
    }
  }
}


export class Geometry {
  constructor(
    public readonly boundingBox: BoundingBox,
    public readonly polygons: Polygons
  ) {
  }

  mergeWith(that: Geometry): Geometry {
    const newBoundingBox = this.boundingBox.mergeWith(that.boundingBox)
    return new Geometry(
      newBoundingBox, newBoundingBox.toPolygonPoints()
    )
  }

  angleDegree(pageWidth: number, pageHeight: number): number {
    return this.polygons.angleDegree(pageWidth, pageHeight)
  }

  static fromJson(geometry: IGeometry): Geometry {
    return new Geometry(
      BoundingBox.fromJson(geometry.BoundingBox),
      new Polygons(
        Polygon.fromJson(geometry.Polygon[0]),
        Polygon.fromJson(geometry.Polygon[1]),
        Polygon.fromJson(geometry.Polygon[2]),
        Polygon.fromJson(geometry.Polygon[3]))
    )
  }
}

export default class Polygons {
  constructor(
    public readonly topLeft: Polygon,
    public readonly topRight: Polygon,
    public readonly bottomRight: Polygon,
    public readonly bottomLeft: Polygon
  ) {
  }

  angleDegree(pageWidth: number, pageHeight: number): number {
    const left = this.getMidPoint(this.topLeft, this.bottomLeft)
    const right = this.getMidPoint(this.topRight, this.bottomRight)
    return Math.atan2((left.y - right.y) * pageHeight, (right.x - left.x) * pageWidth) * 180 / Math.PI
  }

  getMidPoint(pointA: Polygon, pointB: Polygon): Polygon {
    return new Polygon((pointA.x + pointB.x) / 2, (pointA.y + pointB.y) / 2)
  }

  transform(page: IPageTransform): BoundingBox {
    const {x, y} = applyAngleToPoint(this.topLeft, page.angleDegree, page.width, page.height)
    const width = getLineLength(getMidPoint(this.topLeft, this.bottomLeft),
      getMidPoint(this.topRight, this.bottomRight)) * page.angledWidth
    const height = getLineLength(getMidPoint(this.topLeft, this.topRight),
      getMidPoint(this.bottomLeft, this.bottomRight)) * page.angledHeight
    return new BoundingBox(y, x, width, height).scale(page.scale)
  }
}

export class BoundingBox {
  constructor(
    public readonly top: number,
    public readonly left: number,
    public readonly width: number,
    public readonly height: number
  ) {
  }

  static fromJson(box: IBoundingBox): BoundingBox {
    return new BoundingBox(
      box.Top, box.Left, box.Width, box.Height
    )
  }

  scale(n: number): BoundingBox {
    const top = (this.top - 0.5) * n + 0.5
    const left = (this.left - 0.5) * n + 0.5
    const width = this.width * n
    const height = this.height * n
    return new BoundingBox(top, left, width, height)
  }

  mergeWith(that: BoundingBox): BoundingBox {
    const newLeft = Math.min(this.left, that.left)
    const newTop = Math.min(this.top, that.top)
    const newWidth = Math.max(this.width, that.width)
    const newHeight = Math.max(this.height, that.height)

    return new BoundingBox(newTop, newLeft, newWidth, newHeight)
  }

  toPolygonPoints(): Polygons {
    const topLeft = new Polygon(this.left, this.top)
    const topRight = new Polygon(this.left + this.width, this.top)
    const bottomRight = new Polygon(this.left + this.width, this.top + this.height)
    const bottomLeft = new Polygon(this.left, this.top + this.height)
    return new Polygons(
      topLeft,
      topRight,
      bottomRight,
      bottomLeft
    )
  }
}

export class Polygon {
  constructor(
    public readonly x: number,
    public readonly y: number
  ) {
  }

  static fromJson(polygon: IPolygon): Polygon {
    return new Polygon(
      polygon.X, polygon.Y
    )
  }

}

