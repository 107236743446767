import {OcrElement, Geometry} from "./OcrElement"
import {List} from "immutable"
import {LineVisitor, TableVisitor} from "../DocumentVisitor"
import {IPageBlock, IRelationship} from "../types/ISmartDocsResult"
import {Line} from "./Line"
import {Table} from "./Table"

export class Page extends OcrElement {
  constructor(
    id: string,
    page: number,
    geometry: Geometry,
    public readonly pageWidth: number,
    public readonly pageHeight: number,
    public readonly lines: List<Line>,
    public readonly tables: List<Table>,
  ) {
    super(id, page, 1.0, geometry)
  }

  acceptLineVisitor<R>(visitor: LineVisitor<R>): List<R> {
    return this.lines.map(line => visitor.visitLine(line))
  }

  acceptTableVisitor<R>(visitor: TableVisitor<R>): List<R> {
    return this.tables.map(t => visitor.visitTable(t))
  }

  static fromJson(
    width: number,
    height: number,
    page: IPageBlock,
    linesBuilder: (relationships: IRelationship[]) => List<Line>,
    tablesBuilder: (relationships: IRelationship[]) => List<Table>
  ): Page {
    return new Page(
      page.Id,
      page.Page,
      Geometry.fromJson(page.Geometry),
      width, height,
      linesBuilder(page.Relationships),
      tablesBuilder(page.Relationships),
    )
  }

}
