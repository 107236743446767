import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import React from 'react'

export const useConfirmationDialogStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        borderRadius: 0
      },
      '& .MuiDialogTitle-root': {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        '& .MuiTypography-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: '600'
        }
      },
      '& .MuiDialogContentText-root': {
        color: theme.palette.common.black,
        fontSize: 14,
        margin: theme.spacing(3, 1)
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 3),
        '& button': {
          padding: theme.spacing(0.5, 2),
          borderRadius: 0,
          textTransform: 'none',
          fontWeight: 600
        }
      }
    },
    title: {
      color: 'white'
    },
    actionBtnContainer: {
      position: 'relative'
    },
    actionBtn: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    buttonProgress: {
      color: theme.palette.primary.dark,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      animationDuration: '300ms'
    },
    cancelBtn: {
      color: theme.palette.common.black,
      backgroundColor: '#efece7',
      '&:hover': {
        backgroundColor: '#e5e0d9'
      }
    }
  })
)

export interface IConfirmationDialog {
  title: string
  content: React.ReactNode
  action: () => Promise<void>
  actionName: string
  dialogOpen: boolean
  handleCancel: () => void
}

const ConfirmationDialog = (props: IConfirmationDialog) => {
  const classes = useConfirmationDialogStyles()
  const [loading, setLoading] = React.useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    await props.action()
    setLoading(false)
    props.handleCancel()
  }

  return (
    <Dialog open={props.dialogOpen} className={classes.root}>
      <DialogTitle>
        <Typography className={classes.title}>{props.title}</Typography>
        <IconButton onClick={props.handleCancel}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {props.content}
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          onClick={props.handleCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <div className={classes.actionBtnContainer}>
          <Button
            className={classes.actionBtn}
            disabled={loading}
            onClick={handleConfirm}
          >
            {props.actionName}
          </Button>
          {loading && (
            <FontAwesomeIcon
              icon={faSpinnerThird}
              spin
              size={'lg'}
              className={classes.buttonProgress}
            />
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
