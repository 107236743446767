import React from 'react'

function SuccessInNumbers() {
  return (
    <section className="success-in-numbers">
      <div className="container container-wrapper">
        <h3>Success In Numbers</h3>
        <div className="success-in-numbers-layout">
          <div className="number">
            <span className="documents-uploaded">8,246,510</span>
            <p>Documents Uploaded</p>
          </div>

          <div className="number">
            <span className="pages-processed">25,201,239</span>
            <p>Pages Processed</p>
          </div>

          <div className="number">
            <span className="ai-models-trained">1,613</span>
            <p>AI Models Trained</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessInNumbers
