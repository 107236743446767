import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CheckCircle } from '@material-ui/icons'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useMemo, useState } from 'react'

import { isProd } from '../../ApiPath'
import PaymentApi from '../../services/PaymentApi'
import { useInitStore } from '../../services/stores/InitStore'
import { usePaymentStore } from '../../services/stores/PaymentStore'
import CheckoutForm from './CheckoutForm'

const paymentStyles = makeStyles((theme) =>
  createStyles({
    dialogTitle: {
      color: theme.palette.text.primary,
      '& .MuiTypography-root': {
        fontSize: '1.1rem'
      }
    },
    amountToPay: {
      color: theme.palette.text.primary,
      fontWeight: 'bold'
    },
    loadingCircle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '260px'
    },
    paymentSuccessWrapper: {
      height: '260px'
    },
    paymentSuccessIcon: {
      color: theme.palette.primary.main,
      fontSize: '3rem',
      marginBottom: '1rem'
    },
    paymentSuccessSubtitle: {
      color: theme.palette.grey[600]
    },
    paymentNoteText: {
      fontSize: '0.8rem',
      margin: '0.5rem 1rem',
      '&::before': {
        content: '"• "'
      }
    }
  })
)

const publishableKey = isProd
  ? 'pk_live_51NFRnMALZWVCbTQWhyxnXqDFvQHS9c87DrqZmey9CTAFnKCKVIQ03rEJJyqiLedxTJWCvZyaXSJuq4ITL5ZQNX2N00TOXqoVVu'
  : 'pk_test_51NFRnMALZWVCbTQWv0jObPr40FZdmEk8rQJpo51S27rjJEq7s1u5yo91a66jyfwmieA8CZFRibKnJtWEcReamWl500ODQoPJ5R'
const stripePromise = loadStripe(publishableKey)

const quickPaymentAmounts = [50, 100, 200]

export interface IPaymentDialogProps {
  businessId: string
}

export default function PaymentDialog({ businessId }: IPaymentDialogProps) {
  const classes = paymentStyles()
  const {
    paymentSuccess,
    paymentDialogOpen,
    closePaymentDialog,
    setPaymentSuccess
  } = usePaymentStore((state) => ({
    paymentSuccess: state.paymentSuccess,
    paymentDialogOpen: state.paymentDialogOpen,
    closePaymentDialog: state.closePaymentDialog,
    setPaymentSuccess: state.setPaymentSuccess
  }))

  const currentBusiness = useInitStore((state) => state.currentBusiness)

  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined
  )
  const [paymentAmount, setPaymentAmount] = useState<number | undefined>(
    undefined
  )

  const [loading, setLoading] = useState(false)

  const [checkoutError, setCheckoutError] = useState<string | undefined>(
    undefined
  )

  const paymentApi = PaymentApi.getInstance()
  const handleCheckout = async () => {
    if (!paymentAmount) return
    setLoading(true)
    try {
      paymentApi
        .createPaymentIntent(businessId, paymentAmount)
        .then((secret) => {
          if (secret === undefined) {
            setCheckoutError('Payment failed, please try again later')
          } else {
            setClientSecret(secret)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (e) {
      console.error('Error creating payment intent')
      setCheckoutError('Payment failed, please try again later')
    }
  }

  const checkAmountFail = (): string => {
    if (paymentAmount === undefined) {
      return 'Please enter payment amount'
    } else if (paymentAmount < 50) {
      return 'Minimum payment amount is $50'
    } else if (paymentAmount > 999999) {
      return 'Maximum payment amount is $999999'
    } else {
      return ''
    }
  }

  const handleCustomerInputAmount = (amount: string) => {
    setPaymentAmount(parseInt(amount))
  }

  const handleClose = (
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeBtnClick'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    setPaymentAmount(undefined)
    setClientSecret(undefined)
    setPaymentSuccess(false)
    setCheckoutError(undefined)
    setLoading(false)
    closePaymentDialog()
  }

  const renderLoading = () => {
    return (
      <div className={classes.loadingCircle}>
        <CircularProgress />
      </div>
    )
  }

  const beforeCheckout = useMemo(() => {
    return Boolean(clientSecret === undefined && !loading && !checkoutError)
  }, [clientSecret, loading, checkoutError])

  const isCheckingOut = useMemo(() => {
    return Boolean(clientSecret !== undefined && !paymentSuccess)
  }, [clientSecret, paymentSuccess])

  const isPaymentSuccess = useMemo(() => {
    return Boolean(paymentSuccess && !loading)
  }, [paymentSuccess, loading])

  return (
    <Dialog
      onClose={(_, reason) => handleClose(reason)}
      keepMounted={false}
      open={paymentDialogOpen}
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle id="payment-dialog-title" className={classes.dialogTitle}>
        Payment amount:{' '}
        <span className={classes.amountToPay}>
          {paymentAmount && !checkAmountFail() ? `$${paymentAmount} USD` : ''}
        </span>
      </DialogTitle>
      <DialogContent dividers>
        {beforeCheckout && (
          <>
            <Typography variant={'subtitle1'} style={{ margin: '1rem' }}>
              Enter payment amount:
            </Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={paymentAmount ? paymentAmount : ''}
                type={'number'}
                error={!!checkAmountFail()}
                onChange={(event) =>
                  handleCustomerInputAmount(event.target.value)
                }
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                labelWidth={60}
              />
              {checkAmountFail() && (
                <FormHelperText error>{checkAmountFail()}</FormHelperText>
              )}
            </FormControl>
            <Typography
              variant={'subtitle1'}
              style={{ margin: '1rem 1rem 0 1rem' }}
            >
              Or select amount:
            </Typography>
            <ButtonGroup fullWidth>
              {quickPaymentAmounts.map((amount) => (
                <Button
                  key={amount}
                  style={{ margin: '1rem' }}
                  variant={'contained'}
                  onClick={() => setPaymentAmount(amount)}
                  color="primary"
                >
                  ${amount}
                </Button>
              ))}
            </ButtonGroup>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.paymentNoteText}
            >
              Minimum $50 payment
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.paymentNoteText}
            >
              $10 monthly subscription fee will be deducted from your account
              balance on the first day of every month
            </Typography>
          </>
        )}
        {loading && renderLoading()}
        {!loading && checkoutError && (
          <Typography variant={'subtitle1'} color={'error'}>
            {checkoutError}
          </Typography>
        )}
        {isCheckingOut && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )}
        {isPaymentSuccess && (
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.paymentSuccessWrapper}
          >
            <Grid item>
              <CheckCircle className={classes.paymentSuccessIcon} />
            </Grid>
            <Grid item>
              <Typography variant={'h5'} color={'primary'}>
                Payment Successful!
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={'subtitle1'}
                className={classes.paymentSuccessSubtitle}
              >
                Thank you for your payment!
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={'subtitle1'}
                className={classes.paymentSuccessSubtitle}
              >
                You have a remaining balance of:{' '}
                <b>${currentBusiness.creditsLeft}</b> usd
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose('closeBtnClick')} color="default">
          {paymentSuccess ? 'Close' : 'Cancel'}
        </Button>
        {!clientSecret && (
          <Button
            onClick={() => handleCheckout()}
            variant={'contained'}
            color="primary"
            disabled={!!checkAmountFail()}
          >
            Checkout
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
