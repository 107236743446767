import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, ItemSummaryField, StringField} from '../DetectedField'
import { PropertySettlementStatementMapper } from './PropertySettlementStatementMapper'


export class PropertySettlementStatement extends BusinessModel {
  constructor(
      modelType: IModelType,
      version: string,
      public readonly purchaserName?: StringField,
      public readonly vendorName?: StringField,
      public readonly settlementDate?: DateField,
      public readonly propertyAddress?: StringField,
      public readonly saleOrPurchasePrice?: ItemSummaryField[],
      public readonly expenseCouncilRates?: ItemSummaryField[],
      public readonly expenseWaterRates?: ItemSummaryField[],
      public readonly expenseElectricityAndGas?: ItemSummaryField[],
      public readonly rentalIncome?: ItemSummaryField[],
      public readonly expenseAgentsCommissions?: ItemSummaryField[],
      public readonly expenseSolicitorFees?: ItemSummaryField[],
      public readonly expenseStationeryPhoneAndPostage?: ItemSummaryField[],
      public readonly expenseTitleFees?: ItemSummaryField[],
      public readonly saleRelatedAgentFee?: ItemSummaryField[],
      public readonly depositPaid?: ItemSummaryField[],
      public readonly expenseLandTax?: ItemSummaryField[],
      public readonly searchFees?: ItemSummaryField[],
      public readonly settlementFees?: ItemSummaryField[],
      public readonly GSTExpense?: ItemSummaryField[],
      public readonly transferDuty?: ItemSummaryField[],
      public readonly deductibleExpense?: ItemSummaryField[],
      public readonly registrationFees?: ItemSummaryField[],
      public readonly fundsByLoan?: ItemSummaryField[],
      public readonly fundsByPerson?: ItemSummaryField[],
      public readonly certificateFees?: ItemSummaryField[],
      public readonly expenseStrataLevyFees?: ItemSummaryField[],
      public readonly penaltyInterest?: ItemSummaryField[],
      public readonly  mortgageReleaseFees?: ItemSummaryField[],
      public readonly stampDuty?: ItemSummaryField[],
      public readonly expensePestControl?: ItemSummaryField[],
      public readonly vendorFees?: ItemSummaryField[],
      public readonly residualMoney?: ItemSummaryField[],
  ){
    super(modelType, version)
  }

  toJson(): IBusinessModel {
    return PropertySettlementStatementMapper.toJson(this)
  }
}
