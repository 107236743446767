import { OcrDocument } from '@bgl/textract-business-model-editor'
import SmartDocPDFViewer, { IOverlay, pdfjs } from '@bgl/textract-doc-viewer'
import autobind from 'autobind-decorator'
import { List, OrderedMap } from 'immutable'
import memoize from 'memoize-one'
import React from 'react'

import { adapterFromDocument } from './IOverlayPageTransform'
import { wordsOverlay } from './OverlayFactory'

interface WordLevelDocViewerProps {
  pdfBlob: Blob
  fileName: string
  document: OcrDocument
  onDocumentReady?: (pdf: pdfjs.PDFDocumentProxy) => void
  onSelecting?: (selectingItems: IOverlay[]) => void
  onSelectionFinish?: (selectedItems: IOverlay[]) => void
  annotationMap: OrderedMap<string, List<string>>
  onPasswordCancel?: () => void
  disableDownload?: boolean
  disableSelection?: boolean
}

@autobind
export default class WordLevelDocViewer extends React.Component<WordLevelDocViewerProps> {
  private pdfViewer: SmartDocPDFViewer | null = null
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
  private readonly buildOverlay = memoize((document: OcrDocument) =>
    wordsOverlay(document, this.props.annotationMap)
  )

  constructor(props: WordLevelDocViewerProps) {
    super(props)
  }

  clearSelection() {
    if (this.pdfViewer) {
      this.pdfViewer.clearSelection()
    }
  }

  scrollIntoView(overlayId?: string) {
    if (this.pdfViewer) {
      this.pdfViewer.scrollIntoView(overlayId)
    }
  }

  selectOverlays(overlayIds: Set<string>) {
    if (this.pdfViewer) {
      this.pdfViewer.selectOverlays(overlayIds)
    }
  }

  render() {
    const overlays = this.buildOverlay(this.props.document)
    return (
      <SmartDocPDFViewer
        ref={(ref) => {
          this.pdfViewer = ref
        }}
        pdfBlob={this.props.pdfBlob}
        fileName={this.props.fileName}
        overlays={overlays}
        pageTransforms={adapterFromDocument(this.props.document)}
        onDocumentReady={this.props.onDocumentReady}
        onSelecting={this.props.onSelecting}
        onSelectionFinish={this.props.onSelectionFinish}
        viewMode="wordTagging"
        onPasswordCancel={this.props.onPasswordCancel}
        disableDownload={this.props.disableDownload}
        disableSelection={this.props.disableSelection}
      />
    )
  }
}
