import {
  faHeadSideGear,
  faInfinity,
  faLockKeyhole,
  faMasksTheater,
  faPenRuler,
  faWebhook
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function RedactionKeyFeatures() {
  const iconColor = '#810ed3'
  return (
    <section className="redactionKeFeatures">
      <div className="container">
        <div>
          <h1>Key Features</h1>
        </div>
        <div className="featuresWrapper">
          <div className="feature">
            <FontAwesomeIcon
              icon={faHeadSideGear}
              size={'4x'}
              color={iconColor}
            />
            <h3>AI-based Redaction</h3>
            <p>
              Machine learning algorithms are utilised to not only identify
              uniform patterns, but also to deal with unstructured documents and
              custom requirements.
            </p>
          </div>

          <div className="feature">
            <FontAwesomeIcon
              icon={faMasksTheater}
              size={'4x'}
              color={iconColor}
            />
            <h3>Pseudonymisation Option</h3>
            <p>
              Take advantage of our pseudonymisation option to make the document
              less identifiable while reserving your ability to comprehend
              contexts and process further analytics.
            </p>
          </div>

          <div className="feature">
            <FontAwesomeIcon
              icon={faLockKeyhole}
              size={'4x'}
              color={iconColor}
            />
            <h3>Maximum Security</h3>
            <p>
              SmartDocs AI Studio removes all traces of redacted data and this
              non-reversible methodology ensuress your compliance with
              regulations(e.g. GDPR) and privacy policies.
            </p>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faPenRuler} size={'4x'} color={iconColor} />
            <h3>Custom Training</h3>
            <p>
              Customised training is available upon request. Contact us if the
              data types to be redacted are not supported by our pre-trained
              matching learning model yet.
            </p>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faWebhook} size={'4x'} color={iconColor} />
            <h3>API Integration</h3>
            <p>
              Integrate with your products or services to enjoy our advanced
              solution on your own platform or to automate your workflow.
            </p>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faInfinity} size={'4x'} color={iconColor} />
            <h3>Infinite Scalability</h3>
            <p>
              SmartDocs AI Studio scales up or down instantaneously based on
              your needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RedactionKeyFeatures
