import { IDataType, IModelKeyValue, TransactionType } from 'domain/types/ISmartDocsResult'
import {List} from 'immutable'
import * as Immutable from 'immutable'
import * as _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import {compact} from '../../utils/utils'
import {Line} from '../ocr/Line'
import {Word} from '../ocr/Word'

export abstract class DetectedField {
  constructor(
    readonly id: string,
    readonly parsedValue: any,
    readonly wordIds: string[],
    readonly words: List<Word>,
    readonly modifiedBy: string | undefined,
    readonly probability?: number
  ) {
  }

  canUpdateValue(value: any): boolean {
    return !!value
  }

  static detectedFieldFromObject(obj: Object): List<DetectedField> {
    const fieldsDict = _.mapKeys(obj, (value, key) => {
      if (value instanceof DetectedField) {
        return value as DetectedField
      } else {
        return undefined
      }
    })
    const fields = Immutable.Map(fieldsDict).toList()
    // @ts-ignore
    return compact(fields)
  }

  abstract updateValue(value: any, modifiedBy: string): DetectedField

  sameKey(other: DetectedField): boolean {
    return this.id === other.id
  }

  toMergedLines(): List<Line> {
    return Word.mergeToLineBasedOnPage(this.words)
  }

  page(): number | undefined {
    return this.words.first()?.page
  }

  toString(): string {
    return ''
  }

  toString2(): string {
    return this.toString2()
  }

  test(tester: string | number | moment.Moment | any[] | undefined, isAsc: boolean = true): boolean | undefined {
    return undefined
  }

  abstract toModelKeyValue(): IModelKeyValue
}

export class StringField extends DetectedField {
  constructor(
    readonly id: string,
    readonly parsedValue: string,
    readonly wordIds: string[],
    readonly words: List<Word>,
    readonly modifiedBy: string | undefined = undefined,
    readonly probability?: number
  ) {
    super(id, parsedValue, wordIds, words, modifiedBy, probability)
  }

  canUpdateValue(value: any): boolean {
    return !!value
  }

  toString(): string {
    return this.parsedValue
  }

  test(tester: string | number | moment.Moment | any[] | undefined): boolean | undefined {
    if (_.isString(tester)) return tester === this.parsedValue
    if (_.isNumber(tester)) return tester.toString() === this.parsedValue
    return undefined
  }

  updateValue(value: string, modifiedBy: string): StringField {
    return new StringField(
      this.id, value, this.wordIds, this.words, modifiedBy, this.probability
    )
  }

  toModelKeyValue(): IModelKeyValue {
    return {
      DataType: IDataType.String,
      WordIds: this.wordIds,
      Id: this.id,
      ParsedValue: this.parsedValue,
      Probability: this.probability
    }
  }
}

export class DateField extends DetectedField {
  constructor(
    readonly id: string,
    readonly parsedValue: moment.Moment,
    readonly wordIds: string[],
    readonly words: List<Word> = List(),
    readonly modifiedBy: string | undefined = undefined,
    readonly probability?: number
  ) {
    super(id, parsedValue, wordIds, words, modifiedBy, probability)
  }

  canUpdateValue(value: any): boolean {
    return !!value && moment.isMoment(value) && value.isValid()
  }

  toString(): string {
    return this.parsedValue.format('DD/MM/YYYY')
  }

  test(tester: string | number | moment.Moment | any[] | undefined, isAsc: boolean = true): boolean | undefined {
    const testFn = (t: moment.Moment) => isAsc
      ? t.isSameOrBefore(this.parsedValue, 'date')
      : t.isSameOrAfter(this.parsedValue, 'date')
    if (_.isString(tester)) return testFn(moment(tester))
    if (moment.isMoment(tester)) return testFn(tester)
    return undefined
  }

  updateValue(value: moment.Moment, modifiedBy: string): DateField {
    return new DateField(
      this.id, value, this.wordIds, this.words, modifiedBy, this.probability
    )
  }

  toModelKeyValue(): IModelKeyValue {
    return {
      DataType: IDataType.Date,
      WordIds: this.wordIds,
      Id: this.id,
      ParsedValue: this.parsedValue.format('YYYY-MM-DD')
    }
  }
}

export class DollarField extends DetectedField {
  constructor(
    readonly id: string,
    readonly parsedValue: number,
    readonly wordIds: string[],
    readonly words: List<Word> = List(),
    readonly modifiedBy: string | undefined = undefined,
    readonly probability?: number
  ) {
    super(id, parsedValue, wordIds, words, modifiedBy, probability)
  }

  canUpdateValue(value: any): boolean {
    return !!value || value === 0
  }
  
  copy({
         id = this.id,
         parsedValue = this.parsedValue,
         wordIds = this.wordIds,
         words = this.words,
         modifiedBy = this.modifiedBy,
         probability = this.probability
       }): DollarField {
    return new DollarField(
      id, parsedValue, wordIds, words, modifiedBy, probability
    )
  }

  updateValue(value: number, modifiedBy: string): DollarField {
    return this.copy({
      parsedValue: value,
      modifiedBy
    })
  }

  toString(): string {
    return numeral(this.parsedValue).format('$0,0.00')
  }

  toString2(): string {
    return this.parsedValue > 0
      ? numeral(this.parsedValue).format('$0,0.00') + ' DR'
      : numeral(-this.parsedValue).format('$0,0.00') + ' CR'
  }

  test(tester: string | number | moment.Moment | any[] | undefined): boolean | undefined {
    if (_.isNumber(tester)) return Math.abs(tester) === Math.abs(this.parsedValue)
    if (_.isString(tester)) return Math.abs(parseFloat(tester)) === Math.abs(this.parsedValue)
    if (_.isArray(tester)) return tester.length > 0 ? tester.findIndex(t => this.test(t) === true) > -1 : undefined
    return undefined
  }

  toModelKeyValue(): IModelKeyValue {
    return {
      DataType: IDataType.Dollar,
      WordIds: this.wordIds,
      Id: this.id,
      ParsedValue: this.parsedValue
    }
  }
}

export class DoubleField extends DetectedField {
  constructor(
    readonly id: string,
    readonly parsedValue: number,
    readonly wordIds: string[],
    readonly words: List<Word> = List(),
    readonly modifiedBy: string | undefined = undefined,
    readonly probability?: number
  ) {
    super(id, parsedValue, wordIds, words, modifiedBy, probability)
  }

  canUpdateValue(value: any): boolean {
    return !!value || value === 0
  }

  copy({
         id = this.id,
         parsedValue = this.parsedValue,
         wordIds = this.wordIds,
         words = this.words,
         modifiedBy = this.modifiedBy,
         probability = this.probability
       }): DoubleField {
    return new DoubleField(
      id, parsedValue, wordIds, words, modifiedBy, probability
    )
  }

  updateValue(value: number, modifiedBy: string): DoubleField {
    return this.copy({
      parsedValue: value,
      modifiedBy
    })
  }

  toString(): string {
    return numeral(this.parsedValue).format('0,0.00')
  }

  toString2(): string {
    return this.toString()
  }

  test(tester: string | number | moment.Moment | any[] | undefined): boolean | undefined {
    if (_.isNumber(tester)) return Math.abs(tester) === Math.abs(this.parsedValue)
    if (_.isString(tester)) return Math.abs(parseFloat(tester)) === Math.abs(this.parsedValue)
    if (_.isArray(tester)) return tester.length > 0 ? tester.findIndex(t => this.test(t) === true) > -1 : undefined
    return undefined
  }

  toModelKeyValue(): IModelKeyValue {
    return {
      DataType: IDataType.Number,
      WordIds: this.wordIds,
      Id: this.id,
      ParsedValue: this.parsedValue
    }
  }
}

export class ItemSummaryField {
  Description?: StringField
  Amount?: DollarField
  TransactionType: TransactionType

  constructor(
    TransactionType: TransactionType,
    Description?: StringField,
    Amount?: DollarField,
    ) {
    this.Description = Description
    this.Amount = Amount
    this.TransactionType = TransactionType
  }

}


/**
 * Amounts returned in RentalSummaryModel
 * MoneyIn and MoneyOut are mutually exclusive
 */

export class RentalField {
  /**
   * GstAmount figures from doc, currently ignored by docs-ui
   */
  GstAmount?: DollarField
  MoneyIn?: DollarField
  MoneyOut?: DollarField

  constructor(GstAmount?: DollarField, MoneyIn?: DollarField, MoneyOut?: DollarField) {
    this.GstAmount = GstAmount
    this.MoneyIn = MoneyIn
    this.MoneyOut = MoneyOut
  }

  getAmount = () => {
    return this.MoneyIn?.parsedValue || -(this.MoneyOut?.parsedValue || 0)
  }
  getGst = () => {
    return this.GstAmount?.parsedValue
  }
}


export class DetectedFields {
  modelBlocks: List<DetectedField>

  constructor(modelBlocks: List<DetectedField> = List()) {
    this.modelBlocks = modelBlocks
  }
}
