import { ITransactionTableDataRow } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import * as _ from 'lodash'
import moment from 'moment'
import { uuid } from 'utils/utils'
import {DateField, DetectedField, DollarField, StringField} from '../DetectedField'
import IItemObject from '../IItemObject'
import ModelObject from '../ModelObject'
import {FormGridRowValue} from "../../../utils/DataTypeMapper";


export type TransactionColumn =
  FieldAndColumnName.TransactionTable_OpeningBalance
  | FieldAndColumnName.TransactionTable_TotalCredit
  | FieldAndColumnName.TransactionTable_Date
  | FieldAndColumnName.TransactionTable_TotalDebit
  | FieldAndColumnName.TransactionTable_Description

export const TRANSACTION_COLUMN_COUNT = 5

export class Transaction extends ModelObject implements IItemObject {
  readonly amount: DollarField

  static generate(
    date: moment.Moment,
    description?: string,
    debit?: number,
    credit?: number,
    balance?: number
  ) {
    const dateField = new DateField(uuid(), date, [])
    const descriptionField = description ? new StringField(uuid(), description, [], List()) : undefined    
    const debitField = typeof debit === "number" ? new DollarField(uuid(), debit, []) : undefined    
    const creditField = typeof credit === "number" ? new DollarField(uuid(), credit, []) : undefined
    const balanceField = typeof balance === "number" ? new DollarField(uuid(), balance, []) : undefined

    return new Transaction(dateField, descriptionField, debitField, creditField, balanceField)
  }

  static isEqual(left: Transaction, right: Transaction): boolean {
    return left.date.id === right.date.id &&
      left.description?.id === right.description?.id &&
      left.debit?.id === right.debit?.id &&
      left.credit?.id === right.credit?.id &&
      left.balance?.id === right.balance?.id
  }

  constructor(
    readonly date: DateField,
    readonly description?: StringField,
    readonly debit?: DollarField,
    readonly credit?: DollarField,
    readonly balance?: DollarField
  ) {
    super()
    const amountOption = Transaction.amountFromDebitCredit(debit, credit)
    if (amountOption) {
      this.amount = amountOption
    } else {
      throw new Error(`A transaction have to have either debit or credit but got ${debit} and ${credit}`)
    }
  }

  addColumn(
    gridColumnName: FieldAndColumnName,
    newValue: FormGridRowValue,
    modifiedBy: string
  ): Transaction {
    switch(gridColumnName) {
      case FieldAndColumnName.TransactionTable_Date:
        return this.copy({ date: new DateField(uuid(), newValue as moment.Moment, [], List(), modifiedBy)})

      case FieldAndColumnName.TransactionTable_Description:
        return this.copy({ description: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.TransactionTable_OpeningBalance:
        return this.copy({ balance: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.TransactionTable_TotalCredit:
        return this.copy({ credit: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.TransactionTable_TotalDebit:
        return this.copy({ debit: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      default:
        return this
    }
  }

  deleteColumn(gridColumn: FieldAndColumnName): Transaction {
    return this.copy({ [gridColumn]: null })
  }

  static amountFromDebitCredit(debit?: DollarField, credit?: DollarField): DollarField | undefined {
    if (debit && credit) {
      return undefined
    } else if (debit) {
      return debit
    } else if (credit) {
      return credit.copy({parsedValue: 0 - credit.parsedValue})
    } else {
      return undefined
    }
  }

  fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
    switch (column) {
      case FieldAndColumnName.TransactionTable_Date:
        return this.date
      case FieldAndColumnName.TransactionTable_TotalCredit:
        return this.credit
      case FieldAndColumnName.TransactionTable_TotalDebit:
        return this.debit
      case FieldAndColumnName.TransactionTable_Description:
        return this.description
      case FieldAndColumnName.TransactionTable_OpeningBalance:
        return this.balance
      default:
        return undefined
    }
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }

  // toGridRow(id: number): GridRowData {
  //   return {
  //     id,
  //     [FieldAndColumnName.TransactionTable_Date]: this.date?.parsedValue?.format("DD/MM/YYYY"),
  //     [FieldAndColumnName.TransactionTable_Description]: this.description?.parsedValue,
  //     [FieldAndColumnName.TransactionTable_TotalDebit]: this._debit()?.parsedValue,
  //     [FieldAndColumnName.TransactionTable_TotalCredit]: this._credit()?.parsedValue,
  //     [FieldAndColumnName.TransactionTable_OpeningBalance]: this.balance?.parsedValue
  //   }
  // }

  _debit(): DollarField | undefined {
    if (this.amount?.parsedValue && this.amount.parsedValue > 0) {
      return this.amount
    } else {
      return undefined
    }
  }

  _credit(): DollarField | undefined {
    if (this._debit()) {
      return undefined
    } else {
      return this.amount
    }
  }

  copy({
    date = this.date,
    description = this.description,
    debit = this.debit,
    credit = this.credit,
    balance = this.balance
  }): Transaction {
    return new Transaction(date, description, debit, credit, balance)
  }

  updateBalance(balanceValue: number, modifiedBy: string): Transaction {
    if (this.balance) {
      return this.copy({ balance: this.balance?.updateValue(balanceValue, modifiedBy) })
    }
    return this.copy({ balance: new DollarField(uuid(), balanceValue, []) })
  }
  //
  // setDate(cell: IModelKeyValue): Transaction {
  //   return this.copy({ date: new DateField(moment(cell.ParsedValue as string), cell.WordIds, cell.Id) })
  // }
  //
  // setDescription(cell: IModelKeyValue): Transaction {
  //   return this.copy({ description: new StringField(cell.ParsedValue as string, cell.WordIds, cell.Id) })
  // }
  //
  // setDebit(cell: IModelKeyValue): Transaction {
  //   return this.copy({ amount: new DollarField(cell.ParsedValue as number, cell.WordIds, cell.Id) })
  // }
  //
  // setCredit(cell: IModelKeyValue): Transaction {
  //   return this.copy({ amount: new DollarField(-cell.ParsedValue as number, cell.WordIds, cell.Id) })
  // }
  //
  // setBalance(cell: IModelKeyValue): Transaction {
  //   return this.copy({ balance: new DollarField(cell.ParsedValue as number, cell.WordIds, cell.Id) })
  // }

  blockIds(): List<string> {
    return List(_.compact([this.date?.id, this.description?.id,
      this.amount?.id, this.balance?.id]))
  }

  toJson(): ITransactionTableDataRow {
    return {
      TransactionDate: this.date.toModelKeyValue(),
      Description: this.description?.toModelKeyValue(),
      Credit: this.credit?.toModelKeyValue(),
      Debit: this.debit?.toModelKeyValue(),
      Balance: this.balance?.toModelKeyValue()
    }
  }
}

