/* eslint-env browser */
import { Button } from '@material-ui/core'
import React from 'react'

import redactionHeroImg from '../../../assets/images/redaction/redaction-mock.png'

function Hero() {
  return (
    <section className="hero-s3 hero">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-8 hero-s3__content">
            <h2 className="content-sh">SmartDocs Redaction</h2>
            <h1 className="content-h">Faster, Smarter, Simpler</h1>
            <p className="content-d">
              Leverage our AI-powered solution to discover and anonymise your
              personal, sensitive or confidential information swiftly and
              accurately.
            </p>
            <p className="content-d">
              Sanitise your documents with SmartDocs Redaction to minimise the
              needs of time-consuming and costly manual process.
            </p>
            <div className="prHeroBtnWrapper">
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => {
                  window.location.href = '/#/contact-us'
                }}
              >
                Request Demo
              </Button>
              <Button
                variant={'contained'}
                color={'default'}
                onClick={() => {
                  window.location.href = '/#/sign-up'
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 hero-s3__thumb d-lg-none">
            <img src={redactionHeroImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
