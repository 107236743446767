import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, MenuItem, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField/TextField'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { PrimaryButton } from '../../assets/StyledButton'
import { FlexColPaper, SmContainer } from '../../assets/StyledContainer'
import { trackEvent } from '../../services/GoogleAnalytics'
import { useInitStore } from '../../services/stores/InitStore'
import TaggingApi from '../../services/TaggingApi'
import {
  emailHelpText,
  isEmailValid,
  isNameValid,
  nameHelpText
} from '../amplify/Verification'
import CallToActionWrapper from '../callToAction/CallToActionWrapper'

interface IContactUsProps {
  msg?: string
  defaultSubject?: SUBJECT
  internalEmail?: boolean
}

export enum SUBJECT {
  GENERAL_ENQUIRY = 'General Enquiry',
  REQUEST_A_DEMO = 'Request a Demo',
  NEW_FEATURE_REQUEST = 'New Feature Request',
  REPORT_AN_ISSUE = 'Report An Issue',
  FEEDBACK_OR_COMPLAINT = 'Feedback or Complaint'
}

const Subjects: SUBJECT[] = [
  SUBJECT.GENERAL_ENQUIRY,
  SUBJECT.REQUEST_A_DEMO,
  SUBJECT.NEW_FEATURE_REQUEST,
  SUBJECT.REPORT_AN_ISSUE,
  SUBJECT.FEEDBACK_OR_COMPLAINT
]

const SubjectsToLogEventNames = (subject: SUBJECT) => {
  switch (subject) {
    case SUBJECT.GENERAL_ENQUIRY:
      return 'GeneralEnquiry'
    case SUBJECT.REQUEST_A_DEMO:
      return 'RequestDemo'
    case SUBJECT.NEW_FEATURE_REQUEST:
      return 'NewFeatureRequest'
    case SUBJECT.REPORT_AN_ISSUE:
      return 'ReportIssue'
    case SUBJECT.FEEDBACK_OR_COMPLAINT:
      return 'FeedbackOrComplaint'
  }
}

const taggingApi = TaggingApi.getInstance()

const ContactUs = ({ msg, defaultSubject, internalEmail }: IContactUsProps) => {
  const user = useInitStore((state) => state.authorizations?.userFullName)
  const email = useInitStore((state) => state.authorizations?.username)
  const business = useInitStore((state) => state.currentBusiness)
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const checkSubject = (subjectStr: string | null): SUBJECT => {
    for (const subject of Object.values(SUBJECT)) {
      if (subject === subjectStr) {
        return subject
      }
    }
    return defaultSubject ? defaultSubject : SUBJECT.REQUEST_A_DEMO
  }
  const presetSubject = checkSubject(
    decodeURIComponent(checkSubject(urlSearchParams.get('subject')) ?? '')
  )
  const presetName =
    user ?? decodeURIComponent(urlSearchParams.get('name') ?? '')
  const presetEmail =
    user ?? decodeURIComponent(urlSearchParams.get('email') ?? '')
  const presetMessage = msg ?? ''
  const [subject, setSubject] = useState<SUBJECT>(presetSubject)
  const [userName, setUserName] = useState(presetName || undefined)
  const [userEmail, setUserEmail] = useState(presetEmail || undefined)
  const [message, setMessage] = useState<string>(presetMessage)
  const [sending, setSending] = useState<boolean>()
  const [isVerified, setIsVerified] = useState<boolean>(Boolean(user))

  useEffect(() => {
    if (user) {
      setIsVerified(Boolean(user))
      setUserName(user)
    }
    if (email) {
      setUserEmail(email)
    }
  }, [user, email])
  const canSubmit = () =>
    sending !== true &&
    isNameValid(userName) &&
    isEmailValid(userEmail) &&
    _.trim(message ?? '') !== ''

  const onSubmit = () => {
    if (userName && userEmail && canSubmit()) {
      setSending(true)
      const businessName =
        business?.name ?? (isVerified ? 'RegisteredUser_' : '')
      taggingApi
        .createTicket(userName, userEmail, subject, message, businessName)
        .then(() => {
          trackEvent({
            category: 'ContactUs',
            action: `${SubjectsToLogEventNames(subject)}`,
            label: ``
          })
          setSending(false)
          setTimeout(() => {
            setSubject(SUBJECT.REQUEST_A_DEMO)
            setMessage('')
            setSending(undefined)
          }, 5000)
        })
        .catch((err) => {
          trackEvent({
            category: 'ContactUsError',
            action: `${SubjectsToLogEventNames(subject)}`,
            label: `${err}`
          })
        })
    } else {
      setUserName(userName ?? '')
      setUserEmail(userEmail ?? '')
    }
  }

  const renderForm = () => {
    return (
      <FlexColPaper elevation={0}>
        <Typography component="h1" variant="h5">
          Contact Us
        </Typography>
        <SmContainer>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Full Name"
                className="textFiled"
                value={userName ?? ''}
                disabled={isVerified}
                required
                error={!isNameValid(userName)}
                helperText={nameHelpText(userName)}
                onChange={(event) =>
                  setUserName((event.target.value || '').slice(0, 200))
                }
                onBlur={() => setTimeout(() => setUserName(userName ?? ''), 1)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                type="email"
                className="textFiled"
                value={userEmail ?? ''}
                disabled={isVerified}
                required
                error={!isEmailValid(userEmail)}
                helperText={emailHelpText(userEmail)}
                onChange={(event) => setUserEmail(event.target.value || '')}
                onBlur={() =>
                  setTimeout(() => setUserEmail(userEmail ?? ''), 1)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                select
                label="Subject"
                className="textFiled"
                value={subject}
                onChange={(event) => setSubject(event.target.value as SUBJECT)}
              >
                {Subjects.map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="textFiled"
                variant="outlined"
                label="Message"
                multiline
                fullWidth
                minRows={5}
                required
                value={message ?? ''}
                onChange={(event) => setMessage(event.target.value || '')}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={onSubmit}
                disabled={!canSubmit()}
              >
                {sending ? (
                  'Sending'
                ) : sending === undefined ? (
                  'Send Message'
                ) : (
                  <div>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ marginRight: '8px' }}
                    />
                    Sent
                  </div>
                )}
              </PrimaryButton>
            </Grid>
          </Grid>
        </SmContainer>
      </FlexColPaper>
    )
  }

  return !internalEmail ? (
    <CallToActionWrapper childComponent={renderForm()} />
  ) : (
    renderForm()
  )
}

export default ContactUs
