import { FieldAndColumnName } from '@bgl/textract-business-model-editor'
// eslint-disable-next-line max-len
import { PayGInstalmentYearlyTableRow } from '@bgl/textract-business-model-editor/dist/domain/businessModel/atoPaygInstalmentReport/PayGInstalmentYearlyTableRow'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class PayGInstalmentTableData {
  static toRows(
    payGInstalments: List<PayGInstalmentYearlyTableRow>
  ): GridRowsProp {
    return payGInstalments
      .map((payGInstalment, index) =>
        PayGInstalmentTableData.toGridRow(payGInstalment, index)
      )
      .toArray()
  }

  static toGridRow = (
    payGInstalment: PayGInstalmentYearlyTableRow,
    id: number
  ) => {
    return {
      id,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber]:
        payGInstalment.tfnNumber?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName]:
        payGInstalment.clientName?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1]:
        payGInstalment.quarter1?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2]:
        payGInstalment.quarter2?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3]:
        payGInstalment.quarter3?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4]:
        payGInstalment.quarter4?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment]:
        payGInstalment.totalInstalment?.parsedValue
    }
  }
}
