import React, {MouseEvent, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {v4 as uuidv4} from 'uuid'
import Canvas from '../drawer/Canvas'
import {shapeFactory} from '../drawer/shapeFactory'
import {Dot} from '../models/Dot'
import {Rect} from '../models/Rect'
import {BaseShape} from '../models/Shape'
import {CreateType, DrawStyle, Point} from '../models/Types'
import Toolbar from './Toolbar'

const CanvasContainer = styled.div`
	min-width: 500px;
	width: 100%;
	height: calc(100% - 2rem);
	display: flex;
	flex-direction: column;
`

export interface AnnotatorOptions {
	defaultDrawStyle: DrawStyle,
	defaultLabelName?: string
}

interface IAnnotatorProps {
	imageSrc: string,
	shapes: BaseShape[]
	setSelectedShape: (shape: BaseShape | null) => void,
	onShapeDeleted: (shape: BaseShape) => void,
	onShapeUpdated: (shape: BaseShape) => void,
	onShapeCreated: (shape: BaseShape) => void,
	defaultCreateType: CreateType
	hideLabels?: string[]
	options?: AnnotatorOptions
}

export default function Annotator({ imageSrc, shapes, setSelectedShape, defaultCreateType, onShapeDeleted, onShapeUpdated, onShapeCreated }: IAnnotatorProps) {

	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const canvasContainer = useRef<HTMLDivElement | null>(null)
	const [createType, setCreateType] = useState<CreateType>(defaultCreateType)
	const [canvas, setCanvas] = useState<Canvas | null>(null)

	const handleMouseMove = (e: MouseEvent) => {
		const mousePoint: Point = [e.nativeEvent.offsetX, e.nativeEvent.offsetY]
		if(canvas?.activeShape){
			const percentagePoint = canvas.convertAbsoluteToPercentage(mousePoint)
			const activeShape = canvas.activeShape
			if(activeShape.shapeType === CreateType.Rect) {
				const rect = activeShape as Rect
				switch (e.buttons){
					case	1: {
						if(rect.isCreating){
							rect.create(percentagePoint)
						}
						if(rect.isResizing){
							rect.resize(percentagePoint)
							rect.isShapeUpdated = true
						}
						if(rect.isDragging){
							rect.drag(percentagePoint)
							rect.isShapeUpdated = true
						}
						break
					}
				}
			} else if (activeShape.shapeType === CreateType.Dot) {
				const dot = activeShape as Dot
				switch (e.buttons){
					case	1: {
						if(dot.isDragging){
							dot.drag(percentagePoint)
							dot.isShapeUpdated = true
						}
						break
					}
				}
			}
		}
		if(canvas){
			canvas.draw()
		}
	}

	const handleMouseWheel = (e: React.WheelEvent<HTMLCanvasElement>) => {
		e.stopPropagation()
		if(canvas && e.altKey){
			const mousePoint: Point = [e.nativeEvent.offsetX, e.nativeEvent.offsetY]
			e.deltaY > 0 ? canvas.zoom('in', mousePoint) : canvas.zoom('out', mousePoint)
		}
	}


	const handleMouseDown = (e: MouseEvent) => {
		if(canvas){
			const mousePoint: Point = [e.nativeEvent.offsetX, e.nativeEvent.offsetY]
			const percentageMousePoint = canvas.convertAbsoluteToPercentage(mousePoint)
			switch(e.buttons){
				case 1: {
					const mouseOverType = canvas.getMouseOver(percentageMousePoint)
					if(mouseOverType === 'NoShape'){
						// Mouse clicked on empty space - create shape
						const coordinates = [percentageMousePoint, percentageMousePoint]
						const defaultDrawStyle = {
							fillStyle: 'rgba(0, 0, 0, 0.5)',
							strokeStyle: 'rgb(0, 0, 0)',
							lineWidth: 1
						}
						const newShape = shapeFactory(createType, uuidv4(), shapes.length, coordinates, defaultDrawStyle)
						newShape.isCreating = true
						newShape.isActive = true
						canvas.deselectActiveShape()
						canvas.shapes.push(newShape)
					}

					if(mouseOverType === 'CtrlPoint' && canvas.activeShape?.shapeType === CreateType.Rect){
						// Mouse clicked on active shape ctrl point - resize shape
						const activeRect = canvas.activeShape as Rect
						activeRect.isResizing = true
					}

					if(mouseOverType === 'ActiveShape'){
						// Mouse clicked on active shape [not on ctrl point] - drag shape
						if(canvas.activeShape){
							const activeRect = canvas.activeShape
							activeRect.isDragging = true
							activeRect.setDragRebuildReference(percentageMousePoint)
						}
					}

					if(mouseOverType === 'Shape'){
						// Mouse clicked on shape - select shape [change this shape to active]
						const clickedShape = canvas.findShapeMouseOver(percentageMousePoint)
						if(clickedShape){
							const activeShape = clickedShape
							canvas.shapes.forEach(shape => shape.isActive = false)
							activeShape.isActive = true
							activeShape.isDragging = true
							activeShape.setDragRebuildReference(percentageMousePoint)
						}
					}
				}
			}
			canvas.draw(mousePoint)
		}
	}

	const handleMouseUp = () => {
		if(canvas?.activeShape){
			const activeShape = canvas.activeShape
			if(activeShape.isCreating){
				if(activeShape.isValidShape()){
					activeShape.sortCoordinates()
					activeShape.isCreating = false
					canvas.draw()
					onShapeCreated(activeShape)
					setSelectedShape(activeShape)
				} else {
					setSelectedShape(null)
					canvas.removeShape(activeShape)
				}
			}

			if(activeShape.isDragging){
				// update shape coordinates if shape is dragged
				if(activeShape.isShapeUpdated){
					activeShape.isDragging = false
					canvas.draw()
					onShapeUpdated(activeShape)
				// else set active shape on click-on shape
				} else {
					activeShape.isDragging = false
					canvas.draw()
					setSelectedShape(activeShape)
				}
			}

			if(activeShape.shapeType === CreateType.Rect){
				const rect = activeShape as Rect
				if(rect.isResizing && rect.isShapeUpdated){
					rect.sortCoordinates()
					rect.isResizing = false
					canvas.draw()
					onShapeUpdated(activeShape)
				}

			}
		}
	}

	const handleKeyUp = (e: KeyboardEvent) => {
		if(e.key === 'Escape' && canvas){
			canvas.deselectActiveShape()
			setSelectedShape(null)
		}

		if((e.key === 'Delete' || e.key === 'Backspace') && canvas){
			if(canvas.activeShape) {
				onShapeDeleted(canvas.activeShape)
				setSelectedShape(null)
			}
		}
	}

	const handleWindowResize = () => {
		if(canvasContainer.current && canvas){
			canvas.setNewCanvasDimensionOnResize(canvasContainer.current.clientWidth, canvasContainer.current.clientHeight)
			canvas.draw()
		}
	}

	useLayoutEffect(() => {
		if(canvas){
			canvas.shapes = shapes
			canvas.draw()
		}
		document.body.addEventListener('keyup', handleKeyUp)
		return () => {
			document.body.removeEventListener('keyup', handleKeyUp)
		}
	}, [shapes, canvas])


	useLayoutEffect(() => {
		window.addEventListener('resize', handleWindowResize)
		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [canvas])

	useLayoutEffect(() => {
		const image = new Image()
		image.src = imageSrc
		image.addEventListener('load', () => {
			if(canvasRef.current && canvasContainer.current){
				const newCanvasDrawer = new Canvas(canvasRef.current, canvasContainer.current, image)
				newCanvasDrawer.init()
				setCanvas(newCanvasDrawer)
			}
		})
	}, [imageSrc])

	return (
		<div style={{ height: '100%' }}>
			<Toolbar
				createType={createType}
				setCreateType={setCreateType}
				canvasDrawer={canvas}
			/>
			<CanvasContainer ref={canvasContainer}>
				<div style={{ position: 'relative' }}>
					<canvas
						style={{ cursor: 'crosshair', position: 'absolute', top: 0, left: 0 }}
						ref={canvasRef}
						onFocus={() => console.log('focus')}
						onContextMenu = {(e) => e.preventDefault()}
						onMouseOver = {() => {}}
						onMouseLeave = {() => {
							shapes.forEach((shape) => {
								if(shape.isCreating) shape.isCreating = false
							})
						}}
						onMouseMove = {handleMouseMove}
						onMouseUp = {handleMouseUp}
						onMouseDown = {handleMouseDown}
						onWheel={handleMouseWheel}
					></canvas>
				</div>
			</CanvasContainer>
		</div>
	)
}