import React from 'react'

import DataBreachImg from '../../../assets/images/redaction/data_breach.svg'

const CircleWithSign = () => (
  <div
    style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: '#eb4b4b',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#761bcb',
      fontSize: '15px',
      paddingTop: '2px',
      marginTop: '0.25rem'
    }}
  >
    !
  </div>
)

function RedactionBriSection() {
  return (
    <section className="redactionDataBrief">
      <div className="container">
        <div className="row">
          <div className="col-xxl-5 col-xl-5 col-lg-5 redactionDataBrImgWrapper">
            <img src={DataBreachImg} alt="" />
          </div>
          <div className="col-xxl-7 col-xl-7 col-lg-7 rightWrapper">
            <h1 className="content-sh">Our Data Are At Risk</h1>
            <div className="content-item">
              {CircleWithSign()}
              <p className="line">
                Average total cost of a data breach in 2023: USD 4.45 million
              </p>
            </div>
            <div className="content-item">
              {CircleWithSign()}
              <p className="line">
                52% breaches involved Personally Identifiable Information (PII)
                of customers in 2023
              </p>
            </div>
            <p className="content-d">
              * Data Source: IBM -&nbsp;
              <a href="https://www.ibm.com/downloads/cas/E3G5JMBP">
                Cost of a Data Breach Report 2023
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RedactionBriSection
