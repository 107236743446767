import autobind from 'autobind-decorator'
import {List} from 'immutable'
import * as _ from 'lodash'
import {OcrDocument} from '../../OcrDocument'
import {
    IBankAuthorityAccount,
    IBankAuthorityModel,
    IBusinessModel,
    IModelType
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {BankAuthority} from './BankAuthority'
import {BankAuthorityAccount} from './BankAuthorityAccount'
import {BankAuthorityAccounts} from './BankAuthorityAccounts'


@autobind
export class BankAuthorityMapper extends BusinessModelMapper {
    constructor(
        document: OcrDocument,
        private readonly jsonModel: IBankAuthorityModel
    ){
        super(document)
    }

    fromJson(): BankAuthority {
        const bankAuthorityModel = this.jsonModel
        const accounts = _.compact((bankAuthorityModel.Accounts || []).map(this.mapAccounts))
        return new BankAuthority(
            IModelType.BankAuthority,
            bankAuthorityModel.Version,
            this.mapTextBlock(bankAuthorityModel.PracticeName),
            this.mapTextBlock(bankAuthorityModel.AcnAbn),
            new BankAuthorityAccounts(List(accounts)),
            this.mapTextBlocks(bankAuthorityModel.SignatoriesName),
            this.mapTextBlocks(bankAuthorityModel.Signature),
            this.mapDateBlocks(bankAuthorityModel.SignatureDate),
            this.mapTextBlock(bankAuthorityModel.SmsfName)
        )
    }

    mapAccounts(row: IBankAuthorityAccount): BankAuthorityAccount {
        const accountName = this.mapTextBlock(row.AccountName)
        const accountNumber = this.mapTextBlock(row.AccountNumber)
        const bsb = this.mapTextBlock(row.Bsb)
        return new BankAuthorityAccount(
            accountName,
            accountNumber,
            bsb
        )
    }

    static toJson(model: BankAuthority): IBusinessModel {
        return {
            ModelType: IModelType.BankAuthority,
            PracticeName: model.practiceName?.toModelKeyValue(),
            AcnAbn: model.acnAbn?.toModelKeyValue(),
            Accounts: model.accounts?.mapToArray(x => {
                return {
                    AccountName: x.accountName?.toModelKeyValue(),
                    AccountNumber: x.accountNumber?.toModelKeyValue(),
                    Bsb: x.bsb?.toModelKeyValue()
                }
            }),
            SignatoriesName: model.signatoriesName?.map(x => x.toModelKeyValue()),
            Signature: model.signature?.map(x => x.toModelKeyValue()),
            SignatureDate: model.signatureDate?.map(x => x.toModelKeyValue()),
            SmsfName: model.smsfName?.toModelKeyValue()
        } as IBankAuthorityModel
    }
}
