import {FieldAndColumnName} from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import {clamp} from 'lodash'
import React from 'react'
import {mathRound} from 'utils/utils'
import {DateField, DetectedField} from '../DetectedField'
import ModelObject from '../ModelObject'
import {Transaction, TransactionColumn} from './Transaction'

export class Transactions extends ModelObject {
    constructor(
        readonly transactions: List<Transaction>
    ) {
        super()
    }

    transactionByIndex(index: number): Transaction | undefined {
        return this.transactions.get(index)
    }

    listFields(): List<DetectedField> {
        return this.transactions.reduce((combined, transaction) => {
            return combined.concat(transaction.listFields())
        }, List<DetectedField>())
    }

    // static getCredit(params: GridValueGetterParams): GridCellValue {
    //     if (params.value) {
    //         return Math.abs(params.value as number)
    //     } else {
    //         return undefined
    //     }
    // }

    fieldByColRow(columnName: TransactionColumn, rowIndex: number): DetectedField | undefined {
        const transaction = this.transactionByIndex(rowIndex)
        if (transaction) {
            return transaction.fieldByColumn(columnName)
        } else {
            return undefined
        }
    }

    // static toGridColDef(errors: { [key: string]: string }, tableReadOnly: boolean = true,
    //                     renderCell: (params: GridCellParams, errors: {
    //                         [p: string]: string
    //                     }, width: number) => React.ReactNode): GridColDef[] {
    //     return [
    //         {
    //             field: FieldAndColumnName.TransactionTable_Date,
    //             type: 'string',
    //             headerName: 'DATE',
    //             description: 'date',
    //             width: 100,
    //             editable: !tableReadOnly,
    //             renderCell: (params: GridCellParams) => renderCell(params, errors, 100)
    //
    //         },
    //         {
    //             field: FieldAndColumnName.TransactionTable_TotalDebit,
    //             type: 'number',
    //             headerName: 'DEBIT',
    //             width: 120,
    //             // resizable: true,
    //             editable: !tableReadOnly,
    //             renderCell: (params: GridCellParams) => renderCell(params, errors, 120)
    //
    //         },
    //         {
    //             field: FieldAndColumnName.TransactionTable_TotalCredit,
    //             type: 'number',
    //             headerName: 'CREDIT',
    //             width: 120,
    //             // resizable: true,
    //             valueGetter: this.getCredit,
    //             editable: !tableReadOnly,
    //             renderCell: (params: GridCellParams) => renderCell(params, errors, 120)
    //
    //         },
    //         {
    //             field: FieldAndColumnName.TransactionTable_Description,
    //             headerName: 'DESCRIPTION',
    //             width: 600,
    //             // resizable: true,
    //             editable: !tableReadOnly,
    //             renderCell: (params: GridCellParams) => renderCell(params, errors, 600)
    //
    //         },
    //         {
    //             field: FieldAndColumnName.TransactionTable_OpeningBalance,
    //             type: 'number',
    //             headerName: 'BALANCE',
    //             width: 90,
    //             // resizable: true,
    //             hide: false,
    //             editable: !tableReadOnly,
    //             renderCell: (params: GridCellParams) => renderCell(params, errors, 90)
    //         }
    //     ]
    // }

    // toGridRowsProp(): GridRowsProp {
    //     return this.transactions.map((item, index) => {
    //         return item.toGridRow(index)
    //     }).toArray()
    // }

    blockIds(): List<string> {
        return this.transactions.flatMap(t => t.blockIds())
    }

    totalCredits(): number {
        return mathRound(this.reduce((sum, trans) => {
            return trans.amount && trans.amount.parsedValue < 0 ? sum - trans.amount.parsedValue : sum
        }, 0.0))
    }

    totalDebits(): number {
        return mathRound(this.reduce((sum, trans) => {
            return trans.amount && trans.amount.parsedValue > 0 ? sum + trans.amount.parsedValue : sum
        }, 0.0))
    }

    isDateAsc(): boolean {
        return this.reduce(({difference, lastDate}: { difference: number, lastDate?: DateField }, trans) => ({
            difference: difference + (trans.date?.parsedValue.isValid() && lastDate?.parsedValue.isValid() && trans.date.page() === lastDate.page()
                ? clamp(trans.date.parsedValue.diff(lastDate.parsedValue, 'days'), -1, 1) : 0),
            lastDate: trans.date || lastDate
        }), {difference: 0}).difference >= 0
    }

    size(): number {
        return this.transactions.size
    }

    reduce<T>(f: (reduction: T, value: Transaction, key: number) => T, init: T): T {
        return this.transactions.reduce(f, init)
    }

    mapToArray<Z>(mapper: (value: Transaction, key: number) => Z): Z[] {
        return this.transactions.map(mapper).valueSeq().toArray()
    }

    reverse(): Transactions {
        return new Transactions(this.transactions.reverse())
    }
}
