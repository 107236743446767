import { IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import { DateField, StringField } from '../DetectedField'
import { PayGInstalmentYearlyTable } from './PayGInstalmentYearlyTable'

export class AtoPaygInstalmentReport extends BusinessModel {
  constructor(
    version: string,
    readonly name: string,
    readonly payGInstalmentYearlyTable: PayGInstalmentYearlyTable,
    readonly financialYear?: StringField,
    readonly taxAgent?: StringField,
    readonly lastUpdatedDate?: DateField
  ) {
    super(IModelType.AtoPaygInstalmentReport, version)
  }
}
