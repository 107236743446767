import { List } from 'immutable'
import { FieldAndColumnName } from '../../validator/FieldValidatorDef'

export class BusinessModelTypePageTableRow {
  constructor(
    readonly id: string,
    readonly modelName?: string,
    readonly probability?: number
  ) {
  }

//   toGridRow(id: number) {
//     return {
//       id,
//       [FieldAndColumnName.BusinessModelTypePagesEditor_ModelName]: this.modelName,
//       [FieldAndColumnName.BusinessModelTypePagesEditor_Probability]: this.probability
//     }
//   }
}

export class BusinessModelTypePageTable {
  constructor(
    readonly businessModelTypePageTableRows: List<BusinessModelTypePageTableRow>
  ) {
  }

  // static toGridColDef = (renderLongTextCell: (params: GridCellParams) => JSX.Element): GridColDef[] => {
  //   return [
  //     {
  //       field: 'id',
  //       type: 'string',
  //       headerName: 'Page',
  //       width: 80,
  //       editable: false
  //     },
  //     {
  //       field: FieldAndColumnName.BusinessModelTypePagesEditor_ModelName,
  //       type: 'string',
  //       headerName: 'Model Name',
  //       width: 200,
  //       editable: true,
  //       renderCell: (params: GridCellParams) => renderLongTextCell(params)
  //     },
  //     {
  //       field: FieldAndColumnName.BusinessModelTypePagesEditor_Probability,
  //       type: 'number',
  //       headerName: 'Probability',
  //       width: 100,
  //       editable: true,
  //       headerAlign: 'left',
  //       align: 'left'
  //     }
  //   ]
  // }

  // toGridRowsProp(): GridRowsProp {
  //   return this.businessModelTypePageTableRows.map((row,index) => row.toGridRow(index+1)).toArray()
  // }

  size(): number {
    return this.businessModelTypePageTableRows.size
  }
}
