import React from 'react'

import { ReactComponent as DataExtractionIcon } from '../../assets/images/feature-data-extraction.svg'
import { ReactComponent as DocumentClassificationIcon } from '../../assets/images/feature-document-classification.svg'
import { ReactComponent as Feature6Icon } from '../../assets/images/feature-streamline-icon-cloud-settings.svg'
import { ReactComponent as Feature2Icon } from '../../assets/images/feature-streamline-icon-database-subtract.svg'
import { ReactComponent as Feature1Icon } from '../../assets/images/feature-streamline-icon-hierarchy.svg'
import { ReactComponent as Feature3Icon } from '../../assets/images/feature-streamline-icon-light-bulb-1.svg'
import { ReactComponent as Feature4Icon } from '../../assets/images/feature-streamline-icon-rest-api.svg'
import { ReactComponent as Feature5Icon } from '../../assets/images/feature-streamline-icon-tags-favorite-star.svg'

function FeatureSection() {
  return (
    <section className="features">
      <div className="container container-wrapper">
        <div className="title-wrap">
          <span className="sub-title">
            Empowering Data Extraction and Exploration
          </span>
          <p className="sub-text">
            Take full advantage of our Intelligent Document Processing (IDP)
            technology to extract, categorise and interpret data
          </p>
        </div>
        <div className="row">
          <div className="feature">
            <div className="data-extraction">
              <DataExtractionIcon />
              <h3>Data Extraction</h3>
              <p>Label data value in a document based on content and context</p>
            </div>
            <div className="data-extraction-demo requestDemoButton">
              <a href="/#/contact-us">Request Demo</a>
            </div>
          </div>

          <div className="feature">
            <div className="document-classification">
              <DocumentClassificationIcon />
              <h3>Document Classification</h3>
              <p>Classifying documents’ topic based on content and context</p>
            </div>
            <div className="document-classification-demo requestDemoButton">
              <a href="/#/contact-us">Request Demo</a>
            </div>
          </div>
        </div>

        <div className="details">
          <div className="detail">
            <Feature1Icon />
            <h3>State-of-the-Art Models</h3>
            <p>
              Document AI + Vision AI supported. High performance on form
              understanding.
            </p>
          </div>

          <div className="detail">
            <Feature2Icon />
            <h3>All-in-one AI Platform</h3>
            <p>
              Data Managing, Labelling, Training and Model Hosting in one
              platform.
            </p>
          </div>

          <div className="detail">
            <Feature3Icon />
            <h3>User Friendly</h3>
            <p>
              Extract data from any type of document or image. No coding
              required.
            </p>
          </div>

          <div className="detail">
            <Feature4Icon />
            <h3>REST API</h3>
            <p>Affordable and Scalable API based model hosting.</p>
          </div>

          <div className="detail">
            <Feature5Icon />
            <h3>Easy Labelling</h3>
            <p>
              Tag 10x faster with Zero-Shot and Al model Assisted Labelling.
            </p>
          </div>

          <div className="detail">
            <Feature6Icon />
            <h3>AI Training like a Breeze</h3>
            <p>Have production ready AI model in an afternoon.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureSection
