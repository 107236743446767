import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {IBusinessModel, IModelType, IRentalSummaryModel} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {RentalSummary} from './RentalSummary'

@autoBind
export class RentalSummaryMapper extends BusinessModelMapper {
    constructor(
        document: OcrDocument,
        private readonly jsonModel: IRentalSummaryModel
    ) {
        super(document)
    }

    fromJson(): RentalSummary {
        const rentalJson = this.jsonModel

        const model = new RentalSummary(
            IModelType.RentalSummary,
            rentalJson.Version,
            this.mapDateBlock(rentalJson.StartDate),
            this.mapDateBlock(rentalJson.EndDate),
            this.mapTextBlock(rentalJson.Address),
            this.mapRentalBlocks(rentalJson.IncomeSummary),
            this.mapRentalBlocks(rentalJson.PaymentToOwner),
            this.mapRentalBlocks(rentalJson.ExpenseAgentsManagementFees),
            this.mapRentalBlocks(rentalJson.ExpenseAgentsCommissions),
            this.mapRentalBlocks(rentalJson.ExpenseElectricityAndGas),
            this.mapRentalBlocks(rentalJson.ExpenseWaterRates),
            this.mapRentalBlocks(rentalJson.ExpenseInsurancePremium),
            this.mapRentalBlocks(rentalJson.ExpenseGardenAndLawn),
            this.mapRentalBlocks(rentalJson.ExpenseStrataLevyFees),
            this.mapRentalBlocks(rentalJson.ExpenseAdvertising),
            this.mapRentalBlocks(rentalJson.ExpenseStationeryPhoneAndPostage),
            this.mapRentalBlocks(rentalJson.ExpensePestControl),
            this.mapRentalBlocks(rentalJson.ExpenseCleaning),
            this.mapRentalBlocks(rentalJson.ExpenseCouncilRate),
            this.mapRentalBlocks(rentalJson.ExpenseLandTax),
            this.mapRentalBlocks(rentalJson.ExpenseLegalFees),
            this.mapRentalBlocks(rentalJson.ExpenseSundry),
            this.mapRentalBlocks(rentalJson.ExpenseNonSpecified),
            this.mapRentalBlocks(rentalJson.ExpenseRepairsMaintenance),
            this.mapRentalBlocks(rentalJson.ExpensePropertyCapitalImprovement),
            this.mapRentalBlocks(rentalJson.ExpenseGST),
            this.mapDateBlock(rentalJson.PaymentStartDate),
            this.mapDateBlock(rentalJson.PaymentEndDate)
        )
        return model
    }

    static toJson(model: RentalSummary): IBusinessModel {
        return {
            ModelType: IModelType.RentalSummary,
            Version: model.version
        } as IRentalSummaryModel
    }
}
