import NLPReport from '../../models/jobReport/NLPReport'
import ObjectCountReport from '../../models/jobReport/ObjectCountReport'
import ObjectDetectionReport from '../../models/jobReport/ObjectDetectionReport'
import TrainingReport, {
  TrainReportDTONLP,
  TrainReportDTOObjectCount,
  TrainReportDTOObjectDetection,
  TrainReportsDTO
} from '../../models/jobReport/TrainingReport'
import { ProjectType } from '../../models/ProjectType'

export const jobReportAdapter = (
  response: { reports: TrainReportsDTO },
  projectType: ProjectType
): TrainingReport[] => {
  if (projectType === ProjectType.ObjectDetection) {
    const reports = response.reports as TrainReportDTOObjectDetection[]
    return reports.map((report) => ObjectDetectionReport.fromJson(report))
  }

  if (projectType === ProjectType.ObjectCounting) {
    const reports = response.reports as TrainReportDTOObjectCount[]
    return reports.map((report) => ObjectCountReport.fromJson(report))
  }

  const reports = response.reports as TrainReportDTONLP[]
  return reports.map((report) => NLPReport.fromJson(report))
}
