import { FieldAndColumnName, IdCard } from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import React from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormStringField } from '../FormStringField'

interface IdCardEditorProps extends EditorFieldEvents {
  idCard: IdCard
  className?: string
}

export const IdCardEditor = (props: IdCardEditorProps) => {
  const idCard = props.idCard

  return (
    <form noValidate autoComplete="on">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_DocumentNumber}
            label={'Id'}
            events={props}
            field={idCard.documentNumber}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_FirstName}
            label={'First Name'}
            events={props}
            field={idCard.firstName}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_LastName}
            label={'Last Name'}
            events={props}
            field={idCard.lastName}
            className={props.className}
          />
        </Grid>

        <Grid item xs={12}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_Address}
            label={'Address'}
            events={props}
            field={idCard.address}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_Sex}
            label={'Sex'}
            events={props}
            field={idCard.sex}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.IdCardEditor_DateOfBirth}
            label={'Date of Birth'}
            events={props}
            field={idCard.dateOfBirth}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_Region}
            label={'Region'}
            events={props}
            field={idCard.region}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.IdCardEditor_CountryRegion}
            label={'Country'}
            events={props}
            field={idCard.countryRegion}
            className={props.className}
          />
        </Grid>

        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.IdCardEditor_DateOfExpiration}
            label={'Expiration'}
            events={props}
            field={idCard.dateOfExpiration}
            className={props.className}
          />
        </Grid>
      </Grid>
    </form>
  )
}
