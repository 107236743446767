import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {
    IASICFeeModel,
    IBusinessModel,
    IModelType, ITransactionDetails
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {ASICFee} from './ASICFee'
import {TransactionDetails} from "./TransactionDetails";
import {List} from "immutable";


@autoBind
export class ASICFeeMapper extends BusinessModelMapper {
    constructor(
        document:OcrDocument,
        private readonly jsonModel: IASICFeeModel
    ){
        super(document)
    }

    fromJson(): ASICFee {
        const ASICFeeModel = this.jsonModel
        const TransactionDetailsForThisPeriod = this.jsonModel.TransactionDetailsForThisPeriod
          const OutstandingTransactions = this.jsonModel.OutstandingTransactions
        const transactionDetailsForThisPeriod = TransactionDetailsForThisPeriod?.map((table: ITransactionDetails) => {
            return this.mapTransactionDetails(table)
        })
        const outstandingTransactions = OutstandingTransactions?.map((table: ITransactionDetails) => {
            return this.mapTransactionDetails(table)
        })


        const model = new ASICFee(
            IModelType.ASICFee,
            ASICFeeModel.Version,
            this.mapDateBlock(ASICFeeModel.ASICFeeIssueDate),
            this.mapTextBlock(ASICFeeModel.ASICFeeCompanyName),
            this.mapTextBlock(ASICFeeModel.ASICFeeACN),
            this.mapTextBlock(ASICFeeModel.ASICFeeAccountNumber),
            this.mapDoubleBlock(ASICFeeModel.ASICFeeCurrentDueAmount),
            this.mapDateBlock(ASICFeeModel.ASICFeeCurrentDueDate),
            this.mapDoubleBlock(ASICFeeModel.ASICFeeOverdueAmount),
            this.mapDoubleBlock(ASICFeeModel.ASICFeeTotalDue),
            this.mapTextBlock(ASICFeeModel.CompanyStatementCompanyName),
            this.mapTextBlock(ASICFeeModel.CompanyStatementACN),
            this.mapDateBlock(ASICFeeModel.CompanyStatementIssueDate),
            this.mapTextBlock(ASICFeeModel.CompanyRegisteredAddress),
            this.mapTextBlocks(ASICFeeModel.CompanyOfficeholder),
            this.mapDateBlocks(ASICFeeModel.CompanyOfficeholderDOB),
            this.mapTextBlocks(ASICFeeModel.CompanyMember),
          List(transactionDetailsForThisPeriod?? []),
          List(outstandingTransactions ?? []),
        )
        return model
    }

    static toJson(model: ASICFee): IBusinessModel {
        return {
            ModelType: IModelType.ASICFee,
            Version: model.version,
            ASICFeeIssueDate: model.ASICFeeIssueDate?.toModelKeyValue(),
            ASICFeeCompanyName: model.ASICFeeCompanyName?.toModelKeyValue(),
            ASICFeeACN: model.ASICFeeACN?.toModelKeyValue(),
            ASICFeeAccountNumber: model.ASICFeeAccountNumber?.toModelKeyValue(),
            ASICFeeCurrentDueAmount: model.ASICFeeCurrentDueAmount?.toModelKeyValue(),
            ASICFeeCurrentDueDate: model.ASICFeeCurrentDueDate?.toModelKeyValue(),
            ASICFeeOverdueAmount: model.ASICFeeOverdueAmount?.toModelKeyValue(),
            ASICFeeTotalDue: model.ASICFeeTotalDue?.toModelKeyValue(),
            CompanyStatementCompanyName: model.companyStatementCompanyName?.toModelKeyValue(),
            CompanyStatementACN: model.companyStatementACN?.toModelKeyValue(),
            CompanyStatementIssueDate: model.companyStatementIssueDate?.toModelKeyValue(),
            CompanyRegisteredAddress:model.companyRegisteredAddress?.toModelKeyValue(),
            CompanyOfficeholder:model.companyOfficeholder?.map(it=>it.toModelKeyValue()),
            CompanyOfficeholderDOB:model.companyOfficeholderDOB?.map(it=>it.toModelKeyValue()),
            CompanyMember:model.companyMember?.map(it=>it.toModelKeyValue()),
            TransactionDetailsForThisPeriod: model.transactionDetailsForThisPeriod?.toArray().map(table => table.toJson()),
            OutstandingTransactions: model.outstandingTransactions?.toArray().map(table => table.toJson())
        } as IASICFeeModel
    }

    mapTransactionDetails(transactionDetails: ITransactionDetails):TransactionDetails{
        return new TransactionDetails(
            this.mapDateBlock(transactionDetails.TransactionDate),
            this.mapTextBlock(transactionDetails.TransactionDescription),
            this.mapTextBlock(transactionDetails.TransactionReference),
            this.mapDoubleBlock(transactionDetails.TransactionAmount)
        )
    }
}
