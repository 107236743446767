import { faCommentsAlt, faEnvelopes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Popover, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'

import { usePopoverMessengerStyles } from '../../assets/styles/PopoverMessengerStyles'
import ContactUs, { SUBJECT } from './ContactUs'

const PopoverMessenger = (): JSX.Element => {
  const classes = usePopoverMessengerStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'contactus-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <Tooltip title={'Email Us'}>
        <IconButton onClick={handleClick}>
          <FontAwesomeIcon icon={faEnvelopes} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          paper: classes.popoverPaper
        }}
      >
        <ContactUs
          defaultSubject={SUBJECT.GENERAL_ENQUIRY}
          internalEmail={true}
        />
      </Popover>
    </div>
  )
}

export default PopoverMessenger
