import {
  ASICFee,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { FC } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDateFieldArray } from '../FormDateFieldArray'
import { FormDollarField } from '../FormDollarField'
import { FormStringField } from '../FormStringField'
import { FormStringFieldArray } from '../FormStringFieldArray'

interface IASICFeeProps extends EditorFieldEvents {
  asicFee: ASICFee
  className?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      fontWeight: 600
    },
    totalDue: {
      '& label': {
        fontWeight: 600,
        color: 'black',
        fontSize: '1.2rem'
      }
    }
  })
)

export const ASICFeeEditor: FC<IASICFeeProps> = (props: IASICFeeProps) => {
  const styles = useStyles()
  const asicFee = props.asicFee
  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ASICFeeEditor_ASICFeeCompanyName}
            label={'Company Name'}
            events={props}
            field={asicFee.ASICFeeCompanyName}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ASICFeeEditor_ASICFeeACN}
            label={'ACN'}
            events={props}
            field={asicFee.ASICFeeACN}
            className={props.className}
          />
        </Grid>
        <Grid item xs={12}>
          <FormStringField
            id={FieldAndColumnName.ASICFeeEditor_CompanyRegisteredAddress}
            label={'Company Registered Address'}
            events={props}
            field={asicFee.companyRegisteredAddress}
            className={props.className}
          />
        </Grid>
        {props.asicFee.companyOfficeholder && (
          <Grid item xs={6}>
            <FormStringFieldArray
              id={FieldAndColumnName.ASICFeeEditor_CompanyOfficeholder}
              label={'Company Officeholders'}
              events={props}
              fields={props.asicFee.companyOfficeholder}
              className={props.className}
            />
          </Grid>
        )}
        {props.asicFee.companyOfficeholderDOB && (
          <Grid item xs={6}>
            <FormDateFieldArray
              id={FieldAndColumnName.ASICFeeEditor_CompanyOfficeholderDOB}
              label={"Company Officeholders' DOB"}
              events={props}
              fields={props.asicFee.companyOfficeholderDOB}
              className={props.className}
            />
          </Grid>
        )}
        {props.asicFee.companyMember && (
          <Grid item xs={6}>
            <FormStringFieldArray
              id={FieldAndColumnName.ASICFeeEditor_CompanyMember}
              label={'Company Members'}
              events={props}
              fields={props.asicFee.companyMember}
              className={props.className}
            />
          </Grid>
        )}
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.ASICFeeEditor_ASICFeeIssueDate}
            label={'Issue Date'}
            events={props}
            field={props.asicFee.ASICFeeIssueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.ASICFeeEditor_ASICFeeCurrentDueDate}
            label={'Due Date'}
            events={props}
            field={props.asicFee.ASICFeeCurrentDueDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ASICFeeEditor_ASICFeeCurrentDueAmount}
            label={'Total Amount Due'}
            events={props}
            field={asicFee.ASICFeeCurrentDueAmount}
            className={clsx([styles.totalDue, props.className])}
          />
        </Grid>
      </Grid>
    </form>
  )
}
