import React from 'react'

import {BiFullscreen} from 'react-icons/bi'
import {BsBoundingBoxCircles, BsDot} from 'react-icons/bs'
import {FiZoomIn, FiZoomOut} from 'react-icons/fi'
import styled from 'styled-components'
import Canvas from '../drawer/Canvas'
import {CreateType} from '../models/Types'

const StyledToolbar = styled.div`
	width: 100%;
	height: 2rem;
	display: flex;
	align-items: center;
	flex-direction: row;
	background-color: #eceffa;
`
const ToolButton = styled.button<{selected?: boolean}>`
  margin-left: 5px;
	width: 30px;
	height: 25px;
	border: none;
	border-radius: 5px;
	background-color: ${props => props.selected ? '#cecece' : '#eceffa'};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: black;
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
		color: #5F5CD7;
	};
`
const VerticalDivider = styled.div`
	margin: 0 1rem;
	height: 50%;
	display: inline-block;
	border: 1px solid rgba(0, 0, 0, 0.2);

`
interface IToolbarProps {
  canvasDrawer: Canvas | null,
  createType: CreateType,
  setCreateType: (createType: CreateType) => void
}
const Toolbar = ({canvasDrawer, createType, setCreateType}: IToolbarProps) => {
  return <StyledToolbar>
    <ToolButton
      onClick={() => {
        if(canvasDrawer){
          canvasDrawer.zoom('in')
        }
      }}
    > <FiZoomIn size={20}/> </ToolButton>
    <ToolButton
      onClick={() => {
        if(canvasDrawer){
          canvasDrawer.zoom('out')
        }
      }}
    > <FiZoomOut size={20}/> </ToolButton>
    <ToolButton
      onClick={() => {
        if(canvasDrawer){
          canvasDrawer.fitZoom()
        }
      }}
    > <BiFullscreen size={20} /> </ToolButton>
    <VerticalDivider />
    <ToolButton
      selected={createType === CreateType.Rect}
      onClick={() => setCreateType(CreateType.Rect)}
    > <BsBoundingBoxCircles size={15} /> </ToolButton>
    <ToolButton
      selected={createType === CreateType.Dot}
      onClick={() => setCreateType(CreateType.Dot)}
    > <BsDot size={20} /> </ToolButton>
  </StyledToolbar>
}

export default Toolbar