import create from 'zustand'

import Authorizations, {
  Authorization,
  AuthResponseDTO
} from '../../models/Authorization'
import Business, { Businesses } from '../../models/Business'
import AuthorizationApi from '../AuthorizationApi'
import TaggingApi from '../TaggingApi'

const authorizationApi = AuthorizationApi.getInstance()
const taggingApi = TaggingApi.getInstance()

interface IInitState {
  authorizations: Authorizations | undefined
  fetchAuthorizations: () => Promise<Authorizations | undefined>
  currentBusiness: Business
  setCurrentBusiness: (business: Business) => void
  businesses: Businesses
  setBusinesses: (businesses: Business[]) => void
  getBusinesses: () => Promise<Businesses | undefined>
  businessError?: string
}

export const useInitStore = create<IInitState>((set) => ({
  authorizations: undefined,
  fetchAuthorizations: async (): Promise<Authorizations | undefined> => {
    try {
      const dto: AuthResponseDTO | undefined =
        await authorizationApi.getAuthorizations()
      if (dto) {
        const authorizations = Authorizations.fromDTO(dto)
        set({ authorizations })
        return authorizations
      } else {
        console.error('AuthResponse is undefined')
      }
    } catch (e) {
      console.error(e)
      throw new Error('Error fetching authorizations')
    }
  },
  currentBusiness: new Business(),
  setCurrentBusiness: (business) => set({ currentBusiness: business }),
  businesses: new Businesses([]),
  setBusinesses: (businesses: Business[]) =>
    set({ businesses: new Businesses(businesses) }),
  getBusinesses: async () => {
    return taggingApi
      .getBusinesses()
      .then((businesses) => {
        set({ businesses })
        set({ currentBusiness: businesses.businesses[0] })
        return businesses
      })
      .catch((error: string) => {
        set({ businessError: error })
        throw new Error(error)
      })
  }
}))

export const hasPermission = (requestPermission: Authorization[]): boolean => {
  const { authorizations: auth } = useInitStore.getState()
  if (auth) {
    return requestPermission.every((authorization) =>
      auth.hasAuthority(authorization)
    )
  } else {
    return false
  }
}
