import TrainingReport, {
  ConfusionMatrix,
  DetailNLPMetricsType,
  Evaluation,
  Metrics,
  OverallNLPMetricsType,
  TrainReportDTONLP
} from './TrainingReport'

export default class NLPReport extends TrainingReport {
  constructor(
    public id: string,
    public datetime: string,
    public projectId: string,
    public epoch: number,
    public details: DetailNLPMetricsType[],
    public confusionMatrix: ConfusionMatrix | undefined,
    public overallMetrics: OverallNLPMetricsType
  ) {
    super(id, datetime, projectId, epoch)
  }

  static fromJson(trainingReportDTO: TrainReportDTONLP): NLPReport {
    const {
      id,
      datetime,
      projectId,
      epoch,
      details,
      confusionMatrix,
      ...overallMetrics
    } = trainingReportDTO
    return new NLPReport(
      id,
      datetime,
      projectId,
      epoch,
      details,
      confusionMatrix,
      {
        ...overallMetrics
      }
    )
  }

  getMainMetrics() {
    const metrics = this.overallMetrics.f1Score
    return {
      name: Metrics.F1Score,
      value: metrics,
      score: (metrics * 100).toFixed(1),
      evaluation: this.getEvaluation(metrics)
    }
  }

  generateOverallTableColumns = () => {
    const NLPOverallTableHeader = [
      'f1Score',
      'accuracy',
      'precision',
      'recall',
      'support'
    ]
    return this.generateColumn(NLPOverallTableHeader)
  }

  generateOverallTableRow = () => {
    const { f1Score, accuracy, precision, recall, support } =
      this.overallMetrics
    return [
      {
        id: this.epoch.toString(),
        f1Score: f1Score.toPrecision(3),
        accuracy: accuracy.toPrecision(3),
        precision: precision.toPrecision(3),
        recall: recall.toPrecision(3),
        support: support.toString()
      }
    ]
  }

  generateDetailTableColumns = () => {
    const NLPDetailTableHeader = ['f1Score', 'precision', 'recall', 'support']
    return this.generateColumn(NLPDetailTableHeader)
  }

  generateDetailTableRows = (tagIdToName: (tagId: string) => string) => {
    return this.details.map((detail) => {
      const { f1Score, precision, recall, support, labelId } = detail
      return {
        id: labelId.toString(),
        tagName: tagIdToName(labelId),
        f1Score: f1Score.toPrecision(3),
        precision: precision.toPrecision(3),
        recall: recall.toPrecision(3),
        support: support.toString()
      }
    })
  }

  private getEvaluation(metrics: number) {
    if (metrics > 0.9) return Evaluation.Good
    if (metrics <= 0.9 && metrics > 0.6) return Evaluation.Fair
    if (metrics <= 0.6) return Evaluation.Poor
    return Evaluation.NotAvailable
  }
}
