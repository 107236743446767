import {DateField, DollarField, StringField} from "../DetectedField";
import {ITransactionDetails, ITransactionTable} from "../../types/ISmartDocsResult";

export class TransactionDetails {
  constructor(
    public readonly transactionDate?: DateField,
    readonly transactionDescription?: StringField,
    readonly transactionReference?: StringField,
    readonly transactionAmount?: DollarField,
  ) {}

  copy({
    transactionDate = this.transactionDate,
    transactionDescription = this.transactionDescription,
    transactionReference = this.transactionReference,
    transactionAmount = this.transactionAmount,
  }): TransactionDetails {
    return new TransactionDetails(
      transactionDate,
      transactionDescription,
      transactionReference,
      transactionAmount,
    )
  }

  toJson(): ITransactionDetails {
    return {
      TransactionDate: this.transactionDate?.toModelKeyValue(),
      TransactionDescription: this.transactionDescription?.toModelKeyValue(),
      TransactionReference: this.transactionReference?.toModelKeyValue(),
      TransactionAmount: this.transactionAmount?.toModelKeyValue(),
    }
  }


}