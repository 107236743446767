import ModelObject from 'domain/businessModel/ModelObject'
import { FormFieldValue } from 'domain/validator/IFieldValidator'
import { isNull, isString } from 'lodash'
import moment from 'moment'
import { FormGridRowId } from 'utils/DataTypeMapper'
import { FieldAndColumnName, FormFieldValidationResult } from './FieldValidatorDef'
import { FormFieldValidator } from './FormFieldValidator'
import { IValidatorFactory } from './IValidatorFactory'

const BANK_BSB_LENGTH: number = 6

class BSBValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BankStatementEditor_BSB

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (!isString(value)) {
        //     return FormFieldValidationResult.INVALID_INPUT
        // }

        // return value.length === BANK_BSB_LENGTH
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.BANKSTATEMENT_INCORRECT_BSB
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return BSBValidator.fieldName
    }
}

class BankNameValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BankStatementEditor_BankName

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (!isString(value)) {
        //     return FormFieldValidationResult.INVALID_INPUT
        // }

        // return value.length === BANK_BSB_LENGTH
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.BANKSTATEMENT_INCORRECT_BSB
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return BankNameValidator.fieldName
    }
}

class AccountNumberValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BankStatementEditor_AccountNumber

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (!isString(value)) {
        //     return FormFieldValidationResult.INVALID_INPUT
        // }

        // return value.length > 0 && /^\d+$/.test(value)
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.BANKSTATEMENT_INCORRECT_ACCOUNT_NUMBER
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return AccountNumberValidator.fieldName
    }
}

class StartDateValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BankStatementEditor_StartDate

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (!moment.isMoment(value)) {
        //     return FormFieldValidationResult.INVALID_INPUT
        // }

        // return moment(value).isSameOrBefore(moment())
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.BANKSTATEMENT_INCORRECT_DATE
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return StartDateValidator.fieldName
    }
}

class EndDateValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BankStatementEditor_EndDate

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        // if (isNull(value)) {
        //     return FormFieldValidationResult.VALIDATION_SUCCESS
        // }

        // if (!moment.isMoment(value)) {
        //     return FormFieldValidationResult.INVALID_INPUT
        // }

        // return moment(value).isSameOrBefore(moment())
        //     ? FormFieldValidationResult.VALIDATION_SUCCESS
        //     : FormFieldValidationResult.BANKSTATEMENT_INCORRECT_DATE
        return FormFieldValidationResult.VALIDATION_SUCCESS
    }

    getName() {
        return EndDateValidator.fieldName
    }
}

export const BankStatementValidatorFactory: IValidatorFactory<FormFieldValidator> = (fieldName: string) => {
    switch (fieldName) {
        case BankNameValidator.fieldName:
            return new BankNameValidator()
        case AccountNumberValidator.fieldName:
            return new AccountNumberValidator()
        case BSBValidator.fieldName:
            return new BSBValidator()
        case EndDateValidator.fieldName:
            return new EndDateValidator()
        case StartDateValidator.fieldName:
            return new StartDateValidator()
        default:
            throw Error(`Invalid fieldName(${fieldName}) received in BankStatementValidatorFactory`)
    }
}
