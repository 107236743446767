import { Rect } from '../models/Rect'
import { BaseShape } from '../models/Shape'
import {CreateType, Point, ZoomType} from '../models/Types'
import {Renderer} from './Renderer'


export default class Canvas {
	private readonly zoomStep = 0.1
	private readonly IMAGE_ORIGINAL_WIDTH: number
	private readonly IMAGE_ORIGINAL_HEIGHT: number
	private readonly ctx: CanvasRenderingContext2D
	private readonly renderer: Renderer
	private CANVAS_WIDTH: number
	private CANVAS_HEIGHT: number

	// TODO:
	// Replace baseshape[] BaseShapes
	shapes: BaseShape[] = []
	isMouseOnCanvas = true
	imageWidth = 0
	imageHeight = 0
	originX = 0
	originY = 0
	scale = 1

	constructor(
		readonly canvas: HTMLCanvasElement,
		readonly container: HTMLDivElement,
		readonly image: HTMLImageElement
	){
		this.IMAGE_ORIGINAL_WIDTH = image.width
		this.IMAGE_ORIGINAL_HEIGHT = image.height
		this.CANVAS_WIDTH = container.clientWidth
		this.CANVAS_HEIGHT = container.clientHeight
		this.ctx = canvas.getContext('2d') || new CanvasRenderingContext2D()
		this.renderer = new Renderer(this.ctx)
	}

	init(){
		this.scale = this.getFitScale()
		this.canvas.width = this.CANVAS_WIDTH
		this.canvas.height = this.CANVAS_HEIGHT
		this.imageWidth = this.IMAGE_ORIGINAL_WIDTH * this.scale
		this.imageHeight = this.IMAGE_ORIGINAL_HEIGHT * this.scale
		this.originX = (this.CANVAS_WIDTH - this.imageWidth) / 2
		this.originY = (this.CANVAS_HEIGHT - this.imageHeight) / 2
	}

	draw(mousePoint?: Point){
		this.ctx.save()
		// set the origin into original point
		this.ctx.setTransform(1, 0, 0, 1, 0, 0)
		this.ctx.clearRect(0, 0, this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
		this.ctx.fillStyle	= 'gray'
		this.ctx.fillRect(0, 0, this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
		this.ctx.translate(this.originX, this.originY)
		this.ctx.drawImage(this.image, 0, 0, this.imageWidth, this.imageHeight)
		this.ctx.restore()
		this.drawShapes()
	}

	private drawShapes(){
		this.shapes.forEach(shape => this.renderer.draw(shape, this.convertPercentageToAbsolute.bind(this)))
	}

	deselectActiveShape(){
		this.shapes.forEach(shape => shape.isActive = false)
	}

	setNewCanvasDimensionOnResize (width: number, height: number){
		this.canvas.height = height
		this.canvas.width = width
		this.CANVAS_WIDTH = width
		this.CANVAS_HEIGHT = height
		this.originX = (this.CANVAS_WIDTH - this.imageWidth) / 2
		this.originY = (this.CANVAS_HEIGHT - this.imageHeight) / 2
	}

	fitZoom(){
		const fitScale = this.getFitScale()
		this.scale = fitScale
		this.imageWidth = this.IMAGE_ORIGINAL_WIDTH * fitScale
		this.imageHeight = this.IMAGE_ORIGINAL_HEIGHT * fitScale
		this.originX = (this.CANVAS_WIDTH - this.imageWidth) / 2
		this.originY = (this.CANVAS_HEIGHT - this.imageHeight) / 2
		this.draw()
	}

	zoom(type: ZoomType, center?: Point) {
		if(this.allowZoom(type)) return
		let newImageWidth
		let newImageHeight

		if(type === 'in'){
			newImageWidth = Math.round(this.imageWidth * (this.zoomStep + 1))
			newImageHeight = Math.round(this.imageHeight * (this.zoomStep + 1))
		} else {
			newImageWidth = Math.round(this.imageWidth * (1 - this.zoomStep))
			newImageHeight = Math.round(this.imageHeight * (1 - this.zoomStep))
		}

		// recalculate the original point to make sure the image is zooming based on the center point
		const zoomCenter = center ?? [this.CANVAS_WIDTH / 2, this.CANVAS_HEIGHT / 2]
		const ratioX = (zoomCenter[0] - this.originX) / this.imageWidth
		const ratioY = (zoomCenter[1] - this.originY) / this.imageHeight

		// set new drawing dimensions and scale
		this.originX = (zoomCenter[0] - newImageWidth * ratioX)
		this.originY = (zoomCenter[1] - newImageHeight * ratioY)
		this.imageWidth = newImageWidth
		this.imageHeight = newImageHeight
		this.scale = newImageHeight / this.IMAGE_ORIGINAL_HEIGHT
		this.draw()
	}

	private allowZoom(type: ZoomType): boolean {
		return (type === 'out' && this.scale < this.getFitScale())
			|| (type === 'in' && this.imageWidth >= this.CANVAS_WIDTH * 2)
			|| (this.zoomStep > 0.3 && this.zoomStep < 0.1)
	}

	private getFitScale(){
		const fitScaleOffsetFactor = 0.95
		const widthScale = this.CANVAS_WIDTH / this.IMAGE_ORIGINAL_WIDTH
		const heightScale = this.CANVAS_HEIGHT / this.IMAGE_ORIGINAL_HEIGHT
		return Math.min(widthScale, heightScale) * fitScaleOffsetFactor
	}

	convertAbsoluteToPercentage(currentPoint: Point): Point{
		const [x, y] = currentPoint
		return [(x - this.originX) / this.imageWidth, (y - this.originY)  / this.imageHeight]
	}

	convertPercentageToAbsolute(percentagePoint: Point): Point{
		const [x, y] = percentagePoint
		return [(x * this.imageWidth) + this.originX, (y * this.imageHeight) + this.originY]
	}

	getMouseOver(percentageMousePoint:Point){
		if(this.activeShape && this.activeShape.getActiveCtrlPointIndex(percentageMousePoint, this.convertPercentageToAbsolute.bind(this)) !== -1) return 'CtrlPoint'
		if(this.activeShape && this.activeShape.isMouseOver(percentageMousePoint, this.convertPercentageToAbsolute.bind(this) )) return 'ActiveShape'
		if(this.findShapeMouseOver(percentageMousePoint)) return 'Shape'
		if(this.isMouseOverImage(percentageMousePoint)) return 'NoShape'
		if(this.isMouseOnCanvas) return 'OnCanvas'
		return null
	}


	removeShape(shape: BaseShape){
		this.shapes.splice(shape.shapeIndex, 1)
		this.shapes.forEach((shape, index) => shape.shapeIndex = index)
	}

	private isMouseOverImage(percentageMousePoint: Point){
		const [x, y] = percentageMousePoint
		return x > 0 && x < 1 && y > 0 && y < 1
	}


	findShapeMouseOver(mousePoint: Point): BaseShape | null {
		const targetShape = this.shapes.reduceRight((target: BaseShape | null, shape: BaseShape, index:number) => {
			if(!target && shape.isMouseOver(mousePoint, this.convertPercentageToAbsolute.bind(this))){
				return target = shape
			}
			return target
		}, null)
		return targetShape
	}

	get activeShape(){
		return this.shapes.find(shape => shape.isActive === true)
	}
}
