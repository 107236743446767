import React from 'react'

import AboutBGL from './AboutBGL'
import ConsultingServices from './ConsultingServices'
import CustomTraining from './CustomTraining'
import FeatureSection from './FeatureSection'
import Hero from './Hero'
import Media from './Media'
import PretrainedAIModels from './PretrainedAIModels'
import PretrainedAIModelsTop from './PretrainedAIModelsTop'
import SuccessInNumbers from './SuccessInNumbers'
import UseCase from './UseCase'

function index() {
  return (
    <>
      <Hero />
      <FeatureSection />
      <PretrainedAIModelsTop />
      <CustomTraining />
      <ConsultingServices />
      <UseCase />
      <SuccessInNumbers />
      <Media />
      <AboutBGL />
    </>
  )
}

export default index
