import { faPowerOff } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { useCookies } from 'react-cookie'

import { ssoURL } from '../ApiPath'

const SignOut = () => {
  const [, , removeCookie] = useCookies(['JSESSIONID'])
  const handleSignOut = () => {
    removeCookie('JSESSIONID')
    window.location.replace(`${ssoURL}/logout?app=sdd360`)
  }
  return (
    <Tooltip title={'Sign Out'}>
      <IconButton onClick={handleSignOut}>
        <FontAwesomeIcon icon={faPowerOff} />
      </IconButton>
    </Tooltip>
  )
}

export default SignOut
