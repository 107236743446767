import create from 'zustand'

import Project from '../../models/Project'
import { isDefaultItemisedProjectType } from '../../models/ProjectType'
import TaggingApi from '../TaggingApi'

const taggingApi = TaggingApi.getInstance()

interface IProjectState {
  currentProject: Project
  projects: Project[]
  projectLoading: boolean
  projectError?: string
}

export const useProjectStore = create<IProjectState>(() => ({
  currentProject: new Project(),
  projects: [],
  projectLoading: false,
  projectError: undefined
}))

export const setCurrentProject: (project: Project) => void = (project) =>
  useProjectStore.setState({ currentProject: project })

export const setProjects: (projects: Project[]) => void = (projects) =>
  useProjectStore.setState({ projects })

export const getProject: (projectId: string) => Promise<void> = async (
  projectId
) => {
  useProjectStore.setState({ projectLoading: true })
  const project = await taggingApi.getProject(projectId)
  return useProjectStore.setState({
    currentProject: project ?? new Project(),
    projectLoading: false
  })
}

export const addProject: (
  businessId: string,
  project: Project
) => Promise<Project | undefined> = async (businessId, project) => {
  const isDefaultItemised = isDefaultItemisedProjectType(project.projectType)
  const newProject = isDefaultItemised ? project.setIsItemised(true) : project
  useProjectStore.setState({ projectLoading: true })
  try {
    const projectRes = await taggingApi.addProject(businessId, newProject)
    if (projectRes) {
      setProjects([...useProjectStore.getState().projects, projectRes])
      useProjectStore.setState({ projectLoading: false })
      return projectRes
    }
  } catch (error) {
    useProjectStore.setState({ projectError: error, projectLoading: false })
    return await Promise.reject(error)
  }
}

export const updateProject: (project: Project) => Promise<void> = async (
  project
) => {
  useProjectStore.setState({ projectLoading: true })
  try {
    const projectRes = await taggingApi.updateProject(project)
    if (projectRes) {
      projectRes.id === useProjectStore.getState().currentProject.id &&
        setCurrentProject(projectRes)
      setProjects(
        useProjectStore
          .getState()
          .projects.map((p) => (p.id === projectRes.id ? projectRes : p))
      )
      useProjectStore.setState({ projectLoading: false })
    }
  } catch (error) {
    useProjectStore.setState({ projectError: error, projectLoading: false })
    return await Promise.reject(error)
  }
}
