import { Set as ImmutableSet } from "immutable"
import { Annotation, AnnotationId } from "./Annotation"

export class PageAnnotation extends Annotation{
  constructor(
    public readonly pageId: string,
    public readonly tagId: string,
    public readonly probability: number,
    public readonly source: string,
    public readonly tagUpdated: boolean
  ) {
    super(new AnnotationId(pageId, pageId), tagId, probability, source)
  }

  static createByTagIds(pageId: string, tagIds: Set<string>, source: string): PageAnnotation[] {
    return ImmutableSet(tagIds).map(id => {
      return new PageAnnotation(pageId, id, 1.0, source, true)
    }).toArray()
  }

  viewText(
    idToPageNumber: (id: AnnotationId) => number | undefined
  ): string {
    const pageNumber = idToPageNumber(this.id)
    if (pageNumber) {
      return `Page ${pageNumber}`;
    } else {
      return `PageId ${this.pageId}`;
    }
  }
}
