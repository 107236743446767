import { IAtoActivityStatementTransactionTableDataRow } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import * as _ from 'lodash'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import {DateField, DetectedField, DollarField, StringField} from '../DetectedField'
import IItemObject from '../IItemObject'
import ModelObject from '../ModelObject'


export type AtoActivityStatementTransactionColumn =
  FieldAndColumnName.AtoActivityStatementTransactionTable_Balance
  | FieldAndColumnName.AtoActivityStatementTransactionTable_Credit
  | FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate
  | FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate
  | FieldAndColumnName.AtoActivityStatementTransactionTable_Debit
  | FieldAndColumnName.AtoActivityStatementTransactionTable_Description

export const TRANSACTION_COLUMN_COUNT = 5

export class AtoActivityStatementTransaction extends ModelObject implements IItemObject {
  readonly amount: DollarField

  constructor(
    readonly processedDate: DateField,
    readonly effectiveDate: DateField,
    readonly description?: StringField,
    readonly debit?: DollarField,
    readonly credit?: DollarField,
    readonly balance?: DollarField
  ) {
    super()
    const amountOption = AtoActivityStatementTransaction.amountFromDebitCredit(debit, credit)
    if (amountOption) {
      this.amount = amountOption
    } else {
      throw new Error(`A transaction have to have either debit or credit but got ${debit} and ${credit}`)
    }
  }

  addColumn(
    gridColumnName: FieldAndColumnName,
    newValue: FormGridRowValue,
    modifiedBy: string
  ): AtoActivityStatementTransaction {
    switch(gridColumnName) {
      case FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate:
        return this.copy({ processedDate: new DateField(uuid(), newValue as moment.Moment, [], List(), modifiedBy)})

      case FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate:
        return this.copy({ effectiveDate: new DateField(uuid(), newValue as moment.Moment, [], List(), modifiedBy)})

      case FieldAndColumnName.AtoActivityStatementTransactionTable_Description:
        return this.copy({ description: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.AtoActivityStatementTransactionTable_Balance:
        return this.copy({ balance: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.AtoActivityStatementTransactionTable_Credit:
        return this.copy({ credit: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.AtoActivityStatementTransactionTable_Debit:
        return this.copy({ debit: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      default:
        return this
    }
  }

  deleteColumn(gridColumn: FieldAndColumnName): AtoActivityStatementTransaction {
    return this.copy({ [gridColumn]: null })
  }

  static amountFromDebitCredit(debit?: DollarField, credit?: DollarField): DollarField | undefined {
    if (debit && credit) {
      return undefined
    } else if (debit) {
      return debit
    } else if (credit) {
      return credit.copy({parsedValue: 0 - credit.parsedValue})
    } else {
      return undefined
    }
  }

  fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
    switch (column) {
      case FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate:
        return this.processedDate
      case FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate:
        return this.effectiveDate
      case FieldAndColumnName.TransactionTable_TotalCredit:
        return this.credit
      case FieldAndColumnName.TransactionTable_TotalDebit:
        return this.debit
      case FieldAndColumnName.TransactionTable_Description:
        return this.description
      case FieldAndColumnName.TransactionTable_OpeningBalance:
        return this.balance
      default:
        return undefined
    }
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }

  // toGridRow(id: number) {
  //   return {
  //     id,
  //     [FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate]: this.processedDate?.parsedValue?.toDate(),
  //     [FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate]: this.effectiveDate?.parsedValue?.toDate(),
  //     [FieldAndColumnName.AtoActivityStatementTransactionTable_Description]: this.description?.parsedValue,
  //     [FieldAndColumnName.AtoActivityStatementTransactionTable_Debit]: this._debit()?.parsedValue,
  //     [FieldAndColumnName.AtoActivityStatementTransactionTable_Credit]: this._credit()?.parsedValue,
  //     [FieldAndColumnName.AtoActivityStatementTransactionTable_Balance]: this.balance?.parsedValue
  //   }
  // }

  _debit(): DollarField | undefined {
    if (this.amount?.parsedValue && this.amount.parsedValue > 0) {
      return this.amount
    } else {
      return undefined
    }
  }

  _credit(): DollarField | undefined {
    if (this._debit()) {
      return undefined
    } else {
      return this.amount
    }
  }

  copy({
         processedDate = this.processedDate,
         effectiveDate = this.effectiveDate,
         description = this.description,
         debit = this.debit,
         credit = this.credit,
         balance = this.balance
       }): AtoActivityStatementTransaction {
    return new AtoActivityStatementTransaction(processedDate, effectiveDate, description, debit, credit, balance)
  }

  blockIds(): List<string> {
    return List(_.compact([this.processedDate?.id, this.effectiveDate?.id, this.description?.id,
      this.amount?.id, this.balance?.id]))
  }

  toJson(): IAtoActivityStatementTransactionTableDataRow {
    return {
      ProcessedDate: this.processedDate.toModelKeyValue(),
      EffectiveDate: this.effectiveDate.toModelKeyValue(),
      Description: this.description?.toModelKeyValue(),
      Credit: this.credit?.toModelKeyValue(),
      Debit: this.debit?.toModelKeyValue(),
      Balance: this.balance?.toModelKeyValue()
    }
  }
}

