import { IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import { DateField, DollarField, StringField } from '../DetectedField'

export class AtoQuarterlyPaygInstalmentNotice extends BusinessModel {
  constructor(
    version: string,
    readonly name: string,
    readonly recipient?: StringField,
    readonly address?: StringField,
    readonly documentId?: StringField,
    readonly abn?: StringField,
    readonly quarter?: StringField,
    readonly year?: StringField,
    readonly formDueOn?: DateField,
    readonly paymentDueOn?: DateField,
    readonly instalmentAmount?: DollarField,
    readonly atoCode?: StringField,
    readonly eftCode?: StringField
  ) {
    super(IModelType.AtoQuarterlyPaygInstalmentNotice, version)
  }

}
