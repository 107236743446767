import { CreateType, DrawStyle, Point } from './Types'

export abstract class BaseShape  {

  abstract readonly ctrlPointSize: number

  isActive = false
  isShapeUpdated = false
  isVisible = true
  isCreating =  false
  isDragging =  false
  isResizing = false
  activeCtrlPointIndex = -1
  dragRebuildReference: [number, number][] = []

  protected constructor(
    public shapeId: string,
    public shapeIndex: number,
    public coordinates: Point[],
    public drawStyle: DrawStyle,
    public tagName: string,
    public tagId: string,
    public shapeType: CreateType
  ){}

  abstract getAnnotationCoor(): any
  abstract isMouseOver(percentageMousePoint: Point, converter: (point: Point) => Point): boolean
  abstract drawShape(ctx: CanvasRenderingContext2D, coordinateConverter: (percentageMousePoint: Point) => Point): void
  abstract drawCtrlPoints(ctx: CanvasRenderingContext2D, coordinateConverter: (percentageMousePoint: Point) => Point): void

  isValidShape(): boolean {
    return true
  }

  getActiveCtrlPointIndex(point: Point, coordinateConverter: (percentageMousePoint: Point) => Point): number {
    return -1
  }

  setDragRebuildReference(percentageMousePoint: Point): void {
    const [x, y] = percentageMousePoint
    this.dragRebuildReference = this.coordinates.map(point => [point[0] - x, point[1] - y])
  }

  drag(percentageMousePoint: Point): void {
    const [x, y] = percentageMousePoint
    this.coordinates = this.dragRebuildReference.map( ref => [ref[0] + x, ref[1] + y]) as Point[]
  }

  sortCoordinates(): void {}
}


export class BaseShapes {
	constructor(
    public shapes: BaseShape[] = []
	) {}

	removeShape(shape: BaseShape){
		this.shapes.splice(shape.shapeIndex, 1)
	}

	replaceShape(shape: BaseShape){
		this.shapes.splice(shape.shapeIndex, 1, shape)
	}

	addShape(shape: BaseShape){
		this.shapes.push(shape)
	}

	forEach(cb: (shape: BaseShape, index: number) => void){
		return this.shapes.forEach(cb)
	}
}