import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {
    IBusinessModel,
    IModelType,
    IRolloverBenefitStatementModel
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {RolloverBenefitStatement} from './RolloverBenefitStatement'

@autoBind
export class RolloverBenefitStatementMapper extends BusinessModelMapper {
    constructor(
        document:OcrDocument,
        private readonly jsonModel: IRolloverBenefitStatementModel
    ){
        super(document)
    }

    fromJson(): RolloverBenefitStatement {
        const RolloverBenefitStatementModel = this.jsonModel
        const model = new RolloverBenefitStatement(
            IModelType.RolloverBenefitStatement,
            RolloverBenefitStatementModel.Version,
            this.mapTextBlock(RolloverBenefitStatementModel.ReceivingFundABN),
            this.mapTextBlock(RolloverBenefitStatementModel.ReceivingFundName),
            this.mapTextBlock(RolloverBenefitStatementModel.TransferringFundName),
            this.mapTextBlock(RolloverBenefitStatementModel.TransferringFundABN),
            this.mapTextBlock(RolloverBenefitStatementModel.Tfn),
            this.mapTextBlock(RolloverBenefitStatementModel.FullName),
            this.mapDateBlock(RolloverBenefitStatementModel.ServicePeriodStartDate),
            this.mapDoubleBlock(RolloverBenefitStatementModel.TaxFreeComponent),
            this.mapDoubleBlock(RolloverBenefitStatementModel.PreservedAmount),
            this.mapDoubleBlock(RolloverBenefitStatementModel.ElementTaxedInTheFund),
            this.mapDoubleBlock(RolloverBenefitStatementModel.ElementUntaxedInTheFund),
            this.mapDoubleBlock(RolloverBenefitStatementModel.RestrictedNonPreservedAmount),
            this.mapDoubleBlock(RolloverBenefitStatementModel.UnrestrictedNonPreservedAmount)
        )
        return model
    }

    static toJson(model: RolloverBenefitStatement): IBusinessModel {
        return {
            ModelType: IModelType.RolloverBenefitStatement,
            Version: model.version,
            ReceivingFundABN: model.receivingFundABN?.toModelKeyValue(),
            ReceivingFundName: model.receivingFundName?.toModelKeyValue(),
            TransferringFundName: model.transferringFundName?.toModelKeyValue(),
            TransferringFundABN: model.transferringFundABN?.toModelKeyValue(),
            Tfn: model.tfn?.toModelKeyValue(),
            FullName: model.fullName?.toModelKeyValue(),
            ServicePeriodStartDate: model.servicePeriodStartDate?.toModelKeyValue(),
            TaxFreeComponent: model.taxFreeComponent?.toModelKeyValue(),
            PreservedAmount: model.preservedAmount?.toModelKeyValue(),
            ElementTaxedInTheFund: model.elementTaxedInTheFund?.toModelKeyValue(),
            ElementUntaxedInTheFund: model.elementUntaxedInTheFund?.toModelKeyValue(),
            RestrictedNonPreservedAmount: model.restrictedNonPreservedAmount?.toModelKeyValue(),
            UnrestrictedNonPreservedAmount: model.unrestrictedNonPreservedAmount?.toModelKeyValue()

        } as IRolloverBenefitStatementModel
    }
}
