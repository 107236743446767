import {
  BankAuthorityAccounts,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import type { GridColDef } from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React, { useMemo } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { BankAuthTableData } from './BankAuthTableData'

interface IBankAuthorityTableEditorProps extends EditorFieldEvents {
  accounts?: BankAuthorityAccounts
}

export const BankAuthorityAccountsTableEditor = (
  props: IBankAuthorityTableEditorProps
) => {
  const columns: GridColDef[] = [
    {
      field: FieldAndColumnName.BankAuthorityAccounts_AccountName,
      headerName: 'ACCOUNT NAME',
      description: 'Account Name',
      width: 350,
      editable: true
    },
    {
      field: FieldAndColumnName.BankAuthorityAccounts_AccountNumber,
      headerName: 'ACCOUNT NUMBER',
      description: 'Account number',
      width: 200,
      editable: true
    },
    {
      field: FieldAndColumnName.BankAuthorityAccounts_Bsb,
      headerName: 'BSB',
      description: 'bsb',
      width: 200,
      editable: true
    }
  ]

  const rows = BankAuthTableData.toRows(props.accounts?.bankAuthorityAccounts)
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
    />
  )
}
