export const domain = window.location.origin
export const isLocalhost =
  domain.indexOf('localhost') > -1 ||
  domain.indexOf('192.168') > -1 ||
  domain.indexOf('0.0.0.0') > -1
const isUat = domain.indexOf('uat.doc.com.ai') > -1
export const isProd = !isLocalhost && !isUat

export const ssoURL = isProd
  ? 'https://sso.bgl360.com.au'
  : 'https://sso.uat.bgl360.com.au'

export const apiPath = isLocalhost
  ? 'http://localhost:8888'
  : isProd
  ? 'https://smartdocs-web.doc.com.ai'
  : 'https://smartdocs-web.uat.doc.com.ai'

export const assistantWebBaseUrl = isLocalhost
    ? 'http://localhost:8888'
    : isProd
        ? 'https://smartdocs-web.doc.com.ai'
        : 'https://smartdocs-ai-studio-web.uat.doc.com.ai'
// export const apiPath = 'http://localhost:8000'
