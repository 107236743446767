export type AuthResponseDTO = {
  username: string
  userFirstName: string
  userLastName: string
  bglFirmId: string
  roleName: string
  authorities: Authorization[]
  logoutId: string
  firmCountry: string
  firmName: string
  shortName: string
}

// export type Authority =
//   | 'AUTH_CREATE_PROJECT'
//   | 'AUTH_EDIT_PROJECT'
//   | 'AUTH_DELETE_PROJECT'
//   | 'AUTH_SYNC_PROJECT'
//   | 'AUTH_PAYMENT'
//   | 'AUTH_LABEL_UPLOAD_DOCUMENT'
//   | 'AUTH_LABEL_DELETE_DOCUMENT'
//   | 'AUTH_LABEL_DOWNLOAD_DOCUMENT'
//   | 'AUTH_MANAGE_TAG'
//   | 'AUTH_CREATE_TRAINING'
//   | 'AUTH_VIEW_TRAINING'
//   | 'AUTH_USE_UPLOAD_DOCUMENT'
//   | 'AUTH_USE_DELETE_DOCUMENT'
//   | 'AUTH_USE_DOWNLOAD_DOCUMENT'
//   | 'AUTH_DOCUMENT_CSV_EXPORT'
//   | 'AUTH_VIEW_API_KEY'
//   | 'AUTH_MANAGE_API_KEY'
//   | 'AUTH_MANAGE_USERS'
//   | 'AUTH_MANAGE_ROLES'

export enum ProjectAuthorization {
  AUTH_CREATE_PROJECT = 'AUTH_CREATE_PROJECT',
  AUTH_EDIT_PROJECT = 'AUTH_EDIT_PROJECT',
  AUTH_DELETE_PROJECT = 'AUTH_DELETE_PROJECT',
  AUTH_SYNC_PROJECT = 'AUTH_SYNC_PROJECT'
}

export enum PaymentAuthorization {
  AUTH_PAYMENT = 'AUTH_PAYMENT'
}

export enum DocumentAuthorization {
  AUTH_DOCUMENT_UPLOAD = 'AUTH_DOCUMENT_UPLOAD',
  AUTH_DOCUMENT_DELETE = 'AUTH_DOCUMENT_DELETE',
  AUTH_DOCUMENT_DOWNLOAD = 'AUTH_DOCUMENT_DOWNLOAD'
}

export enum LabelDocumentAuthorization {
  AUTH_LABEL_UPLOAD_DOCUMENT = 'AUTH_LABEL_UPLOAD_DOCUMENT',
  AUTH_LABEL_DELETE_DOCUMENT = 'AUTH_LABEL_DELETE_DOCUMENT',
  AUTH_LABEL_DOWNLOAD_DOCUMENT = 'AUTH_LABEL_DOWNLOAD_DOCUMENT'
}

export enum UseDocumentAuthorization {
  AUTH_USE_UPLOAD_DOCUMENT = 'AUTH_USE_UPLOAD_DOCUMENT',
  AUTH_USE_DELETE_DOCUMENT = 'AUTH_USE_DELETE_DOCUMENT',
  AUTH_USE_DOWNLOAD_DOCUMENT = 'AUTH_USE_DOWNLOAD_DOCUMENT'
}

export enum DocumentCsvExportAuthorization {
  AUTH_DOCUMENT_CSV_EXPORT = 'AUTH_DOCUMENT_CSV_EXPORT'
}

export enum ManageTagAuthorization {
  AUTH_MANAGE_TAG = 'AUTH_MANAGE_TAG'
}

export enum TrainingAuthorization {
  AUTH_CREATE_TRAINING = 'AUTH_CREATE_TRAINING',
  AUTH_VIEW_TRAINING = 'AUTH_VIEW_TRAINING'
}

export enum ApiKeyAuthorization {
  AUTH_VIEW_API_KEY = 'AUTH_VIEW_API_KEY',
  AUTH_MANAGE_API_KEY = 'AUTH_MANAGE_API_KEY'
}

export enum ManageAuthorization {
  AUTH_MANAGE_USERS = 'AUTH_MANAGE_USERS',
  AUTH_MANAGE_ROLES = 'AUTH_MANAGE_ROLES'
}

export type Authorization =
  | ProjectAuthorization
  | PaymentAuthorization
  | LabelDocumentAuthorization
  | UseDocumentAuthorization
  | DocumentCsvExportAuthorization
  | ManageTagAuthorization
  | TrainingAuthorization
  | ApiKeyAuthorization
  | ManageAuthorization

class Authorizations {
  constructor(
    readonly authorities: Authorization[],
    readonly username: string,
    readonly bglFirmId: string,
    readonly roleName: string,
    readonly firmName: string,
    readonly shortName: string,
    readonly userFirstName: string,
    readonly userLastName: string,
    readonly userFullName: string
  ) {}

  static fromDTO(dto: AuthResponseDTO): Authorizations {
    return new Authorizations(
      dto.authorities,
      dto.username,
      dto.bglFirmId,
      dto.roleName,
      dto.firmName,
      dto.shortName,
      dto.userFirstName,
      dto.userLastName,
      `${dto.userFirstName} ${dto.userLastName}`
    )
  }

  hasAuthority(authority: Authorization): boolean {
    return this.authorities.includes(authority)
  }
}

export default Authorizations
