import {
  AnnualTaxStatement,
  ASICFee,
  AtoActivityStatement,
  AtoPaygInstalmentReport,
  AtoQuarterlyPaygInstalmentNotice,
  BankAuthority,
  BankStatement,
  BusinessCard,
  BusinessModel,
  BusinessModelType,
  ChartOfAccounts360,
  ContractNote,
  CouncilRate,
  DividendStatement,
  IdCard,
  IModelType,
  Invoice,
  Receipt,
  RolloverBenefitStatement,
  UtilityBill,
  WaterBill
} from '@bgl/textract-business-model-editor'
import React, { FunctionComponent } from 'react'

import { AnnualTaxStatementEditor } from './annualTaxStatement/AnnualTaxStatementEditor'
import { ASICFeeEditor } from './asicFee/ASICFeeEditor'
import { AtoActivityStatementEditor } from './atoActivityStatement/AtoActivityStatementEditor'
import { AtoPaygInstalmentReportEditor } from './atoPaygInstalmentReport/AtoPaygInstalmentReportEditor'
import { AtoQuarterlyPaygInstalmentNoticeEditor } from './atoQuarterlyPaygInstalmentNotice/AtoQuarterlyPaygInstalmentNoticeEditor'
import { BankAuthorityEditor } from './bankAuthority/BankAuthorityEditor'
import { BankStatementEditor } from './bankstatement/BankStatementEditor'
import { BusinessCardEditor } from './businesscard/BusinessCardEditor'
import { BusinessModelTypeEditor } from './businessModelType/BusinessModelTypeEditor'
import ChartOfAccounts360Editor from './chartOfAccounts360/ChartOfAccounts360Editor'
import { ContractNoteEditor } from './contractnote/ContractNoteEditor'
import { CouncilRateEditor } from './councilRate/CouncilRateEditor'
import { DividendStatementEditor } from './dividendstatement/DividendStatementEditor'
import { EditorFieldEvents } from './EditorFieldEvents'
import { IdCardEditor } from './idcard/IdCardEditor'
import { InvoiceEditor } from './invoice/InvoiceEditor'
import { ReceiptEditor } from './receipt/ReceiptEditor'
import { RolloverBenefitStatementEditor } from './rolloverBenefitStatement/RolloverBenefitStatementEditor'
import { WaterBillEditor } from './waterbill/WaterBillEditor'

interface BusinessEditorProps extends EditorFieldEvents {
  businessModel: BusinessModel
  className?: string
  readonlyMode?: boolean
  tableReadOnly?: boolean
}

export const BusinessModelEditor: FunctionComponent<BusinessEditorProps> = (
  props: BusinessEditorProps
) => {
  const { businessModel, className, readonlyMode, ...others } = props

  switch (businessModel?.modelType) {
    case IModelType.BankStatement:
      return (
        <BankStatementEditor
          bankStatement={businessModel as BankStatement}
          className={className}
          readonlyMode={readonlyMode}
          tableReadOnly={props.tableReadOnly}
          {...others}
        />
      )
    case IModelType.ContractNote:
      return (
        <ContractNoteEditor
          contractNote={businessModel as ContractNote}
          className={className}
          readonlyMode={readonlyMode}
          {...others}
        />
      )
    case IModelType.Receipt:
      return (
        <ReceiptEditor
          receiptProps={businessModel as Receipt}
          className={className}
          {...others}
        />
      )
    case IModelType.BusinessCard:
      return (
        <BusinessCardEditor
          businessCard={businessModel as BusinessCard}
          className={className}
          {...others}
        />
      )
    case IModelType.Invoice:
      return (
        <InvoiceEditor
          invoiceProps={businessModel as Invoice}
          className={className}
          {...others}
        />
      )
    case IModelType.IdCard:
      return (
        <IdCardEditor
          idCard={businessModel as IdCard}
          className={className}
          {...others}
        />
      )
    case IModelType.WaterBill:
      return (
        <WaterBillEditor
          waterBill={businessModel as WaterBill}
          className={className}
          readonlyMode={readonlyMode}
          {...others}
        />
      )

    case IModelType.UtilityBill:
      return (
        <WaterBillEditor
          utilityBill={businessModel as UtilityBill}
          className={className}
          readonlyMode={readonlyMode}
          {...others}
        />
      )

    case IModelType.DividendStatement:
      return (
        <DividendStatementEditor
          dividendStatement={businessModel as DividendStatement}
          className={className}
          {...others}
        />
      )
    case IModelType.AnnualTaxStatement:
      return (
        <AnnualTaxStatementEditor
          annualTaxStatement={businessModel as AnnualTaxStatement}
          className={className}
          {...others}
        />
      )
    case IModelType.ASICFee:
      return (
        <ASICFeeEditor
          asicFee={businessModel as ASICFee}
          className={className}
          {...others}
        />
      )
    case IModelType.CouncilRate:
      return (
        <CouncilRateEditor
          councilRate={businessModel as CouncilRate}
          className={className}
          {...others}
        />
      )
    case IModelType.AtoActivityStatement:
      return (
        <AtoActivityStatementEditor
          atoActivityStatement={businessModel as AtoActivityStatement}
          className={className}
          {...others}
        />
      )
    case IModelType.AtoPaygInstalmentReport:
      return (
        <AtoPaygInstalmentReportEditor
          paygInstalmentReport={businessModel as AtoPaygInstalmentReport}
          className={className}
          {...others}
        />
      )
    case IModelType.AtoQuarterlyPaygInstalmentNotice:
      return (
        <AtoQuarterlyPaygInstalmentNoticeEditor
          atoQuarterlyPaygInstalmentNotice={
            businessModel as AtoQuarterlyPaygInstalmentNotice
          }
          className={className}
          {...others}
        />
      )
    case IModelType.RolloverBenefitStatement:
      return (
        <RolloverBenefitStatementEditor
          rolloverBenefitStatement={businessModel as RolloverBenefitStatement}
          className={className}
          {...others}
        />
      )
    case IModelType.ChartOfAccounts360:
      return (
        <ChartOfAccounts360Editor
          chartOfAccounts360={businessModel as ChartOfAccounts360}
          {...others}
        />
      )
    case IModelType.BankAuthority:
      return (
        <BankAuthorityEditor
          bankAuthority={businessModel as BankAuthority}
          className={className}
          {...others}
        />
      )
    case IModelType.BusinessModelType:
      return (
        <BusinessModelTypeEditor
          businessModelType={businessModel as BusinessModelType}
          {...others}
        />
      )
    default:
      return <></>
  }
}
export default BusinessModelEditor
