import {
  AtoPaygInstalmentReport,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import React from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormStringField } from '../FormStringField'
import PayGInstalmentYearlyTableEditor from './PayGInstalmentYearlyTableEditor'

interface IPagyInstalmentReportEditorProps extends EditorFieldEvents {
  paygInstalmentReport: AtoPaygInstalmentReport
  className?: string
}

export const AtoPaygInstalmentReportEditor = (
  props: IPagyInstalmentReportEditorProps
) => {
  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormStringField
            id={
              FieldAndColumnName.AtoPaygInstallmentStatementEditor_FinancialYear
            }
            label={'Financial Year'}
            events={props}
            field={props.paygInstalmentReport.financialYear}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoPaygInstallmentStatementEditor_TaxAgent}
            label={'TFN Number'}
            events={props}
            field={props.paygInstalmentReport.taxAgent}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={
              FieldAndColumnName.AtoPaygInstallmentStatementEditor_LastUpdated
            }
            label={'Last Updated'}
            events={props}
            field={props.paygInstalmentReport.lastUpdatedDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={12}>
          <PayGInstalmentYearlyTableEditor
            payGInstalmentYearlyTable={
              props.paygInstalmentReport.payGInstalmentYearlyTable
            }
          />
        </Grid>
      </Grid>
    </form>
  )
}
