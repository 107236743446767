import ModelObject from "domain/businessModel/ModelObject";
import { FormFieldValue } from "domain/validator/IFieldValidator";
import { isNil, isNull, isString } from "lodash";
import { FormGridRowId } from "utils/DataTypeMapper";
import { FieldAndColumnName, FormFieldValidationResult } from "./FieldValidatorDef";
import { FormFieldValidator } from "./FormFieldValidator";
import { IValidatorFactory } from "./IValidatorFactory";

class WebsiteValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BusinessCardEditor_Websites

    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        if (isNil(value)) {
            return FormFieldValidationResult.BUSINESSCARD_EMPTY_WEBSITE
        }

        if (!isString(value)) {
            return FormFieldValidationResult.INVALID_INPUT
        }

        return value.startsWith("www.")
            ? FormFieldValidationResult.VALIDATION_SUCCESS
            : FormFieldValidationResult.BUSINESSCARD_INCORRECT_WEBSITE
    }

    getName() {
        return WebsiteValidator.fieldName
    }
}

class MobileValidator extends FormFieldValidator {
    static fieldName = FieldAndColumnName.BusinessCardEditor_MobilePhones
    
    validate(value: FormFieldValue, record: ModelObject | undefined, id: FormGridRowId): FormFieldValidationResult {
        // TODO: validate condition below is demo code, need real logic
        if (isNull(value)) {
            return FormFieldValidationResult.VALIDATION_SUCCESS
        }

        if (!isString(value)) {
            return FormFieldValidationResult.INVALID_INPUT
        }

        return value.startsWith("04")
            ? FormFieldValidationResult.VALIDATION_SUCCESS
            : FormFieldValidationResult.BUSINESSCARD_INCORRECT_MOBILE
    }

    getName() {
        return MobileValidator.fieldName
    }
}

export const BusinessCardValidatorFactory: IValidatorFactory<FormFieldValidator> = (fieldName: string) => {
    switch (fieldName) {
        case WebsiteValidator.fieldName:
            return new WebsiteValidator()
        case MobileValidator.fieldName:
            return new MobileValidator()
        
        default:
            throw Error(`Invalid fieldName(${fieldName}) received in BusinessCardValidatorFactory`)
    }
}
