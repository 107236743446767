import React, { useState } from 'react'

import autoMatedLablling from '../../assets/images/xdigrame001.png.pagespeed.ic.UElIOBRUFQ.png'
import step1 from '../../assets/videos/step1.mp4'
import step2 from '../../assets/videos/step2.mp4'
import step3 from '../../assets/videos/step3.mp4'

const STEP_1 = 'step1'
const STEP_2 = 'step2'
const STEP_3 = 'step3'

function CustomTraining() {
  const [step, updateStep] = useState(STEP_1)

  const handlClick = () => {
    if (step === STEP_1) {
      updateStep(STEP_2)
    } else if (step === STEP_2) {
      updateStep(STEP_3)
    } else if (step === STEP_3) {
      updateStep(STEP_1)
    }
  }

  return (
    <section className="custom-training" id="customform">
      <div className="top">
        <div className="container container-wrapper">
          <h3 className="title">Approach 2:</h3>
          <h3 className="sub-title">Custom Training</h3>
          {/* eslint-disable-next-line */}
          <p>
            SmartDocs AI Studio also supports you to train any document by
            creating your own model. You are ready to extract in three easy
            steps - with no coding required.
          </p>
        </div>
      </div>
      <div className="bottom">
        <div className="container bottom-wrapper">
          <div className="steps">
            <div className="left">
              <h3 className={step === STEP_1 ? 'selected-h3' : ''}>
                Step 01 - TAG
              </h3>
              <p className={step === STEP_1 ? 'selected-p' : ''}>
                Upload the document file and tag your desired information.
              </p>
              <h3 className={step === STEP_2 ? 'selected-h3' : ''}>
                STEP 02 - TRAIN
              </h3>
              <p className={step === STEP_2 ? 'selected-p' : ''}>
                Train a customised model. Then check the performance via the
                Visual Dashboard.
              </p>
              <h3 className={step === STEP_3 ? 'selected-h3' : ''}>
                STEP 03 - PREDICT/USE
              </h3>
              <p className={step === STEP_3 ? 'selected-p' : ''}>
                Upload new files that you would like to extract information
                from. The Model then predicts the results.
              </p>
              <button
                type="button"
                onClick={handlClick}
                className={'stepButton'}
              >
                {step === STEP_3 ? 'Start Again' : 'Next Step'}
              </button>
            </div>
            <div className="right">
              <video
                width="100%"
                height="auto"
                autoPlay
                loop
                muted
                controls
                preload="auto"
                // src={require(`../../assets/videos/step${step === STEP_1 ? "1" : step === STEP_2 ? "2" : "3"}.mp4`)}
                src={step === STEP_1 ? step1 : step === STEP_2 ? step2 : step3}
              >
                <track kind="captions" />
              </video>
            </div>
          </div>
          <div className="circles">
            {/* eslint-disable-next-line */}
            <div
              className={step === STEP_1 ? 'filled-circle' : 'circle'}
              onClick={() => updateStep(STEP_1)}
            />
            {/* eslint-disable-next-line */}
            <div
              className={step === STEP_2 ? 'filled-circle' : 'circle'}
              onClick={() => updateStep(STEP_2)}
            />
            {/* eslint-disable-next-line */}
            <div
              className={step === STEP_3 ? 'filled-circle' : 'circle'}
              onClick={() => updateStep(STEP_3)}
            />
          </div>
          <div className="img">
            <img src={autoMatedLablling} alt="automated-labelling" />
          </div>
          <div className="details">
            <div className="left">
              <h3>
                Build your first AI Model with SmartDocs AI Studio in 30 minutes
              </h3>
              <p>
                SmartDocs AI Studio offers an easy and quick way to train AI
                models. Plus you do not need any AI knowledge to make it happen.
              </p>
              <div className="custom-training-demo">
                <a href="/#/contact-us">Request Demo</a>
              </div>
            </div>
            <div className="right">
              <iframe
                loading="lazy"
                title="SmartDocs AI Studio | Product demonstration | BGL Corporate Solutions"
                src="https://www.youtube.com/embed/NsbehDqwcMM?feature=oembed"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                name="fitvid0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomTraining
