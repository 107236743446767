import {Geometry, OcrElement} from "./OcrElement"
import {IWordBlock} from "../types/ISmartDocsResult"
import {List} from "immutable"
import {Line} from "./Line"
import {uuid} from "../../utils/utils"

export class Word extends OcrElement {
  constructor(
    id: string,
    page: number,
    confidence: number,
    geometry: Geometry,
    public readonly text: string,
    public readonly textType?: string
  ) {
    super(id, page, confidence, geometry)
  }

  static mergeToLineBasedOnPage(elements: List<Word>): List<Line> {
    const groupByPages = elements.groupBy(e => e.page)
    const lines = groupByPages.map((elements, page) => {
      const listOfElements = elements.toList()
      const text = listOfElements.map(textElement => textElement.text).join(" ")
      return new Line(
        uuid(),
        page,
        OcrElement.averageConfidence(listOfElements),
        OcrElement.mergeGeometries(listOfElements.map(e => e.geometry))!,
        text,
        listOfElements
      )
    })
    return lines.toList()
  }

  static fromJson(word: IWordBlock): Word {
    return new Word(
      word.Id, word.Page, word.Confidence, Geometry.fromJson(word.Geometry), word.Text, word.TextType
    )
  }
}
