import {
  ISmartDocsResult,
  SmartDocResult
} from '@bgl/textract-business-model-editor'
import { Box, Paper, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { List, OrderedMap } from 'immutable'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

// import AnnualTaxStatementResult from '../../assets/documents/AnnualTaxStatement.json'
// import AnnualTaxStatementPDF from '../../assets/documents/AnnualTaxStatement.pdf'
import BankStatementResult from '../../assets/documents/BankStatement.json'
import BankStatementPDF from '../../assets/documents/BankStatement.pdf'
import ContractNoteResult from '../../assets/documents/ContractNote.json'
import ContractNotePDF from '../../assets/documents/ContractNote.pdf'
import {
  DemoAnnualTaxStatementTags,
  DemoBankStatementTags,
  DemoContractNoteTags,
  DemoDividendStatementTags,
  DemoRentalSummaryTags,
  DemoWaterBillTags,
  EmptyTags
} from '../../assets/documents/DemoTags'
import DividendStatementResult from '../../assets/documents/DividendStatement.json'
import DividendStatementPDF from '../../assets/documents/DividendStatement.pdf'
import InvoiceResult from '../../assets/documents/Invoice.json'
import InvoicePdf from '../../assets/documents/Invoice.pdf'
import ReceiptResult from '../../assets/documents/Receipt.json'
import ReceiptPDF from '../../assets/documents/Receipt.pdf'
// import RentalSummaryResult from '../../assets/documents/RentalSummary.json'
// import RentalSummaryPDF from '../../assets/documents/RentalSummary.pdf'
import WaterBillResult from '../../assets/documents/WaterBill.json'
import WaterBillPDF from '../../assets/documents/WaterBill.pdf'
import { Tags } from '../../models/Tag'
import { InsertAnnotationCss } from '../../utils/InsertCss'
import BusinessModelEditor from '../businessModelEditor/BusinessModelEditor'
import WordLevelDocViewer from '../businessModelEditor/docviewer/WordLevelDocViewer'

interface DemoModel {
  fileId: string
  pdf: Blob
  result: SmartDocResult
  tags: Tags
}

const models = {
  ModelBankStatement: {
    fileId: 'demo-bankstatement-fileid',
    pdf: BankStatementPDF as unknown as Blob,
    result: SmartDocResult.fromJson(
      BankStatementResult as unknown as ISmartDocsResult
    ),
    tags: DemoBankStatementTags
  },
  ModelUtilityBill: {
    fileId: 'demo-waterbill-fileid',
    pdf: WaterBillPDF as unknown as Blob,
    result: SmartDocResult.fromJson(
      WaterBillResult as unknown as ISmartDocsResult
    ),
    tags: DemoWaterBillTags
  },
  ModelContractNote: {
    fileId: 'demo-contractnote-fileid',
    pdf: ContractNotePDF as unknown as Blob,
    result: SmartDocResult.fromJson(
      ContractNoteResult as unknown as ISmartDocsResult
    ),
    tags: DemoContractNoteTags
  },
  ModelInvoice: {
    fileId: 'demo-invoice-fileid',
    pdf: InvoicePdf as unknown as Blob,
    result: SmartDocResult.fromJson(
      InvoiceResult as unknown as ISmartDocsResult
    ),
    tags: EmptyTags
  },
  ModelReceipt: {
    fileId: 'demo-receipt-fileid',
    pdf: ReceiptPDF as unknown as Blob,
    result: SmartDocResult.fromJson(
      ReceiptResult as unknown as ISmartDocsResult
    ),
    tags: EmptyTags
  },
  ModelDividendStatement: {
    fileId: 'demo-dividendstatement-fileid',
    pdf: DividendStatementPDF as unknown as Blob,
    result: SmartDocResult.fromJson(
      DividendStatementResult as unknown as ISmartDocsResult
    ),
    tags: DemoDividendStatementTags
  }
  // ModelAnnualTaxStatement: {
  //   fileId: 'demo-annualtaxstatement-fileid',
  //   pdf: AnnualTaxStatementPDF as unknown as Blob,
  //   result: SmartDocResult.fromJson(
  //     AnnualTaxStatementResult as unknown as ISmartDocsResult
  //   ),
  //   tags: DemoAnnualTaxStatementTags
  // },
  // ModelRentalSummary: {
  //   fileId: 'demo-rentalsummary-fileid',
  //   pdf: RentalSummaryPDF as unknown as Blob,
  //   result: SmartDocResult.fromJson(
  //     RentalSummaryResult as unknown as ISmartDocsResult
  //   ),
  //   tags: DemoRentalSummaryTags
  // }
}
const useStyles = makeStyles(() => ({
  homePagePdfViewerDemo: {
    width: '50%',
    '& .selectedAnnotationIds': {
      transform: 'rotateY(180deg)',
      transition:
        'transform 0.6s ease-in-out, boxShadow 0.3s ease-in-out, backgroundColor 0.3s ease-in-out',
      boxShadow: '0 5px 1px -3px purple',
      backgroundColor: 'rgba(255, 255, 0, 0.3)'
    },
    '& .scaleFactorText': {
      margin: '0'
    },
    '& .appBar': {
      position: 'unset'
    },
    '@media (max-width: 767px)': {
      width: '100%',
      flexBasis: '100%',
      height: '50%',
      margin: 0
    }
  },
  homePageModelEditor: {
    width: '50%',
    backgroundColor: 'white',
    padding: '1.5rem',
    overflowY: 'auto',
    '& p': {
      fontSize: '14px !important'
    },
    '@media (max-width: 767px)': {
      width: '100%',
      flexBasis: '100%',
      height: '50%',
      margin: 0
    }
  },
  sampleWrapper: {
    width: '100%',
    borderBottom: '1px solid #dcdcdc',
    borderLeft: '1px solid #dcdcdc',
    borderRight: '1px solid #dcdcdc'
  },
  sample: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    },
    width: '100%',
    height: '600px',
    paddingTop: '10px',
    paddingBottom: '10px'
  }
}))

function PretrainedAIModels() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [model, setModel] = useState<DemoModel>(models.ModelBankStatement)
  const addClassNameToSelectedAnnotationIds = (id: string) => {
    const element = document.getElementById(id)
    element && element.classList.add('selectedAnnotationIds')
  }
  const removeAllSelectedAnnotationIds = () => {
    document.querySelectorAll('.selectedAnnotationIds').forEach((element) => {
      element.classList.remove('selectedAnnotationIds')
    })
  }
  const pdfViewer = useRef<WordLevelDocViewer | null>(null)

  useEffect(() => {
    const annotations = model.result.wordAnnotations.toJson()
    InsertAnnotationCss(model.fileId, annotations, model.tags)
  }, [model])

  const showAndSelect = (ids?: string[]) => {
    if (!ids || ids.length === 0) {
      return
    }
    pdfViewer.current?.scrollIntoView(_.head(ids))
    removeAllSelectedAnnotationIds()
    ids.map((id) => addClassNameToSelectedAnnotationIds(id))
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        setModel(models.ModelBankStatement)
        break
      case 1:
        setModel(models.ModelUtilityBill)
        break
      case 2:
        setModel(models.ModelContractNote)
        break
      case 3:
        setModel(models.ModelInvoice)
        break
      case 4:
        setModel(models.ModelReceipt)
        break
      case 5:
        setModel(models.ModelDividendStatement)
        break
      // case 6:
      //   setModel(models.ModelAnnualTaxStatement)
      //   break
      // case 7:
      //   setModel(models.ModelRentalSummary)
      //   break
      default:
        setModel(models.ModelBankStatement)
    }
    setValue(newValue)
  }

  // @ts-ignore
  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <Paper elevation={2} square>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
        >
          <Tab label="Bank Statement" className={'modelTab'} />
          <Tab label="Utility Bill" className={'modelTab'} />
          <Tab label="Contract Note" className={'modelTab'} />
          <Tab label="Invoice" className={'modelTab'} />
          <Tab label={'Receipt'} className={'modelTab'} />
          <Tab label={'Dividend Statement'} className={'modelTab'} />
          {/*<Tab label={'Annual Tax Statement'} className={'modelTab'} />*/}
          {/*<Tab label={'Rental Summary'} className={'modelTab'} />*/}
        </Tabs>
      </Paper>
      <div className={classes.sampleWrapper}>
        <Box role={'tabpanel'} className={classes.sample}>
          <div className={classes.homePagePdfViewerDemo}>
            <WordLevelDocViewer
              ref={(ref) => {
                pdfViewer.current = ref
              }}
              pdfBlob={model.pdf}
              fileName="BankStatement"
              document={model.result.document}
              annotationMap={OrderedMap<string, List<string>>()}
              disableDownload={true}
              disableSelection={true}
            />
          </div>
          <div className={classes.homePageModelEditor}>
            {model.result.models.first() && (
              <BusinessModelEditor
                // @ts-ignore
                businessModel={model.result.models.first()}
                onFieldFocus={(editorElementId, field) =>
                  showAndSelect(field?.wordIds)
                }
              />
            )}
          </div>
        </Box>
      </div>
    </div>
  )
}

export default PretrainedAIModels
