import {
  AtoActivityStatementTransactions,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import type {
  GridCellParams,
  GridColDef,
  GridValueGetterParams
} from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import moment from 'moment'
import React from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { AtoTransactionTableRow } from './AtoTransactionToTableData'

interface IAtoTransactionTableEditorProps extends EditorFieldEvents {
  transactions?: AtoActivityStatementTransactions
}

export const AtoTransactionTableEditor = (
  props: IAtoTransactionTableEditorProps
) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function renderCell(params: GridCellParams): React.ReactNode {
    const dateString = params.value?.toString()
    const newValue = moment(dateString).format('DD/MM/YYYY')
    return <p>{newValue}</p>
  }

  const columns: GridColDef[] = [
    {
      field:
        FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate,
      type: 'date',
      headerName: 'PROCESSED DATE',
      description: 'Processed Date',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => renderCell(params)
    },
    {
      field:
        FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate,
      type: 'date',
      headerName: 'EFFECTIVE DATE',
      description: 'Effective Date',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => renderCell(params)
    },
    {
      field: FieldAndColumnName.TransactionTable_Description,
      headerName: 'DESCRIPTION',
      width: 350,
      editable: true
    },
    {
      field: FieldAndColumnName.TransactionTable_TotalDebit,
      type: 'number',
      headerName: 'DEBIT',
      width: 120,
      editable: true
    },
    {
      field: FieldAndColumnName.TransactionTable_TotalCredit,
      type: 'number',
      headerName: 'CREDIT',
      width: 120,
      valueGetter: (params: GridValueGetterParams) => {
        return Math.abs(params.value as number)
      },
      editable: true
    },
    {
      field: FieldAndColumnName.TransactionTable_OpeningBalance,
      type: 'number',
      headerName: 'BALANCE',
      width: 120,
      hide: false,
      editable: true
    }
  ]
  const rows = AtoTransactionTableRow.toRows(props.transactions)
  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
    />
  )
}

export default AtoTransactionTableEditor
