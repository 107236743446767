import {
  FieldAndColumnName,
  FormControlContext,
  FormFieldValidator,
  FormGridRowValue,
  StringField
} from '@bgl/textract-business-model-editor'
import { TextField } from '@material-ui/core'
import { debounce, has } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { EditorFieldEvents } from './EditorFieldEvents'

export const FormStringField: FunctionComponent<{
  id: FieldAndColumnName
  label: string
  events: EditorFieldEvents
  field?: StringField
  className?: string
  readonlyMode?: boolean
}> = ({ id, label, events, field, className, readonlyMode }) => {
  const {
    // handleInputValue,
    errors
    // record
  } = useContext(FormControlContext)
  const errorKey = useMemo(
    () => FormFieldValidator.generateErrorKey(id, field?.id || ''),
    [id, field]
  )
  const update = (value: FormGridRowValue) =>
    events.onValueChanged?.(id, value, field)
  const debouncedOnChange = useCallback(debounce(update, 400), [
    events.onValueChanged
  ])

  // REMINDER: We delete the useEffect because it may cause a delay in the input render, thus if user inputs too fast, it will cause flashing and disappearing
  // However, as we set the initial state of "value" to field?.parsedValue, it may cause a bug that if the parsedValue changes after the initial render,"value"
  // will not be updated.
  // For example, if units changes, and the total amount is calculated based on units, the total amount will not be updated.
  const [value, updateValue] = useState<string>(field?.parsedValue || '')

  // NOTE: DO NOT DELETE
  // useEffect(() => updateValue(field?.parsedValue), [field?.parsedValue])

  return (
    <TextField
      className={className}
      id={id}
      label={label}
      value={value}
      fullWidth
      multiline
      placeholder="N/A"
      InputLabelProps={{ shrink: true }}
      onFocus={() => events.onFieldFocus?.(id, field)}
      onChange={(event) => {
        // NOTE: Do not call handleInputValue() to immediately validate new value as onValueChanged() trigger
        // a new instance of model been injected to model editor, and useFormControl() validates all fields.
        // I intend to leave handleInputValue() for later use, redux-form might be used if performance is concern.
        // handleInputValue(id, event.target.value, field?.id || "", record)
        debouncedOnChange(event.target.value)
        updateValue(event.target.value)
      }}
      error={has(errors, errorKey)}
      helperText={errors[errorKey]}
      disabled={readonlyMode}
    />
  )
}
