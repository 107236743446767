import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { createContext, useCallback, useState } from 'react'

type AlertType = 'success' | 'error'

interface IAlertDialogContextParams {
  open: boolean
  title: string
  content: string
  type: AlertType
}

interface IAlertDialogContext {
  openAlertDialog: (alertDialogContextParams: IAlertDialogContextParams) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiDialogTitle-root': {
        color: theme.palette.common.white,
        background: theme.palette.error.main
      },
      '& .MuiDialogContentText-root': {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '16px',
        margin: theme.spacing(3, 1)
      }
    }
  })
)

const initAlertDialogContextParams: IAlertDialogContextParams = {
  open: false,
  title: '',
  content: '',
  type: 'success'
}

export const AlertDialogContext = createContext<IAlertDialogContext>({
  openAlertDialog: () => {}
})

const AlertDialogProvider = ({ children }: { children: JSX.Element }) => {
  const classes = useStyles()
  const [alertDialogParams, setAlertDialogParams] =
    useState<IAlertDialogContextParams>(initAlertDialogContextParams)

  const openDialog = useCallback((params: IAlertDialogContextParams) => {
    setAlertDialogParams(params)
  }, [])

  return (
    <AlertDialogContext.Provider value={{ openAlertDialog: openDialog }}>
      {children}
      <Dialog
        className={classes.root}
        open={alertDialogParams.open}
        onClose={() =>
          setAlertDialogParams((prev) => ({ ...prev, open: false }))
        }
      >
        <DialogTitle id="alert-dialog-title">
          {alertDialogParams.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertDialogParams.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setAlertDialogParams((prev) => ({ ...prev, open: false }))
            }
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </AlertDialogContext.Provider>
  )
}

export default AlertDialogProvider
