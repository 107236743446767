import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { List } from 'immutable'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, StringField} from '../DetectedField'
import { IdCardMapper } from './IdCardMapper'

export class IdCard extends BusinessModel {

  constructor(
    version: string,
    public readonly documentType?: StringField,
    public readonly machineReadableZone?: StringField,
    public readonly sex?: StringField,
    public readonly nationality?: StringField,
    public readonly address?: StringField,
    public readonly countryRegion?: StringField,
    public readonly dateOfBirth?: DateField,
    public readonly dateOfExpiration?: DateField,
    public readonly documentNumber?: StringField,
    public readonly firstName?: StringField,
    public readonly lastName?: StringField,
    public readonly region?: StringField
  ) {
    super(IModelType.IdCard, version)
  }

  copy({
    version = this.version,
    documentType = this.documentType,
    machineReadableZone = this.machineReadableZone,
    sex = this.sex,
    nationality = this.nationality,
    address = this.address,
    countryRegion = this.countryRegion,
    dateOfBirth = this.dateOfBirth,
    dateOfExpiration = this.dateOfExpiration,
    documentNumber = this.documentNumber,
    firstName = this.firstName,
    lastName = this.lastName,
    region = this.region
  }): IdCard {
    return new IdCard(
      version,
      documentType,
      machineReadableZone,
      sex,
      nationality,
      address,
      countryRegion,
      dateOfBirth,
      dateOfExpiration,
      documentNumber,
      firstName,
      lastName,
      region
    )
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.IdCardEditor_DocumentNumber:
      case FieldAndColumnName.IdCardEditor_FirstName:
      case FieldAndColumnName.IdCardEditor_LastName:
      case FieldAndColumnName.IdCardEditor_Address:
      case FieldAndColumnName.IdCardEditor_Sex:
      case FieldAndColumnName.IdCardEditor_Region:
      case FieldAndColumnName.IdCardEditor_CountryRegion:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      case FieldAndColumnName.IdCardEditor_DateOfBirth:
      case FieldAndColumnName.IdCardEditor_DateOfExpiration:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)
      
      default:
        return undefined
    }
  }

  toJson(): IBusinessModel {
    return IdCardMapper.toJson(this)
  }
}

