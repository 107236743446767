import {
  Avatar,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import React, { useState } from 'react'

import { ssoURL } from '../../ApiPath'
import { useDashboardProfileStyle } from '../../assets/styles/DashboardUserProfileStyle'
import { ManageAuthorization } from '../../models/Authorization'
import { hasPermission, useInitStore } from '../../services/stores/InitStore'

const DashboardUserProfile = () => {
  const classes = useDashboardProfileStyle()
  const user = useInitStore((state) => state.authorizations?.userFullName)
  const email = useInitStore((state) => state.authorizations?.username)
  const currentBusiness = useInitStore((state) => state.currentBusiness)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = !!anchorEl
  const userName = user ?? 'Unknown'
  const userEmail = email ?? 'Unknown'
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleProfile = () => {
    handleCloseMenu()
    window.open(`${ssoURL}/app/user/profile`, '_blank')
  }

  const handleInvitation = () => {
    handleCloseMenu()
    window.open(`${ssoURL}/app/admin/invitations`, '_blank')
  }

  const handleUserManagement = () => {
    handleCloseMenu()
    window.open(`${ssoURL}/app/admin/userManager`, '_blank')
  }

  const handleRoleSetting = () => {
    handleCloseMenu()
    window.open(`${ssoURL}/app/admin/roleSettings`, '_blank')
  }

  const handleChangePassword = () => {
    handleCloseMenu()
    window.open(`${ssoURL}/app/password/changePassword`, '_blank')
  }

  return (
    <>
      <Button endIcon={<ArrowDropDown />} onClick={handleOpenMenu}>
        <div className={classes.btnLabelContainer}>
          <Typography>{userName}</Typography>
          <Typography className={classes.btnBusinessName}>
            {currentBusiness.name ?? ''}
          </Typography>
        </div>
      </Button>
      <Menu
        open={open}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        classes={{
          paper: classes.menuPaper
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem className={classes.accountSummary} disableRipple={true}>
          <Avatar className={classes.avatar}>
            {userName[0].toUpperCase()}
          </Avatar>
          <Typography noWrap>{userName}</Typography>
          <Typography noWrap className={classes.userEmail}>
            {userEmail}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleProfile}>
          <Typography noWrap className={classes.menuItemText}>
            My Profile
          </Typography>
        </MenuItem>
        <Divider />
        {hasPermission([ManageAuthorization.AUTH_MANAGE_USERS]) && (
          <div>
            <MenuItem onClick={handleUserManagement}>
              <Typography noWrap className={classes.menuItemText}>
                User Management
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleInvitation}>
              <Typography noWrap className={classes.menuItemText}>
                Invite User
              </Typography>
            </MenuItem>
            <Divider />
          </div>
        )}
        {hasPermission([ManageAuthorization.AUTH_MANAGE_ROLES]) && (
          <div>
            <MenuItem onClick={handleRoleSetting}>
              <Typography noWrap className={classes.menuItemText}>
                Role Setting
              </Typography>
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem onClick={handleChangePassword}>
          <Typography noWrap className={classes.menuItemText}>
            Change Password
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default DashboardUserProfile
