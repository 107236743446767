import { BaseShape } from '../models/Shape'
import { Point } from '../models/Types'

export class Renderer {
  constructor(
    readonly ctx: CanvasRenderingContext2D
  ) {}

  draw(shape: BaseShape, converter: (point: Point) => Point) {
    if(shape.isVisible) shape.drawShape(this.ctx, converter)
    if (!shape.isCreating) this.drawTextLabel(shape, converter)
    if (shape.isActive && !shape.isCreating) shape.drawCtrlPoints(this.ctx, converter)
  }

  private drawTextLabel(shape: BaseShape, converter: (point: Point) => Point){
    const [x, y] = converter(shape.coordinates[0])
    if(shape.tagName){
      this.ctx.save()
      this.ctx.fillStyle = 'rgba(0,0,0,0.7)'
      this.ctx.fillRect(x - 1, y - 15, shape.tagName.length * 8 , 15)
      this.ctx.fillStyle = '#fff'
      this.ctx.font = 'bold 10px Courier New'
      this.ctx.fillText(shape.tagName, x + 4, y - 5)
      this.ctx.restore()
    }
  }
}