import {OcrDocument} from '../../OcrDocument'
import {IBusinessModel, IContractNoteModel, IModelType} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {ContractNote} from './ContractNote'

export class ContractNoteMapper extends BusinessModelMapper{
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IContractNoteModel
  ) {
    super(document)
  }

  fromJson(): ContractNote {
    const contractNoteModel = this.jsonModel
    const model = new ContractNote(
      contractNoteModel.Version,
      this.mapTextBlock(contractNoteModel.BrokerName),
      this.mapTextBlock(contractNoteModel.TradeType),
      this.mapDollarBlock(contractNoteModel.TotalAmount),
      this.mapDateBlock(contractNoteModel.TransactionDate),
      this.mapDateBlock(contractNoteModel.SettlementDate),
      this.mapDoubleBlock(contractNoteModel.Unit),
      this.mapDollarBlock(contractNoteModel.UnitPrice),
      this.mapDollarBlock(contractNoteModel.Consideration),
      this.mapDollarBlock(contractNoteModel.Brokerage),
      this.mapDollarBlock(contractNoteModel.Gst),
      this.mapTextBlock(contractNoteModel.ReferenceNumber),
      this.mapTextBlock(contractNoteModel.AsxCode),
      this.mapDollarBlock(contractNoteModel.OtherFee),
      this.mapTextBlock(contractNoteModel.SecurityName),
      this.mapTextBlock(contractNoteModel.SecurityType),
      this.mapTextBlock(contractNoteModel.Market),
      this.mapDollarBlock(contractNoteModel.AccruedInterest),
      this.mapDateBlock(contractNoteModel.MaturityDate),
      this.mapTextBlock(contractNoteModel.Currency),
      this.mapDoubleBlock(contractNoteModel.ExchangeRate),
      this.mapDollarBlock(contractNoteModel.ConvertedTotalAmount)
    )
    return model
  }

  static toJson(model: ContractNote): IBusinessModel {
    return {
      ModelType: IModelType.ContractNote,
      Version: model.version,
      BrokerName: model.brokerName?.toModelKeyValue(),
      TradeType: model.tradeType?.toModelKeyValue(),
      TotalAmount: model.totalAmount?.toModelKeyValue(),
      TransactionDate: model.transactionDate?.toModelKeyValue(),
      SettlementDate: model.settlementDate?.toModelKeyValue(),
      Unit: model.unit?.toModelKeyValue(),
      UnitPrice: model.unitPrice?.toModelKeyValue(),
      Consideration: model.consideration?.toModelKeyValue(),
      Brokerage: model.brokerage?.toModelKeyValue(),
      Gst: model.gst?.toModelKeyValue(),
      ReferenceNumber: model.referenceNumber?.toModelKeyValue(),
      AsxCode: model.asxCode?.toModelKeyValue(),
      OtherFee: model.otherFee?.toModelKeyValue(),
      SecurityName: model.securityName?.toModelKeyValue(),
      SecurityType:model.securityType?.toModelKeyValue(),
      Market:model.market?.toModelKeyValue(),
      AccruedInterest: model.accruedInterest?.toModelKeyValue(),
      MaturityDate:model.maturityDate?.toModelKeyValue(),
      Currency:model.currency?.toModelKeyValue(),
      ExchangeRate:model.exchangeRate?.toModelKeyValue(),
      ConvertedTotalAmount:model.convertedTotalAmount?.toModelKeyValue()
    } as IContractNoteModel
  }

}
