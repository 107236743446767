import { createStyles, makeStyles } from '@material-ui/core/styles'

export const usePopoverMessengerStyles = makeStyles(() =>
  createStyles({
    root: {
      marginLeft: 'auto'
    },
    popoverPaper: {
      height: 'fit-content'
    }
  })
)
