import { faDownload, faExpand, faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import _ from 'lodash'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { IOverlay } from '../overlay/IOverlay'
import { IOverlayPageTransform } from '../overlay/IOverlayPageTransform'
import SelectableOverlay, { SelectableOverlayProps } from '../overlay/SelectableOverlay'
import { SelectableGroup, TSelectableItemProps } from '../selectable'
import { onPassword } from '../utils/utils'
import CircularProgress from './CircularProgress'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IPDFViewerProps {
  pdf: Blob
  fileName: string
  overlays: IOverlay[],
  forceUpdateCounter: number
  handleDocumentLoadSuccess: (pdf: pdfjs.PDFDocumentProxy) => void
  handleDeselectBlocks: (event: React.MouseEvent) => void
  handleClearSelection: () => void
  handleSelectAll: () => void
  pageTransforms: IOverlayPageTransform[]
  onDocumentClose?: () => void
  onDownloadFile: () => void
  bottomBarElem?: JSX.Element | JSX.Element[]
  onSelecting?: (selectingItems: IOverlay[]) => void
  onSelectionFinish?: (selectedItems: IOverlay[]) => void
  viewMode?: 'wordTagging' | 'pageTagging'
  onPasswordCancel?: () => void
  disableDownload?: boolean
  disableSelection?: boolean
}

export const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  isEvalSupported: false
}

export default function PDFViewer(props: IPDFViewerProps) {

  const [numPages, setNumPages] = useState(0)
  const [scaleFactor, setScaleFactor] = useState(1)
  // const [viewMode, setViewMode] = useState(props.viewMode ? props.viewMode : 'wordTagging')
  const [pageDimensionsReady, setPageDimensionsReady] = useState(false)
  const [pageDimensions, setPageDimensions] = useState<Map<any, any> | null>(null)
  const [pageNum, setPageNum] = useState(1)
  const [selectedItems, setSelectedItems] = useState<IOverlay[]>([] as IOverlay[])
  const [visiblePages, setVisiblePages] = useState<Set<number>>(new Set([]))
  const pageRefs = useRef<(HTMLDivElement | null)[]>([])

  const setVisible = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const pageNum = parseInt(entry.target.getAttribute('data-page-number') || '0')
        setVisiblePages(visiblePages => new Set(visiblePages).add(pageNum))
      } else {
        const pageNum = parseInt(entry.target.getAttribute('data-page-number') || '0')
        setVisiblePages(visiblePages => {
          const newSet = new Set(visiblePages)
          newSet.delete(pageNum)
          return newSet
        })
      }
    })
  }, [])


  useEffect(() => {
    const observerOptions = {
      root: document.querySelector('#viewerContainer'),
      rootMargin: '0px',
      threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
    }
    const observer = new IntersectionObserver(setVisible, observerOptions)
    pageRefs.current.forEach(ref => {
      if (ref) observer.observe(ref)
    })

    return () => {
      observer.disconnect()
    }
  }, [numPages, setVisible])

  useEffect(() => {
    if (visiblePages.size < 1) {
      setPageNum(1)
    } else {
      const visiblePageArray = Array.from(visiblePages)
      const pageNum = Math.min(...visiblePageArray)
      setPageNum(pageNum)
    }
  }, [visiblePages])

  const scaleFactorToFitPage = () => {
    const containerWidth = getContainerWidth() - 48 // 48px for margins
    const pageWidthArray = pageDimensions && Array.from(pageDimensions.values()).map(([w]) => w) || []
    const pageMaxWidth = Math.max(...pageWidthArray)
    return pageMaxWidth && containerWidth ? containerWidth / pageMaxWidth : 1
  }

  useEffect(() => {
    if (props.pdf) {
      setPageDimensionsReady(false)
      setScaleFactor(1)
      setPageDimensions(null)
      setPageNum(1)
      setSelectedItems([])
      setNumPages(0)
    }
  }, [props.pdf])

  useEffect(() => {
    if (!pageDimensionsReady) return
    let currentScaleFactor = scaleFactor
    if (props.viewMode === 'pageTagging') {
      setScaleFactor(0.2)
    } else {
      setScaleFactor(scaleFactorToFitPage)
    }
    const handleResize = _.debounce(() => {
        setScaleFactor(scaleFactorToFitPage)
    }, 500)

    const handleWheelZoom = (e: WheelEvent) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault()
        e.stopPropagation()
        if (e.deltaY < 0) {
          if (currentScaleFactor < 10) {
            setScaleFactor(scaleFactor => {
              const updatedScaleFactor = scaleFactor + 0.1
              currentScaleFactor = updatedScaleFactor
              return updatedScaleFactor
            })
          }
        } else {
          if (currentScaleFactor >= 0.2) {
            setScaleFactor(scaleFactor => {
              const updatedScaleFactor = scaleFactor - 0.1
              currentScaleFactor = updatedScaleFactor
              return updatedScaleFactor
            })
          }
        }
      }
    }

    const container = document.getElementById('viewerContainer')
    if (container) {
      container.addEventListener('wheel', handleWheelZoom)
      container.addEventListener('resize', handleResize)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      container?.removeEventListener('wheel', handleWheelZoom)
      container?.removeEventListener('resize', handleResize)
      setPageDimensionsReady(false)
      setScaleFactor(1)
    }

  }, [props.pdf, pageDimensionsReady, props.viewMode])



  const cachePageDimensions = (pdf: any) => {
    if (!pdf) return
    const promises = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map((pageNumber) => {
      return pdf.getPage(pageNumber)
    })

    // Assuming all pages may have different heights. Otherwise, we can just
    // load the first page and use its height for determining all the row
    // heights.
    Promise.all(promises).then((pages) => {

      const pageDimensions = new Map()

      for (const page of pages) {
        const w = page.view[2] * scaleFactor
        const h = page.view[3] * scaleFactor
        pageDimensions.set(page._pageIndex + 1, [w, h])
      }

      setPageDimensions(pageDimensions)
      setPageDimensionsReady(true)
    })
  }

  const getContainerWidth = (): number => {
    return document.querySelector('#PDFContainer')?.clientWidth ?? 600
  }

  const findTransformByPage = (pageNum: number): IOverlayPageTransform | undefined => {
    return _.head(props.pageTransforms?.filter(transform => transform.page === pageNum))
  }

  const rotatePage = (index: number) => {
    const pageNum = index + 1
    const pageElem = document.querySelector('[data-page-number=\'' + pageNum + '\']')
    const canvasElem = ([].slice.call(pageElem?.getElementsByTagName('canvas')) as HTMLCanvasElement[])[0]
    const transformByPage = findTransformByPage(pageNum)
    if (transformByPage && canvasElem) {
      canvasElem.style.transform = `rotate(${transformByPage.angleDegree}deg) scale(${transformByPage.scale})`
    }
  }

  const scaleFactorIncrease = () => {
    setScaleFactor(scaleFactor => scaleFactor + 0.1)
  }

  const scaleFactorDecrease = () => {
    setScaleFactor(scaleFactor => scaleFactor - 0.1)
  }

  const scaleFromPage = (page: number) => {
    const pageTransform = props.pageTransforms?.find(p => p.page === page)
    if (pageTransform) {
      return pageTransform.scale
    }
    return 1
  }

  // const changeViewMode = (event: React.MouseEvent<HTMLElement>, newViewMode: 'wordTagging' | 'pageTagging') => {
  //   if (newViewMode) {
  //     if (newViewMode === 'wordTagging') {
  //       setScaleFactor(scaleFactorToFitPage)
  //     } else if (newViewMode === 'pageTagging') {
  //       setScaleFactor(0.2)
  //     }
  //     setViewMode(newViewMode)
  //   }
  // }

  function onDocumentLoadSuccess(pdf: pdfjs.PDFDocumentProxy) {
    props.handleDocumentLoadSuccess(pdf)
    setNumPages(pdf.numPages)
    cachePageDimensions(pdf)
  }

  const getSelectableGroupRef = (ref: SelectableGroup | null) => {
    (window as any).selectableGroup = ref
  }

  const handleDeselectBlocks = (event?: React.MouseEvent) => {

  }

  const handleSelecting = (selectingItems: FunctionComponent[]) => {
    if (props.onSelecting) {
      const items = selectingItems.map((item) => {
        // @ts-ignore
        const props = item.props as SelectableOverlayProps & TSelectableItemProps
        return props.overlay
      })
      props.onSelecting(items)
    }
  }

  const handleSelectionFinish = (selectedItems: FunctionComponent[]) => {
    if (props.onSelectionFinish) {
      const items = selectedItems.map((item) => {
        // @ts-ignore
        const props = item.props as SelectableOverlayProps & TSelectableItemProps
        return props.overlay
      })
      setSelectedItems(items)
      props.onSelectionFinish(items)
    }
  }

  const handleSelectedItemUnmount = (_unmountedItem: any, selectedItems: any) => {
    console.log('handle SelectedItemUnmount')
  }

  const handleSelectionClear = () => {
    handleSelectionFinish([])
    setSelectedItems([])
  }

  const renderPDFOverlaysByPage = (pageNumber: number) => {
    const overlaysOfPage: IOverlay[] = props.overlays.filter((overlay) => overlay.page === pageNumber)
    return overlaysOfPage.map((overlay) => {
      return (
        <SelectableOverlay
          key={overlay.id}
          overlay={overlay}
        />
      )
    })
  }

  function handleOnPassword(callback: (password: string) => void, reason: number) {
    onPassword(callback, reason, props.onPasswordCancel)
  }

  return (
    <div id="PDFContainer" className="textractDocViewer">
      <div color="default" className="appBar">
        <div className="toolBar">
          <div className="leftSection">
            {/*<div className="viewSection">*/}
            {/*  <ToggleButtonGroup value={viewMode}*/}
            {/*                     exclusive*/}
            {/*                     onChange={changeViewMode}*/}
            {/*                     aria-label="view mode"*/}
            {/*                     className="toggleViewBtnGroup">*/}
            {/*    <ToggleButton value="wordTagging"*/}
            {/*                  aria-label="wordTagging"*/}
            {/*                  disableRipple*/}
            {/*                  className="toggleViewBtnWord">*/}
            {/*      <FontAwesomeIcon icon={faFileAlt} size={'sm'} />*/}
            {/*      <span className="toggleViewBtnWordText">Full</span>*/}
            {/*    </ToggleButton>*/}
            {/*    <ToggleButton value="pageTagging"*/}
            {/*                  aria-label="pageTagging"*/}
            {/*                  disableRipple*/}
            {/*                  className="toggleViewBtnPage">*/}
            {/*      <FontAwesomeIcon icon={faThLarge} size={'sm'} />*/}
            {/*      <span className="toggleViewBtnPageText">Wrapped</span>*/}
            {/*    </ToggleButton>*/}
            {/*  </ToggleButtonGroup>*/}
            {/*</div>*/}
            <div className="zoomSection">
              <button aria-label="Zoom Out"
                      onClick={scaleFactorDecrease}
                      disabled={scaleFactor <= 0.3 || !props.pdf}
                      className="iconBtn zoomOutBtn">
                <FontAwesomeIcon icon={faMinusCircle} size={'xs'} />
              </button>
              <div className="scaleFactorWrapper">
                <p className="scaleFactorText">{(scaleFactor * 100).toFixed(0)}%</p>
              </div>
              <button aria-label="Zoom In"
                      onClick={scaleFactorIncrease}
                      disabled={scaleFactor >= 5 || !props.pdf}
                      className="iconBtn zoomInBtn">
                <FontAwesomeIcon icon={faPlusCircle} size={'xs'} />
              </button>
              <div className="toolTip" title="Fit to Page">
                <button aria-label="Fit to Page"
                        onClick={() => {
                          setScaleFactor(scaleFactorToFitPage)
                        }}
                        disabled={!props.pdf}
                        className="iconBtn fitPageBtn">
                  <FontAwesomeIcon icon={faExpand} size={'xs'} />
                </button>
              </div>
            </div>
          </div>
          <div className="middleSection">
            <div className="pageInfoWrapper">
              <p className="currentPageInfo">{pageNum}</p>
              <span className="pageInfoDivider">/</span>
              <p className="totalPageInfo">{numPages}</p>
            </div>
          </div>
          <div className="rightSection">
            <div className="functionSection">
              <div className="selectBtnGroup">
                {props.viewMode === 'pageTagging' && <button className="selectAllBtn" onClick={props.handleSelectAll}>
                  <span className="selectAllBtnText">SELECT ALL</span>
                </button>}
                <button className="clearAllBtn" onClick={props.handleClearSelection} disabled={selectedItems.length === 0}>
                  <span className="clearAllBtnText">CLEAR ALL</span>
                </button>
                {!props.disableDownload && <div className="toolTip" title="Download File">
                  <button aria-label="download"
                    onClick={props.onDownloadFile}
                    className="iconBtn downloadBtn">
                    <FontAwesomeIcon icon={faDownload} size={'xs'} />
                  </button>
                </div>}
              </div>

            </div>
          </div>
        </div>
      </div>
      <div id="viewerContainer" tabIndex={0} onClick={handleDeselectBlocks}>
        <SelectableGroup
          ref={getSelectableGroupRef}
          className="bglLayer-selection"
          enableDeselect
          mixedDeselect
          scrollContainer={'#viewerContainer'}
          tolerance={0}
          allowClickWithoutSelected={true}
          duringSelection={handleSelecting}
          onSelectionClear={handleSelectionClear}
          onSelectionFinish={handleSelectionFinish}
          onSelectedItemUnmount={handleSelectedItemUnmount}
          selectOnClick={false}
          ignoreList={['.not-selectable']}
          disabled={props.disableSelection}
        >
          <div key="viewer" id="viewer" className="pdfViewer">
            <Document file={props.pdf}
                      loading={<CircularProgress />}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onPassword={(callback: (password: string) => void, reason: number) => handleOnPassword(callback, reason)}
                      options={options}
                      className={clsx(props.viewMode === 'pageTagging' ? 'spread' : '')}
            >
              {numPages !== 0 && Array.from(new Array(numPages), (el, index) => (
                <div key={`pageWrapper_${index + 1}`}
                     className="pageWrapper"
                     ref={ref => pageRefs.current[index] = ref}
                     data-page-number={index + 1}
                >
                  <Page key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={scaleFromPage(index + 1) * scaleFactor}
                        onRenderSuccess={() => rotatePage(index)}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        loading={<CircularProgress />}
                  >
                    <div key={`bglLayer_${index + 1}`} className="bglLayer">{renderPDFOverlaysByPage(index + 1)}</div>
                  </Page>
                </div>
              ))}
            </Document>
          </div>
        </SelectableGroup>
      </div>
    </div>
  )
}
