import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import { IBusinessModel, IContactNames, IModelType } from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DetectedField, StringField} from '../DetectedField'
import ModelObject from '../ModelObject'
import { BusinessCardMapper } from './BusinessCardMapper'

export class BusinessCard extends BusinessModel {

  constructor(
    modelType: IModelType,
    version: string,
    readonly contactNames: List<ContactName>,
    readonly jobTitles: List<StringField>,
    readonly departments: List<StringField>,
    readonly emails: List<StringField>,
    readonly websites: List<StringField>,
    readonly mobilePhones: List<StringField>,
    readonly otherPhones: List<StringField>,
    readonly workPhones: List<StringField>,
    readonly faxes: List<StringField>,
    readonly addresses: List<StringField>,
    readonly companyNames: List<StringField>
  ) {
    super(modelType, version)
  }

  protected listFields(): List<DetectedField> {
    const contactNameFields = this.contactNames.flatMap(c => c.listFields())
    return contactNameFields.concat(
      this.jobTitles).concat(
      this.departments).concat(
      this.emails).concat(
      this.websites).concat(
      this.mobilePhones).concat(
      this.otherPhones).concat(
      this.workPhones).concat(
      this.faxes).concat(
      this.addresses).concat(
      this.companyNames
    )
  }

  copy({
      version = this.version,
      contactNames = this.contactNames,
      jobTitles = this.jobTitles,
      departments = this.departments,
      emails = this.emails,
      websites = this.websites,
      mobilePhones = this.mobilePhones,
      otherPhones = this.otherPhones,
      workPhones = this.workPhones,
      faxes = this.faxes,
      addresses = this.addresses,
      companyNames = this.companyNames
  }): BusinessCard {
    return new BusinessCard(
      IModelType.BusinessCard,
      version,
      contactNames,
      jobTitles,
      departments,
      emails,
      websites,
      mobilePhones,
      otherPhones,
      workPhones,
      faxes,
      addresses,
      companyNames
    )
  }

  update(id: FieldAndColumnName, field: DetectedField, value: FormGridRowValue, modifiedBy: string): BusinessModel {
    if ([
      FieldAndColumnName.BusinessCardEditor_ContactFirstName,
      FieldAndColumnName.BusinessCardEditor_ContactLastName
    ].includes(id)) {
      const contactNameFieldId = ContactName.convertFieldId(id)
      const index = this.contactNames.findIndex(c => (c.get(contactNameFieldId)! as DetectedField).id === field.id)
      if (index === -1) {
        return this
      }

      const updatedContactNames = this.contactNames.setIn(
        [index],
        this.contactNames.get(index)!.update(contactNameFieldId, field, value, modifiedBy)
      )

      return this.copy({ contactNames: updatedContactNames })
    } else {
      const found = this.get(id)
      if (found) {
        if (List.isList(found)) {
          const updatedList = this.updateArray((found as List<DetectedField>), id, field, value, modifiedBy)

          return this.copy({ [id]: updatedList })
        }
      }
    }

    return this
  }

  protected handleArrayAdd(current:List<DetectedField>, id: FieldAndColumnName, value: any, modifiedBy: string): List<DetectedField> {
    switch(id) {
      case FieldAndColumnName.BusinessCardEditor_JobTitles:
      case FieldAndColumnName.BusinessCardEditor_Departments:
      case FieldAndColumnName.BusinessCardEditor_Emails:
      case FieldAndColumnName.BusinessCardEditor_Websites:
      case FieldAndColumnName.BusinessCardEditor_MobilePhones:
      case FieldAndColumnName.BusinessCardEditor_OtherPhones:
      case FieldAndColumnName.BusinessCardEditor_WorkPhones:
      case FieldAndColumnName.BusinessCardEditor_Faxes:
      case FieldAndColumnName.BusinessCardEditor_Address:
      case FieldAndColumnName.BusinessCardEditor_CompanyName:
        return current.push(new StringField(uuid(), value, [], List(), modifiedBy))

      default:
        return current
    }
  }

  toJson(): IBusinessModel {
    return BusinessCardMapper.toJson(this)
  }
}

export class ContactName extends ModelObject {
  constructor(
    readonly firstName?: StringField,
    readonly lastName?: StringField
  ) {
    super()
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }

  copy({ firstName = this.firstName, lastName = this.lastName}): ContactName {
    return new ContactName(firstName, lastName)
  }

  static convertFieldId(id: FieldAndColumnName): string {
    return id === FieldAndColumnName.BusinessCardEditor_ContactFirstName ? 'firstName' : 'lastName'
  }

  update(id: string, field: DetectedField, value: any, modifiedBy: string): ContactName {
    return this.copy({ [id]: (this.get(id)! as DetectedField).updateValue(value, modifiedBy) })
  }

  toJson(): IContactNames {
    return {
      FirstName: this.firstName?.toModelKeyValue(),
      LastName: this.lastName?.toModelKeyValue()
    }
  }
}
