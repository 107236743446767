import React from 'react'

import { ReactComponent as DeveloperIcon } from '../../assets/images/Logo_SDAIStudio.svg'
import smartDocs from '../../assets/images/smartdocs.png'

function Hero() {
  return (
    <section className="hero">
      <div className="container container-wrapper">
        <div className="layout">
          <div className="left">
            <div className="img">
              <img src={smartDocs} alt="" />
            </div>
          </div>
          <div className="right">
            <div className="img">
              <DeveloperIcon style={{}} />
            </div>
            <h1 className="sub-title">
              Turn unstructured data into actionable insights
            </h1>
            <p className="content">
              Unlock business potential through Document Extraction | Document
              Classification | Object Detection | Object Counting
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
