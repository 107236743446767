
export enum FormFieldValidationResult {
    // Below for generic
    VALIDATION_SUCCESS = '',
    INCORRECT_VALUE = 'Please enter correct value',
    INVALID_INPUT = 'Invalid input',
    // Above are predifined generic errors

    // Below for BankStatementEditor
    BANKSTATEMENT_INCORRECT_DATE = 'Future date not allowed',
    BANKSTATEMENT_INCORRECT_BSB = 'BSB number is 6 digits only',
    BANKSTATEMENT_INCORRECT_ACCOUNT_NUMBER = 'Account number is digit only',
    TRANSACTIONTABLE_EMPTY_DATE = 'Date is required',
    TRANSACTIONTABLE_ZERO_DEBIT = '0 is not allowed',
    TRANSACTIONTABLE_INCORRECT_CREDIT = 'Credit should be less than 30000',
    TRANSACTIONTABLE_MISSING_DATE_RANGE = 'Start/End date required in bank statement to validate transaction date',
    TRANSACTIONTABLE_IOCORRECT_DATE = 'Transaction date should not be early than start date or later than end date of bank statement',
    TRANSACTIONTABLE_INCORRECT_BALANCE = 'Balance is not correctly calculated with last transaction',
    // Above are BankStatementEditor errors

    // Below for BusinessCardEditor
    BUSINESSCARD_EMPTY_WEBSITE = 'Empty website not allowed',
    BUSINESSCARD_INCORRECT_WEBSITE = 'Websit starts with \'www.\'',
    BUSINESSCARD_INCORRECT_MOBILE = 'Mobile number must start with \'04\''
    // Above are BusinessCardEditor errors
}

export enum FieldAndColumnName {
    INVALID_FIELD_NAME = '',
    BankStatementEditor_BankName = 'BankName',
    BankStatementEditor_FinancialInstitution = 'financialInstitution',
    BankStatementEditor_BSB = 'bsb',
    BankStatementEditor_AccountNumber = 'accountNumber',
    BankStatementEditor_StartDate = 'startDate',
    BankStatementEditor_EndDate = 'endDate',
    BankStatementEditor_Transactions = 'transactions',
    TransactionTable_OpeningBalance = 'openingBalance',
    TransactionTable_ClosingBalance = 'closingBalance',
    TransactionTable_Date = 'date',
    TransactionTable_TotalDebit = 'totalDebits',
    TransactionTable_TotalCredit = 'totalCredits',
    TransactionTable_Description = 'description',
    TransactionTable_ABN = 'abn',
    TransactionTable_AccountName = 'accountName',

    BusinessCardEditor_ContactFirstName = 'contactFirstName',
    BusinessCardEditor_ContactLastName = 'contactLastName',
    BusinessCardEditor_JobTitles = 'jobTitles',
    BusinessCardEditor_Departments = 'departments',
    BusinessCardEditor_Websites = 'websites',
    BusinessCardEditor_Emails = 'emails',
    BusinessCardEditor_MobilePhones = 'mobilePhones',
    BusinessCardEditor_OtherPhones = 'otherPhones',
    BusinessCardEditor_WorkPhones = 'workPhones',
    BusinessCardEditor_Faxes = 'faxes',
    BusinessCardEditor_Address = 'address',
    BusinessCardEditor_CompanyName = 'companyName',

    ContractNoteEditor_BrokerName = 'brokerName',
    ContractNoteEditor_ReferenceNumber = 'referenceNumber',
    ContractNoteEditor_TradeType = 'tradeType',
    ContractNoteEditor_AsxCode = 'asxCode',
    ContractNoteEditor_Market = 'market',
    ContractNoteEditor_SecurityName = 'securityName',
    ContractNoteEditor_SecurityType = 'securityType',
    ContractNoteEditor_TransactionDate = 'transactionDate',
    ContractNoteEditor_SettlementDate = 'settlementDate',
    ContractNoteEditor_Unit = 'unit',
    ContractNoteEditor_UnitPrice = 'unitPrice',
    ContractNoteEditor_Brokerage = 'brokerage',
    ContractNoteEditor_Gst = 'gst',
    ContractNoteEditor_AccruedInterest = 'accruedInterest',
    ContractNoteEditor_MaturityDate = 'maturityDate',
    ContractNoteEditor_Consideration = 'consideration',
    ContractNoteEditor_TotalAmount = 'totalAmount',
    ContractNoteEditor_Currency = 'currency',
    ContractNoteEditor_ExchangeRate = 'exchangeRate',
    ContractNoteEditor_ConvertedTotalAmount = 'convertedTotalAmount',


    IdCardEditor_DocumentNumber = 'documentNumber',
    IdCardEditor_FirstName = 'firstName',
    IdCardEditor_LastName = 'lastName',
    IdCardEditor_Address = 'address',
    IdCardEditor_Sex = 'sex',
    IdCardEditor_DateOfBirth = 'dateOfBirth',
    IdCardEditor_Region = 'region',
    IdCardEditor_CountryRegion = 'countryRegion',
    IdCardEditor_DateOfExpiration = 'dateOfExpiration',

    InvoiceEditor_CustomerName = 'customerName',
    InvoiceEditor_CustomerId = 'customerId',
    InvoiceEditor_PurchaseOrder = 'purchaseOrder',
    InvoiceEditor_InvoiceId = 'invoiceId',
    InvoiceEditor_InvoiceDate = 'invoiceDate',
    InvoiceEditor_DueDate = 'dueDate',
    InvoiceEditor_VendorName = 'vendorName',
    InvoiceEditor_VendorAddress = 'vendorAddress',
    InvoiceEditor_VendorAddressRecipient = 'vendorAddressRecipient',
    InvoiceEditor_CustomerAddress = 'customerAddress',
    InvoiceEditor_CustomerAddressRecipient = 'customerAddressRecipient',
    InvoiceEditor_BillingAddress = 'billingAddress',
    InvoiceEditor_BillingAddressRecipient = 'billingAddressRecipient',
    InvoiceEditor_ShippingAddress = 'shippingAddress',
    InvoiceEditor_ShippingAddressRecipient = 'shippingAddressRecipient',
    InvoiceEditor_SubTotal = 'subTotal',
    InvoiceEditor_TotalTax = 'totalTax',
    InvoiceEditor_InvoiceTotal = 'invoiceTotal',
    InvoiceEditor_AmountDue = 'amountDue',
    InvoiceItemColumn_Amount = 'amount',
    InvoiceItemColumn_Description = 'description',
    InvoiceItemColumn_Quantity = 'quantity',
    InvoiceItemColumn_UnitPrice = 'unitPrice',
    InvoiceItemColumn_ProductCode = 'productCode',
    InvoiceItemColumn_Unit = 'unit',
    InvoiceItemColumn_Date = 'date',
    InvoiceItemColumn_Tax = 'tax',

    ReceiptEditor_MerchantName = 'merchantName',
    ReceiptEditor_MerchantPhoneNumber = 'merchantPhoneNumber',
    ReceiptEditor_MerchantAddress = 'merchantAddress',
    ReceiptEditor_TransactionDate = 'transactionDate',
    ReceiptEditor_TransactionTime = 'transactionTime',
    ReceiptEditor_Total = 'total',
    ReceiptEditor_Subtotal = 'subtotal',
    ReceiptEditor_Tax = 'tax',
    ReceiptEditor_Tip = 'tip',
    ReceiptItemColumn_Name = 'name',
    ReceiptItemColumn_Quantity = 'quantity',
    ReceiptItemColumn_Price = 'price',
    ReceiptItemColumn_TotalPrice = 'total',

    WaterBillEditor_PropertyAddress = 'propertyAddress',
    WaterBillEditor_DueDate = 'dueDate',
    WaterBillEditor_IssueDate = 'issueDate',
    WaterBillEditor_TotalAmount = 'totalAmount',
    WaterBillEditor_ClientAddress = 'clientAddress',

    UtilityBillEditor_PropertyAddress = 'propertyAddress',
    UtilityBillEditor_DueDate = 'dueDate',
    UtilityBillEditor_IssueDate = 'issueDate',
    UtilityBillEditor_TotalAmount = 'totalAmount',
    UtilityBillEditor_AccountNumber = 'accountNumber',
    UtilityBillEditor_InvoiceNumber = 'invoiceNumber',
    UtilityBillEditor_CustomerName = 'customerName',
    UtilityBillEditor_OverDueAmount = 'overDueAmount',
    UtilityBillEditor_CustomerABN = 'customerABN',
    UtilityBillEditor_GSTAmount = 'GSTAmount',
    UtilityBillEditor_CustomerAddress = 'customerAddress',
    UtilityBillEditor_SupplierName = 'supplierName',
    UtilityBillEditor_SupplierABN = 'supplierABN',
    UtilityBillEditor_BillingStartDate = 'billingStartDate',
    UtilityBillEditor_BillingEndDate = 'billingEndDate',
    UtilityBillEditor_ServicePhoneNumber = 'servicePhoneNumber',

    DividendStatementEditor_DividendFranked = 'dividendFranked',
    DividendStatementEditor_FrankingCredits = 'frankingCredits',
    DividendStatementEditor_DividendUnfranked = 'dividendUnfranked',
    DividendStatementEditor_FrankingCreditsFromNZ = 'frankingCreditsFromNZ',
    DividendStatementEditor_AssessableForeignIncome = 'assessableForeignIncome',
    DividendStatementEditor_ForeignIncomeTaxOffset = 'foreignIncomeTaxOffset',
    DividendStatementEditor_WithholdingTax = 'withholdingTax',
    DividendStatementEditor_ConvertedNetDividend = 'convertedNetDividend',
    DividendStatementEditor_FeeDeduction = 'feeDeduction',
    DividendStatementEditor_PaymentDate = 'paymentDate',
    DividendStatementEditor_ShareCode = 'shareCode',
    DividendStatementEditor_CompanyName = 'companyName',
    DividendStatementEditor_GrossPayment = 'grossDividend',
    DividendStatementEditor_NetPayment = 'netDividend',
    DividendStatementEditor_IndividualTrustLIC = 'IndividualTrustLIC',
    DividendStatementEditor_SMSFLIC = 'SMSFLIC',

    AtoActivityStatementEditor_AbnNumber = 'abn',
    AtoActivityStatementEditor_TfnNumber = 'tfn',
    AtoActivityStatementEditor_StartDate = 'startDate',
    AtoActivityStatementEditor_EndDate = 'endDate',
    AtoActivityStatementEditor_Transactions = 'transactions',
    AtoActivityStatementEditor_Balance = 'balance',
    AtoActivityStatementEditor_Date = 'date',
    AtoActivityStatementEditor_Debit = 'debit',
    AtoActivityStatementEditor_Credit = 'credit',
    AtoActivityStatementEditor_Description = 'description',
    AtoActivityStatementTransactionTable_Balance = 'balance',
    AtoActivityStatementTransactionTable_ProcessedDate = 'processedDate',
    AtoActivityStatementTransactionTable_EffectiveDate = 'effectiveDate',
    AtoActivityStatementTransactionTable_Debit = 'debit',
    AtoActivityStatementTransactionTable_Credit = 'credit',
    AtoActivityStatementTransactionTable_Description = 'description',

    AtoPaygInstallmentStatementEditor_TaxAgent = 'taxAgent',
    AtoPaygInstallmentStatementEditor_LastUpdated = 'lastUpdated',
    AtoPaygInstallmentStatementEditor_FinancialYear = 'financialYear',

    PayGInstalmentYearlyTableColumn_TfnNumber = 'tfn',
    PayGInstalmentYearlyTableColumn_ClientName = 'clientName',
    PayGInstalmentYearlyTableColumn_Quarter1 = 'quarter1',
    PayGInstalmentYearlyTableColumn_Quarter2 = 'quarter2',
    PayGInstalmentYearlyTableColumn_Quarter3 = 'quarter3',
    PayGInstalmentYearlyTableColumn_Quarter4 = 'quarter4',
    PayGInstalmentYearlyTableColumn_TotalInstalment = 'totalInstalment',

    AtoQuarterlyPaygInstalmentNoticeEditor_DocumentId = 'documentId',
    AtoQuarterlyPaygInstalmentNoticeEditor_Abn = 'abn',
    AtoQuarterlyPaygInstalmentNoticeEditor_Recipient = 'recipient',
    AtoQuarterlyPaygInstalmentNoticeEditor_Address = 'address',
    AtoQuarterlyPaygInstalmentNoticeEditor_Quarter = 'quarter',
    AtoQuarterlyPaygInstalmentNoticeEditor_Year = 'year',
    AtoQuarterlyPaygInstalmentNoticeEditor_FormDueOn = 'formDueOn',
    AtoQuarterlyPaygInstalmentNoticeEditor_PaymentDueOn = 'paymentDueOn',
    AtoQuarterlyPaygInstalmentNoticeEditor_InstalmentAmount = 'instalmentAmount',
    AtoQuarterlyPaygInstalmentNoticeEditor_AtoCode = 'atoCode',
    AtoQuarterlyPaygInstalmentNoticeEditor_EftCode = 'eftCode',

    RolloverBenefitStatement_ReceivingFundABN = 'receivingFundABN',
    RolloverBenefitStatement_ReceivingFundName = 'receivingFundName',
    RolloverBenefitStatement_TransferringFundName = 'transferringFundName',
    RolloverBenefitStatement_TransferringFundABN = 'transferringFundABN',
    RolloverBenefitStatement_Tfn = 'tfn',
    RolloverBenefitStatement_FullName = 'fullName',
    RolloverBenefitStatement_ServicePeriodStartDate = 'servicePeriodStartDate',
    RolloverBenefitStatement_TaxFreeComponent = 'taxFreeComponent',
    RolloverBenefitStatement_PreservedAmount = 'preservedAmount',
    RolloverBenefitStatement_ElementTaxedInTheFund = 'elementTaxedInTheFund',
    RolloverBenefitStatement_ElementUntaxedInTheFund = 'elementUntaxedInTheFund',
    RolloverBenefitStatement_RestrictedNonPreservedAmount = 'restrictedNonPreservedAmount',
    RolloverBenefitStatement_UnrestrictedNonPreservedAmount = 'unrestrictedNonPreservedAmount',

    ChartOfAccounts360Editor_Code = 'code',
    ChartOfAccounts360Editor_Name = 'name',
    ChartOfAccounts360Editor_Probability = 'probability',
    ChartOfAccounts360PagesEditor_Code = 'code',
    ChartOfAccounts360PagesEditor_Name = 'name',
    ChartOfAccounts360PagesEditor_Probability = 'probability',

    BusinessModelTypePagesEditor_ModelName = 'modelName',
    BusinessModelTypePagesEditor_Probability = 'probability',

    SplitPageDetectionEditor_DocumentSplitType = 'documentSplitType',
    SplitPageDetectionEditor_Probability = 'probability',

    BankAuthorityEditor_PracticeName = 'practiceName',
    BankAuthorityEditor_AcnAbn = 'acnAbn',
    BankAuthorityEditor_Accounts = 'accounts',
    BankAuthorityEditor_SignatoriesName = 'signatoriesName',
    BankAuthorityEditor_Signature = 'signature',
    BankAuthorityEditor_SignatureDate = 'signatureDate',
    BankAuthorityEditor_SmsfName = 'smsfName',
    BankAuthorityAccounts_AccountName = 'accountName',
    BankAuthorityAccounts_AccountNumber = 'accountNumber',
    BankAuthorityAccounts_Bsb = 'bsb',

    AnnualTaxStatementEditor_Year = 'year',
    AnnualTaxStatementEditor_InvestCode = 'investCode',
    AnnualTaxStatementEditor_InvestName = 'investName',
    AnnualTaxStatementEditor_OtherIncome = 'otherIncome',
    AnnualTaxStatementEditor_Interest = 'interest',
    AnnualTaxStatementEditor_GrossFranked = 'grossFranked',
    AnnualTaxStatementEditor_FrankingCredits = 'frankingCredits',
    AnnualTaxStatementEditor_Unfranked = 'unfranked',
    AnnualTaxStatementEditor_NetFranked = 'netFranked',
    AnnualTaxStatementEditor_DiscountedCG = 'discountedCG',
    AnnualTaxStatementEditor_OtherCG = 'otherCG',
    AnnualTaxStatementEditor_CgtConcession =' cgtConcession',
    AnnualTaxStatementEditor_DiscountForCGCredits = 'discountForCGCredits',
    AnnualTaxStatementEditor_OtherForCGCredits= 'otherForCGCredits',
    AnnualTaxStatementEditor_DiscountForCG = 'discountForCG',
    AnnualTaxStatementEditor_OtherForCG= 'otherForCG',
    AnnualTaxStatementEditor_IndexCG = 'indexCG',
    AnnualTaxStatementEditor_GrossOtherForeignIncome = 'grossOtherForeignIncome',
    AnnualTaxStatementEditor_ForeignCredits = 'foreignCredits',
    AnnualTaxStatementEditor_GrossForeignInc = 'grossForeignInc',
    AnnualTaxStatementEditor_NZFrankingCredits = 'NZFrankingCredits',
    AnnualTaxStatementEditor_TaxDeferred = 'taxDeferred',
    AnnualTaxStatementEditor_TaxExempt = 'taxExempt',
    AnnualTaxStatementEditor_TaxFree = 'taxFree',
    AnnualTaxStatementEditor_AmitExcess = 'amitExcess',
    AnnualTaxStatementEditor_AmitShortfall = 'amitShortfall',
    AnnualTaxStatementEditor_TfnCredits = 'tfnCredits',
    AnnualTaxStatementEditor_TrustDeduction = 'trustDeduction',
    AnnualTaxStatementEditor_OtherExpense = 'otherExpense',
    AnnualTaxStatementEditor_NetCash = 'netCash',
    AnnualTaxStatementEditor_NetForeignIncome = 'netForeignIncome',
    AnnualTaxStatementEditor_NppNCMI = 'nppNCMI',
    AnnualTaxStatementEditor_NppExcNCMI = 'nppExcNCMI',
    AnnualTaxStatementEditor_PpExcNCMI = 'ppExcNCMI',
    AnnualTaxStatementEditor_PpNCMI = 'ppNCMI',
    AnnualTaxStatementEditor_DiscountGainExcNCMI = 'discountGainExcNCMI',
    AnnualTaxStatementEditor_DiscountGainNCMI = 'discountGainNCMI',
    AnnualTaxStatementEditor_OtherGainExcNCMI = 'otherGainExcNCMI',
    AnnualTaxStatementEditor_OtherGainNCMI = 'otherGainNCMI',
    AnnualTaxStatementEditor_NcmiCapitalGain = 'ncmiCapitalGain',
    AnnualTaxStatementEditor_ExcNCMICapitalGain = 'excNCMICapitalGain',
    AnnualTaxStatementEditor_CfcIncome = 'cfcIncome',
    AnnualTaxStatementEditor_AbnWithheldTax = 'abnWithheldTax',
    AnnualTaxStatementEditor_ForeignResidentCGWithheldTax = 'foreignResidentCGWithheldTax',
    AnnualTaxStatementEditor_NationalRentalOffset = 'nationalRentalOffset',
    AnnualTaxStatementEditor_ExplorationCredits = 'explorationCredits',
    AnnualTaxStatementEditor_EarlyStageVentureCapitalOffset = 'earlyStageVentureCapitalOffset',
    AnnualTaxStatementEditor_EarlyStageInvestorOffset = 'earlyStageInvestorOffset',
    AnnualTaxStatementEditor_Abn = 'abn',
    AnnualTaxStatementEditor_Acn = 'acn',

    ASICFeeEditor_ASICFeeIssueDate = 'ASICFeeIssueDate',
    ASICFeeEditor_ASICFeeCompanyName = 'ASICFeeCompanyName',
    ASICFeeEditor_ASICFeeACN = 'ASICFeeACN',
    ASICFeeEditor_ASICFeeAccountNumber = 'ASICFeeAccountNumber',
    ASICFeeEditor_ASICFeeCurrentDueAmount = 'ASICFeeCurrentDueAmount',
    ASICFeeEditor_ASICFeeCurrentDueDate = 'ASICFeeCurrentDueDate',
    ASICFeeEditor_ASICFeeOverdueAmount = 'ASICFeeOverdueAmount',
    ASICFeeEditor_ASICFeeTotalDue = 'ASICFeeTotalDue',
    ASICFeeEditor_CompanyStatementCompanyName = 'companyStatementCompanyName',
    ASICFeeEditor_CompanyStatementACN = 'companyStatementACN',
    ASICFeeEditor_CompanyStatementIssueDate = 'companyStatementIssueDate',
    ASICFeeEditor_CompanyRegisteredAddress = 'companyRegisteredAddress',
    ASICFeeEditor_CompanyOfficeholder = 'companyOfficeholder',
    ASICFeeEditor_CompanyOfficeholderDOB = 'companyOfficeholderDOB',
    ASICFeeEditor_CompanyMember = 'companyMember',
    TransactionDetails_TransactionDate = 'transactionDate',
    TransactionDetails_TransactionDescription = 'transactionDescription',
    TransactionDetails_TransactionReference = "transactionReference",
    TransactionDetails_TransactionAmount = 'transactionAmount',


    CouncilRate_PropertyAddress = 'propertyAddress',
    CouncilRate_IssueDate = 'issueDate',
    CouncilRate_CurrentAmountDueDate = 'currentAmountDueDate',
    CouncilRate_CurrentAmountDue = 'currentAmountDue',
    CouncilRate_FirstInstalmentDueDate = 'firstInstalmentDueDate',
    CouncilRate_FirstInstalmentAmount = 'firstInstalmentAmount',
    CouncilRate_SecondInstalmentDueDate = 'secondInstalmentDueDate',
    CouncilRate_SecondInstalmentAmount = 'secondInstalmentAmount',
    CouncilRate_ThirdInstalmentDueDate = 'thirdInstalmentDueDate',
    CouncilRate_ThirdInstalmentAmount = 'thirdInstalmentAmount',
    CouncilRate_FourthInstalmentDueDate = 'fourthInstalmentDueDate',
    CouncilRate_FourthInstalmentAmount = 'fourthInstalmentAmount',
    CouncilRate_DiscountTotalAmountDue = 'discountTotalAmountDue',
    CouncilRate_TotalAmount = 'totalAmount',

}
