import {
  FieldAndColumnName,
  ReceiptItem
} from '@bgl/textract-business-model-editor'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class ReceiptItemToTableData {
  static toRows(items: List<ReceiptItem>): GridRowsProp {
    return items
      .map((item, index) => ReceiptItemToTableData.toGridRow(item, index))
      .toArray()
  }

  static toGridRow = (item: ReceiptItem, id: number) => {
    return {
      id,
      [FieldAndColumnName.ReceiptItemColumn_Name]: item.name?.parsedValue,
      [FieldAndColumnName.ReceiptItemColumn_Quantity]:
        item.quantity?.parsedValue,
      [FieldAndColumnName.ReceiptItemColumn_Price]: item.price?.parsedValue,
      [FieldAndColumnName.ReceiptItemColumn_TotalPrice]:
        item.totalPrice?.parsedValue
    }
  }
}
