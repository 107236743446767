import React from 'react'

import { ReactComponent as Step1Icon } from '../../../assets/images/redaction/Group50.svg'
import { ReactComponent as Step2Icon } from '../../../assets/images/redaction/Group51.svg'
import { ReactComponent as Step4Icon } from '../../../assets/images/redaction/Group52.svg'
import { ReactComponent as Step3Icon } from '../../../assets/images/redaction/Group53.svg'

function HowRedactionWork() {
  return (
    <section className="how-redaction-work">
      <div className="container">
        <div>
          <h1>How SmartDocs Redaction Works</h1>
        </div>
        <div className="steps">
          <div className="step">
            <Step1Icon />
            <h3>Select Files</h3>
            <p>
              Upload your files to our platform or email them to SmartDocs AI
              Studio for processing
            </p>
          </div>

          <div className="step">
            <Step2Icon />
            <h3>Auto-Annotate</h3>
            <p>
              Rely on our pre-trained machine learning models to detect
              sensitive information from your files
            </p>
          </div>

          <div className="step">
            <Step3Icon />
            <h3>Review / Configure</h3>
            <p>Review and modify (if necessary) redaction results</p>
          </div>

          <div className="step">
            <Step4Icon />
            <h3>Finalise Redaction</h3>
            <p>Download a redacted copy in your preferred format</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowRedactionWork
