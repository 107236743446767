import { List, OrderedMap } from 'immutable'
import { v4 as uuidv4 } from 'uuid'
import { Annotation, AnnotationId, Annotations } from './Annotation'
import { Page } from './ocr/Page'
import {IObjectAnnotation, IObjectAnnotations} from './types/ISmartDocsResult'
import { flatten } from "../utils/utils"


export interface IRectObjectCoordinate {
  xmin: number,
  ymin: number,
  xmax: number,
  ymax: number,
}

export class ObjectAnnotation extends Annotation{
  constructor(
    readonly objectId: string,
    readonly tagId: string,
    readonly confidence: number,
    readonly objectAnnotationCoor: IRectObjectCoordinate,
    readonly source: string
  ) {
    super(new AnnotationId("1000", objectId), tagId, confidence, source)
  }

  viewText(
    idToPageNumber: (id: AnnotationId) => number | undefined
  ): string {
    const pageNumber = idToPageNumber(this.id)
    if (pageNumber) {
      return `Page${pageNumber}(${this.objectAnnotationCoor.xmin}...)`
    } else {
      return `${this.objectAnnotationCoor.xmin}...`
    }
  }

   tagObject(tagId: string, objectAnnotationCoor: IRectObjectCoordinate, newSource: string): ObjectAnnotation {
    return this.copy({tagId, objectAnnotationCoor, confidence: 1, source: newSource})
  }

  copy({
    objectId = this.objectId,
    tagId = this.tagId,
    confidence = this.confidence,
    objectAnnotationCoor = this.objectAnnotationCoor,
    source = this.source
  }): ObjectAnnotation {
    return new ObjectAnnotation(objectId, tagId, confidence, objectAnnotationCoor, source)
  }

  toJson(): IObjectAnnotation{
    return {
      Annotation: this.tagId,
      Confidence: this.confidence,
      XMin: this.objectAnnotationCoor.xmin,
      YMin: this.objectAnnotationCoor.ymin,
      XMax: this.objectAnnotationCoor.xmax,
      YMax: this.objectAnnotationCoor.ymax,
      Source: this.source
    }
  }

  static fromJson(objectAnnotation: IObjectAnnotation): ObjectAnnotation {
    const objectAnnotationCoor = {
      xmin: objectAnnotation.XMin,
      ymin: objectAnnotation.YMin,
      xmax: objectAnnotation.XMax,
      ymax: objectAnnotation.YMax
    }
    return new ObjectAnnotation(uuidv4(), objectAnnotation.Annotation, objectAnnotation.Confidence, objectAnnotationCoor, objectAnnotation.Source)
  }
}

export class ObjectAnnotationsOnPage {
  constructor(
    readonly pageId: string,
    readonly annotations: List<ObjectAnnotation>
  ){}

  annotationsForViewer(): List<Annotation> {
    return List()
  }

  copy({
    pageId = this.pageId,
    annotations = this.annotations
   }): ObjectAnnotationsOnPage {
    return new ObjectAnnotationsOnPage(pageId, annotations)
  }

  static fromJson(objectAnnotationsOnPage: IObjectAnnotations): ObjectAnnotationsOnPage {
    const pageId = objectAnnotationsOnPage.Page
    const annotations = objectAnnotationsOnPage.Annotations.map((annotation => {
      return ObjectAnnotation.fromJson(annotation)
    }))
    return new ObjectAnnotationsOnPage(pageId, List(annotations))
  }

  toJson(): IObjectAnnotations {
    return {
      Page: this.pageId,
      Annotations: this.annotations.map(annotation => annotation.toJson()).toArray()
    }
  }

  // TODO Delete
  groupByTagOnPage(): OrderedMap<string, List<ObjectAnnotation>> {
    let map = OrderedMap<string, List<ObjectAnnotation>>()
    this.annotations.forEach(annotation => {
      const exists = map.get(annotation.tagId)
      if (exists) {
        map = map.set(annotation.tagId, exists.push(annotation))
      } else {
        map = map.set(annotation.tagId, List([annotation]))
      }
    })
    return map
  }

  addOrUpdateAnnotationOnPage(objectId: string, tagId: string, objectAnnotationCoor: IRectObjectCoordinate, newSource: string): ObjectAnnotationsOnPage {
    const objectAnnotation = this.annotations.find(annotation => annotation.objectId === objectId)
    if(objectAnnotation){
      return this.copy({
        annotations: this.annotations.map(annotation => {
          if(annotation.objectId === objectId){
            return annotation.tagObject(tagId, objectAnnotationCoor, newSource)
          }
          return annotation
        })
      })
    } else {
      return this.copy({
        annotations: this.annotations.push(new ObjectAnnotation(objectId, tagId, 1, objectAnnotationCoor, newSource))
      })
    }
  }

  removeAnnotationOnPage(objectId: string): ObjectAnnotationsOnPage {
    return this.copy({
      annotations: this.annotations.filter(annotation => annotation.objectId !== objectId)
    })
  }
}

export class ObjectAnnotationsOnFile extends Annotations {
  constructor(
    readonly objectAnnotations: List<ObjectAnnotationsOnPage>
  ){
    super()
  }

  annotationsForViewer(): List<Annotation> {
    return flatten(List(this.groupByTagOnFile().values()))
  }

  find(pageId: string, objectId: string): ObjectAnnotation | undefined {
    const page = this.objectAnnotations.find(objectAnnotationsOnPage => objectAnnotationsOnPage.pageId === pageId)
    if(page){
      return page.annotations.find(objectAnnotation => objectAnnotation.objectId === objectId)
    }
    return undefined
  }

  groupByTagOnFile(): OrderedMap<string, List<ObjectAnnotation>> {
    let map = OrderedMap<string, List<ObjectAnnotation>>()
    this.objectAnnotations.forEach(objectAnnotationsOnPage => {
      const pageGroupedMap = objectAnnotationsOnPage.groupByTagOnPage()
      map = map.mergeWith(
        (oldVal: List<ObjectAnnotation>, newVal: List<ObjectAnnotation>) => oldVal.concat(newVal),
        pageGroupedMap
      )
    })
    return map
  }

  copy({
         objectAnnotations = this.objectAnnotations
       }): ObjectAnnotationsOnFile {
    return new ObjectAnnotationsOnFile(objectAnnotations)
  }

  static fromJson(rawPages: List<Page>, objectAnnotations?: IObjectAnnotations[]): ObjectAnnotationsOnFile {
    const annotations = objectAnnotations ?? []
    const objectAnnotationsOnPages = rawPages.map(page => {
      const annotationsOnPage = annotations.find(annotation => annotation.Page === page.id)
      if (annotationsOnPage) {
        return ObjectAnnotationsOnPage.fromJson(annotationsOnPage)
      } else {
        return new ObjectAnnotationsOnPage(page.id, List<ObjectAnnotation>())
      }
    })
    return new ObjectAnnotationsOnFile(objectAnnotationsOnPages)
  }

  toJson(): IObjectAnnotations[] {
    const nonEmptyPages = this.objectAnnotations.filter(objectAnnotationsOnPage => !objectAnnotationsOnPage.annotations.isEmpty())
    return nonEmptyPages.map(objectAnnotationsOnPage => objectAnnotationsOnPage.toJson()).toArray()
  }

  addOrUpdateObjectAnnotation(pageId: string, objectId: string, tagId: string, objectCoordinate: IRectObjectCoordinate, source: string): ObjectAnnotationsOnFile {
    return this.copy({
      objectAnnotations: this.objectAnnotations.map(objectAnnotationsOnPage => {
        if(objectAnnotationsOnPage.pageId === pageId){
          return objectAnnotationsOnPage.addOrUpdateAnnotationOnPage(objectId, tagId, objectCoordinate, source)
        }
        return objectAnnotationsOnPage
      })
    })
  }

  removeObjectAnnotation(pageId: string, objectId: string): ObjectAnnotationsOnFile {
    return this.copy({
      objectAnnotations: this.objectAnnotations.map(objectAnnotationsOnPage => {
          if(objectAnnotationsOnPage.pageId === pageId){
            return objectAnnotationsOnPage.removeAnnotationOnPage(objectId)
          }
          return objectAnnotationsOnPage
        }
      )
    })
  }

  getAnnotationsOnPage(pageId: string): ObjectAnnotationsOnPage {
    return this.objectAnnotations.find(objectAnnotationsOnPage => objectAnnotationsOnPage.pageId === pageId)
      ?? new ObjectAnnotationsOnPage(pageId, List<ObjectAnnotation>())
  }
}

