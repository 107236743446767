import {IModelType, ISplitDocType} from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import { BusinessModelTypePageTable } from './BusinessModelTypePageTable'
import {List} from "immutable";

export class BusinessModelType extends BusinessModel {
  constructor(
    version: string,
    readonly modelName: string,
    readonly probability: number,
    readonly businessModelTypePages: BusinessModelTypePageTable,
    readonly splitPageDetections: List<SplitPageDetection>,
    readonly splitRanges: List<SplitDocRange>,
  ) {
    super(IModelType.BusinessModelType, version)
  }
}

export class SplitDocRange {
  constructor(
    readonly start: number,
    readonly end: number,
  ) {}
}

export class SplitPageDetection {
  constructor(
    readonly pageNumber: number,
    readonly documentSplitType: ISplitDocType,
    readonly probability: number,
  ) {}
}
