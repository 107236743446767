import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List, isList} from 'immutable'
import moment from 'moment'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import {Lazy} from '../../utils/Lazy'
import { Transaction } from './bankstatement/Transaction'
import {DetectedField} from './DetectedField'
import {FieldVisitor} from './DetectedFieldVisitor'
import IItemObject from './IItemObject'
import ModelObject from './ModelObject'
import {ModelSpecificLineItems} from './ModelSpecificLineItems'

export enum Action {
  Add = 'add',
  Update = 'update',
  Delete = 'delete',
  Invalid = 'invalid'
}

export default abstract class BusinessModel extends ModelObject {
  private readonly lazyModelSpecificLineItems: Lazy<ModelSpecificLineItems>

  protected constructor(
    readonly modelType: IModelType,
    readonly version: string
  ) {
    super()
    this.lazyModelSpecificLineItems = new Lazy(() => ModelSpecificLineItems.fromFields(this.listFields()))
  }

  modelSpecificLineItems(): ModelSpecificLineItems {
    return this.lazyModelSpecificLineItems.value
  }

  accept<R>(fieldVisitor: FieldVisitor<R>): List<R> {
    return this.listFields().map(field => fieldVisitor.visitField(field))
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    return undefined
  }

  protected getAction(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue): Action {
    if (!Object.keys(this).includes(id)) {
      return Action.Invalid
    }

    const found = this.get(id)
    if (found && field?.canUpdateValue(value)) {
      return Action.Update
    } else if ((!found || (isList(found) && found.size === 0)) && !field && value) { // some field is List and with empty List as default value
      return Action.Add
    } else if (found && field && !value) {
      return Action.Delete
    }

    return Action.Invalid
  }

  protected handleUpdate(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue, modifiedBy: string): BusinessModel {
    const found = this.get(id)
    if (found) {
      if (found instanceof DetectedField) {
        if (value === (found as DetectedField).parsedValue) {
          return this
        }

        const updatedField = (found as DetectedField).updateValue(value, modifiedBy)

        return this.copy({ [id]: updatedField }) as BusinessModel
      } else if (List.isList(found)) {
        const updatedList = this.updateArray((found as List<DetectedField>), id, field, value, modifiedBy)

        return this.copy({ [id]: updatedList }) as BusinessModel
      }
    }

    return this
  }

  protected handleAdd(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue, modifiedBy: string): BusinessModel {
    const newMember = this.createMember(id, value, modifiedBy)
    if (newMember) {
      return this.copy({ [id]: newMember })
    }

    return this
  }

  protected handleDelete(id: FieldAndColumnName): BusinessModel {
    return this.copy({ [id]: null })
  }

  /**
   * Function used to create, amend and delete change from UI on individual and array field.
   */
  update(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue, modifiedBy: string): BusinessModel {
    // Default update process for class with all members are DetectedField or List<DetectedField>. For class containing grid
    // or other type member, you need override update() in that class and implement the process.
    switch (this.getAction(id, field, value)) {
      case Action.Update:
        return this.handleUpdate(id, field, value, modifiedBy)
      case Action.Add:
        return this.handleAdd(id, field, value, modifiedBy)
      case Action.Delete:
        return this.handleDelete(id)
      default:
        return this
    }
  }

  protected getGridAction(row: IItemObject, gridColumnName: FieldAndColumnName, value: FormGridRowValue): Action {
    if (!Object.keys(row).includes(gridColumnName)) {
      return Action.Invalid
    }

    const found = row.fieldByColumn(gridColumnName)
    if (found && value) {
      return Action.Update
    } else if (!found && value) {
      return Action.Add
    } else if (found && !value) {
      return Action.Delete
    }

    return Action.Invalid
  }

  /**
   * Function used to create, amend and delete change from UI on grid cell.
   */
  updateGrid(
    gridColumnName: FieldAndColumnName,
    gridFieldId: string | number,
    newValue: FormGridRowValue,
    modifiedBy: string
  ): BusinessModel {
    const items = this.getGridMember()
    const row = items.get(gridFieldId as number)
    // NOTE: Current UI does not allow user adding/deleting a row, implement the logic once UI changed
    if (!row) {
      console.error('Updating in a row which is not existing!')
      return this
    }

    let updatedItems: List<IItemObject>, updatedRow: IItemObject

    switch(this.getGridAction(row, gridColumnName, newValue)) {
      case Action.Update:
        const updatedField = (row.fieldByColumn(gridColumnName)! as DetectedField).updateValue(newValue, modifiedBy)
        updatedRow = row.copy({ [gridColumnName]: updatedField })
        updatedItems = items.setIn([gridFieldId], updatedRow)

        return this.copy({ items: updatedItems })
      case Action.Add:
        updatedRow = row.addColumn(gridColumnName, newValue, modifiedBy)
        updatedItems = items.setIn([gridFieldId], updatedRow)

        return this.copy({ items: updatedItems })
      case Action.Delete:
        updatedRow = row.deleteColumn(gridColumnName)
        updatedItems = items.setIn([gridFieldId], updatedRow)

        return this.copy({ items: updatedItems })

      default:
        return this
    }
  }

  protected getGridMember(): List<IItemObject> {
    return List()
  }

  /**
   * Function used to create, amend and delete transaction list.
   */
  updateTransactionList(modifiedBy: string, existingValue?: Transaction, newValue?: Transaction): BusinessModel {
    return this
  }

  protected getTransactionListAction(existing: IItemObject, value?: IItemObject): Action {
    if (existing && value) {
      return Action.Update
    } else if (!existing && value) {
      return Action.Add
    } else if (existing && !value) {
      return Action.Delete
    }

    return Action.Invalid
  }

  protected getArrayAction(index: number, value: any): Action {
    if (value && index !== -1) {
      return Action.Update
    } else if (value && index === -1) {
      return Action.Add
    } else if (!value && index !== -1) {
      return Action.Delete
    }
    return Action.Invalid
  }

  protected handleArrayUpdate(current: List<DetectedField>, index: number, value: any, modifiedBy: string): List<DetectedField> {
    const updated = current.get(index)!.updateValue(value, modifiedBy)

    return current.setIn([index], updated)
  }

  protected handleArrayAdd(current:List<DetectedField>, id: FieldAndColumnName, value: any, modifiedBy: string): List<DetectedField> {
    return List()
  }

  protected handleArrayDelete(current:List<DetectedField>, index: number): List<DetectedField> {
    return current.deleteIn([index])
  }

  protected updateArray(current: List<DetectedField>, id: FieldAndColumnName, field: DetectedField | undefined, value: any, modifiedBy: string): List<DetectedField> {
    const index = current.findIndex(v => v.id === field?.id)

    switch(this.getArrayAction(index, value)) {
      case Action.Update:
        return this.handleArrayUpdate(current, index, value, modifiedBy)
      case Action.Add:
        return this.handleArrayAdd(current, id, value, modifiedBy)
      case Action.Delete:
        return this.handleArrayDelete(current, index)

      default:
        return current
    }

  }

  copy<T, U extends BusinessModel>(args: T): U | BusinessModel {
    return this
  }

  toJson(): IBusinessModel {
    return {
      ModelType: IModelType.Unrecognized,
      Version: "1.0",
      PageIndexes: []
    }
  }

  protected listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }

  pageIndexes(): number[] {
    return this.modelSpecificLineItems().pageIndexes()
  }

  getShareCode(): string {
    return ''
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return undefined
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return undefined
  }

  getTotalAmount(): number {
    return 0
  }
}

