import moment from 'moment'

export interface TModelDTO {
  projectId: string
  id: string
  s3Key: string
  createdTime: string
  tagIds: string[]
}

export class TModel {
  constructor(
    public id: string,
    public s3Key: string,
    public createdTime: moment.Moment,
    public tagIds: string[]
  ) {}

  static fromJson(tModelDTO: TModelDTO): TModel {
    const { id, s3Key, createdTime, tagIds } = tModelDTO
    return new TModel(id, s3Key, moment(createdTime), tagIds)
  }
}

export class TModels {
  constructor(public models: TModel[] = []) {}

  static fromJson(tModelDTOs: TModelDTO[]): TModels {
    const models = tModelDTOs.map(TModel.fromJson)
    return new TModels(models)
  }

  findModel(id: string): TModel | undefined {
    return this.models.find((model) => model.id === id)
  }
}
