/* eslint-env browser */
import React, { useEffect } from 'react'

import DataProtection from './DataProtection'
import DataSanitisation from './DataSanitisation'
import Hero from './Hero'
import HowRedactionWork from './HowRedactionWork'
import ReadyForExperience from './ReadyForExperience'
import RedactionBriSection from './RedactionBriSection'
import RedactionKeyFeatures from './RedactionKeyFeatures'
import RedactionUseCase from './RedactionUseCase'
import TryItYourself from './TryItYourself'

function Redaction() {
  useEffect(() => {
    const selectBody = document.querySelector('body')
    selectBody.setAttribute('id', 'home-3')
  }, [])

  return (
    <div id="privacyredaction">
      <Hero />
      <RedactionBriSection />
      <DataProtection />
      <RedactionKeyFeatures />
      <HowRedactionWork />
      <DataSanitisation />
      <RedactionUseCase />
      <TryItYourself />
      <ReadyForExperience />
    </div>
  )
}

export default Redaction
