import { List } from 'immutable'
import { isEmpty } from 'lodash'
import { IAtoTransactionTable, IModelType } from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import { DateField, StringField } from '../DetectedField'
import ModelObject from '../ModelObject'
import { AtoActivityStatementTransactions} from './AtoActivityStatementTransactions'

export class AtoActivityStatement extends BusinessModel {
  constructor(
    version: string,
    readonly name: string,
    readonly atoTransactionTables: List<AtoTransactionTable>
  ) {
    super(IModelType.AtoActivityStatement, version)
  }

  firstTable(): AtoTransactionTable | undefined {
    return this.atoTransactionTables.first(undefined)
  }

}

export class AtoTransactionTable extends ModelObject {
  constructor(
    readonly transactions: AtoActivityStatementTransactions,
    readonly abnNumber?: StringField,
    readonly tfnNumber?: StringField,
    readonly startDate?: DateField,
    readonly endDate?: DateField
  ) {
    super()
  }

  private generateStatementPeriod(): object | undefined {
    const statementPeriod = {}
    if (this.startDate) {
      Object.assign(statementPeriod, { Start: this.startDate?.toModelKeyValue() })
    }

    if (this.endDate) {
      Object.assign(statementPeriod, { ...statementPeriod, End: this.endDate?.toModelKeyValue() })
    }

    return isEmpty(statementPeriod) ? undefined : statementPeriod
  }

  toJson(): IAtoTransactionTable {
    return {
      TfnNumber: this.tfnNumber?.toModelKeyValue(),
      AbnNumber: this.abnNumber?.toModelKeyValue(),
      StatementPeriod: this.generateStatementPeriod(),
      Table: {
      // Headers and SpecialRows are required from client side?
      // Headers?: ITransactionTableDataRow,
      // SpecialRows?: ITransactionTableSpecialRow[],
      DataRows: this.transactions.atoActivityTransactions.toArray().map(t => t.toJson())
    }
    }
  }
}

