import {
  IBusinessModel,
  IInvoiceItem,
  IInvoiceModel,
  IModelType,
} from "../../types/ISmartDocsResult"
import {BusinessModelMapper} from "../BusinessModelMapper"
import {Invoice} from "./Invoice"
import {InvoiceItem} from "./InvoiceItem"
import {OcrDocument} from "../../OcrDocument"
import {List} from "immutable"

export class InvoiceMapper extends BusinessModelMapper{
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IInvoiceModel,
  ) {
    super(document)
  }

  fromJson(): Invoice {
    const invoiceModel = this.jsonModel
    const items = invoiceModel.Items?.map((item: IInvoiceItem) => {
      return new InvoiceItem(
        this.mapDoubleBlock(item.Amount),
        this.mapTextBlock(item.Description),
        this.mapDoubleBlock(item.Quantity),
        this.mapDoubleBlock(item.UnitPrice),
        this.mapTextBlock(item.ProductCode),
        this.mapTextBlock(item.Unit),
        this.mapDateBlock(item.Date),
        this.mapDoubleBlock(item.Tax)
      )
    })
    const model = new Invoice(
      invoiceModel.Version,
      items ? List(items) : List(),
      this.mapTextBlock(invoiceModel.CustomerName),
      this.mapTextBlock(invoiceModel.CustomerId),
      this.mapTextBlock(invoiceModel.PurchaseOrder),
      this.mapTextBlock(invoiceModel.InvoiceId),
      this.mapDateBlock(invoiceModel.InvoiceDate),
      this.mapDateBlock(invoiceModel.DueDate),
      this.mapTextBlock(invoiceModel.VendorName),
      this.mapTextBlock(invoiceModel.VendorAddress),
      this.mapTextBlock(invoiceModel.VendorAddressRecipient),
      this.mapTextBlock(invoiceModel.CustomerAddress),
      this.mapTextBlock(invoiceModel.CustomerAddressRecipient),
      this.mapTextBlock(invoiceModel.BillingAddress),
      this.mapTextBlock(invoiceModel.BillingAddressRecipient),
      this.mapTextBlock(invoiceModel.ShippingAddress),
      this.mapTextBlock(invoiceModel.ShippingAddressRecipient),
      this.mapDoubleBlock(invoiceModel.SubTotal),
      this.mapDoubleBlock(invoiceModel.TotalTax),
      this.mapDoubleBlock(invoiceModel.InvoiceTotal),
      this.mapDoubleBlock(invoiceModel.AmountDue),
      this.mapTextBlock(invoiceModel.ServiceAddress),
      this.mapTextBlock(invoiceModel.ServiceAddressRecipient),
      this.mapTextBlock(invoiceModel.RemittanceAddress),
      this.mapTextBlock(invoiceModel.RemittanceAddressRecipient),
      this.mapDateBlock(invoiceModel.ServiceStartDate),
      this.mapDateBlock(invoiceModel.ServiceEndDate),
      this.mapDoubleBlock(invoiceModel.PreviousUnpaidBalance)
    )
    return model
  }

  static toJson(model: Invoice): IBusinessModel {
    return {
      ModelType: IModelType.Invoice,
      Version: model.version,
      Items: model.items?.toArray().map(i => i.toJson()),
      CustomerName: model.customerName?.toModelKeyValue(),
      CustomerId: model.customerId?.toModelKeyValue(),
      PurchaseOrder: model.purchaseOrder?.toModelKeyValue(),
      InvoiceId: model.invoiceId?.toModelKeyValue(),
      InvoiceDate: model.invoiceDate?.toModelKeyValue(),
      DueDate: model.dueDate?.toModelKeyValue(),
      VendorName: model.vendorName?.toModelKeyValue(),
      VendorAddress: model.vendorAddress?.toModelKeyValue(),
      VendorAddressRecipient: model.vendorAddressRecipient?.toModelKeyValue(),
      CustomerAddress: model.customerAddress?.toModelKeyValue(),
      CustomerAddressRecipient: model.customerAddressRecipient?.toModelKeyValue(),
      BillingAddress: model.billingAddress?.toModelKeyValue(),
      BillingAddressRecipient: model.billingAddressRecipient?.toModelKeyValue(),
      ShippingAddress: model.shippingAddress?.toModelKeyValue(),
      ShippingAddressRecipient: model.shippingAddressRecipient?.toModelKeyValue(),
      SubTotal: model.subTotal?.toModelKeyValue(),
      TotalTax: model.totalTax?.toModelKeyValue(),
      InvoiceTotal: model.invoiceTotal?.toModelKeyValue(),
      AmountDue: model.amountDue?.toModelKeyValue(),
      ServiceAddress: model.serviceAddress?.toModelKeyValue(),
      ServiceAddressRecipient: model.serviceAddressRecipient?.toModelKeyValue(),
      RemittanceAddress: model.remittanceAddress?.toModelKeyValue(),
      RemittanceAddressRecipient: model.remittanceAddressRecipient?.toModelKeyValue(),
      ServiceStartDate: model.serviceStartDate?.toModelKeyValue(),
      ServiceEndDate: model.serviceEndDate?.toModelKeyValue(),
      PreviousUnpaidBalance: model.previousUnpaidBalance?.toModelKeyValue()
    } as IInvoiceModel
  }

}
