import React, { useEffect } from 'react'

import Loading from './components/others/Loading'
import { useInitStore } from './services/stores/InitStore'

const ProtectRouter = ({ children }: { children: JSX.Element }) => {
  const businesses = useInitStore((state) => state.businesses)
  const getBusinesses = useInitStore((state) => state.getBusinesses)
  const fetchAuthorizations = useInitStore((state) => state.fetchAuthorizations)

  useEffect(() => {
    Promise.all([fetchAuthorizations(), getBusinesses()])
      .then(([, businesses]) => {
        if (!businesses || businesses.businesses.length === 0) {
          alert('No business found, please contact support')
          window.location.href = `${window.location.origin}`
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
        window.location.href = `${window.location.origin}`
      })
  }, [])

  return businesses.businesses[0]?.id ? children : <Loading />
}

export default ProtectRouter
