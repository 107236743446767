import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, DoubleField, StringField} from '../DetectedField'
import { RolloverBenefitStatementMapper } from './RolloverBenefitStatementMapper'

export class RolloverBenefitStatement extends BusinessModel {
    constructor(
        modelType: IModelType,
        version: string,
        readonly receivingFundABN?: StringField,
        readonly receivingFundName?: StringField,
        readonly transferringFundName?: StringField,
        readonly transferringFundABN?: StringField,
        readonly tfn?: StringField,
        readonly fullName?: StringField,
        readonly servicePeriodStartDate?: DateField,
        readonly taxFreeComponent?: DoubleField,
        readonly preservedAmount?: DoubleField,
        readonly elementTaxedInTheFund?: DoubleField,
        readonly elementUntaxedInTheFund?: DoubleField,
        readonly restrictedNonPreservedAmount?: DoubleField,
        readonly unrestrictedNonPreservedAmount?: DoubleField
    ){
        super(modelType, version)
    }

    copy({
            version = this.version,
             modelType = this.modelType,
             receivingFundABN = this.receivingFundABN,
             receivingFundName = this.receivingFundName,
             transferringFundName = this.transferringFundName,
             transferringFundABN = this.transferringFundABN,
             tfn = this.tfn,
             fullName = this.fullName,
             servicePeriodStartDate = this.servicePeriodStartDate,
             taxFreeComponent = this.taxFreeComponent,
             preservedAmount = this.preservedAmount,
             elementTaxedInTheFund = this.elementTaxedInTheFund,
             elementUntaxedInTheFund = this.elementUntaxedInTheFund,
             restrictedNonPreservedAmount = this.restrictedNonPreservedAmount,
             unrestrictedNonPreservedAmount = this.unrestrictedNonPreservedAmount
         }): RolloverBenefitStatement {
        return new RolloverBenefitStatement(
            modelType,
            version,
            receivingFundABN,
            receivingFundName,
            transferringFundName,
            transferringFundABN,
            tfn,
            fullName,
            servicePeriodStartDate,
            taxFreeComponent,
            preservedAmount,
            elementTaxedInTheFund,
            elementUntaxedInTheFund,
            restrictedNonPreservedAmount,
            unrestrictedNonPreservedAmount
        )
    }
    toJson(): IBusinessModel {
        return RolloverBenefitStatementMapper.toJson(this)
    }
}