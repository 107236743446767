import TrainingReport, {
  DetailObjectCountMetricsType,
  Evaluation,
  Metrics,
  metricsNameMap,
  OverallObjectCountMetricsType,
  TrainReportDTOObjectCount
} from './TrainingReport'

export default class ObjectCountReport extends TrainingReport {
  constructor(
    public id: string,
    public datetime: string,
    public projectId: string,
    public epoch: number,
    public details: DetailObjectCountMetricsType[],
    public overallMetrics: OverallObjectCountMetricsType
  ) {
    super(id, datetime, projectId, epoch)
  }

  static fromJson(
    trainingReportDTO: TrainReportDTOObjectCount
  ): ObjectCountReport {
    const { id, datetime, projectId, epoch, details, ...overallMetrics } =
      trainingReportDTO
    return new ObjectCountReport(
      id,
      datetime,
      projectId,
      epoch,
      details,
      overallMetrics
    )
  }

  getMainMetrics() {
    const metrics = this.overallMetrics.mae
    return {
      name: Metrics.Mae,
      value: metrics,
      score: metrics.toFixed(1),
      evaluation: this.getEvaluation(metrics)
    }
  }

  generateOverallTableColumns = () => {
    const ObjectCountOverallTableHeader = ['mae', 'rmse']
    return ObjectCountOverallTableHeader.map((key) => ({
      field: key as string,
      headerName: metricsNameMap[key as string]
    }))
  }

  generateOverallTableRow = () => {
    const { mae, rmse } = this.overallMetrics
    return [
      {
        id: this.epoch.toString(),
        mae: mae.toPrecision(3),
        rmse: rmse.toPrecision(3)
      }
    ]
  }

  generateDetailTableColumns = () => {
    const ObjectCountDetailTableHeader = [
      'mae',
      'maeTrain',
      'rmse',
      'rmseTrain'
    ]
    return this.generateColumn(ObjectCountDetailTableHeader)
  }

  generateDetailTableRows = (tagIdToName: (tagId: string) => string) => {
    return this.details.map((detail) => {
      const { mae, rmse, maeTrain, rmseTrain, labelId } = detail
      return {
        id: labelId,
        tagName: tagIdToName(labelId),
        mae: mae.toPrecision(3),
        maeTrain: maeTrain.toPrecision(3),
        rmse: rmse.toPrecision(3),
        rmseTrain: rmseTrain.toPrecision(3)
      }
    })
  }

  private getEvaluation(metrics: number) {
    //TODO: Evaluation algorithm need to be discussed
    if (metrics > 0.9) return Evaluation.Good
    if (metrics <= 0.9 && metrics > 0.6) return Evaluation.Fair
    if (metrics <= 0.6) return Evaluation.Poor
    return Evaluation.NotAvailable
  }
}
