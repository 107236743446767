import { Map } from 'immutable'
import * as _ from 'lodash'

export enum TagDataType {
  String = 'String',
  Date = 'Date',
  DateTime = 'DateTime',
  Currency = 'Currency',
  Number = 'Number'
}

export enum TagDataFormatRegion {
  UK = 'UK',
  US = 'US',
  NONE = 'NONE'
}

export interface TagDTO {
  id: string
  projectId: string
  name: string
  dataType: TagDataType
  order: number
  isHidden: boolean
  supportingQuestions?: string[]
  dataFormats?: string[]
  description?: string
}

export interface AddTagBody {
  name: string
  dataType: TagDataType
  supportingQuestions?: string[]
  dataFormats?: string[]
  description?: string
}

export interface EditTagBody {
  name: string
  dataType?: TagDataType
  supportingQuestions?: string[]
  dataFormats?: string[]
  description?: string
}

export const UKDateFormatArray = [
  'dd MMM yy',
  'dd MMMM yyyy',
  'dd-MMM-yyyy',
  'dd/MM/yy',
  'dd/MM/yyyy',
  'dd-MM-yyyy'
]
export const UKDateTimeFormatArray = [
  'dd MMM yy HH:mm',
  'dd MMMM yyyy HH:mm',
  'dd-MMM-yyyy HH:mm',
  'dd/MM/yy HH:mm',
  'dd/MM/yyyy HH:mm',
  'dd-MM-yyyy HH:mm'
]
export const USDateFormatArray = [
  'MMM dd yy',
  'MMMM dd yyyy',
  'MMM-dd-yyyy',
  'MM/dd/yy',
  'MM/dd/yyyy',
  'MM-dd-yyyy'
]
export const USDateTimeFormatArray = [
  'MMM dd yy HH:mm',
  'MMMM dd yyyy HH:mm',
  'MMM-dd-yyyy HH:mm',
  'MM/dd/yy HH:mm',
  'MM/dd/yyyy HH:mm',
  'MM-dd-yyyy HH:mm'
]

const colorScheme = [
  'rgb(255, 127, 0)',
  'rgb(0, 199, 146)',
  'rgb(65, 153, 208)',
  'rgb(208,65,65)',
  'rgb(232, 99, 189)',
  'rgb(112, 170, 19)',
  'rgb(153, 16, 198)',
  'rgb(120, 84, 70)',
  'rgb(72, 51, 229)',
  'rgb(0,146,255)',
  'rgb(1,149,135)',
  'rgb(255,196,0)',
  'rgb(148, 32, 36)',
  'rgb(253, 86, 33)',
  'rgb(255, 100, 100)',
  'rgb(54, 63, 70)',
  'rgb(165, 217, 255)',
  'rgb(200, 255, 42)',
  'rgb(173, 162, 255)',
  'rgb(35, 255, 174)'
]

const randomColorGenerator = (tag: Tag): string => {
  let hash = 0
  const rgb = [0, 0, 0]
  do {
    if (tag.id.length === 0) return ''
    for (let i = 0; i < tag.id.length; i++) {
      hash = tag.id.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    for (let i = 0; i < 3; i++) {
      rgb[i] = (hash >> (i * 8)) & 255
    }
  } while (colorScheme.indexOf(`rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`) > -1)
  const generatedRGB = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
  colorScheme.push(generatedRGB)
  return generatedRGB
}

export class Tag {
  public color: string

  constructor(
    public id: string,
    public name: string,
    public dataType: TagDataType,
    public order: number,
    public isHidden: boolean,
    public supportingQuestions: string[] = [],
    public dataFormats: string[] = [],
    public description?: string
  ) {
    this.color = this._toRGB()
  }

  static fromJson(tagDTO: TagDTO): Tag {
    const {
      id,
      name,
      dataType,
      order,
      isHidden,
      supportingQuestions,
      dataFormats,
      description
    } = tagDTO
    return new Tag(
      id,
      name,
      dataType,
      order,
      isHidden,
      supportingQuestions,
      dataFormats,
      description
    )
  }

  static readonly UNKNOWN_TAG_NAME = 'Unknown Tag'

  _toRGB(): string {
    if (this.order && this.order <= colorScheme.length) {
      return colorScheme[this.order - 1]
    }
    return randomColorGenerator(this)
  }
}

export class Tags {
  constructor(public tags: Tag[] = []) {}

  static fromJson(tagDTOs: TagDTO[]): Tags {
    const tags = tagDTOs.map(Tag.fromJson)
    return new Tags(tags)
  }

  public get allTags(): Tag[] {
    return _.sortBy(this.tags, (tag) => tag.name.toLowerCase())
  }

  copy({ tags }: Partial<Tags>) {
    return new Tags(tags)
  }

  isEmpty(): boolean {
    return this.tags.length === 0
  }

  nonEmpty(): boolean {
    return !this.isEmpty()
  }

  onlyTagId(): string | null {
    return this.tags.length === 1 ? this.tags[0].id : null
  }

  ids(): string[] {
    return this.tags.map(({ id }) => id)
  }

  addTag(tag: Tag): Tags {
    return this.copy({ tags: _.concat(this.tags, tag) })
  }

  getTagColorMap(): Map<string, string> {
    return Map<string, string>(this.tags.map(({ id, color }) => [id, color]))
  }

  toIdNameMap(): Map<string, string> {
    return Map<string, string>(this.tags.map(({ id, name }) => [id, name]))
  }

  toIdOrderMap(): { [key in string]: number } {
    return _.fromPairs(this.tags.map(({ id, order }) => [id, order]))
  }

  isVisible(): Tags {
    return this.copy({ tags: _.filter(this.tags, (tag) => !tag.isHidden) })
  }

  findTagName = (id: string) => {
    return this.toIdNameMap().get(id) ?? 'Unknown Tag'
  }

  mapValues<T>(fn: (tag: Tag) => T): T[] {
    return this.tags.map((tag) => fn(tag))
  }

  sortToFirst(id: string): Tags {
    const tag = this.tags.find((tag) => tag.id === id)
    if (tag) {
      const tags = _.filter(this.tags, (tag) => tag.id !== id)
      return this.copy({ tags: _.concat([tag], tags) })
    }
    return this
  }

  deletedTags = (): Tags => {
    return this.copy({ tags: _.filter(this.tags, (tag) => tag.isHidden) })
  }
}
