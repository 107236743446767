import { apiPath } from '../ApiPath'
import BaseApi from './BaseApi'
import { trackEvent } from './GoogleAnalytics'

export type SupportedApp = {
  configName: string
  name: string
  desc: string
  displayName: string
  logo: string
  url: string
  logoUrl: string
  lightLogoUrl: string
  frontendRedirect: boolean
  serviceAccessible: boolean
}

export type ZendeskMemberShipDataDTO = {
  organization: {
    id: number
    phoneNumber: string
  }
  user: {
    id: number
    phoneNumber: string
  }
}

export class SsoClientApi extends BaseApi {
  private static instance: SsoClientApi
  apiPath: string

  private constructor(apiPath: string) {
    super()
    this.apiPath = apiPath
  }

  static getInstance(): SsoClientApi {
    if (!SsoClientApi.instance) {
      SsoClientApi.instance = new SsoClientApi(apiPath)
    }
    return SsoClientApi.instance
  }

  async getZendeskMembership(): Promise<ZendeskMemberShipDataDTO | undefined> {
    try {
      return await this.loadByGet(`${this.apiPath}/sso-client/membership`)
    } catch (error) {
      return undefined
    }
  }

  async getChatToken(): Promise<string | undefined> {
    try {
      return await this.loadByGet(`${this.apiPath}/sso-client/chat-token`)
    } catch (error) {
      return undefined
    }
  }

  async getSupportedApplications(): Promise<SupportedApp[] | undefined> {
    try {
      return await this.loadByGet(`${this.apiPath}/sso-client/supported-apps`)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetSupportedApplications',
        label: `${this.mapError(error)}`
      })
      return undefined
    }
  }
}
