import {List, OrderedMap, Set} from 'immutable'
import _ from 'lodash'
import {compact} from '../utils/utils'
import { Annotation, AnnotationId, Annotations } from './Annotation'
import {ElementLocator} from './locator/ElementLocator'
import {Word} from './ocr/Word'
import {IWordAnnotation} from './types/ISmartDocsResult'

export class WordAnnotation extends Annotation {
  constructor(
    readonly pageId: string,
    readonly word: Word,
    readonly wordId: string,
    readonly tagId: string,
    readonly probability: number,
    readonly source: string
  ) {
    super(new AnnotationId(pageId, wordId), tagId, probability, source)
  }

  viewText(
    idToPageNumber: (id: AnnotationId) => number | undefined
  ): string {
    return this.word.text
  }

  tagAs(tagId: string, newSource: string): WordAnnotation {
    return this.copy({tagId: tagId, source: newSource})
  }

  copy({
    pageId = this.pageId,
    word = this.word,
    wordId = this.wordId,
    tagId = this.tagId,
    probability = this.probability,
    source = this.source
       }): WordAnnotation {
    return new WordAnnotation(
      pageId, word, wordId, tagId, probability, source
    )
  }

  static fromJson(annotation: IWordAnnotation, elementLocator: ElementLocator): WordAnnotation | undefined {
    const word = elementLocator.word(annotation.WordId)
    const page = elementLocator.pageIndexToNumber(word?.page)
    if (word && page) {
      return new WordAnnotation(
        page.id,
        word,
        annotation.WordId,
        annotation.Annotation,
        annotation.Probability,
        annotation.Source
      )
    } else {
      console.error(`${annotation.WordId} not able to found corespondent element, will ignore.`)
      return undefined
    }
  }
}

export class WordAnnotations extends Annotations {
  constructor(
    readonly annotations: List<WordAnnotation>
  ) {
    super()
  }

  annotationsForViewer(): List<Annotation> {
    return this.annotations
  }

  find(wordId: string): WordAnnotation | undefined {
    return this.annotations.find(word => word.wordId === wordId)
  }

  addOrUpdateWordsTag(wordIds: string[], newTagId: string, newSource: string, elementLocator: ElementLocator): WordAnnotations {
    const idSet = Set(wordIds)
    const allExistsAnnotations = this.annotations.map(annotation => {
      if (idSet.has(annotation.wordId)) {
        return annotation.tagAs(newTagId, newSource)
      } else {
        return annotation
      }
    })
    const newWordIds = wordIds.filter(wordId => this.find(wordId) === undefined)
    const newAnnotations = newWordIds.map(wordId => {
      const word = elementLocator.word(wordId)
      const page = elementLocator.pageIndexToNumber(word?.page)
      if (word && page) {
        return new WordAnnotation(page.id, word, wordId, newTagId, 1.0, newSource)
      } else {
        return undefined
      }
    })
    return this.copy({
      annotations: allExistsAnnotations.concat(compact(List(newAnnotations)))
    })
  }

  removeWordTag(wordIds: string[]): WordAnnotations {
    const idSet = Set(wordIds)
    const removed = this.annotations.filter(annotation => !idSet.has(annotation.wordId))
    return this.copy({annotations: removed})
  }

  copy({
    annotations = this.annotations
       }): WordAnnotations {
    return new WordAnnotations(annotations)
  }

  toJson(): IWordAnnotation[] {
    return this.annotations.map(annotation => {
      return {
        WordId: annotation.wordId,
        Annotation: annotation.tagId,
        Probability: annotation.probability,
        Source: annotation.source
      }
    }).toArray()
  }

  static fromJson(elementLocator: ElementLocator, annotations?: IWordAnnotation[]): WordAnnotations {
    if (annotations) {
      const withUndefined = annotations.map(annotation => WordAnnotation.fromJson(annotation, elementLocator))
      return new WordAnnotations(
        List(_.compact(withUndefined))
      )
    } else {
      return new WordAnnotations(List())
    }
  }
}
