import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { List } from 'immutable'
import * as _ from 'lodash'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import {IBankAuthorityAccount} from '../../types/ISmartDocsResult'
import { DetectedField, StringField } from '../DetectedField'
import ModelObject from '../ModelObject'

export type BankAuthorityAccountColumn =
    FieldAndColumnName.BankAuthorityAccounts_AccountName
    | FieldAndColumnName.BankAuthorityAccounts_AccountNumber
    | FieldAndColumnName.BankAuthorityAccounts_Bsb

export class BankAuthorityAccount extends ModelObject {
    constructor(
        readonly accountName?: StringField,
        readonly accountNumber?: StringField,
        readonly bsb?: StringField
    ) {
        super()
    }

    addColumn(
        gridColumnName: FieldAndColumnName,
        newValue: FormGridRowValue,
        modifiedBy: string
    ): BankAuthorityAccount {
        switch(gridColumnName) {
            case FieldAndColumnName.BankAuthorityAccounts_AccountName:
                return this.copy({ accountName: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})
            case FieldAndColumnName.BankAuthorityAccounts_AccountNumber:
                return this.copy({ accountNumber: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})
            case FieldAndColumnName.BankAuthorityAccounts_Bsb:
                return this.copy({ bsb: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})
            default:
                return this
        }
    }

    fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
        switch (column) {
            case FieldAndColumnName.BankAuthorityAccounts_AccountName:
                return this.accountName
            case FieldAndColumnName.BankAuthorityAccounts_AccountNumber:
                return this.accountNumber
            case FieldAndColumnName.BankAuthorityAccounts_Bsb:
                return this.bsb
            default:
                return undefined
        }
    }

    listFields(): List<DetectedField> {
        return DetectedField.detectedFieldFromObject(this)
    }

    // toGridRow(id: number) {
    //     return {
    //         id,
    //         [FieldAndColumnName.BankAuthorityAccounts_AccountName]: this.accountName?.parsedValue,
    //         [FieldAndColumnName.BankAuthorityAccounts_AccountNumber]: this.accountNumber?.parsedValue,
    //         [FieldAndColumnName.BankAuthorityAccounts_Bsb]: this.bsb?.parsedValue
    //     }
    // }

    copy({
             accountName = this.accountName,
             accountNumber = this.accountNumber,
             bsb = this.bsb
         }): BankAuthorityAccount {
        return new BankAuthorityAccount(accountName, accountNumber, bsb)
    }

    blockIds(): List<string> {
        return List(_.compact([this.accountName?.id, this.accountNumber?.id, this.bsb?.id]))
    }

    toJson(): IBankAuthorityAccount {
        return {
            AccountName: this.accountName?.toModelKeyValue(),
            AccountNumber: this.accountNumber?.toModelKeyValue(),
            Bsb: this.bsb?.toModelKeyValue()
        }
    }
}

