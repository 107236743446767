import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { useDashboardStyles } from '../assets/styles/DashboardStyles'
import Copyright from '../components/others/Copyright'
import TopUpBanner from '../components/others/TopUpBannner'
import PaymentDialog from '../components/payment/PaymentDialog'
import {
  getSupportedApplications,
  useBusinessStore
} from '../services/stores/BusinessStore'
import { getProject } from '../services/stores/ProjectStore'
import AlertDialogProvider from '../utils/contexts/AlertDialogContext'
import { ConfirmationDialogProvider } from '../utils/contexts/ConfirmationDialogContext'
import ErrorPage from './ErrorPage'
import Header from './Header'
import MainDrawer from './MainDrawer'

export enum PageError {
  BusinessError = 'Having trouble to load your business',
  ProjectError = 'Having trouble to load your project'
}

const Layout = () => {
  const [error, setError] = useState<PageError>()
  const [openMenu, setOpenMenu] = useState(window.innerWidth >= 1200)

  const getBusiness = useBusinessStore((state) => state.getBusiness)

  const classes = useDashboardStyles()
  const { businessId, projectId } = useParams()

  useEffect(() => {
    if (businessId) {
      getBusiness(businessId).catch(() => {
        setError(PageError.BusinessError)
      })
      getSupportedApplications()
    }
    if (projectId) {
      getProject(projectId).catch(() => {
        setError(PageError.ProjectError)
      })
    }
  }, [businessId, getBusiness, getProject, projectId])

  if (error) return <ErrorPage message={error} />

  return (
    <div style={{ display: 'flex', overflowX: 'auto' }}>
      <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <MainDrawer openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <ConfirmationDialogProvider>
        <AlertDialogProvider>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: openMenu
            })}
          >
            <div className={classes.drawerHeader} />
            <div className={classes.activeMenuViewer}>
              <Outlet />
            </div>
            <Copyright />
          </main>
        </AlertDialogProvider>
      </ConfirmationDialogProvider>
      {businessId && <PaymentDialog businessId={businessId} />}
      {/* Disable top up banner as discussed */}
      {/*{businessId && <TopUpBanner businessId={businessId} />}*/}
    </div>
  )
}

export default Layout
