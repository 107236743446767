import { List } from 'immutable'
import _ from 'lodash'
import { OcrDocument } from '../../OcrDocument'
import {
  IChartOfAccounts360Model, IChartOfAccounts360Page
} from '../../types/ISmartDocsResult'
import { BusinessModelMapper } from '../BusinessModelMapper'
import { ChartOfAccounts360 } from './ChartOfAccounts360'
import { ChartOfAccounts360PageTable, ChartOfAccounts360PageTableRow } from './ChartOfAccounts360PageTable'

export class ChartOfAccounts360Mapper extends BusinessModelMapper {
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IChartOfAccounts360Model
  ) {
    super(document)
  }

  fromJson(): ChartOfAccounts360 {
    const chartOfAccounts360Model = this.jsonModel
    const chartOfAccounts360PageTable = this.mapTable(chartOfAccounts360Model.Pages)
    return new ChartOfAccounts360(
      chartOfAccounts360Model.Version,
      chartOfAccounts360Model.Code,
      chartOfAccounts360Model.Name,
      chartOfAccounts360Model.Probability,
      chartOfAccounts360PageTable
    )
  }

  mapPageRow(page: IChartOfAccounts360Page): ChartOfAccounts360PageTableRow | undefined {
    return new ChartOfAccounts360PageTableRow(
      page.Id,
      page.Code,
      page.Name,
      page.Probability
    )
  }

  mapTable(pages: IChartOfAccounts360Page[]): ChartOfAccounts360PageTable {
    const rows = _.compact((pages || []).map(this.mapPageRow))
    return new ChartOfAccounts360PageTable(
      List(rows)
    )
  }
}
