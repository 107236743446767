import { FieldAndColumnName } from '@bgl/textract-business-model-editor'
import { ChartOfAccounts360PageTableRow } from '@bgl/textract-business-model-editor/dist/domain/businessModel/ChartOfAccounts360/ChartOfAccounts360PageTable'
import type { GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'

export class ChartOfAccounts360PageToTableData {
  static toRows(rows: List<ChartOfAccounts360PageTableRow>): GridRowsProp {
    return rows
      .map((row, index) =>
        ChartOfAccounts360PageToTableData.toGridRow(row, index + 1)
      )
      .toArray()
  }

  static toGridRow = (row: ChartOfAccounts360PageTableRow, id: number) => {
    return {
      id,
      [FieldAndColumnName.ChartOfAccounts360PagesEditor_Code]: row.code,
      [FieldAndColumnName.ChartOfAccounts360PagesEditor_Name]: row.name,
      [FieldAndColumnName.ChartOfAccounts360PagesEditor_Probability]:
        row.probability
    }
  }
}
