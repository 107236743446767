import {IBusinessModel, IModelType} from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, RentalField, StringField} from '../DetectedField'
import {RentalSummaryMapper} from './RentalSummaryMapper'


export class RentalSummary extends BusinessModel {
    constructor(
        modelType: IModelType,
        version: string,
        readonly startDate?: DateField,
        readonly endDate?: DateField,
        readonly address?: StringField,
        readonly IncomeSummary?: RentalField[],
        readonly PaymentToOwner?: RentalField[],
        readonly ExpenseAgentsManagementFees?: RentalField[],
        readonly ExpenseAgentsCommissions?: RentalField[],
        readonly ExpenseElectricityAndGas?: RentalField[],
        readonly ExpenseWaterRates?: RentalField[],
        readonly ExpenseInsurancePremium?: RentalField[],
        readonly ExpenseGardenAndLawn?: RentalField[],
        readonly ExpenseStrataLevyFees?: RentalField[],
        readonly ExpenseAdvertising?: RentalField[],
        readonly ExpenseStationeryPhoneAndPostage?: RentalField[],
        readonly ExpensePestControl?: RentalField[],
        readonly ExpenseCleaning?: RentalField[],
        readonly ExpenseCouncilRate?: RentalField[],
        readonly ExpenseLandTax?: RentalField[],
        readonly ExpenseLegalFees?: RentalField[],
        readonly ExpenseSundry?: RentalField[],
        readonly ExpenseNonSpecified?: RentalField[],
        readonly ExpenseRepairsMaintenance?: RentalField[],
        readonly ExpensePropertyCapitalImprovement?: RentalField[],
        readonly ExpenseGST?: RentalField[],
        readonly paymentStartDate?: DateField,
        readonly paymentEndDate?: DateField
    ) {
        super(modelType, version)
    }

    toJson(): IBusinessModel {
        return RentalSummaryMapper.toJson(this)
    }
}
