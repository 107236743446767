import { FormGridRowValue } from 'utils/DataTypeMapper'
import { Transaction } from './businessModel/bankstatement/Transaction'
import { BusinessModels } from './businessModel/BusinessModels'
import { DetectedField } from './businessModel/DetectedField'
import { ModifiedWordTexts } from './ModifiedWordText'
import { IRectObjectCoordinate, ObjectAnnotationsOnFile } from './ObjectAnnotation'
import { OcrDocument } from './OcrDocument'
import { TaggingPages } from './TaggingPage'
import { ISmartDocsResult } from './types/ISmartDocsResult'
import { FieldAndColumnName } from './validator/FieldValidatorDef'
import { WordAnnotations } from './WordAnnotation'

export class SmartDocResult {
  constructor(
    private readonly json: ISmartDocsResult,
    readonly document: OcrDocument,
    readonly models: BusinessModels,
    readonly wordAnnotations: WordAnnotations,
    readonly modifiedTexts: ModifiedWordTexts,
    readonly taggingPages: TaggingPages,
    readonly objectAnnotations: ObjectAnnotationsOnFile
  ) {
  }

  copy({
    document = this.document,
    models = this.models,
    wordAnnotations = this.wordAnnotations,
    modifiedTexts = this.modifiedTexts,
    taggingPages = this.taggingPages,
    objectAnnotations = this.objectAnnotations
       }): SmartDocResult {
    return new SmartDocResult(
      this.json, document, models, wordAnnotations, modifiedTexts, taggingPages, objectAnnotations)
  }

  updateField(id: FieldAndColumnName, field: DetectedField | undefined, value: FormGridRowValue, modifiedBy: string, index: number = 0 ): SmartDocResult {
    const models = this.models.businessModels.map((businessModel, key) =>
        key === index ? businessModel.update(id, field, value, modifiedBy) : businessModel)
    return this.copy({
      models: new BusinessModels(models)
    })
  }

  updateGrid(gridColumnName: FieldAndColumnName, gridFieldId: string | number, newValue: FormGridRowValue, modifiedBy: string): SmartDocResult {
    const models = this.models.businessModels.map((businessModel) =>
      businessModel.updateGrid(gridColumnName, gridFieldId, newValue, modifiedBy))
    return this.copy({
      models: new BusinessModels(models)
    })
  }

  // Multi-models supported in one result file now, adding index to indicate only modify wanted one 
  updateTransactionList(modifiedBy: string, existingValue?: Transaction, newValue?: Transaction, index: number = 0): SmartDocResult {
    const models = this.models.businessModels.map((businessModel, key) =>
      key === index ? businessModel.updateTransactionList(modifiedBy, existingValue, newValue) : businessModel)
    return this.copy({
      models: new BusinessModels(models)
    })
  }

  addOrUpdateWordsTag(wordIds: string[], newTagId: string, newSource: string): SmartDocResult {
    return this.copy({
      wordAnnotations: this.wordAnnotations.addOrUpdateWordsTag(wordIds, newTagId, newSource, this.document.elementLocator)
    })
  }

  removeWordTag(wordIds: string[]): SmartDocResult {
    return this.copy({
      wordAnnotations: this.wordAnnotations.removeWordTag(wordIds)
    })
  }

  addOrUpdateWordText(wordId: string, newText: string, newSource: string): SmartDocResult {
    return this.copy({
      modifiedTexts: this.modifiedTexts.addOrUpdateText(wordId, newText, newSource)
    })
  }

  addOrUpdatePageTags(pageId: string, tagIds: Set<string>, userEmail: string): SmartDocResult {
    return this.copy({
      taggingPages: this.taggingPages.setTags(new Set([pageId]), tagIds, userEmail)
    })
  }

  removePageTags(pageId: string): SmartDocResult {
    return this.copy({
      taggingPages: this.taggingPages.removeTags(pageId)
    })
  }

  addTagToPage(pageIds: Set<string>, tagId: string, userEmail: string): SmartDocResult {
    return this.copy({
      taggingPages: this.taggingPages.addTag(pageIds, tagId, userEmail)
    })
  }

  removeTagFromPage(pageIds: Set<string>, tagId: string): SmartDocResult {
    return this.copy({
      taggingPages: this.taggingPages.removeTag(pageIds, tagId)
    })
  }

  addOrUpdateObjectAnnotationTag(pageId: string, objectId: string, tagId: string, objectAnnotationCoor: IRectObjectCoordinate, source: string ): SmartDocResult {
    const newObj = this.copy({
      objectAnnotations: this.objectAnnotations.addOrUpdateObjectAnnotation(pageId, objectId, tagId, objectAnnotationCoor, source)
    })
    return newObj
  }

  removeObjectAnnotation(pageId: string, objectId: string): SmartDocResult {
    return this.copy({
      objectAnnotations: this.objectAnnotations.removeObjectAnnotation(pageId, objectId)
    })
  }

  toJson(): ISmartDocsResult {
    return {
      // backend complaint on Document existing
      // Document: this.json.Document,
      WordAnnotations: this.wordAnnotations.toJson(),
      WordTexts: this.modifiedTexts.toJson(),
      PageAnnotations: this.taggingPages.toJson(),
      ObjectAnnotations: this.objectAnnotations.toJson(),
      BusinessModels: this.models.toJson()
    }
  }

  static fromJson(json: ISmartDocsResult): SmartDocResult {
    if (!json.Document) {
      throw Error('Document required to generate SmartDocResult.')
    }
    const doc = OcrDocument.fromJson(json.Document)
    const models = BusinessModels.fromJson(doc, json.BusinessModels)
    const wordAnnotations = WordAnnotations.fromJson(doc.elementLocator, json.WordAnnotations)
    const modifiedTexts = ModifiedWordTexts.fromJson(json.WordTexts)
    const taggingPages = TaggingPages.fromJson(doc.pages, json.PageAnnotations)
    const objectAnnotations = ObjectAnnotationsOnFile.fromJson(doc.pages, json.ObjectAnnotations)
    return new SmartDocResult(
      json, doc, models, wordAnnotations, modifiedTexts, taggingPages, objectAnnotations
    )
  }
}
