import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import React from 'react'

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}))

interface PlaceType {
  description: string
  place_id: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

interface IGooglePlaceProps {
  handleUpdatePlaceWithGoogleApi: (
    address: string,
    postcode: string,
    country: string
  ) => void
  error: boolean | undefined
  helperText: string | undefined
}

export default function GooglePlaces({
  handleUpdatePlaceWithGoogleApi,
  error,
  helperText
}: IGooglePlaceProps) {
  const classes = useStyles()
  const [value, setValue] = React.useState<PlaceType | string | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<(PlaceType | string)[]>([])
  const loaded = React.useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDUggK7wEALAUmhjHxvjlAGpS1rQH34xhc&libraries=places',
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) =>
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          ),
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as any[] // Change type to any[] to include strings

        // Add the custom input as an option
        if (inputValue) {
          newOptions.push(`${inputValue}`)
        }

        if (value && typeof value !== 'string') {
          newOptions.push(value)
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const getPlaceDetails = (
    placeId: string,
    callback: (details: google.maps.places.PlaceResult | null) => void
  ) => {
    const service = new google.maps.places.PlacesService(
      document.createElement('div')
    )
    service.getDetails(
      { placeId: placeId },
      (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          callback(place)
        }
      }
    )
  }

  return (
    <Autocomplete
      id="google-address-autocomplete"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      fullWidth
      size={'small'}
      freeSolo
      value={value}
      clearOnBlur
      onChange={(event: any, newValue: any) => {
        if (typeof newValue === 'string') {
          // Custom address entered
          handleUpdatePlaceWithGoogleApi(newValue, '', '')
          setValue(newValue)
        } else {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          if (newValue) {
            getPlaceDetails(
              newValue.place_id,
              (details: google.maps.places.PlaceResult | null) => {
                if (details) {
                  const address = details.formatted_address
                  const postcode = details.address_components?.find(
                    (component) => component.types.includes('postal_code')
                  )?.long_name
                  const country = details.address_components?.find(
                    (component) => component.types.includes('country')
                  )?.long_name
                  handleUpdatePlaceWithGoogleApi(
                    address || '',
                    postcode || '',
                    country || ''
                  )
                }
              }
            )
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Address"
          variant="outlined"
          fullWidth
          required
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(option: any) => {
        if (typeof option === 'string') {
          // Render the custom hint
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {option}
              </Grid>
            </Grid>
          )
        }
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length
          ])
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
