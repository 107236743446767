import { Container, Paper } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { styled, withStyles } from '@material-ui/styles'

export const FlexColPaper = withStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4)
    }
  })
)(Paper)

export const SmContainer = withStyles((theme) =>
  createStyles({
    root: {
      maxWidth: theme.breakpoints.values.sm,
      marginTop: theme.spacing(3)
    }
  })
)(Container)

export const NoWrap = styled('div')(() => ({
  maxWidth: 300,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}))

export const FullHeightFlexColPaper = withStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
      height: '100%'
    }
  })
)(Paper)
